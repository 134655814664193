import { useStyles } from '@hooks/useStyles'
import { useModalContext } from '@ui-kit/Modal/context'
import { Spacer } from '@ui-kit/Spacer'
import { FC, Fragment, HTMLAttributes, ReactNode } from 'react'

import styles from './ModalBody.module.scss'

export interface ModalBodyProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  image?: ReactNode
  children?: ReactNode
  className?: string | string[]
  'data-testid'?: string
}

export const ModalBody: FC<ModalBodyProps> = ({
  image,
  children,
  className = '',
  'data-testid': dataTestId = 'modal-body',
  ...restProps
}) => {
  const { isCentered, isFullscreen } = useModalContext()

  const modalBodyStyles = useStyles(
    {
      [styles.modalBody]: true,
      [styles['modalBody--centered']]: isCentered,
      [styles['modalBody--fullscreen']]: isFullscreen
    },
    className
  )

  return (
    <Fragment>
      {image && (
        <div className={styles.modalImage}>
          <Spacer space={20} />
          {image}
        </div>
      )}
      <div {...restProps} className={modalBodyStyles} data-testid={dataTestId}>
        {children ? <div className={styles.body}>{children}</div> : null}
      </div>
    </Fragment>
  )
}

ModalBody.displayName = 'ModalBody'
