import { useStyles } from '@hooks/useStyles'
import { FC, ReactNode } from 'react'

import styles from './Layout.module.scss'

export interface FullBleedProps {
  className?: string | string[]
  children?: ReactNode
  'data-testid'?: string
}

/* when used with Layout component causes element to expand from left to right for full container width */
export const FullBleed: FC<FullBleedProps> = ({
  className = '',
  children,
  'data-testid': dataTestId = 'full-bleed'
}) => {
  const elementClasses = useStyles(
    {
      [styles['full-bleed']]: true
    },
    className
  )

  return (
    <div data-testid={dataTestId} className={elementClasses}>
      {children}
    </div>
  )
}

FullBleed.displayName = 'FullBleed'
