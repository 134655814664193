import { MODAL_PRICING_UPDATE } from '@components/Modals/ModalPricingUpdate'
import { CLICK_PRICING_INFO } from '@constants/mixpanel'
import NiceModal from '@ebay/nice-modal-react'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { useLazyService } from '@hooks/useLazyService'
import { mixpanelEvent } from '@services/mixpanel'
import { Alert, Button, Paragraph, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

export const PricingUpdateAlert = () => {
  const { data, isLoading } = useSWR('payments/pricing/info')
  const hasAgreement = data?.has_agreement

  const { t } = useTranslation()

  const [sendMixpanelEvent] = useLazyService(mixpanelEvent)

  const isVisible =
    useBreakpoint('breakpoint-biggerLaptop', 'min') &&
    !isLoading &&
    !hasAgreement

  return (
    isVisible && (
      <Alert
        variant='info'
        additionalContent={
          <Fragment>
            <Spreader spread={15} />
            <Button
              variant='text-primary'
              size={12}
              onClick={() => {
                NiceModal.show(MODAL_PRICING_UPDATE)

                sendMixpanelEvent({
                  name: CLICK_PRICING_INFO
                })
              }}
            >
              {t('pricing.update.alert.learn.more')}
            </Button>
          </Fragment>
        }
      >
        <Paragraph weight={600} size={12}>
          {t('pricing.update.alert.description')}
        </Paragraph>
      </Alert>
    )
  )
}
