import EventTrackerRegistrationImage from '@images/registration/eventTracker/landingi-et-promo-oct.svg'
import {
  Badge,
  Button,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import ShapesContainer from '@pages/Authentication/components/SecondColumn/ShapesContainer'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { motion } from 'framer-motion'
import { Fragment, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './RefreshedColumnContent.module.scss'

const EventTrackerLogin = () => {
  const { secondColumnControls } = useAnimationContext()
  const { t } = useTranslation()

  const openLearnMoreLink = () =>
    window.open(t('event.tracker.learn.more.link'), '_blank')

  return (
    <Fragment>
      <ShapesContainer />
      <motion.div
        className={styles['column-content']}
        animate={secondColumnControls}
      >
        <div>
          <Badge type='ai'>
            <Icon icon='icon-star' color='white' /> {t('new.feature')}
          </Badge>
        </div>
        <Heading level={1} margin='none' bold>
          <Trans i18nKey='registration.flow.event.tracker.title' />
        </Heading>

        <Heading level={2} margin='none'>
          <Trans i18nKey='registration.flow.event.tracker.subtitle' />
        </Heading>

        <Image src={EventTrackerRegistrationImage} />

        <List>
          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.event.tracker.1st.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space='mini' />

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.event.tracker.2nd.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space='mini' />

          <Spacer space='tiny' />

          <ListItem>
            <Row>
              <Icon
                icon='icon-check-circle'
                color='primary'
                className={styles['refreshed-registration-list-icon']}
              />

              <Spreader spread='small' />

              <Paragraph size={16} line={24} padding='none'>
                <Trans i18nKey='registration.flow.event.tracker.3rd.paragraph' />
              </Paragraph>
            </Row>
          </ListItem>
        </List>

        <Spacer space='small' />

        <Paragraph size={16} line={24} padding='none' weight={700}>
          <Trans i18nKey='registration.flow.event.tracker.get.for.free' />
        </Paragraph>

        <Spacer />

        <div>
          <Button tag='a' buttonStyle onClick={openLearnMoreLink}>
            {t('learn.more')}
          </Button>
        </div>

        <Spacer />
      </motion.div>
    </Fragment>
  )
}

EventTrackerLogin.displayName = 'EventTrackerLogin'

export default memo(EventTrackerLogin)
