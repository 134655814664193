import { Spacer } from '@landingi/landingi-ui-kit'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import BillingInformation from '@pages/Authentication/routes/CreditCardStep/components/BillingInformation'
import PaymentMethod from '@pages/Authentication/routes/CreditCardStep/components/PaymentMethod'
import SubscriptionSummaryPanel from '@pages/Authentication/routes/CreditCardStep/components/SubscriptionSummaryPanel'

/**
 * CreditCardStep form - stateful presentational component
 * @param {object} props - props
 * @return {object} An object of children
 */
const CreditCardStepForm = () => {
  const {
    formik: { handleSubmit },
    isLoaded
  } = useCreditCardStepContext()

  return isLoaded ? (
    <form onSubmit={handleSubmit} className='credit-card-step-form'>
      <BillingInformation />

      <Spacer />

      <PaymentMethod />

      <Spacer />

      <SubscriptionSummaryPanel />
    </form>
  ) : null
}

CreditCardStepForm.displayName = 'CreditCardStepForm'

export default CreditCardStepForm
