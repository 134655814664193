import { Icon } from '@ui-kit/Icon'
import { Row } from 'simple-flexbox'

import styles from './Select.module.scss'
import { SelectOption } from './types'

export const Trigger = <T extends number | string | undefined>({
  isOpen,
  isDisabled,
  placeholder,
  selectedItem
}: {
  isOpen: boolean
  isDisabled: boolean
  placeholder?: string
  selectedItem?: SelectOption<T>
}) => (
  <button
    type='button'
    className={styles['select-trigger__button']}
    disabled={isDisabled}
  >
    <Row
      className={styles['select-trigger__button-content']}
      justifyContent='space-between'
    >
      {!selectedItem && placeholder && (
        <span className={styles['select-trigger__placeholder']}>
          {placeholder}
        </span>
      )}

      {selectedItem && (
        <span className={styles['select-trigger__selected-item']}>
          {selectedItem.labelDisplayedWhenSelected || selectedItem.label}
        </span>
      )}

      <Icon
        icon={isOpen ? 'icon-caret-up' : 'icon-caret-down'}
        color='neutral'
      />
    </Row>
  </button>
)
