import { newLandingiClient } from '@lib/http/client'
import useSWR from 'swr'

interface GetCSRFTokenResponse {
  token: string
}

const getCSRFToken = async (url: string) => {
  const response = await newLandingiClient.get<GetCSRFTokenResponse>(url)

  return response.data
}

export const useGetCSRFToken = () =>
  useSWR<GetCSRFTokenResponse>('/csrf', getCSRFToken)
