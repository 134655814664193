import {
  Button,
  InputForm,
  ShowPassword,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import loginSchema from '@pages/Authentication/routes/Login/FormSchema'
import { WEB_TO_LEARN_COLOR } from '@pages/Authentication/routes/WhitelabelPages/constants'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const LoginForm = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { signIn } = useWhitelabelPageContext()
  const { t } = useTranslation()

  const initialValues = {
    email: '',
    password: ''
  }

  const onSubmit = useCallback(
    async values => {
      const { email, password } = values

      await signIn(email, password, 'webtolearn')
    },
    [executeRecaptcha]
  )

  const {
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    values,
    errors,
    touched,
    handleChange,
    handleBlur
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema: loginSchema()
  })

  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )
  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <InputForm
          field={{
            name: 'email',
            value: values.email,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          i18n={{
            label: t('registration.flow.email')
          }}
          alwaysShowLabel
          form={{
            errors,
            touched
          }}
          id='email'
          allowHotjar
        />

        <Spacer space='small' />

        <Row>
          <Column flexGrow='1'>
            <InputForm
              field={{
                name: 'password',
                value: values.password,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              i18n={{
                label: t('registration.flow.password')
              }}
              alwaysShowLabel
              form={{
                errors,
                touched
              }}
              id='password'
              type={passwordInputType}
            />
          </Column>

          <Spreader spread='tiny' />

          <Column>
            <ShowPassword setHidden={handleInputTypeState} />
          </Column>
        </Row>

        <Button
          type='submit'
          size='large'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          customEnabledBackgroundColor={WEB_TO_LEARN_COLOR}
        >
          {t('registration.flow.sign.in')}
        </Button>
      </Column>
    </form>
  )
}

LoginForm.displayName = 'WhitelabelLoginForm'

export default LoginForm
