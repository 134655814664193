import { CustomLink } from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { LANDINGS } from '@routes/path'
import { PublicationType } from '@services/landings/list/types'
import { Button, Paragraph, Spreader, Tooltip } from '@ui-kit'
import { FC, Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import style from './UrlCell.module.scss'

interface UrlCellProps {
  url: string
  isFake: boolean
  publicationType: PublicationType
}

export const UrlCell: FC<UrlCellProps> = ({ url, isFake, publicationType }) => {
  const {
    landingPageUrls: { handleRefreshExternalUrls },
    publishingOptions: {
      data: { isLandingPagePublished }
    },
    uuid
  } = usePublishingOptionsContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleOpenArticle = (path: string) => {
    navigate(
      generatePath(path, {
        identifier: uuid
      })
    )
  }

  if (isFake && publicationType === TYPES.WORDPRESS) {
    return (
      <Row
        alignItems='center'
        justifyContent='space-between'
        className={style.urlBox}
      >
        <Paragraph color='neutral-6' size={12}>
          {t('publishing.options.table.wordpress.refresh.info')}
        </Paragraph>

        <Fragment>
          <Tooltip
            content={
              <Trans i18nKey='publishing.options.article.button.tooltip' />
            }
          >
            <Button
              variant='text-neutral'
              icon='icon-article'
              size={12}
              onClick={() =>
                handleOpenArticle(
                  LANDINGS.LANDINGS_PUBLISHING_OPTIONS.WORDPRESS
                )
              }
              className={style.buttonMargin}
            />
          </Tooltip>

          <Tooltip
            content={<Trans i18nKey='publishing.options.sync.button.tooltip' />}
          >
            <Button
              variant='text-neutral'
              icon='icon-sync'
              size={12}
              onClick={handleRefreshExternalUrls}
              className={style.buttonMargin}
            />
          </Tooltip>
        </Fragment>
      </Row>
    )
  }

  if (isFake && publicationType === TYPES.EMBED) {
    return (
      <Row
        alignItems='center'
        justifyContent='space-between'
        className={style.urlBox}
      >
        <Paragraph color='neutral-6' size={12}>
          {t('publishing.options.table.embed.refresh.info')}
        </Paragraph>

        <Spreader spread={20} />

        <Fragment>
          <Tooltip
            content={
              <Trans i18nKey='publishing.options.article.button.tooltip' />
            }
          >
            <Button
              variant='text-neutral'
              icon='icon-article'
              size={12}
              onClick={() =>
                handleOpenArticle(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.EMBED)
              }
              className={style.buttonMargin}
            />
          </Tooltip>

          <Tooltip
            content={<Trans i18nKey='publishing.options.sync.button.tooltip' />}
          >
            <Button
              variant='text-neutral'
              icon='icon-sync'
              size={12}
              onClick={handleRefreshExternalUrls}
              className={style.buttonMargin}
            />
          </Tooltip>
        </Fragment>
      </Row>
    )
  }

  return (
    <CustomLink
      href={url}
      label={url}
      target='_blank'
      variant={isLandingPagePublished ? 'active' : 'inactive'}
    />
  )
}

UrlCell.displayName = 'PublishingOptionsViewsMainTableUrlCell'
