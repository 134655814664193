export const INDIVIDUAL = 'individual'
export const COMPANY = 'company'

export const AGENCY = 'AGENCY'
export const CLIENT = 'CLIENT'
export const NORMAL = 'NORMAL'

export const OWNER = 'OWNER'
export const ADMIN = 'ADMIN'
export const MANAGER = 'MANAGER'
export const USER = 'USER'
export const DESIGNER = 'DESIGNER'
export const READONLY = 'READONLY'
export const ACCOUNTANT = 'ACCOUNTANT'
