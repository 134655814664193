const ADDONS_STORE = {
  ADDONS_STORE: '/addons',
  ADDON: '/addons/:addon',
  EVENT_TRACKER_ADDON: '/addons/eventtracker',
  IMPORT_FROM_FIGMA_ADDON: '/addons/import-from-figma',
  OPTIMIZATION_ADDON: '/addons/ab-tests',
  ECOMMERCE_ADDON: '/addons/e-commerce',
  POPUPS_ADDON: '/addons/popups',
  SMART_SECTIONS: '/addons/smart-sections',
  AGENCY_ADDON: '/addons/agency',
  LANDING_PAGE_IMPORT: '/addons/landing-page-import',
  LANDING_PAGE_DESIGN: '/addons/landing-page-design',
  PROGRAMMATIC_ADDON: '/addons/programmatic-lp'
} as const

export default ADDONS_STORE

export type ADDONS_STORE_TYPE = (typeof ADDONS_STORE)[keyof typeof ADDONS_STORE]
