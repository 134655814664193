import { useGetFeatureFlags } from '@services/features'
import { createContext, FC, ReactNode, useContext } from 'react'

const FeatureFlagsContext = createContext<ReturnType<
  typeof useGetFeatureFlags
> | null>(null)

type ProviderProps = {
  children: ReactNode
}

export const FeatureFlagsContextProvider: FC<ProviderProps> = ({
  children
}) => {
  const value = useGetFeatureFlags()

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export const useFeatureFlagsContext = () => {
  const value = useContext(FeatureFlagsContext)

  if (!value) {
    throw new Error(
      'useFeatureFlagsContext must be used inside FeatureFlagsContextProvider'
    )
  }

  return value
}

export const useFeatureFlags = (ff: string) => {
  const value = useContext(FeatureFlagsContext)

  if (!value) {
    throw new Error(
      'useFeatureFlagsContext must be used inside FeatureFlagsContextProvider'
    )
  }

  return value.checkAccessToFeature(ff)
}
