import { Icon } from '@ui-kit'
import { Trans } from 'react-i18next'

export const getFeatures = () => [
  {
    title: <Trans i18nKey='addons.store.popups.features.1.title' />,
    description: <Trans i18nKey='addons.store.popups.features.1.description' />,
    icon: <Icon icon='icon-hand-up' color='primary' size={26} />
  },
  {
    title: <Trans i18nKey='addons.store.popups.features.2.title' />,
    description: <Trans i18nKey='addons.store.popups.features.2.description' />,
    icon: <Icon icon='icon-time' color='primary' size={26} />
  },
  {
    title: <Trans i18nKey='addons.store.popups.features.3.title' />,
    description: <Trans i18nKey='addons.store.popups.features.3.description' />,
    icon: <Icon icon='icon-dashboard' color='primary' size={26} />
  }
]
