export const STATUS = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  PAST_DUE: 'PastDue'
} as const

const { ACTIVE, CANCELED, PENDING, PAST_DUE } = STATUS

export type SubscriptionStatusType =
  | typeof ACTIVE
  | typeof CANCELED
  | typeof PENDING
  | typeof PAST_DUE
  | null
