import LPIUseCase1 from '@images/addons/usecases/landing-page-import/LPI-usecase-1.png'
import LPIUseCase2 from '@images/addons/usecases/landing-page-import/LPI-usecase-2.png'
import LPIUseCase3 from '@images/addons/usecases/landing-page-import/LPI-usecase-3.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: t('addons.store.landing.page.import.use.cases.1.title'),
    image: LPIUseCase1,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.landing.page.import.use.cases.1.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.landing.page.import.use.cases.1.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.landing.page.import.use.cases.2.title'),
    image: LPIUseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.landing.page.import.use.cases.2.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.landing.page.import.use.cases.2.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    ),
    reverse: true
  },
  {
    title: t('addons.store.landing.page.import.use.cases.3.title'),
    image: LPIUseCase3,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.landing.page.import.use.cases.3.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.landing.page.import.use.cases.3.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  }
]
