import { ANNUALLY, MONTHLY } from '@constants/billingPeriods'
import { CurrencyType, PLN } from '@constants/currencies'
import { useAccountPackageContext } from '@pages/Pricing/routes/AccountPackage/contexts'
import { Discount } from '@services/discounts'
import { Badge, Icon, Paragraph, Spreader, Tooltip } from '@ui-kit'
import { Price as PriceComponent } from '@ui-kit/Price'
import { PriceDescription } from '@ui-kit/Price/PriceDescription'
import { Spacer } from '@ui-kit/Spacer'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

export interface PriceProps {
  amount: number
  currency: CurrencyType
  canBePaidAnnually?: boolean
  annualSaving?: number | string
  specialOfferDiscount?: Discount | null
  isOneTimePayment?: boolean
  isOldPlan?: boolean
}

export const Price: FC<PriceProps> = ({
  amount,
  currency,
  canBePaidAnnually = true,
  annualSaving,
  specialOfferDiscount,
  isOneTimePayment,
  isOldPlan
}) => {
  const { t } = useTranslation()

  const { chosenBillingPeriod, accountCurrency } = useAccountPackageContext()

  return (
    <Column>
      <PriceComponent amount={amount} currency={currency}>
        <PriceDescription>
          {accountCurrency === PLN && t('change.plan.page.net')}

          <Spreader spread={5} />

          {isOneTimePayment
            ? t('change.plan.one.time.payment')
            : canBePaidAnnually && t('change.plan.page.per.month')}
        </PriceDescription>
      </PriceComponent>

      {chosenBillingPeriod === ANNUALLY &&
        canBePaidAnnually &&
        !isOneTimePayment && (
          <Fragment>
            <Row>
              <Tooltip
                content={t('change.plan.page.billed.once.annually.tooltip')}
              >
                <Paragraph size={14} color='neutral-6'>
                  {t('change.plan.page.billed.once.annually')}

                  <Spreader spread={5} />

                  <Icon icon='icon-help_outline' color='neutral-4' />
                </Paragraph>
              </Tooltip>
            </Row>

            <Spacer space={10} />

            {annualSaving && !specialOfferDiscount && (
              <div>
                <Badge variant='warning-filled' weight={600}>
                  {t('change.plan.page.save')} {annualSaving} {accountCurrency}
                </Badge>
              </div>
            )}
          </Fragment>
        )}

      {isOneTimePayment && chosenBillingPeriod === ANNUALLY && !isOldPlan && (
        <Spacer space={30} />
      )}

      {specialOfferDiscount ? (
        <div>
          <Spacer space={20} />

          <Badge variant='info-filled' weight={600} isTextUppercase={false}>
            {t('change.plan.page.special.discount')}{' '}
            {specialOfferDiscount.pricing} {accountCurrency}{' '}
            {accountCurrency === PLN &&
              t('registration.flow.credit.card.step.gross')}
          </Badge>

          <Spacer space={20} />

          {(!specialOfferDiscount || chosenBillingPeriod !== MONTHLY) && (
            <Spacer space={20} />
          )}
        </div>
      ) : null}
    </Column>
  )
}

Price.displayName = 'PanelPrice'
