import { useStyles } from '@hooks/useStyles'
import { FC, Fragment, ReactNode } from 'react'
import { Arrow, useHover, useLayer } from 'react-laag'

import styles from './Tooltip.module.scss'

interface TooltipProps {
  className?: string | string[]
  children: ReactNode
  content: ReactNode
  weight?: 400 | 600
  placement?: 'top' | 'left' | 'right' | 'bottom'
  disabled?: boolean
}

export const Tooltip: FC<TooltipProps> = ({
  className = '',
  children,
  content,
  weight = 400,
  placement = 'top',
  disabled = false
}) => {
  const [isHover, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 })

  const { triggerProps, layerProps, renderLayer, arrowProps } = useLayer({
    isOpen: isHover,
    placement: `${placement}-center`,
    triggerOffset: 12,
    onParentClose: () => null
  })

  const tooltipContentStyles = useStyles({
    [styles.tooltip__content]: true,
    [styles[`tooltip__content--weight-${weight}`]]: true
  })

  const tooltipTriggerStyles = useStyles(
    {
      [styles.tooltip__trigger]: true
    },
    className
  )

  return (
    <Fragment>
      {disabled && children}

      {!disabled && (
        <span
          data-testid='tooltip-trigger'
          className={tooltipTriggerStyles}
          {...triggerProps}
          {...hoverProps}
        >
          {children}
        </span>
      )}

      {isHover &&
        renderLayer(
          <div
            data-testid={`tooltip-content-${placement}`}
            className={tooltipContentStyles}
            role='tooltip'
            {...layerProps}
          >
            {content}
            <Arrow {...arrowProps} backgroundColor='#222' size={6} />
          </div>
        )}
    </Fragment>
  )
}

Tooltip.displayName = 'Tooltip'
