import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useGetAccountInfo } from '@services/account'

export const CHRISTMAS_SALE = 'CHRISTMAS_SALE'
export const PROMOTION_3M = 'PROMOTION_3M'
export const BLACK_FRIDAY = 'BLACK_FRIDAY'
export const SUMMER_SALE = 'SUMMER_SALE'
export const PROMOTION_FOR_FREE_PLAN = 'PROMOTION_FOR_FREE_PLAN'

export const PROMOTION_3M_END_DATE = '2024-08-31'
export const PROMOTION_3M_START_DAY = 15

export const useSale = () => {
  const { hasCustomOffer } = useGetAccountInfo()

  const hasAccessToPromotion12MFF = useFeatureFlags('PROMOTION_12M')

  const hasAccessToPromotion12M = hasAccessToPromotion12MFF && !hasCustomOffer
  const PROMOTION_3M_FF = useFeatureFlags('PROMOTION_3M')
  const hasAccessToDiscounts = useAcl('account_settings.invoices.view_page')
  const hasAccessToPromotionForFreePlan = useFeatureFlags(
    'PROMOTION_FOR_FREE_PLAN'
  )

  if (!hasAccessToDiscounts) {
    return null
  }

  // change on CHRISTMAS_SALE or SUMMER_SALE if needed
  if (hasAccessToPromotion12M) {
    return BLACK_FRIDAY
  }

  if (hasAccessToPromotionForFreePlan) {
    return PROMOTION_FOR_FREE_PLAN
  }

  if (PROMOTION_3M_FF) {
    return PROMOTION_3M
  }

  return null
}
