import { SectionType } from '../types'

export const MODERN_HERO_1: SectionType = {
  name: 'modern_hero_1'
}

export const MODERN_HERO_2: SectionType = {
  name: 'modern_hero_2'
}

export const MODERN_HERO_3: SectionType = {
  name: 'modern_hero_3'
}

export const MODERN_HERO_4: SectionType = {
  name: 'modern_hero_4'
}

export const MODERN_HERO_5: SectionType = {
  name: 'modern_hero_5'
}

export const MODERN_HERO_6: SectionType = {
  name: 'modern_hero_6'
}

export const MODERN_HERO_7: SectionType = {
  name: 'modern_hero_7'
}

export const MODERN_HERO_8: SectionType = {
  name: 'modern_hero_8'
}

export const MODERN_HERO_9: SectionType = {
  name: 'modern_hero_9'
}

export const MODERN_HERO_10: SectionType = {
  name: 'modern_hero_10'
}

export const MODERN_HERO_11: SectionType = {
  name: 'modern_hero_11'
}

export const MODERN_HERO_12: SectionType = {
  name: 'modern_hero_12'
}

export const MODERN_HERO_13: SectionType = {
  name: 'modern_hero_13'
}

export const MODERN_HERO_14: SectionType = {
  name: 'modern_hero_14'
}

export const MODERN_HERO_15: SectionType = {
  name: 'modern_hero_15'
}
