import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import { Modal, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { removeUrl } from '@services/landings/publishingOptions'
import { useTranslation } from 'react-i18next'

interface ModalRemoveUrlProps {
  uuid: string
  url_uuid: string
}

export const ModalRemoveUrl = NiceModal.create<ModalRemoveUrlProps>(
  ({ uuid, url_uuid }) => {
    const { t } = useTranslation()

    const { remove } = useModal()

    const [removeUrlRequest, { isLoading }] = useLazyService(removeUrl, {
      onSuccess: () => {
        remove()
      },
      successToastText: t('publishing.options.remove.url.toast.success'),
      errorToastText: t('toast.error.something.went.wrong')
    })

    const handleRemove = () => removeUrlRequest({ uuid, url_uuid })

    return (
      <Modal
        i18n={{
          title: t('publishing.options.remove.url.title'),
          action: t('word.remove'),
          cancel: t('word.cancel')
        }}
        hasFooter
        isActive
        onClick={remove}
        onAction={handleRemove}
        isClosable
        actionVariant='alert'
        isButtonLoading={isLoading}
        isButtonDisabled={isLoading}
      >
        <Paragraph size={18} weight={400}>
          {t('publishing.options.remove.url.description.1')}
        </Paragraph>

        <Spacer space='tiny' />

        <Paragraph size={16} weight={400}>
          {t('publishing.options.remove.url.description.2')}
        </Paragraph>
      </Modal>
    )
  }
)
