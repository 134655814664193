import LPDoverview from '@images/addons/overview/LPD-overview.png'
import { Image } from '@ui-kit/Image'
import { TFunction } from 'i18next'

export const getOverview = (t: TFunction<'translation', undefined>) => ({
  heading: t('addons.store.landing.page.design.overview.heading'),
  leftSide: <Image src={LPDoverview} height='360px' width='640px' />,

  list: [
    t('addons.store.landing.page.design.overview.list.1'),
    t('addons.store.landing.page.design.overview.list.2'),
    t('addons.store.landing.page.design.overview.list.3')
  ]
})
