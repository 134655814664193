export const PLN = 'PLN'
export const USD = 'USD'
export const EUR = 'EUR'
export const GBP = 'GBP'
export const BR = 'BRL'

export type CurrencyType =
  | typeof PLN
  | typeof USD
  | typeof EUR
  | typeof GBP
  | typeof BR

export const REST = 'USD'

export const CURRENCIES = [
  { code: 'AUD', symbol: 'A$' },
  { code: 'BRL', symbol: 'R$' },
  { code: 'CAD', symbol: 'C$' },
  { code: 'CHF', symbol: '₣' },
  { code: 'CZK', symbol: 'Kč' },
  { code: 'DKK', symbol: 'kr' },
  { code: 'EUR', symbol: '€' },
  { code: 'HRK', symbol: 'kn' },
  { code: 'HUF', symbol: 'Ft' },
  { code: 'INR', symbol: '₹' },
  { code: 'GBP', symbol: '£' },
  { code: 'PLN', symbol: 'zł' },
  { code: 'MXN', symbol: '₱' },
  { code: 'MYR', symbol: 'RM' },
  { code: 'NOK', symbol: 'kr' },
  { code: 'SEK', symbol: 'kr' },
  { code: 'THB', symbol: '฿' },
  { code: 'USD', symbol: '$' },
  { code: 'AED', symbol: 'د.إ' },
  { code: 'PHP', symbol: '₱' },
  { code: 'HKD', symbol: 'HK$' },
  { code: 'TND', symbol: 'د.ت' },
  { code: 'COP', symbol: '$' }
]

export type CurrenciesType = (typeof CURRENCIES)[number]['code']

export const europeanUnionCountries = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SK',
  'SI'
]

export const mapCountryCodeToCurrency = {
  EU: 'EUR',
  PL: 'PLN',
  GB: 'GBP',
  BR: 'BRL',
  REST: 'USD'
}
