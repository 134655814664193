import { AddonID } from '@constants/addonsIds'
import { useModal } from '@ebay/nice-modal-react'
import ADDONS_STORE from '@routes/path/addons'
import { mixpanelEvent } from '@services/mixpanel'
import { Button } from '@ui-kit/Button'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface PrimaryAddonButtonProps {
  addonId: AddonID
  children: React.ReactNode
  featureNameForMixpanel: string
}

export const PrimaryAddonButton: FC<PrimaryAddonButtonProps> = ({
  addonId,
  children,
  featureNameForMixpanel
}) => {
  const { remove } = useModal()

  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate(`${ADDONS_STORE.ADDONS_STORE}/${addonId}`)

    mixpanelEvent({
      name: 'Access to feature - addon profile button',
      properties: {
        feature: featureNameForMixpanel
      }
    })

    remove()
  }

  return (
    <Button
      variant='primary'
      onClick={handleRedirect}
      data-testid='primary-addon-button'
    >
      {children}
    </Button>
  )
}
