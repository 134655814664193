import { MODAL_CREDITS_PACKAGE } from '@components/Modals/ModalCreditsPackage'
import { CLICK_BUY_CREDITS, USAGE_DROPDOWN } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import NiceModal from '@ebay/nice-modal-react'
import { emitCloseDropdown } from '@landingi/landingi-ui-kit'
import { mixpanelEvent } from '@services/mixpanel'
import { CustomLink } from '@ui-kit/CustomLink'
import { Spacer } from '@ui-kit/Spacer'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './BuyCreditsButton.module.scss'

export const BuyCreditsButton = () => {
  const { t } = useTranslation()

  const hasAccessToBuyCredits = useAcl('credits.buy_credits')

  const handleOpenModal = () => {
    emitCloseDropdown()

    mixpanelEvent({
      name: CLICK_BUY_CREDITS,
      properties: {
        click_location: USAGE_DROPDOWN,
        type: 'normal'
      }
    })

    NiceModal.show(MODAL_CREDITS_PACKAGE, {
      properties: {
        click_location: USAGE_DROPDOWN,
        type: 'normal'
      }
    })
  }

  return hasAccessToBuyCredits ? (
    <Fragment>
      <Spacer space={10} />

      <CustomLink
        className={styles.button}
        onClick={handleOpenModal}
        size={12}
        variant='underlined'
      >
        {t('topbar.credits.button')}
      </CustomLink>
    </Fragment>
  ) : null
}

BuyCreditsButton.displayName = 'TopbarMyUsageButtonsBuyCreditsButton'
