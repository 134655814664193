import { Button, Icon } from '@landingi/landingi-ui-kit'
import styles from '@pages/Landings/routes/LandingsPublishingOptions/components/Views/Main/components/Table/Table.module.scss'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { useTranslation } from 'react-i18next'

export const ExtraHeaderContent = () => {
  const { t } = useTranslation()

  const {
    publishingOptions: {
      data: { isLandingPagePublished }
    },
    landingPageUrls: { handleRefreshlUrls }
  } = usePublishingOptionsContext()

  const refreshList = () => handleRefreshlUrls()

  return (
    <Button
      className={styles['table-description__refresh']}
      size='tiny'
      variant='transparent'
      onClick={refreshList}
      hasIcon
      isDisabled={!isLandingPagePublished}
    >
      <Icon icon='icon-sync' size={10} />

      <span>{t('word.refresh')}</span>
    </Button>
  )
}

ExtraHeaderContent.displayName =
  'PublishingOptionsViewsMainTableExtraHeaderContent'
