import ACCESSORIES from '@images/landings/wizard/landing-page-topics/ACCESSORIES.webp'
import ACTING from '@images/landings/wizard/landing-page-topics/ACTING.webp'
import AI from '@images/landings/wizard/landing-page-topics/AI.webp'
import ANIMALS from '@images/landings/wizard/landing-page-topics/ANIMALS.webp'
import ARCHITECTURE from '@images/landings/wizard/landing-page-topics/ARCHITECTURE.webp'
import ART from '@images/landings/wizard/landing-page-topics/ART.webp'
import AUDIO from '@images/landings/wizard/landing-page-topics/AUDIO.webp'
import BAR from '@images/landings/wizard/landing-page-topics/BAR.webp'
import BEAUTY from '@images/landings/wizard/landing-page-topics/BEAUTY.webp'
import BOOKS from '@images/landings/wizard/landing-page-topics/BOOKS.webp'
import CAFE from '@images/landings/wizard/landing-page-topics/CAFE.webp'
import CARPENTRY from '@images/landings/wizard/landing-page-topics/CARPENTRY.webp'
import CARS from '@images/landings/wizard/landing-page-topics/CARS.webp'
import CHARITY from '@images/landings/wizard/landing-page-topics/CHARITY.webp'
import CLEANING from '@images/landings/wizard/landing-page-topics/CLEANING.webp'
import CLUBS from '@images/landings/wizard/landing-page-topics/CLUBS.webp'
import COACHING from '@images/landings/wizard/landing-page-topics/COACHING.webp'
import COLLECTING from '@images/landings/wizard/landing-page-topics/COLLECTING.webp'
import COMMUNITY from '@images/landings/wizard/landing-page-topics/COMMUNITY.webp'
import CONSTRUCTION from '@images/landings/wizard/landing-page-topics/CONSTRUCTION.webp'
import CONSULTING from '@images/landings/wizard/landing-page-topics/CONSULTING.webp'
import CRAFTS from '@images/landings/wizard/landing-page-topics/CRAFTS.webp'
import CYBERSECURITY from '@images/landings/wizard/landing-page-topics/CYBERSECURITY.webp'
import CYCLING from '@images/landings/wizard/landing-page-topics/CYCLING.webp'
import DANCE from '@images/landings/wizard/landing-page-topics/DANCE.webp'
import DECOR from '@images/landings/wizard/landing-page-topics/DECOR.webp'
import DESIGN from '@images/landings/wizard/landing-page-topics/DESIGN.webp'
import DESERTS from '@images/landings/wizard/landing-page-topics/DESSERTS.webp'
import DJ from '@images/landings/wizard/landing-page-topics/DJ.webp'
import DRINKS from '@images/landings/wizard/landing-page-topics/DRINKS.webp'
import EDUCATION from '@images/landings/wizard/landing-page-topics/EDUCATION.webp'
import ELECTRONICS from '@images/landings/wizard/landing-page-topics/ELECTRONICS.webp'
import ENGINEERING from '@images/landings/wizard/landing-page-topics/ENGINEERING.webp'
import ENTERTAINMENT from '@images/landings/wizard/landing-page-topics/ENTERTAINMENT.webp'
import EQUESTRIAN from '@images/landings/wizard/landing-page-topics/EQUESTRIAN.webp'
import ESPORT from '@images/landings/wizard/landing-page-topics/ESPORT.webp'
import EVENTS from '@images/landings/wizard/landing-page-topics/EVENTS.webp'
import FAMILY from '@images/landings/wizard/landing-page-topics/FAMILY.webp'
import FASHION from '@images/landings/wizard/landing-page-topics/FASHION.webp'
import FASTFOOD from '@images/landings/wizard/landing-page-topics/FASTFOOD.webp'
import FINANCE from '@images/landings/wizard/landing-page-topics/FINANCE.webp'
import FINEART from '@images/landings/wizard/landing-page-topics/FINEART.webp'
import FITNESS from '@images/landings/wizard/landing-page-topics/FITNESS.webp'
import FOOD from '@images/landings/wizard/landing-page-topics/FOOD.webp'
import HAIR from '@images/landings/wizard/landing-page-topics/HAIR.webp'
import HEALTH from '@images/landings/wizard/landing-page-topics/HEALTH.webp'
import HIPHOP from '@images/landings/wizard/landing-page-topics/HIPHOP.webp'
import HOBBIES from '@images/landings/wizard/landing-page-topics/HOBBIES.webp'
import HOME from '@images/landings/wizard/landing-page-topics/HOME.webp'
import HOTEL from '@images/landings/wizard/landing-page-topics/HOTEL.webp'
import HR from '@images/landings/wizard/landing-page-topics/HR.webp'
import IMPROVEMENT from '@images/landings/wizard/landing-page-topics/IMPROVEMENT.webp'
import INDUSTRIAL from '@images/landings/wizard/landing-page-topics/INDUSTRIAL.webp'
import INSURANCE from '@images/landings/wizard/landing-page-topics/INSURANCE.webp'
import JEWELRY from '@images/landings/wizard/landing-page-topics/JEWELRY.webp'
import LESSONS from '@images/landings/wizard/landing-page-topics/LESSONS.webp'
import MARKETING from '@images/landings/wizard/landing-page-topics/MARKETING.webp'
import MASSAGE from '@images/landings/wizard/landing-page-topics/MASSAGE.webp'
import MEDIA from '@images/landings/wizard/landing-page-topics/MEDIA.webp'
import MEDICAL from '@images/landings/wizard/landing-page-topics/MEDICAL.webp'
import MIND from '@images/landings/wizard/landing-page-topics/MIND.webp'
import MOTORSPORT from '@images/landings/wizard/landing-page-topics/MOTORSPORT.webp'
import MUSIC from '@images/landings/wizard/landing-page-topics/MUSIC.webp'
import NATURE from '@images/landings/wizard/landing-page-topics/NATURE.webp'
import NUTRITION from '@images/landings/wizard/landing-page-topics/NUTRITION.webp'
import PHARMACY from '@images/landings/wizard/landing-page-topics/PHARMACY.webp'
import PHOTOGRAPHY from '@images/landings/wizard/landing-page-topics/PHOTOGRAPHY.webp'
import PLUMBING from '@images/landings/wizard/landing-page-topics/PLUMBING.webp'
import POLITICS from '@images/landings/wizard/landing-page-topics/POLITICS.webp'
import PUBLISHING from '@images/landings/wizard/landing-page-topics/PUBLISHING.webp'
import REALESTATE from '@images/landings/wizard/landing-page-topics/REALESTATE.webp'
import RESTAURANT from '@images/landings/wizard/landing-page-topics/RESTAURANT.webp'
import ROBOTICS from '@images/landings/wizard/landing-page-topics/ROBOTICS.webp'
import SCHOOL from '@images/landings/wizard/landing-page-topics/SCHOOL.webp'
import SOFTWARE from '@images/landings/wizard/landing-page-topics/SOFTWARE.webp'
import SPA from '@images/landings/wizard/landing-page-topics/SPA.webp'
import SPORTS from '@images/landings/wizard/landing-page-topics/SPORTS.webp'
import TECH from '@images/landings/wizard/landing-page-topics/TECH.webp'
import TELECOM from '@images/landings/wizard/landing-page-topics/TELECOM.webp'
import TELEVISION from '@images/landings/wizard/landing-page-topics/TELEVISION.webp'
import THERAPY from '@images/landings/wizard/landing-page-topics/THERAPY.webp'
import TOURISM from '@images/landings/wizard/landing-page-topics/TOURISM.webp'
import TOYS from '@images/landings/wizard/landing-page-topics/TOYS.webp'
import TRANSLATION from '@images/landings/wizard/landing-page-topics/TRANSLATION.webp'
import TRANSPORT from '@images/landings/wizard/landing-page-topics/TRANSPORT.webp'
import TRAVEL from '@images/landings/wizard/landing-page-topics/TRAVEL.webp'
import WATERSPORTS from '@images/landings/wizard/landing-page-topics/WATERSPORTS.webp'
import WEDDING from '@images/landings/wizard/landing-page-topics/WEDDING.webp'
import WINTERSPORTS from '@images/landings/wizard/landing-page-topics/WINTERSPORTS.webp'
import WRITING from '@images/landings/wizard/landing-page-topics/WRITING.webp'
import YOGA from '@images/landings/wizard/landing-page-topics/YOGA.webp'

export type TopicType =
  | 'accessories'
  | 'acting-modelling'
  | 'animals-pets'
  | 'architecture'
  | 'art-illustration'
  | 'artificial-intelligence'
  | 'audio-production'
  | 'bar'
  | 'beauty'
  | 'books-reading'
  | 'cafe-bakery'
  | 'cakes-desserts'
  | 'carpentry'
  | 'cars'
  | 'charity-nonprofit'
  | 'cleaning-services'
  | 'clubs-associations'
  | 'coaching'
  | 'collecting'
  | 'community'
  | 'construction'
  | 'consulting'
  | 'crafts-handmade'
  | 'cycling'
  | 'dance-performance'
  | 'data-protection-and-cybersecurity'
  | 'dj'
  | 'drinks'
  | 'e-sport'
  | 'education'
  | 'electronics-tech'
  | 'entertainment'
  | 'events-venues'
  | 'family-friends'
  | 'fashion'
  | 'fast-food'
  | 'finance-law-b2b'
  | 'fine-art'
  | 'fitness'
  | 'food-products'
  | 'graphic-design'
  | 'hair-makeup'
  | 'health'
  | 'hiphop-rap'
  | 'hobbies'
  | 'home-garden'
  | 'home-decor'
  | 'home-improvement'
  | 'horses-equestrian'
  | 'hotel-rental'
  | 'human-resources'
  | 'industrial-services'
  | 'insurance'
  | 'jewelry'
  | 'lessons-tutoring'
  | 'marketing-pr'
  | 'massage-bodywork'
  | 'mechanical-engineering'
  | 'media-production'
  | 'medical'
  | 'mind-spirit'
  | 'motorsport'
  | 'music'
  | 'nature'
  | 'nutrition'
  | 'pharmacy'
  | 'photography'
  | 'plumbing'
  | 'politics-social-causes'
  | 'publishing'
  | 'real-estate'
  | 'restaurant'
  | 'robotics'
  | 'school'
  | 'software'
  | 'spa'
  | 'sports-recreation'
  | 'tech-it-services'
  | 'telecommunications'
  | 'television'
  | 'therapy'
  | 'tourism'
  | 'toys-games'
  | 'translation-and-linguistics'
  | 'transport'
  | 'travel'
  | 'water-sports'
  | 'wedding'
  | 'winter-sports'
  | 'writing'
  | 'yoga'

export type TopicsImagesNumbers = {
  [key in TopicType]?: number[]
}

export const topics: {
  name: TopicType
  image: string
}[] = [
  {
    name: 'accessories',
    image: ACCESSORIES
  },
  {
    name: 'acting-modelling',
    image: ACTING
  },
  {
    name: 'animals-pets',
    image: ANIMALS
  },
  {
    name: 'architecture',
    image: ARCHITECTURE
  },
  {
    name: 'art-illustration',
    image: ART
  },
  {
    name: 'artificial-intelligence',
    image: AI
  },
  {
    name: 'audio-production',
    image: AUDIO
  },
  {
    name: 'bar',
    image: BAR
  },
  {
    name: 'beauty',
    image: BEAUTY
  },
  {
    name: 'books-reading',
    image: BOOKS
  },
  {
    name: 'cafe-bakery',
    image: CAFE
  },
  {
    name: 'cakes-desserts',
    image: DESERTS
  },
  {
    name: 'carpentry',
    image: CARPENTRY
  },
  {
    name: 'cars',
    image: CARS
  },
  {
    name: 'charity-nonprofit',
    image: CHARITY
  },
  {
    name: 'cleaning-services',
    image: CLEANING
  },
  {
    name: 'clubs-associations',
    image: CLUBS
  },
  {
    name: 'coaching',
    image: COACHING
  },
  {
    name: 'collecting',
    image: COLLECTING
  },
  {
    name: 'community',
    image: COMMUNITY
  },
  {
    name: 'construction',
    image: CONSTRUCTION
  },
  {
    name: 'consulting',
    image: CONSULTING
  },
  {
    name: 'crafts-handmade',
    image: CRAFTS
  },
  {
    name: 'cycling',
    image: CYCLING
  },
  {
    name: 'dance-performance',
    image: DANCE
  },
  {
    name: 'data-protection-and-cybersecurity',
    image: CYBERSECURITY
  },
  {
    name: 'dj',
    image: DJ
  },
  {
    name: 'drinks',
    image: DRINKS
  },
  {
    name: 'e-sport',
    image: ESPORT
  },
  {
    name: 'education',
    image: EDUCATION
  },
  {
    name: 'electronics-tech',
    image: ELECTRONICS
  },
  {
    name: 'entertainment',
    image: ENTERTAINMENT
  },
  {
    name: 'events-venues',
    image: EVENTS
  },
  {
    name: 'family-friends',
    image: FAMILY
  },
  {
    name: 'fashion',
    image: FASHION
  },
  {
    name: 'fast-food',
    image: FASTFOOD
  },
  {
    name: 'finance-law-b2b',
    image: FINANCE
  },
  {
    name: 'fine-art',
    image: FINEART
  },
  {
    name: 'fitness',
    image: FITNESS
  },
  {
    name: 'food-products',
    image: FOOD
  },
  {
    name: 'graphic-design',
    image: DESIGN
  },
  {
    name: 'hair-makeup',
    image: HAIR
  },
  {
    name: 'health',
    image: HEALTH
  },
  {
    name: 'hiphop-rap',
    image: HIPHOP
  },
  {
    name: 'hobbies',
    image: HOBBIES
  },
  {
    name: 'home-garden',
    image: HOME
  },
  {
    name: 'home-decor',
    image: DECOR
  },
  {
    name: 'home-improvement',
    image: IMPROVEMENT
  },
  {
    name: 'horses-equestrian',
    image: EQUESTRIAN
  },
  {
    name: 'hotel-rental',
    image: HOTEL
  },
  {
    name: 'human-resources',
    image: HR
  },
  {
    name: 'industrial-services',
    image: INDUSTRIAL
  },
  {
    name: 'insurance',
    image: INSURANCE
  },
  {
    name: 'jewelry',
    image: JEWELRY
  },
  {
    name: 'lessons-tutoring',
    image: LESSONS
  },
  {
    name: 'marketing-pr',
    image: MARKETING
  },
  {
    name: 'massage-bodywork',
    image: MASSAGE
  },
  {
    name: 'mechanical-engineering',
    image: ENGINEERING
  },
  {
    name: 'media-production',
    image: MEDIA
  },
  {
    name: 'medical',
    image: MEDICAL
  },
  {
    name: 'mind-spirit',
    image: MIND
  },
  {
    name: 'motorsport',
    image: MOTORSPORT
  },
  {
    name: 'music',
    image: MUSIC
  },
  {
    name: 'nature',
    image: NATURE
  },
  {
    name: 'nutrition',
    image: NUTRITION
  },
  {
    name: 'pharmacy',
    image: PHARMACY
  },
  {
    name: 'photography',
    image: PHOTOGRAPHY
  },
  {
    name: 'plumbing',
    image: PLUMBING
  },
  {
    name: 'politics-social-causes',
    image: POLITICS
  },
  {
    name: 'publishing',
    image: PUBLISHING
  },
  {
    name: 'real-estate',
    image: REALESTATE
  },
  {
    name: 'restaurant',
    image: RESTAURANT
  },
  {
    name: 'robotics',
    image: ROBOTICS
  },
  {
    name: 'school',
    image: SCHOOL
  },
  {
    name: 'software',
    image: SOFTWARE
  },
  {
    name: 'spa',
    image: SPA
  },
  {
    name: 'sports-recreation',
    image: SPORTS
  },
  {
    name: 'tech-it-services',
    image: TECH
  },
  {
    name: 'telecommunications',
    image: TELECOM
  },
  {
    name: 'television',
    image: TELEVISION
  },
  {
    name: 'therapy',
    image: THERAPY
  },
  {
    name: 'tourism',
    image: TOURISM
  },
  {
    name: 'toys-games',
    image: TOYS
  },
  {
    name: 'translation-and-linguistics',
    image: TRANSLATION
  },
  {
    name: 'transport',
    image: TRANSPORT
  },
  {
    name: 'travel',
    image: TRAVEL
  },
  {
    name: 'water-sports',
    image: WATERSPORTS
  },
  {
    name: 'wedding',
    image: WEDDING
  },
  {
    name: 'winter-sports',
    image: WINTERSPORTS
  },
  {
    name: 'writing',
    image: WRITING
  },
  {
    name: 'yoga',
    image: YOGA
  }
]
