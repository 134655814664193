import { useStyles } from '@hooks/useStyles'
import { FC, Fragment, ReactNode } from 'react'
import { Row } from 'simple-flexbox'

import { Paragraph } from '../Paragraph'
import { Spreader } from '../Spreader'
import styles from './Separator.module.scss'

interface SeparatorProps {
  className?: string | string[]
  children?: ReactNode
  height?: 1 | 4
  'data-testid'?: string
}

export const Separator: FC<SeparatorProps> = ({
  className = '',
  height = 1,
  children,
  'data-testid': dataTestId = 'separator'
}) => {
  const elementClasses = useStyles(
    {
      [styles.separator]: true,
      [styles[`separator--height-${height}`]]: height
    },
    className
  )

  return (
    <Row alignItems='center' data-testid={`${dataTestId}`}>
      <div className={elementClasses} data-testid={`${dataTestId}-left`} />

      {children && (
        <Fragment>
          <Spreader spread={10} />
          <Paragraph size={12} weight={600} color='neutral-2'>
            {children}
          </Paragraph>
          <Spreader spread={10} />
        </Fragment>
      )}

      <div className={elementClasses} data-testid={`${dataTestId}-right`} />
    </Row>
  )
}

Separator.displayName = 'Separator'
