import { TabVariantType } from '@ui-kit/Tabs/types'
import { createContext, FC, ReactNode, useContext, useMemo } from 'react'

export type VariantProviderProps = {
  variant: TabVariantType
  children: ReactNode
}

export type VariantProviderValue = {
  variant: TabVariantType
}

const VariantContext = createContext<VariantProviderValue | null>(null)

export const VariantProvider: FC<VariantProviderProps> = ({
  children,
  variant
}) => {
  const ctxValue = useMemo(
    () => ({
      variant
    }),
    [variant]
  )

  return (
    <VariantContext.Provider value={ctxValue}>
      {children}
    </VariantContext.Provider>
  )
}

export const useVariantContext = () => {
  const ctxValue = useContext(VariantContext)

  if (!ctxValue) {
    throw new Error('useVariantContext must be used inside VariantProvider')
  }

  return ctxValue
}
