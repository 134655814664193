/**
 * Asynchronus waiting for element till be attached and visible in DOM
 * @param {string} selector - DOM element selector
 * @returns {promise} promise with element found in DOM
 */
export const waitForElementVisibleInDOM = selector =>
  new Promise(resolve => {
    const mutationObserver = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        const foundTargetInDOM = document.querySelector(selector)

        const disconnectWhenTargetVisible = entries => {
          if (entries[0].isIntersecting) {
            resolve(foundTargetInDOM)
            mutationObserver.disconnect()
            intersectionObserver.disconnect()
          }
        }

        const intersectionObserver = new IntersectionObserver(
          disconnectWhenTargetVisible
        )

        intersectionObserver.observe(foundTargetInDOM)
      }
    })

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
