import WebToLearnImage from '@images/registration/exwhitelabels/webtolearn/webtolearn-image.svg'
import WebToLearnLogo from '@images/registration/exwhitelabels/webtolearn/webtolearn-logo.svg'
import { Image, Spacer } from '@landingi/landingi-ui-kit'
import { WhitelabelPageProvider } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { Row } from 'simple-flexbox'

import styles from './WebToLearn.module.scss'

const MainLayout = ({ children }) => {
  return (
    <Row className={styles.whitelabelLayout}>
      <div className={styles.webToLearn__firstColumn}>
        <div className={styles.whitelabelLayout__content}>
          <Image
            className={styles.webToLearn__logo}
            size={220}
            height={60}
            src={WebToLearnLogo}
          />

          <Spacer />

          <motion.div className={styles.whitelabelLayout__form}>
            {children}
          </motion.div>
        </div>
      </div>
      <div className={styles.webToLearn__secondColumn}>
        <Image
          className={styles.webToLearn__image}
          size='74%'
          height='74%'
          src={WebToLearnImage}
        />
      </div>
    </Row>
  )
}

MainLayout.displayName = 'WebToLearnMainLayout'

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
}

const MainLayoutWrapper = ({ children }) => (
  <WhitelabelPageProvider>
    <MainLayout>{children}</MainLayout>
  </WhitelabelPageProvider>
)

MainLayoutWrapper.displayName = 'WebToLearnMainLayoutWrapper'

MainLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(MainLayoutWrapper)
