import { Icon } from '@ui-kit'
import { Trans } from 'react-i18next'

export const getFeatures = () => [
  {
    title: <Trans i18nKey='addons.store.e.commerce.features.1.title' />,
    description: (
      <Trans i18nKey='addons.store.e.commerce.features.1.description' />
    ),
    icon: <Icon icon='icon-button-pay' color='primary' size={26} />
  },
  {
    title: <Trans i18nKey='addons.store.e.commerce.features.2.title' />,
    description: (
      <Trans i18nKey='addons.store.e.commerce.features.2.description' />
    ),
    icon: <Icon icon='icon-product' color='primary' size={26} />
  },
  {
    title: <Trans i18nKey='addons.store.e.commerce.features.3.title' />,
    description: (
      <Trans i18nKey='addons.store.e.commerce.features.3.description' />
    ),
    icon: <Icon icon='icon-users' color='primary' size={26} />
  }
]
