import { AnimatePresence } from 'framer-motion'
import { Fragment, useEffect, useState } from 'react'

import {
  CLOSE_PRODUCT_UPDATES_DRAWER,
  OPEN_PRODUCT_UPDATES_DRAWER
} from './constants'
import { Drawer } from './Drawer'
import { Popup } from './Popup'

export const ProductUpdates = () => {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)

  const handleDrawerOpen = () => setDrawerOpen(true)

  const handleDrawerClose = () => setDrawerOpen(false)

  useEffect(() => {
    document.addEventListener(OPEN_PRODUCT_UPDATES_DRAWER, handleDrawerOpen)
    document.addEventListener(CLOSE_PRODUCT_UPDATES_DRAWER, handleDrawerClose)

    return () => {
      document.removeEventListener(
        OPEN_PRODUCT_UPDATES_DRAWER,
        handleDrawerOpen
      )
      document.removeEventListener(
        CLOSE_PRODUCT_UPDATES_DRAWER,
        handleDrawerClose
      )
    }
  }, [])

  return (
    <Fragment>
      <AnimatePresence>{isDrawerOpen && <Drawer />}</AnimatePresence>

      <Popup />
    </Fragment>
  )
}
