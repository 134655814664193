import ECommerceOverview from '@images/addons/overview/ECommerce-overview.png'
import { Image } from '@ui-kit/Image'
import { TFunction } from 'i18next'

export const getOverview = (t: TFunction<'translation', undefined>) => ({
  heading: t('addons.store.e.commerce.overview.heading'),
  leftSide: <Image src={ECommerceOverview} height='360px' width='640px' />,
  list: [
    t('addons.store.e.commerce.overview.list.1'),
    t('addons.store.e.commerce.overview.list.2'),
    t('addons.store.e.commerce.overview.list.3')
  ]
})
