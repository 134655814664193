import { useGetCSRFToken } from '@services/authentication/useGetCSRFToken'
import { useGetAnyProducts } from '@services/products/useGetAnyProducts'
import {
  GetProductsResponse,
  SorterTypes,
  useGetProducts
} from '@services/products/useGetProducts'
import { emitTimingToast } from '@ui-kit'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

type FetchParamsTypes = {
  search: string
  sort_field: SorterTypes
  page: number
  limit: number
}

interface ProductsContextValue {
  csrfToken: string
  isCsrfTokenLoading: boolean
  productsAnyData: GetProductsResponse | undefined
  isLoadingAnyProduct: boolean
  productsData: GetProductsResponse | undefined
  isLoadingProduct: boolean
  selectedProduct: string[]
  setSelectedProduct: Dispatch<SetStateAction<string[]>>
  fetchParams: FetchParamsTypes
  onSearchParamChange: (search: string) => void
  onSorterChange: (sort_field: SorterTypes) => void
  onPageChange: (page: number) => void
  onPageSizeChange: (limit: number) => void
}

const ProductsContext = createContext<ProductsContextValue | null>(null)

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()

  const [selectedProduct, setSelectedProduct] = useState<string[]>([])
  const [fetchParams, setFetchParams] = useState<FetchParamsTypes>({
    search: '',
    sort_field: 'created_at',
    page: 1,
    limit: 10
  })

  const {
    data: csrfTokenData,
    isLoading: isCsrfTokenLoading,
    error: csrfTokenError
  } = useGetCSRFToken()

  if (csrfTokenError) {
    emitTimingToast({
      message: t('toast.error.something.went.wrong'),
      type: 'error'
    })
  }

  const { data: productsAnyData, isLoading: isLoadingAnyProduct } =
    useGetAnyProducts()

  const { data: productsData, isLoading: isLoadingProduct } =
    useGetProducts(fetchParams)

  const onSearchParamChange = (search: string) =>
    setFetchParams(prev => ({ ...prev, page: 1, search }))

  const onSorterChange = (sort_field: SorterTypes) =>
    setFetchParams(prev => ({ ...prev, sort_field }))

  const onPageChange = (page: number) =>
    setFetchParams(prev => ({ ...prev, page }))

  const onPageSizeChange = (limit: number) =>
    setFetchParams(prev => ({ ...prev, limit }))

  const ctxValue = useMemo(
    () => ({
      csrfToken: csrfTokenData?.token || '',
      isCsrfTokenLoading,
      productsAnyData,
      isLoadingAnyProduct,
      productsData,
      isLoadingProduct,
      selectedProduct,
      setSelectedProduct,
      fetchParams,
      onSearchParamChange,
      onSorterChange,
      onPageChange,
      onPageSizeChange
    }),
    [
      csrfTokenData,
      isCsrfTokenLoading,
      productsAnyData,
      isLoadingAnyProduct,
      productsData,
      isLoadingProduct,
      selectedProduct,
      setSelectedProduct,
      fetchParams
    ]
  )

  return (
    <ProductsContext.Provider value={ctxValue}>
      {children}
    </ProductsContext.Provider>
  )
}

export const useProductsContext = () => {
  const ctxValue = useContext(ProductsContext)

  if (!ctxValue) {
    throw new Error('useProductsContext must be used inside ProductsProvider')
  }

  return ctxValue
}
