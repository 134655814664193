import { PROD } from '@config/env'
import { BEGINNING } from '@constants/registrationSteps'
import { getRegistrationErrorMessage } from '@helpers/errors'
import { activationCodeMask } from '@helpers/masks'
import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import {
  Alert,
  Button,
  emitTimingToastToggle,
  InputForm,
  MaskedInputForm,
  ShowPassword,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import {
  changeRegistrationStep,
  registerOnPartnerPage
} from '@services/authentication'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

import Checks from './Checks'
import RegistrationFormWitchCodeSchema from './RegistrationFormWitchCodeSchema'

/**
 * isPasswordValid - returns true if all checks have passed
 * @param {string} password - password to validate
 */
const isPasswordValid = (password = '') =>
  hasLowerCase(password) &&
  hasUpperCase(password) &&
  hasNumber(password) &&
  password.length > 9

/**
 * Registration form - stateful presentational component
 * @param {object} props - props
 * @param {string} lang - current language used by user
 * @param {string} chosenPackage - package user is registering on
 * @param {string} variantName - variant of registration page, used as partner parameter when registering
 * @return {object} An object of children
 */
const RegistrationFormWithCode = ({
  lang,
  chosenPackage,
  source,
  startFromFirstStep,
  isDefaultRegistration
}) => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()

  const { playRegistrationToSurveyTransition } = useAnimationContext()

  /**
   * Handle input type state
   * @type {function}
   */
  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )

  const { mutate } = useSWRConfig()

  /**
   * handleSubmit
   * @param  {object} values - formik values
   * @param  {object} actions - formik actions
   * @type {function}
   */
  const onSubmit = useCallback(
    async values => {
      const { firstName, email, password, code } = values

      const codeWithoutSpaces = code.replace(/\s/g, '')
      const timeZone = moment.tz.guess()

      try {
        let token

        if (PROD) {
          token = await executeRecaptcha('registration_with_code')
        } else {
          token = true
        }

        const uri = window.location.href

        await registerOnPartnerPage(
          firstName,
          email,
          password,
          lang,
          timeZone,
          chosenPackage,
          source,
          codeWithoutSpaces,
          token,
          uri,
          isDefaultRegistration ? 'yes' : 'no'
        )

        startFromFirstStep()

        mutate('auth')

        await changeRegistrationStep(BEGINNING)

        await playRegistrationToSurveyTransition()
      } catch (error) {
        emitTimingToastToggle(
          t(getRegistrationErrorMessage(error?.response?.data?.error)),
          'alert'
        )
      }
    },
    [executeRecaptcha]
  )

  const initialValues = {
    firstName: '',
    email: '',
    password: '',
    code: ''
  }

  const {
    values,
    dirty,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors
  } = useFormik({
    initialValues,
    validationSchema: RegistrationFormWitchCodeSchema(),
    onSubmit
  })

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <InputForm
          field={{
            name: 'firstName',
            value: values.firstName,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          i18n={{
            placeholder: t('registration.flow.first.name'),
            label: t('registration.flow.first.name')
          }}
          form={{
            errors,
            touched
          }}
          id='firstName'
        />

        <InputForm
          field={{
            name: 'email',
            value: values.email,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          i18n={{
            placeholder: t('registration.flow.work.email'),
            label: t('registration.flow.work.email')
          }}
          form={{
            errors,
            touched
          }}
          id='email'
        />

        <Row>
          <Column flexGrow='1'>
            <InputForm
              field={{
                name: 'password',
                value: values.password,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              i18n={{
                placeholder: t('registration.flow.password'),
                label: t('registration.flow.password')
              }}
              form={{
                errors,
                touched
              }}
              id='password'
              type={passwordInputType}
            />
          </Column>

          <Spreader spread='tiny' />

          <Column>
            <ShowPassword setHidden={handleInputTypeState} />
          </Column>
        </Row>

        {isPasswordValid(values.password) ? (
          <Alert type='success'>
            <Trans i18nKey='registration.flow.password.alert' />
          </Alert>
        ) : (
          <Checks password={values.password} />
        )}

        <Spacer />

        <Spacer space='tiny' />

        <MaskedInputForm
          id='code'
          field={{
            name: 'code',
            value: values.code,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          form={{
            errors,
            touched
          }}
          mask={activationCodeMask}
          type='text'
          i18n={{
            placeholder: t('registration.flow.activation.code.mask'),
            label: t('registration.flow.activation.code')
          }}
          guide
          focused='true'
        />

        <Button
          size='large'
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
        >
          {t('registration.flow.sign.up.free')}
        </Button>
      </Column>
    </form>
  )
}

RegistrationFormWithCode.propTypes = {
  lang: PropTypes.string.isRequired,
  chosenPackage: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  startFromFirstStep: PropTypes.func.isRequired,
  isDefaultRegistration: PropTypes.bool.isRequired
}

RegistrationFormWithCode.displayName = 'RegistrationFormWithCode'

export default RegistrationFormWithCode
