import { AB_TESTS_TYPE_MAIN } from '@pages/Landings/routes/Dashboard/routes/Optimization/constants'
import useSWR from 'swr'

interface UseGetVariantListRequest {
  identifier: string
  revalidateOnMount?: boolean
}

export type Variant = {
  identifier: string
  name: string
  createdAt: string
  type: (typeof AB_TESTS_TYPE_MAIN)[]
  trafficSplit: number
  visits: number
  leads: number
  sales: number
  conversionRate: number
  isPublished: boolean
}

export interface UseGetVariantListResponse {
  collection: Variant[]
}

export const useGetVariantList = ({
  identifier,
  revalidateOnMount = true
}: UseGetVariantListRequest) =>
  useSWR<UseGetVariantListResponse>(
    `landing-page/landings/${identifier}/variants`,
    {
      revalidateOnMount
    }
  )
