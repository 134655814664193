import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import modalExpired from '@images/modals/modal-expired.svg'
import {
  Button,
  CustomLink,
  Heading,
  Image,
  Modal,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { PAYMENTS } from '@routes/path'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './ModalSubscriptionExpired.module.scss'

export const ModalSubscriptionExpired = NiceModal.create(() => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const { remove } = useModal()

  return (
    <Modal isActive isClosable={false} size='small' isBodyPadding='none'>
      <Spacer space='large' />

      <Spacer space='small' />

      <Image src={modalExpired} className={styles.cart} />

      <Spacer />

      <Heading level={2} align='center' margin='none'>
        {t('modal.account-expired.header')}
      </Heading>

      <Spacer space='small' />

      <Heading level={4} align='center' className={styles.message}>
        <Trans
          i18nKey='modal.account-expired.message'
          components={{
            b: <b />,
            email: (
              <CustomLink
                size={16}
                label={t('support.email')}
                href={t('word.create-support-ticket.url')}
              />
            )
          }}
        />
      </Heading>

      <Spacer />

      <Column alignItems='center'>
        <Row justifyContent='center'>
          <Button variant='secondary-outlined' onClick={remove}>
            {t('modal.expired.new.continue')}
          </Button>

          {hasAccessToPayments && (
            <Fragment>
              <Spreader spread='tiny' />

              <Button
                tag='a'
                buttonStyle
                href={`${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`}
              >
                {t('modal.account-expired.button.proceed')}
              </Button>
            </Fragment>
          )}
        </Row>

        <Spacer />
      </Column>
    </Modal>
  )
})
