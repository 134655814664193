import { NEW_LANDINGI_URL } from '@config/env'
import client, { newLandingiClient } from '@lib/http/client'

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/register
 * @apiName Registration
 * @apiGroup Auth
 *
 * @apiParam {String} firstName
 * @apiParam {String} email
 * @apiParam {String} password
 * @apiParam {String} language
 * @apiParam {String} timeZone
 * @apiParam {String} packageRecordKey
 * @apiParam {String} source
 * @apiParam {String} captchaChallenge
 *
 * @response
 * HTTP/2 204 No Content
 */
export const register = (
  firstName,
  email,
  password,
  language,
  timeZone,
  packageRecordKey,
  source,
  captchaChallenge,
  uri,
  pricing,
  newSignup,
  promotion,
  defaultRegistration
) =>
  client.post(
    `/auth/register`,
    {
      firstName,
      email,
      password,
      language,
      timeZone,
      packageRecordKey,
      source,
      captchaChallenge,
      uri,
      pricing,
      newSignup,
      promotion,
      defaultRegistration
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/partner-register
 * @apiName Registration
 * @apiGroup Auth
 *
 * @apiParam {String} firstName
 * @apiParam {String} email
 * @apiParam {String} password
 * @apiParam {String} language
 * @apiParam {String} timeZone
 * @apiParam {String} packageRecordKey
 * @apiParam {String} partnerName
 * @apiParam {String} partnerCode
 * @apiParam {string} captchaChallenge
 *
 * @response
 * HTTP/2 204 No Content
 */
export const registerOnPartnerPage = (
  firstName,
  email,
  password,
  language,
  timeZone,
  packageRecordKey,
  partnerName,
  partnerCode,
  captchaChallenge,
  uri,
  defaultRegistration
) =>
  client.post(
    `/auth/partner-register`,
    {
      firstName,
      email,
      password,
      language,
      timeZone,
      packageRecordKey,
      partnerName,
      partnerCode,
      captchaChallenge,
      uri,
      defaultRegistration
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/oauth-register
 * @apiName Registration
 * @apiGroup Auth
 *
 * @apiParam {String} token
 * @apiParam {String} timeZone
 * @apiParam {String} packageRecordKey
 * @apiParam {string} captchaChallenge
 * @apiParam {string} source
 *
 * @response
 * HTTP/2 204 No Content
 */
export const registerWithGoogle = (
  token,
  timeZone,
  packageRecordKey,
  uri,
  source,
  pricing,
  newSignup,
  isCustomOffer,
  promotion,
  defaultRegistration,
  language
) =>
  client.post(
    `/auth/oauth-register`,
    {
      token,
      timeZone,
      packageRecordKey,
      uri,
      source,
      pricing,
      newSignup,
      isCustomOffer,
      promotion,
      defaultRegistration,
      language
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/sign-in
 * @apiName Registration
 * @apiGroup Auth
 *
 * @apiParam {String} email
 * @apiParam {String} password
 * @apiParam {String} source
 * @apiParam {string} captchaChallenge
 *
 * @response
 * HTTP/2 204 No Content
 */
export const login = ({ email, password, captchaChallenge }) =>
  client.post(
    '/auth/sign-in',
    {
      email,
      password,
      source: NEW_LANDINGI_URL,
      captchaChallenge
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/oauth-sign-in
 * @apiName Registration
 * @apiGroup Auth
 *
 * @apiParam {String} token
 * @apiParam {String} provider
 * @apiParam {String} source
 *
 * @response
 * HTTP/2 204 No Content
 */
export const loginWithOAuth = (token, provider, source) =>
  client.post(
    `/auth/oauth-sign-in`,
    {
      token,
      provider,
      source
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/reset-password
 * @apiName Registration
 * @apiGroup Auth
 *
 * @apiParam {String} email
 *
 * @response
 * HTTP/2 204 No Content
 */
export const postResetPassword = ({ email }) =>
  client.post(
    `/auth/reset-password`,
    {
      email
    },
    {
      withCredentials: true
    }
  )

export const postChangePassword = ({ token, password }) => {
  const formData = new FormData()
  formData.append('token', token)
  formData.append('password', password)

  return client.post('/auth/reset-password/change', formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/survey
 * @apiName Survey
 * @apiGroup Auth
 *
 * @apiParam {array} surveyInfo - array of question-answers pairs:
 * {
 *  "survey" => [
 *       {
 *         "question" => "multiple-answer-question",
 *       "answer" => [
 *         "value",
 *        "value"
 *        ]
 *      },
 *      {
 *          "question" => "single-answer-question",
 *          "answer" => ["value"]
 *      },
 *      ...
 *  ]
 * }
 *
 * @response
 * HTTP/2 204 No Content
 */
export const postSurveyInfo = survey =>
  client.post(
    `/auth/survey`,
    {
      survey
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {put} /api/auth/registration-step
 * @apiName changeRegistrationStep
 * @apiGroup Register
 * @apiPermission token
 *
 * @apiParam {string} step - step to change to
 * @apiParam {boolean} skip - send when credit card step is skipped in modal for mixpanel tracking
 */
export const changeRegistrationStep = (step, skip) =>
  client.put(
    `/auth/change-signup-step`,
    {
      step,
      skip
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 2.0.0
 * @api {get} /api/auth
 * @apiName auth
 * @apiGroup Register
 * @apiPermission token
 */
export const getAuthInfo = () =>
  client.get(`/auth`, {
    withCredentials: true
  })

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/2fa/totp/verify
 * @apiName verify2FACode
 * @apiGroup Auth
 *
 * @response
 * HTTP/2 204 No Content: code is valid
 * HTTP/2 401 Unauthorized: authentication required
 * HTTP/2 403 Access Denied: TOTP code invalid
 */
export const verify2FACode = code =>
  client.post(`/auth/2fa/totp/verify`, { code }, { withCredentials: true })

/**
 * @apiVersion 2.0.0
 * @api {post} /api/auth/recovery
 * @apiName signInWithRecoveryCode
 * @apiGroup Auth
 *
 * @response
 * HTTP/2 204 No Content: code is valid
 * HTTP/2 403 Invalid recovery code
 */
export const signInWithRecoveryCode = recovery_code =>
  client.post(`/auth/recovery`, { recovery_code }, { withCredentials: true })

/**
 * @apiVersion 2.0.0
 * @api {get} /api/auth/logout
 * @apiName Logout
 * @apiGroup Auth
 *
 * @response
 * HTTP/2 204 No Content
 */
export const logout = () =>
  client.get(`/auth/logout`, { withCredentials: true })

export const logoutNewLandingi = () => newLandingiClient.post('/logout')

export const customLogin = ({
  email,
  password,
  clientName,
  captchaChallenge
}) =>
  client.post(`/auth/custom-sign-in/${clientName}`, {
    email,
    password,
    source: NEW_LANDINGI_URL,
    captchaChallenge
  })

export const customVerify2FACode = (code, clientName) =>
  client.post(`/auth/2fa/totp/custom-verify/${clientName}`, { code })

export const customSignInWithRecoveryCode = (recovery_code, clientName) =>
  client.post(
    `/auth/custom-recovery/${clientName}`,
    { recovery_code },
    { withCredentials: true }
  )

export const postCustomChangePassword = (token, password, clientName) =>
  client.post(`/auth/custom-reset-password/change/${clientName}`, {
    token,
    password
  })

export const postCustomResetPassword = (email, clientName) =>
  client.post(`/auth/custom-reset-password/${clientName}`, {
    email
  })
