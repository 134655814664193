const LIGHTBOXES = {
  LIGHTBOXES: '/lightboxes',
  LIGHTBOXES_CREATE: '/lightboxes/create',
  LIGHTBOXES_DELETE: '/lightboxes/delete/:identifier',
  LIGHTBOXES_DUPLICATE: '/lightboxes/duplicate/:identifier',
  LIGHTBOXES_PREVIEW: '/lightboxes/preview/:identifier',
  LIGHTBOXES_PREVIEW_DESKTOP: '/lightboxes/preview/:identifier/desktop',
  LIGHTBOXES_PREVIEW_MOBILE: '/lightboxes/preview/:identifier/mobile',
  LIGHTBOXES_PUBLISH: '/lightboxes/publish/:identifier',
  LIGHTBOXES_RENAME: '/lightboxes/rename/:identifier'
}

export default LIGHTBOXES
