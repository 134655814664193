import IFUseCase1 from '@images/addons/usecases/import-from-figma/IF-usecase-1.png'
import IFUseCase2 from '@images/addons/usecases/import-from-figma/IF-usecase-2.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: t('addons.store.import-from-figma.use.cases.1.title'),
    image: IFUseCase1,
    reverse: true,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.import-from-figma.use.cases.1.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.import-from-figma.use.cases.1.content.paragraph.2')}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.import-from-figma.use.cases.2.title'),
    image: IFUseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.import-from-figma.use.cases.2.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.import-from-figma.use.cases.2.content.paragraph.2')}
        </Paragraph>
      </Fragment>
    )
  }
]
