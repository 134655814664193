import { useFeatureFlags } from '@contexts/featureFlags'
import { Paragraph, Spacer, useTable } from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { paragraphColor } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/paragraphColor'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import columns from './columns'
import { ExtraHeaderContent } from './components/ExtraHeaderContent'
import { RowActions } from './components/RowActions'
import styles from './Table.module.scss'

export const Table = () => {
  const { t } = useTranslation()

  const {
    landingPageUrls: {
      data: { collection },
      isLoading
    },
    publishingOptions: {
      data: { isLandingPagePublished },
      isLoading: isPublishingOptionsLoading
    }
  } = usePublishingOptionsContext()

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const freePlanRestrictionsFeatureFlag = useFeatureFlags(
    'FREE_PLAN_RESTRICTIONS'
  )

  const isFreePlanRestrictions =
    freePlanRestrictionsFeatureFlag && !hasAllowTestAutoDomainFeatureFlag

  const constantPageLimit = collection.length || 10
  const isEmptyList = collection.length === 0

  const { Table } = useTable({
    columns,
    data: collection,
    constantPageLimit,
    isLoading: isLoading || isPublishingOptionsLoading,
    rowActions: RowActions,
    externalBorder: true
  })

  return (
    <Fragment>
      <Row
        className={styles['table-description']}
        horizontal='space-between'
        vertical='center'
      >
        <Paragraph
          size={18}
          color={paragraphColor(isLandingPagePublished)}
          padding='none'
          weight={400}
        >
          {t('publishing.options.title.table')}
        </Paragraph>

        <ExtraHeaderContent />
      </Row>

      <Spacer />

      {isFreePlanRestrictions && isEmptyList ? null : <Table />}
    </Fragment>
  )
}

Table.displayName = 'PublishingOptionsViewsMainTable'
