import { Back as BackButton } from '@landingi/landingi-ui-kit'
import { SETTINGS } from '@routes/path'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './Back.module.scss'

export const Back = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // TODO
  const isVisible = location.pathname === SETTINGS.SETTINGS

  const handleBack = () => {
    navigate('/')
  }

  return isVisible ? (
    <BackButton className={styles.back} onClick={handleBack} />
  ) : null
}

Back.displayName = 'TopbarBack'
