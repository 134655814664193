export const APP_LANDINGI_URL = import.meta.env.VITE_APP_LANDINGI_URL
export const APP_SESSION_NAME = import.meta.env.VITE_APP_SESSION_NAME
export const EDITOR_DOMAIN = import.meta.env.VITE_EDITOR_DOMAIN
export const FRESHCHAT_TOKEN = import.meta.env.VITE_FRESHCHAT_TOKEN
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID
export const GTM_ID = import.meta.env.VITE_GTM_ID
export const LOCALFORAGE_KEY = import.meta.env.VITE_LOCALFORAGE_KEY
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
export const LANDINGI_URL = import.meta.env.VITE_LANDINGI_URL
export const { MODE } = import.meta.env
export const NEW_LANDINGI_URL = import.meta.env.VITE_NEW_LANDINGI_URL
export const { PROD } = import.meta.env
export const DEV = import.meta.env.MODE !== 'production'
export const RECAPTCHA_KEY = import.meta.env.VITE_RECAPTCHA_KEY
export const HOTJAR_SITE_ID = import.meta.env.VITE_HOTJAR_SITE_ID
export const SEMRUSH_SENTRY_DSN = import.meta.env.VITE_SEMRUSH_SENTRY_DSN
