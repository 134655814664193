import { mapIconToClass } from '@helpers/icons'
import { useStyles } from '@hooks/useStyles'
import { Close } from '@ui-kit/Close'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spreader } from '@ui-kit/Spreader'
import { FC, ReactNode } from 'react'

import styles from './Alert.module.scss'

export interface AlertProps {
  children: ReactNode
  variant: 'success' | 'warning' | 'info' | 'error'
  className?: string | string[]
  contentClassName?: string | string[]
  additionalContent?: ReactNode
  onCloseClick?: () => void
  customIcon?: string
  'data-testid'?: string
}

export const Alert: FC<AlertProps> = ({
  children,
  variant,
  className,
  contentClassName,
  additionalContent,
  onCloseClick,
  customIcon,
  'data-testid': dataTestId = 'alert-component'
}) => {
  const alertStyles = useStyles(
    {
      [styles.alert]: true,
      [styles[`alert--${variant}`]]: true
    },
    className
  )

  const alertContentStyles = useStyles(
    {
      [styles.alerContent]: true
    },
    contentClassName
  )

  return (
    <div className={alertStyles} data-testid={dataTestId}>
      <div className={alertContentStyles}>
        <Icon
          className={styles.alert__body__icon}
          icon={customIcon || mapIconToClass(variant)}
          size={12}
          color={variant}
        />

        <Spreader spread={10} />

        <Paragraph size={12}>{children}</Paragraph>
      </div>

      <div className={alertContentStyles}>
        {additionalContent}

        <Spreader spread={15} />

        {onCloseClick && <Close size={12} onClick={onCloseClick} />}
      </div>
    </div>
  )
}

Alert.displayName = 'Alert'
