import { useFeatureFlags } from '@contexts/featureFlags'
import { useStyles } from '@hooks/useStyles'
import { Button, Paragraph } from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { paragraphColor } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/paragraphColor'
import { Icon, Spacer, Spreader } from '@ui-kit'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Boxes.module.scss'
import { BoxesComponents } from './BoxesComponents'

export const Boxes = () => {
  const { t } = useTranslation()

  const { landingPageUrls, publishingOptions } = usePublishingOptionsContext()

  const { data } = landingPageUrls

  const isCollapsedInitial = data.collection.length > 1
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedInitial)

  const toggleCollapse = () => setIsCollapsed(prev => !prev)

  const { isLandingPagePublished } = publishingOptions.data

  const HIDE_WORDPRESS_PUBLICATION_FF = useFeatureFlags(
    'HIDE_WORDPRESS_PUBLICATION'
  )

  const boxesStyles = useStyles({
    [styles.boxesWrapper]: true,
    [styles['boxesWrapper--no-wordpress']]: HIDE_WORDPRESS_PUBLICATION_FF
  })

  useEffect(() => {
    setIsCollapsed(isCollapsedInitial)
  }, [isCollapsedInitial])

  return (
    <Fragment>
      {isCollapsedInitial && (
        <Fragment>
          <Row>
            <Button variant='secondary' onClick={toggleCollapse}>
              <span>{t('publishing.options.boxes.collapse.button')}</span>

              <Spreader spread={10} />

              <Icon icon={isCollapsed ? 'icon-caret-down' : 'icon-caret-up'} />
            </Button>
          </Row>

          <Spacer space={10} />
        </Fragment>
      )}

      {!isCollapsed && (
        <Fragment>
          <Spacer space={10} />

          <Paragraph
            size={18}
            color={paragraphColor(isLandingPagePublished)}
            padding='none'
            weight={400}
          >
            {t('publishing.options.title.panels')}
          </Paragraph>

          <Spacer space={5} />

          <Paragraph
            color={paragraphColor(isLandingPagePublished)}
            weight={400}
          >
            {t('publishing.options.desc.panels')}
          </Paragraph>

          <div className={boxesStyles}>
            <BoxesComponents />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

Boxes.displayName = 'PublishingOptionsViewsMainBoxes'
