import { useGetAccountInfo } from '@services/account'
import { AxiosError } from 'axios'
import useSWR from 'swr'

type AclDataResponse = { [key: string]: boolean }

export const useGetAcl = () => {
  const {
    data: accountData,
    isLoading: isLoadingAccountInfo,
    ...rest
  } = useGetAccountInfo()

  const searchParams = new URLSearchParams({
    account_uuid: accountData?.identifier || '',
    user_uuid: accountData?.current_user.identifier || ''
  })

  const {
    data: acl,
    isLoading: isLoadingAcl,
    error: aclServiceError
  } = useSWR<AclDataResponse, AxiosError>(
    accountData ? `acl/access/functionalities?${searchParams.toString()}` : null
  )

  const checkAccessToAcl = (aclKey: string) =>
    acl ? acl[aclKey.trim()] : false

  return {
    data: acl,
    checkAccessToAcl,
    isLoading: isLoadingAccountInfo || isLoadingAcl,
    ...rest,
    error: aclServiceError
  }
}
