export const DEFAULT = 'DEFAULT'
export const HOVERED = 'HOVERED'
export const ACTIVE = 'ACTIVE'
export const DISABLED = 'DISABLED'

export const BOX_OUTLINE_STATUSES = {
  DEFAULT,
  HOVERED,
  ACTIVE,
  DISABLED
} as const

export const borders = {
  [HOVERED]: 'primary',
  [ACTIVE]: 'success',
  [DEFAULT]: 'neutral-2',
  [DISABLED]: 'neutral-2'
} as const
