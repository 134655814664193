import { useUserContext } from '@contexts/user'

import { PaymentError } from '../components/PaymentError'
import { AlertTypes, INFOBAR_TYPE } from '../constants'

export const useGetPaymentError = (): {
  type: AlertTypes
  component: () => JSX.Element
} | null => {
  const { subscription, planPackageUuid } = useUserContext()

  // The string is converted to lowercase for translation purposes (may contain "Default" error)
  const paymentError = subscription?.error?.code?.toLowerCase()

  if (paymentError) {
    return {
      type: INFOBAR_TYPE.ALERT,
      component: () => (
        <PaymentError
          paymentError={paymentError}
          planPackageUuid={planPackageUuid}
        />
      )
    }
  }

  return null
}
