import { useStyles } from '@hooks/useStyles'
import { AnchorHTMLAttributes, FC } from 'react'

import styles from './Button.module.scss'
import { ButtonContent } from './ButtonContent'
import { ButtonBaseProps } from './types'

export interface ButtonLinkProps
  extends ButtonBaseProps,
    Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> {
  disabled?: boolean
}

export const ButtonLink: FC<ButtonLinkProps> = ({
  className = '',
  children,
  variant = 'primary',
  size = 14,
  isLoading = false,
  icon = '',
  hasBackgroundRipple = true,
  active,
  isSquare,
  indicator: Indicator,
  disabled = false,
  href,
  'data-testid': dataTestId,
  ...restProps
}) => {
  const buttonStyles = useStyles(
    {
      [styles.button]: true,
      [styles[`button--link`]]: true,
      [styles[`button--variant-${variant}--disabled`]]: disabled,
      [styles[`button--variant-${variant}`]]: variant,
      [styles[`button--variant-${variant}--active`]]: active,
      [styles[`button--variant-${variant}--loading`]]: isLoading,
      [styles[`button--size-${size}`]]: size,
      [styles[`button--size-${size}-square`]]: (icon && !children) || isSquare
    },
    className
  )

  return (
    <a
      {...restProps}
      href={disabled ? undefined : href}
      className={buttonStyles}
      data-testid={dataTestId}
    >
      <ButtonContent
        isLoading={isLoading}
        icon={icon}
        hasBackgroundRipple={hasBackgroundRipple}
      >
        {children}

        {Indicator ? (
          <span className={styles.indicator}>{Indicator}</span>
        ) : null}
      </ButtonContent>
    </a>
  )
}

ButtonLink.displayName = 'Button'
