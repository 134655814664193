export const COMPANY_PRESENTATION = 'company-presentation'
export const CONTEST_SIGNUP = 'contest-signup'
export const EVENT_SIGNUP = 'event-signup'
export const MOBILE_APP_PROMOTION = 'mobile-app-promotion'
export const NEWSLETTER_SIGNUP = 'newsletter-signup'
export const OFFER_DOWNLOAD = 'offer-discount-coupon-download'
export const PRODUCT_SALE = 'product-sale'
export const RECRUITMENT = 'recruitment'
export const RESOURCE_DOWNLOAD = 'resource-ebook-free-download'
export const SAAS_REGISTRATION = 'saas-app-registration'
export const SCHEDULE_MEETING = 'consultation'
export const SERVICE_SALE = 'service-sale'
export const WAITLIST = 'waitlist-coming-soon'
export const WEBINAR_SIGNUP = 'webinar-signup'

export type LpGoal =
  | typeof COMPANY_PRESENTATION
  | typeof CONTEST_SIGNUP
  | typeof EVENT_SIGNUP
  | typeof MOBILE_APP_PROMOTION
  | typeof NEWSLETTER_SIGNUP
  | typeof OFFER_DOWNLOAD
  | typeof PRODUCT_SALE
  | typeof RECRUITMENT
  | typeof RESOURCE_DOWNLOAD
  | typeof SAAS_REGISTRATION
  | typeof SCHEDULE_MEETING
  | typeof SERVICE_SALE
  | typeof WAITLIST
  | typeof WEBINAR_SIGNUP
