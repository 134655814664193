import { COUNTRY_CODES } from '@components/Topbar/components/Infobars/constants'
import styles from '@components/Topbar/components/Infobars/Infobars.module.scss'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { useUserContext } from '@contexts/user'
import { formatNumericBasedOnLanguage } from '@helpers/data'
import { PAYMENTS } from '@routes/path'
import { useGetAccountLimits } from '@services/limits'
import { Button, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const HasSubscription = () => {
  const { t } = useTranslation()

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const { user, auth } = useUserContext()

  const {
    profile: { country }
  } = user

  const buttonLinkContent =
    country === COUNTRY_CODES.BR
      ? 'infobar.quick.demo.href'
      : 'infobar.schedule.free.onboarding.session.href'

  const buttonContent =
    country === COUNTRY_CODES.BR
      ? 'infobar.quick.demo'
      : 'infobar.schedule.free.onboarding.session'

  const limits = useGetAccountLimits()

  const accountCreationDate = new Date(user.created_at.formatted)
  const trialDays = auth?.free_trial_days || 14

  const trialEndDate = new Date(accountCreationDate)
  trialEndDate.setDate(accountCreationDate.getDate() + trialDays)

  const currentDate = new Date()

  const timeDifference = trialEndDate.getTime() - currentDate.getTime()

  const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  const handleOpenLink = () => {
    window.open(t(buttonLinkContent), '_blank')
  }

  const handleOpenPaymentsPage = () => {
    window.open(
      `${NEW_LANDINGI_APP_URL}${PAYMENTS.SUBSCRIPTION.SUBSCRIPTION}`,
      '_self'
    )
  }

  return (
    <Fragment>
      {/* use InfobarContent component instead of <span> tag */}
      <span className={styles.alert__content}>
        <Trans
          i18nKey='infobar.freetrial.subscription.few-days'
          values={{
            count: days,
            trafficCount: formatNumericBasedOnLanguage(
              limits.uniqueVisits?.uniqueVisitsCount ?? 0
            ),
            packageName: user.plan.name,
            regularPaidTrafficLimit: formatNumericBasedOnLanguage(
              limits.uniqueVisits?.regularLimit ?? 0
            )
          }}
          components={{
            b: <b />,
            br: <br />
          }}
        />
      </span>

      <Row>
        <Button variant='text-primary' size={14} onClick={handleOpenLink}>
          {t(buttonContent)}
        </Button>

        {hasAccessToPayments && (
          <Fragment>
            <Spreader spread={20} />

            <Button size={14} onClick={handleOpenPaymentsPage}>
              {t('payments.start-your-plan-now')}
            </Button>
          </Fragment>
        )}
      </Row>
    </Fragment>
  )
}

HasSubscription.displayName = 'TopbarInfobarAccountExpiresAtHasSubscription'
