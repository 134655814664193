import SmartSections from '@images/addons/overview/SmartSections-overview.png'
import { Image } from '@ui-kit/Image'
import { TFunction } from 'i18next'

export const getOverview = (t: TFunction<'translation', undefined>) => ({
  heading: t('addons.store.smart.sections.overview.heading'),
  leftSide: <Image src={SmartSections} height='360px' width='640px' />,
  list: [
    t('addons.store.smart.sections.overview.list.1'),
    t('addons.store.smart.sections.overview.list.2'),
    t('addons.store.smart.sections.overview.list.3')
  ]
})
