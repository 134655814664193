import { useStyles } from '@hooks/useStyles'
import { FC } from 'react'

import styles from './Spacer.module.scss'

export type SpacerSize = 5 | 10 | 15 | 20 | 25 | 30 | 40 | 50 | 70 | 100

interface SpacerProps {
  className?: string | string[]
  space?: SpacerSize
  'data-testid'?: string
}

export const Spacer: FC<SpacerProps> = ({
  className = '',
  space = 15,
  'data-testid': dataTestId = 'spacer'
}) => {
  const spacerStyles = useStyles(
    {
      [styles[`spacer--space-${space}`]]: true
    },
    className
  )

  return <div data-testid={dataTestId} className={spacerStyles} />
}

Spacer.displayName = 'Spacer'
