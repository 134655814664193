import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { THRIVE_COACH_COLOR } from '@pages/Authentication/routes/WhitelabelPages/constants'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import ResetPasswordForm from './Form'

/**
 * ResetPassword - stateless presentational component with view of reset password form
 * @param {object} props - props
 * @return {object} An object of children
 */
const ResetPassword = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel borderRadius='10px' hasShadow={false}>
        <Heading level={1} bold>
          {t('registration.flow.reset.your.password')}
        </Heading>

        {t('registration.flow.enter.your.account.email')}

        <Spacer space='medium' />

        <ResetPasswordForm />
      </Panel>

      <Paragraph size={12} color='accent-2' align='center'>
        <Link
          to={`${REGISTRATION.LOGIN_THRIVE_COACH}`}
          style={{ color: THRIVE_COACH_COLOR }}
        >
          {t('registration.flow.go.back.to.sign.in')}
        </Link>
      </Paragraph>
    </Column>
  )
}

ResetPassword.displayName = 'Reset Password'

export default ResetPassword
