import { List, ListItem } from '@landingi/landingi-ui-kit'
import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

export const getWhatHappensAfterFreeTrialQuestion = (
  t: TFunction<'translation', undefined>
) => ({
  title: (
    <Row alignItems='center'>
      <StepNumber step={1} />

      <Spreader spread={15} />

      {t('addons.store.event.tracker.faq.question.1')}
    </Row>
  ),
  content: (
    <Fragment>
      <Paragraph size={14}>
        {t('addons.store.event.tracker.faq.answer.1')}
      </Paragraph>
      <List listStyle='ordered-decimal'>
        <ListItem variant='list'>
          <Paragraph size={14} weight={600}>
            {t('addons.store.event.tracker.faq.answer.1.case.1')}
          </Paragraph>
          <Paragraph size={14}>
            {t('addons.store.event.tracker.faq.answer.1.case.1.description')}
          </Paragraph>
        </ListItem>
        <ListItem variant='list'>
          <Paragraph size={14} weight={600}>
            {t('addons.store.event.tracker.faq.answer.1.case.2')}
          </Paragraph>
          <Paragraph size={14}>
            {t('addons.store.event.tracker.faq.answer.1.case.2.description')}
          </Paragraph>
        </ListItem>
        <ListItem variant='list'>
          <Paragraph size={14} weight={600}>
            {t('addons.store.event.tracker.faq.answer.1.case.3')}
          </Paragraph>
          <Paragraph size={14}>
            {t('addons.store.event.tracker.faq.answer.1.case.3.description')}
          </Paragraph>
        </ListItem>
      </List>
    </Fragment>
  )
})

export const getHowCanICancelFeatureQuestion = (
  t: TFunction<'translation', undefined>
) => ({
  title: (
    <Row alignItems='center'>
      <StepNumber step={2} />

      <Spreader spread={15} />

      {t('addons.store.faq.question.2')}
    </Row>
  ),
  content: <Paragraph size={14}>{t('addons.store.faq.answer.2')}</Paragraph>
})

export const getAIAddonFirstQuestion = (
  t: TFunction<'translation', undefined>
) => ({
  title: (
    <Row alignItems='center'>
      <StepNumber step={1} />

      <Spreader spread={15} />

      {t('addons.store.ai.addon.faq.1st.question')}
    </Row>
  ),
  content: (
    <Fragment>
      <Paragraph size={14}>
        {t('addons.store.ai.addon.faq.1st.answer')}
      </Paragraph>
      <List listStyle='ordered-disc'>
        <ListItem variant='list'>
          {t('addons.store.ai.addon.faq.1st.answer.1.bullet.point')}
        </ListItem>
        <ListItem variant='list'>
          {t('addons.store.ai.addon.faq.1st.answer.2.bullet.point')}
        </ListItem>
        <ListItem variant='list'>
          {t('addons.store.ai.addon.faq.1st.answer.3.bullet.point')}
        </ListItem>
      </List>
    </Fragment>
  )
})

export const getAIAddonSecondQuestion = (
  t: TFunction<'translation', undefined>
) => ({
  title: (
    <Row alignItems='center'>
      <StepNumber step={2} />

      <Spreader spread={15} />

      {t('addons.store.ai.addon.faq.2nd.question')}
    </Row>
  ),
  content: (
    <Paragraph size={14}>{t('addons.store.ai.addon.faq.2nd.answer')}</Paragraph>
  )
})
