import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

export const getPricing = (
  t: TFunction<'translation', undefined>,
  isAgency: boolean
) => ({
  list: isAgency
    ? [
        <Trans i18nKey='addons.store.event.tracker.pricing.list.1.agency' />,
        <Trans i18nKey='addons.store.event.tracker.pricing.list.2.agency' />,
        <Trans i18nKey='addons.store.event.tracker.pricing.list.3.agency' />
      ]
    : [
        <Trans i18nKey='addons.store.event.tracker.pricing.list.1.business' />,
        <Trans i18nKey='addons.store.event.tracker.pricing.list.2.business' />,
        <Trans i18nKey='addons.store.event.tracker.pricing.list.3.business' />
      ],
  billedAnnuallyTooltip: t('addons.store.longer.access.to.event.tracker')
})
