import {
  BoxOutline,
  BoxOutlineProvider,
  Button,
  Dropdown,
  DropdownElement,
  Icon,
  Image,
  Radio,
  Spacer,
  Spreader
} from '@ui-kit'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const availableBuilders: Record<string, string> = {
  unbounce: 'Unbounce',
  instapage: 'Instapage',
  leadPages: 'LeadPages',
  clickFunnels: 'ClickFunnels',
  swipePages: 'SwipePages',
  carrd: 'Carrd',
  other: 'Other'
}

interface SelectBoxRadioWithDropdownProps {
  iconSource: string
  field: {
    name: string
    value: string
  }
  onChange?: () => void
  values: {
    builder: string
  }
  setFieldValue: (name: string, value: string) => void
  id: string
}

export const SelectBoxRadioWithDropdown: FC<
  SelectBoxRadioWithDropdownProps
> = ({ iconSource, field, onChange, setFieldValue, id, values }) => {
  const { t } = useTranslation()

  const { name, value } = field

  const isActive = () => value === id

  const handleClick = () => {
    setFieldValue('experience_level', `competitor`)

    onChange?.()
  }

  const getLabelOfBuilder = (builder: string) => {
    const nameOfBuilder = builder.split('-')[1]

    return availableBuilders[nameOfBuilder]
  }

  const customTrigger = ({ isOpen }: { isOpen: boolean }) => (
    <Button variant='text-neutral'>
      {values.builder
        ? getLabelOfBuilder(values.builder)
        : t('registration.flow.survey.choose.builder')}

      <Spreader spread={10} />

      <Icon icon={isOpen ? 'icon-angle-up' : 'icon-angle-down'} />
    </Button>
  )

  return (
    <BoxOutlineProvider controlledActive={isActive()}>
      <BoxOutline padding={15} onClick={handleClick}>
        <Row alignItems='center'>
          <Column
            style={{
              marginTop: '-18px'
            }}
          >
            <Radio
              field={{
                name,
                value,
                onChange: event => {
                  setFieldValue('experience_level', event?.target?.value)
                  onChange?.()
                }
              }}
              id={id}
            />
          </Column>

          <Column style={{ marginLeft: '3px' }}>
            <Row alignItems='center'>
              <Image src={iconSource} width='48px' height='48px' />

              <Spreader spread={10} />

              {t('registration.flow.survey.another.builder')}
            </Row>
          </Column>
        </Row>

        {isActive() && (
          <Fragment>
            <Spacer space={15} />

            <Row>
              <Dropdown trigger={customTrigger}>
                {({ close }) => (
                  <Fragment>
                    {Object.entries(availableBuilders).map(([key, value]) => (
                      <DropdownElement
                        key={key}
                        onClick={event => {
                          event.stopPropagation()

                          setFieldValue('builder', `competitor-${key}`)

                          close()
                        }}
                      >
                        {value}
                      </DropdownElement>
                    ))}
                  </Fragment>
                )}
              </Dropdown>
            </Row>
          </Fragment>
        )}
      </BoxOutline>
    </BoxOutlineProvider>
  )
}
