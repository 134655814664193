import client, { newLandingiClient } from '@lib/http/client'

export interface closeSupportMessageRequest {
  identifier: number
}

export const closeSupportMessage = async ({
  identifier
}: closeSupportMessageRequest) =>
  client.get(`technical-measures/support-message/${identifier}`)

export const closeMessageNewLandingi = async ({
  identifier
}: closeSupportMessageRequest) =>
  newLandingiClient.get(`technical-message/close/${identifier}`)
