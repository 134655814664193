import companyPresentation from '@images/wizard/goals/companyPresentation.png'
import contestSignup from '@images/wizard/goals/contestSignup.png'
import eventSignup from '@images/wizard/goals/eventSignup.png'
import mobileAppPromotion from '@images/wizard/goals/mobileAppPromotion.png'
import newsletterSignup from '@images/wizard/goals/newsletterSignup.png'
import offerDownload from '@images/wizard/goals/offerDownload.png'
import products from '@images/wizard/goals/products.png'
import productSale from '@images/wizard/goals/productSale.png'
import recruitment from '@images/wizard/goals/recruitment.png'
import resourceDownload from '@images/wizard/goals/resourceDownload.png'
import saasRegistration from '@images/wizard/goals/saasRegistration.png'
import scheduleMeeting from '@images/wizard/goals/scheduleMeeting.png'
import services from '@images/wizard/goals/services.png'
import serviceSale from '@images/wizard/goals/serviceSale.png'
import waitlist from '@images/wizard/goals/waitlist.png'
import webinarSignup from '@images/wizard/goals/webinarSignup.png'

import {
  COMPANY_PRESENTATION,
  CONTEST_SIGNUP,
  EVENT_SIGNUP,
  MOBILE_APP_PROMOTION,
  NEWSLETTER_SIGNUP,
  OFFER_DOWNLOAD,
  PRODUCT_SALE,
  RECRUITMENT,
  RESOURCE_DOWNLOAD,
  SAAS_REGISTRATION,
  SCHEDULE_MEETING,
  SERVICE_SALE,
  WAITLIST,
  WEBINAR_SIGNUP
} from '../constants/lpGoals'

export const secondStepOptions = [
  { name: COMPANY_PRESENTATION, image: companyPresentation },
  { name: CONTEST_SIGNUP, image: contestSignup },
  { name: EVENT_SIGNUP, image: eventSignup },
  { name: MOBILE_APP_PROMOTION, image: mobileAppPromotion },
  { name: NEWSLETTER_SIGNUP, image: newsletterSignup },
  { name: OFFER_DOWNLOAD, image: offerDownload },
  { name: PRODUCT_SALE, image: productSale },
  { name: RECRUITMENT, image: recruitment },
  { name: RESOURCE_DOWNLOAD, image: resourceDownload },
  { name: SAAS_REGISTRATION, image: saasRegistration },
  { name: SCHEDULE_MEETING, image: scheduleMeeting },
  { name: SERVICE_SALE, image: serviceSale },
  { name: WAITLIST, image: waitlist },
  { name: WEBINAR_SIGNUP, image: webinarSignup }
] as const

export const thirdStepOptions = [
  { name: 'product', image: products },
  { name: 'service', image: services }
]

export const optionsWithAccessToThirdStep = [
  COMPANY_PRESENTATION,
  WAITLIST,
  OFFER_DOWNLOAD
]

export const pallets = {
  modern: [
    {
      mainColors: ['#6D20F7', '#F5DB31', '#8D8D8D'],
      colors: [
        ['#CDB3FC', '#B48DFA', '#6D20F7', '#4915A5', '#240B52'],
        ['#FCF3B9', '#F8EB95', '#F5DB31', '#A39221', '#524910'],
        ['#EBEBEB', '#BCBCBC', '#8D8D8D', '#5E5E5E', '#2F2F2F']
      ],
      font: 'Lato'
    },
    {
      mainColors: ['#EC774D', '#7389B8', '#949190'],
      colors: [
        ['#F9D0C1', '#F2B6A1', '#EC774D', '#9D4F33', '#4F281A'],
        ['#CCDAFA', '#9AB7F5', '#7389B8', '#4D5C7A', '#262E3D'],
        ['#F6F2F0', '#C5C2C0', '#949190', '#626160', '#313030']
      ],

      font: 'Exo'
    },
    {
      mainColors: ['#CB527C', '#6D848C', '#989892'],
      colors: [
        ['#EEBECF', '#DC9BB1', '#CB527C', '#873753', '#441B29'],
        ['#BCD2D9', '#95ABB2', '#6D848C', '#445D65', '#15353F'],
        ['#FEFEF4', '#CBCBC3', '#989892', '#666662', '#333331']
      ],
      font: 'Oswald'
    }
  ],
  dark: [
    {
      mainColors: ['#AD7955', '#6A6A6A', '#b5b5b5'],
      colors: [
        ['#FAD5B8', '#F8AC78', '#AD7955', '#6A4A36', '#2F231A'],
        ['#CACACA', '#979797', '#6A6A6A', '#3F3F3F', '#1C1C1C'],
        ['#FFFFFF', '#ededed', '#b5b5b5', '#5b5b5b', '#000000']
      ],
      font: 'Raleway'
    },
    {
      mainColors: ['#A8494F', '#605B5B', '#b5b5b5'],
      colors: [
        ['#F0ACB0', '#E06169', '#A8494F', '#703135', '#38181A'],
        ['#C5BFBF', '#908A8A', '#605B5B', '#353131', '#121010'],
        ['#FFFFFF', '#ededed', '#b5b5b5', '#5b5b5b', '#000000']
      ],
      font: 'Georgia'
    },
    {
      mainColors: ['#5C4AB6', '#696676', '#b5b5b5'],
      colors: [
        ['#BBAFF9', '#7A62F3', '#5C4AB6', '#3D3179', '#1F183D'],
        ['#C6C3D1', '#9795A4', '#696676', '#383548', '#1C1B24'],
        ['#FFFFFF', '#ededed', '#b5b5b5', '#5b5b5b', '#000000']
      ],
      font: 'Comfortaa'
    }
  ],
  elegant: [
    {
      mainColors: ['#416B7B', '#B6AB9E', '#EDEDED'],
      colors: [
        ['#C0CED3', '#819DA7', '#416B7B', '#023A4F', '#022C3B'],
        ['#E7E3DF', '#CEC7BF', '#B6AB9E', '#9D8F7E', '#766B5F'],
        ['#F6F6F6', '#F2F2F2', '#EDEDED', '#B2B2B2', '#777777']
      ],
      font: 'Neuton'
    },
    {
      mainColors: ['#512D38', '#CC9EA9', '#F3EFE4'],
      colors: [
        ['#A8969C', '#7D626A', '#512D38', '#3D222A', '#29171C'],
        ['#EEDFE2', '#DDBFC6', '#CC9EA9', '#BB7E8C', '#8C5F69'],
        ['#F9F7F2', '#F6F3EB', '#F3EFE4', '#B6B3AB', '#7A7872']
      ],
      font: 'Times New Roman'
    },
    {
      mainColors: ['#7C7168', '#8EB0A7', '#EEE4E1'],
      colors: [
        ['#D3D0CD', '#A8A09B', '#7C7168', '#504136', '#3C3129'],
        ['#D9E5E2', '#B4CBC4', '#8EB0A7', '#689689', '#4E7167'],
        ['#F7F2F0', '#F2EBE9', '#EEE4E1', '#B3ABA9', '#777271']
      ],
      font: 'Dosis'
    }
  ],
  minimal: [
    {
      mainColors: ['#FF6E64', '#084C61', '#b5b5b5'],
      colors: [
        ['#FFB1AB', '#FF928B', '#FF6E64', '#BF534B', '#803732'],
        ['#84A6B0', '#467989', '#084C61', '#063949', '#031D25'],
        ['#FFFFFF', '#ededed', '#b5b5b5', '#5b5b5b', '#000000']
      ],
      font: 'Lato'
    },
    {
      mainColors: ['#2D8655', '#585855', '#b5b5b5'],
      colors: [
        ['#6DC695', '#3CB371', '#2D8655', '#1E5239', '#17432B'],
        ['#C7C7C6', '#90908E', '#585855', '#20201C', '#181815'],
        ['#FFFFFF', '#ededed', '#b5b5b5', '#5b5b5b', '#000000']
      ],
      font: 'Raleway'
    },
    {
      mainColors: ['#DCB203', '#6A4444', '#b5b5b5'],
      colors: [
        ['#EED981', '#E5C542', '#DCB203', '#A58602', '#6E5902'],
        ['#CDC1C1', '#9C8282', '#6A4444', '#380505', '#210303'],
        ['#FFFFFF', '#ededed', '#b5b5b5', '#5b5b5b', '#000000']
      ],
      font: 'Open Sans'
    }
  ]
}
