import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import LoginForm from './Form'

const Login = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel borderRadius='10px'>
        <Heading level={1} bold>
          {t('registration.flow.sign.in.to.account')}
        </Heading>

        <Spacer space='tiny' />

        <Spacer />

        <LoginForm />

        <Spacer space='small' />
      </Panel>

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Paragraph size={12} color='accent-2' align='center'>
        <Link
          to={`${REGISTRATION.FORGOT_PASSWORD_WEBTOLEARN}`}
          style={{ color: 'white' }}
        >
          {t('registration.flow.forgotten.password')}
        </Link>
      </Paragraph>
    </Column>
  )
}

Login.displayName = 'WhitelabelLogin'

export default Login
