import { MODAL_CREDITS_PACKAGE } from '@components/Modals/ModalCreditsPackage'
import { CLICK_BUY_CREDITS } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import NiceModal from '@ebay/nice-modal-react'
import { formatNumeric } from '@helpers/data'
import { CustomLink, Tooltip } from '@landingi/landingi-ui-kit'
import { useGetCredits } from '@services/credits/useGetCredits'
import { mixpanelEvent } from '@services/mixpanel'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spreader } from '@ui-kit/Spreader'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './CreditsBalance.module.scss'

export const CreditsBalance = ({
  clickLocation
}: {
  clickLocation: string
}) => {
  const { t } = useTranslation()

  const { credits, isLoading } = useGetCredits()

  const hasAccessToUseCredits = useAcl('credits.use_credits')
  const hasAccessToBuyCredits = useAcl('credits.buy_credits')

  if (!hasAccessToUseCredits) {
    return null
  }

  const getAmountLabel = () => (
    <span data-testid='credits-amount'>
      {formatNumeric(credits ?? 0)} {t('word.credits.left')}
    </span>
  )

  const loadingLabel = (
    <Fragment>
      <Icon icon='icon-spinner' spin color='primary' />
      <Spreader spread={5} />
      {t('word.credits.left')}
    </Fragment>
  )

  const label = isLoading ? loadingLabel : getAmountLabel()

  const handleOpenModal = () => {
    mixpanelEvent({
      name: CLICK_BUY_CREDITS,
      properties: {
        click_location: clickLocation,
        type: 'normal'
      }
    })

    NiceModal.show(MODAL_CREDITS_PACKAGE, {
      properties: {
        click_location: clickLocation,
        type: 'normal'
      }
    })
  }

  return (
    <Row alignItems='baseline' className={styles.wrapper}>
      {hasAccessToBuyCredits ? (
        <CustomLink
          className={styles.link}
          variant='active'
          label={label}
          bold
          onClick={handleOpenModal}
        />
      ) : (
        <Paragraph
          data-testid='balance-not-clickable'
          color='primary'
          weight={600}
        >
          {label}
        </Paragraph>
      )}

      <Spreader spread={5} />

      <Tooltip placement='top' content={t('credits.left.tooltip')}>
        <Icon icon='icon-info-circle' color='neutral' size={14} />
      </Tooltip>
    </Row>
  )
}
