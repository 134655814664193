import { useUserContext } from '@contexts/user'
import {
  changeUserAccount,
  useSubaccountsIfPossible
} from '@services/subaccounts'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'

import { useLazyService } from './useLazyService'

export const useChangeSubaccountToMain = () => {
  const { t } = useTranslation()

  const { isSubaccount, isTypeClient } = useUserContext()

  const { data: subaccounts, isLoading: areSubaccountsLoading } =
    useSubaccountsIfPossible()

  // kinda tricky way to check if user type is manager, as apparently there is no MANAGER type returned
  // from backend, as types in DB are only ADMIN, OWNER and CLIENT. It will be removed when test is over
  // and replaced by ACL entry with different accesses than those we want for testing purposes.
  const isTypeManager = subaccounts
    ? !subaccounts.some(subaccount => subaccount.isMain)
    : false

  const refreshUserAuthorizationAndInfo = () => {
    mutate(() => true, undefined, { revalidate: false })
    mutate('auth', undefined, { revalidate: true })
    mutate('account/info', undefined, { revalidate: true })
    mutate('acl/access/features', undefined, { revalidate: true })
  }

  const [
    requestChangeUserAccount,
    { isLoading: isRequestChangeUserAccountLoading }
  ] = useLazyService(changeUserAccount, {
    onSuccess: refreshUserAuthorizationAndInfo,
    successToastText: t('toast.change.user-account.success'),
    errorToastText: t('toast.change.user-account.error')
  })

  const changeToMainAccountIfNeeded = async () => {
    if (isSubaccount && !isTypeClient && subaccounts) {
      const mainAccount = subaccounts.find(subaccount => subaccount.isMain)
      if (mainAccount) {
        await requestChangeUserAccount(mainAccount.uuid)
      }
    }
  }

  return {
    areSubaccountsLoading,
    isRequestChangeUserAccountLoading,
    changeToMainAccountIfNeeded,
    isTypeManager
  }
}
