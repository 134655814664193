import { useStyles } from '@hooks/useStyles'
import { useTabsContext } from '@ui-kit/Tabs/context'
import { useVariantContext } from '@ui-kit/Tabs/context/VariantContext'
import {
  ButtonHTMLAttributes,
  FC,
  PointerEvent,
  ReactNode,
  useCallback
} from 'react'
import Ink from 'react-ink'

import styles from './Tab.module.scss'

export interface TabProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  className?: string | string[]
  children: ReactNode
  name: string
  onDisabledTabClick?: (event: PointerEvent<HTMLButtonElement>) => void
  'data-testid'?: string
}

export const Tab: FC<TabProps> = ({
  className,
  children,
  name,
  onClick,
  onDisabledTabClick,
  'data-testid': dataTestId = 'tab',
  ...restProps
}) => {
  const {
    activeTab,
    changeActiveTab,
    isScrollable,
    scrollOffset,
    setCanBeChangedByScrolling
  } = useTabsContext()
  const { variant } = useVariantContext()

  const tabStyles = useStyles(
    {
      [styles.tab]: true,
      [styles[`tab--variant-${variant}`]]: true,
      [styles[`tab--variant-${variant}--active`]]: activeTab === name
    },
    className
  )

  const scrollToTab = useCallback(
    (nextActiveTabName: string) => {
      const scrollableElement = document.querySelector(
        `#${nextActiveTabName}--scrollable`
      )

      if (!scrollableElement) {
        return
      }

      const positionToScrollTo =
        scrollableElement.getBoundingClientRect().top +
        window.scrollY -
        scrollOffset

      window.scrollTo({ top: positionToScrollTo })
    },
    [scrollOffset]
  )

  const handleClick = (event: PointerEvent<HTMLButtonElement>) => {
    if (isScrollable) {
      scrollToTab(name)

      /* disable changing tab by scrolling, so it stays the same while scrolling to
      it. It is activated again on scrollend event. */
      setCanBeChangedByScrolling(false)

      changeActiveTab(name)
    } else {
      changeActiveTab(name)
    }

    onClick?.(event)
  }

  return (
    <button
      {...restProps}
      data-testid={dataTestId}
      className={tabStyles}
      onClick={handleClick}
      type='button'
    >
      {children}

      <Ink />
    </button>
  )
}

Tab.displayName = 'Tab'
