import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.e.commerce.faq.question.2')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.e.commerce.faq.answer.2')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.e.commerce.faq.question.3')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        <Trans
          i18nKey='addons.store.e.commerce.faq.answer.3'
          components={{
            a: (
              <a
                href={t('addons.store.e.commerce.faq.answer.3.link')}
                target='_blank'
                rel='noreferrer'
              />
            )
          }}
        />
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        {t('addons.store.e.commerce.faq.question.4')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.e.commerce.faq.answer.4')}
      </Paragraph>
    )
  }
]
