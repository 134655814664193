import { useStyles } from '@hooks/useStyles'
import { Spacer } from '@ui-kit/Spacer'
import { forwardRef, Fragment, TextareaHTMLAttributes } from 'react'

import styles from './Textarea.module.scss'

export interface TextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'className'> {
  className?: string | string[]
  id: string
  i18n?: {
    placeholder?: string
    label?: string
  }
  isResizable?: boolean
  'data-testid'?: string
  error?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      id,
      i18n,
      isResizable = true,
      error,
      'data-testid': dataTestId = 'textarea',
      ...restProps
    },
    ref
  ) => {
    const labelStyles = useStyles({
      [styles.textarea__label]: true,
      [styles['textarea__label--error']]: error
    })

    const textAreaStyles = useStyles(
      {
        [styles.textarea]: true,
        [styles[`textarea--resizable`]]: isResizable,
        [styles['textarea--error']]: error
      },
      className
    )

    return (
      <Fragment>
        {i18n?.label && (
          <Fragment>
            <label className={labelStyles} htmlFor={id}>
              {i18n?.label}
            </label>

            <Spacer space={10} />
          </Fragment>
        )}

        <textarea
          {...restProps}
          data-testid={dataTestId}
          ref={ref}
          className={textAreaStyles}
          placeholder={i18n?.placeholder}
        />

        {error && <p className={styles.textarea__error}>{error}</p>}
      </Fragment>
    )
  }
)

Textarea.displayName = 'Textarea'
