import { PLANS } from './plans'

const {
  LITE_LIMITED_24,
  LITE_22,
  PROFESSIONAL_24,
  CORE_20,
  CORE_21,
  CREATE_20,
  CREATE_21,
  AUTOMATE_20,
  AUTOMATE_21,
  AGENCY_19,
  AGENCY_PRO_19,
  AGENCY_21,
  AGENCY_22,
  AUTOMATE_DISC_20,
  CORE_DISC_20,
  AGENCY_PRO_20_12M,
  AGENCY_20_12M,
  AUTOMATE_20_12M,
  CREATE_DISC_20,
  AUTOMATE_DISC_21,
  CREATE_DISC_21,
  CORE_DISC_21,
  AGENCY_21_12M,
  AUTOMATE_21_12M,
  CREATE_21_12M,
  CORE_21_12M,
  CREATE_20_12M,
  CORE_20_12M
} = PLANS

export const PROMOTION_3M_PLANS = [
  LITE_LIMITED_24,
  PROFESSIONAL_24,
  AGENCY_22
] as const

export const PROMOTION_FOR_FREE_PLAN_PLANS = [
  LITE_LIMITED_24,
  PROFESSIONAL_24,
  AGENCY_22
] as const

export const PROMOTION_12M_PLANS = [
  LITE_LIMITED_24,
  PROFESSIONAL_24,
  AGENCY_22,
  CORE_21,
  CREATE_21,
  AUTOMATE_21,
  AGENCY_21
] as const

export const promotion3MDiscountedPricesBrutto = {
  [LITE_LIMITED_24]: {
    PLN: 60.89,
    USD: 14.5,
    BRL: 49.5,
    GBP: 12.5,
    EUR: 14.5
  },
  [PROFESSIONAL_24]: {
    PLN: 153.14,
    USD: 34.5,
    BRL: 149.5,
    GBP: 29.5,
    EUR: 34.5
  },
  [AGENCY_22]: {
    PLN: 306.87,
    USD: 74.5,
    BRL: 249.5,
    GBP: 59.5,
    EUR: 74.5
  }
} as const

export const promotion12MDiscountedPricesBrutto = {
  [LITE_LIMITED_24]: {
    PLN: 76.67, // 920.04 / 12
    USD: 19.8333, // 238 / 12
    BRL: 62.3333, // 748 / 12
    GBP: 16.6666, // 200 / 12
    EUR: 19.8333 // 238 / 12
  },
  [PROFESSIONAL_24]: {
    PLN: 203.77, // 2445.24 / 12
    USD: 48.6666, // 584 / 12
    BRL: 215.6666, // 2588 / 12
    GBP: 42.3333, // 508 / 12
    EUR: 48.6666 // 584 / 12
  },
  [AGENCY_22]: {
    PLN: 367.77, // 4413,24 / 12
    USD: 95, // 1140 / 12
    BRL: 399, // 4788 / 12
    GBP: 79, // 948 / 12
    EUR: 95 // 1140 / 12
  },
  [CORE_21]: {
    PLN: 101.27, // 1215,24 / 12
    USD: 24.83, // 298 / 12
    BRL: 128.33, // 1 540 / 12
    GBP: 19.67, // 236 / 12
    EUR: 28.83 // 250 / 12
  },
  [CREATE_21]: {
    PLN: 203.77, // 2445.24 / 12
    USD: 56.67, // 680 / 12
    BRL: 291.67, // 3500 / 12
    GBP: 42.33, // 508 / 12
    EUR: 46.67 // 560 / 12
  },
  [AUTOMATE_21]: {
    PLN: 261.17, // 3134.04 / 12
    USD: 72.33, // 868 / 12
    BRL: 378.33, // 4540 / 12
    GBP: 51.67, // 620 / 12
    EUR: 58.33 // 700 / 12
  },
  [AGENCY_21]: {
    PLN: 306.27, // 3675.24 / 12
    USD: 84.0, // 1008 / 12
    BRL: 445.0, // 5340 / 12
    GBP: 59.0, // 708 / 12
    EUR: 65.0 // 780 / 12
  }
}

export const discount12MForPlan = {
  [LITE_LIMITED_24]: {
    PLN: 200,
    USD: 50,
    BRL: 200,
    GBP: 40,
    EUR: 50
  },
  [LITE_22]: {
    PLN: 200,
    USD: 50,
    BRL: 200,
    GBP: 40,
    EUR: 50
  },
  [PROFESSIONAL_24]: {
    PLN: 400,
    USD: 100,
    BRL: 400,
    GBP: 80,
    EUR: 100
  },
  [AGENCY_22]: {
    PLN: 1200,
    USD: 300,
    BRL: 1200,
    GBP: 240,
    EUR: 300
  },
  [CORE_20]: {
    PLN: 200,
    USD: 50,
    BRL: 200,
    GBP: 40,
    EUR: 50
  },
  [CORE_21]: {
    PLN: 200,
    USD: 50,
    BRL: 200,
    GBP: 40,
    EUR: 50
  },
  [CREATE_20]: {
    PLN: 400,
    USD: 100,
    BRL: 400,
    GBP: 80,
    EUR: 100
  },
  [CREATE_21]: {
    PLN: 400,
    USD: 100,
    BRL: 400,
    GBP: 80,
    EUR: 100
  },
  [AUTOMATE_20]: {
    PLN: 800,
    USD: 200,
    BRL: 800,
    GBP: 160,
    EUR: 200
  },
  [AUTOMATE_21]: {
    PLN: 800,
    USD: 200,
    BRL: 800,
    GBP: 160,
    EUR: 200
  },
  [AGENCY_19]: {
    PLN: 1200,
    USD: 300,
    BRL: 1200,
    GBP: 240,
    EUR: 300
  },
  [AGENCY_PRO_19]: {
    PLN: 1200,
    USD: 300,
    BRL: 1200,
    GBP: 240,
    EUR: 300
  },
  [AGENCY_21]: {
    PLN: 1200,
    USD: 300,
    BRL: 1200,
    GBP: 240,
    EUR: 300
  }
}

export const monthsOfEventTrackerFor12MPromotion = {
  [LITE_LIMITED_24]: 3,
  [PROFESSIONAL_24]: 4,
  [AGENCY_22]: 5,
  [CORE_21]: 2,
  [CREATE_21]: 3,
  [AUTOMATE_21]: 4,
  [AGENCY_21]: 5
}

export const numberOfCreditsFor12MPromotion = {
  [LITE_LIMITED_24]: '5 000',
  [PROFESSIONAL_24]: '10 000',
  [AGENCY_22]: '20 000',
  [CORE_21]: '3 000',
  [CREATE_21]: '5 000',
  [AUTOMATE_21]: '10 000',
  [AGENCY_21]: '20 000'
}

export const promotionForFreePlanDiscountedPricesBrutto = {
  [LITE_LIMITED_24]: {
    PLN: 60.89,
    USD: 14.5,
    BRL: 49.5,
    GBP: 12.5,
    EUR: 14.5
  },
  [PROFESSIONAL_24]: {
    PLN: 153.14,
    USD: 34.5,
    BRL: 149.5,
    GBP: 29.5,
    EUR: 34.5
  },
  [AGENCY_22]: {
    PLN: 306.87,
    USD: 74.5,
    BRL: 249.5,
    GBP: 59.5,
    EUR: 74.5
  }
} as const

export const promotionBlackFridayPlansGroup1 = [
  CORE_20,
  CREATE_20,
  AUTOMATE_20,
  AGENCY_19,
  AGENCY_PRO_19,
  AUTOMATE_DISC_20,
  CREATE_DISC_20,
  CORE_DISC_20,
  AGENCY_PRO_20_12M,
  AGENCY_20_12M,
  AUTOMATE_20_12M,
  CREATE_20_12M,
  CORE_20_12M
] as const

export const promotionBlackFridayPlansGroup2 = [
  CORE_21,
  CREATE_21,
  AUTOMATE_21,
  AGENCY_21,
  AUTOMATE_DISC_21,
  CREATE_DISC_21,
  CORE_DISC_21,
  AGENCY_21_12M,
  AUTOMATE_21_12M,
  CREATE_21_12M,
  CORE_21_12M
] as const

export type PromotionBlackFridayPlansGroup1Types =
  (typeof promotionBlackFridayPlansGroup1)[number]
export type PromotionBlackFridayPlansGroup2Types =
  (typeof promotionBlackFridayPlansGroup2)[number]
