import { useStyles } from '@hooks/useStyles'
import { FC } from 'react'

import styles from './Divider.module.scss'

type DividerProps = {
  className?: string | string[]
} & (
  | {
      variant: 'vertical'
      height: number
    }
  | {
      variant?: 'horizontal'
      height?: never
    }
)

export const Divider: FC<DividerProps> = ({
  className = '',
  variant = 'horizontal',
  height
}) => {
  const elementClasses = useStyles(
    {
      [styles.divider]: true,
      [styles[`divider--height-${height}`]]: height,
      [styles[`divider--${variant}`]]: variant
    },
    className
  )

  return (
    <div className={elementClasses} data-testid='divider' style={{ height }} />
  )
}

Divider.displayName = 'Divider'
