import { useStyles } from '@hooks/useStyles'
import WizardIcon from '@images/wizard/wizard-icon.svg'
import { Badge } from '@ui-kit/Badge'
import { Image } from '@ui-kit/Image'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { FC, Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Hint.module.scss'

interface HintProps {
  children: ReactNode
  isTip?: boolean
  className?: string | string[]
  keyProp?: string
  'data-testid'?: string
}

export const Hint: FC<HintProps> = ({
  children,
  isTip = false,
  className = '',
  keyProp, // it will rerender the component if the key changes and writing animation will play again
  'data-testid': dataTestId = 'hint'
}) => {
  const { t } = useTranslation()
  const hintStyles = useStyles(
    {
      [styles.hint]: true
    },
    className
  )

  const wrapperStyles = useStyles({
    [styles.wrapper]: true
  })

  return (
    <div data-testid={dataTestId} className={wrapperStyles} key={keyProp}>
      <Row alignItems='center'>
        <Image src={WizardIcon} />
        <Spreader spread={10} />
        <Paragraph color='primary' weight={600}>
          Composer
        </Paragraph>

        {isTip && (
          <Fragment>
            <Spreader spread={10} />
            <Badge variant='info-filled' weight={600} data-testid='tip'>
              {t('word.tip')}
            </Badge>
          </Fragment>
        )}
      </Row>

      <Spacer space={10} />

      <Paragraph className={hintStyles} color='neutral-8'>
        {children}
      </Paragraph>
    </div>
  )
}

Hint.displayName = 'Hint'
