import { threeDSecure } from 'braintree-web'

/**
 * @param {bool} liabilityShifted - indicates whether the liability for fraud has been shifted away from the merchant.
 * @param {bool} liabilityShiftPossible - indicates whether the card was eligible for 3D Secure.
 * @return information whether subscription should be posted or not
 */
const shouldPostSubscription = (liabilityShifted, liabilityShiftPossible) =>
  !(liabilityShiftPossible && !liabilityShifted)

/**
 * function handling 3DS verification of credit card
 * @param {string} testChargeAmount - The amount of the transaction in the current merchant account's currency
 * @param {string} nonce - nonce from /payments/create-braintree-nonce endpoint
 * @param {object} bin - bin data from payment method
 * @param {string} email - email
 * @param {object} clientInstance - braintree client instance
 */
export const verifyWith3DS = async (
  testChargeAmount,
  nonce,
  bin,
  email,
  clientInstance
) => {
  const threeDSecureInstance = await threeDSecure.create({
    version: 2,
    client: clientInstance
  })

  threeDSecureInstance.on('lookup-complete', (data, next) => next())

  const payload = await threeDSecureInstance.verifyCard({
    amount: testChargeAmount,
    nonce,
    bin,
    email
  })

  const { threeDSecureInfo, nonce: enrichedNonce } = payload
  const { liabilityShifted, liabilityShiftPossible } = threeDSecureInfo

  return {
    shouldPostSubscription: shouldPostSubscription(
      liabilityShifted,
      liabilityShiftPossible
    ),
    enrichedNonce
  }
}
