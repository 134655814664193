import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { UPGRADE_MODALS } from '@constants/modals'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { Button, Divider, Icon, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './WorkspacesContent.module.scss'

export const BusinessAccountContent = () => {
  const { t } = useTranslation()

  const { ownerEmail } = useUserContext()

  const { toggleWorkspacesOpen } = useSidebarContext()

  const hasAccessToActions = useFeatureFlags('SPA_DRAWER_ACTIONS')

  const handleManageSubaccounts = () => {
    toggleWorkspacesOpen()

    NiceModal.show(MODAL_UPGRADE_PLAN, {
      upgradeType: UPGRADE_MODALS.AGENCY_HUB
    })

    return
  }

  return (
    <Fragment>
      <Divider />

      <Column className={styles['workspaces-content__workspaces--business']}>
        <Row className={styles.container}>
          <Icon icon='icon-home' size={14} />

          <Spreader spread={10} />

          <Column alignItems='start'>
            <span className={styles.container__text}>
              {t('word.main-account')}
            </span>

            <span className={styles.container__email}>{ownerEmail}</span>
          </Column>
        </Row>
      </Column>

      <Divider />

      <Row
        className={styles['workspaces-content__padding--bottom']}
        justifyContent='space-between'
      >
        <Button onClick={handleManageSubaccounts}>
          {t('manage.subaccounts')}
        </Button>

        {hasAccessToActions && <Button>{t('subaccount.create.new')}</Button>}
      </Row>
    </Fragment>
  )
}

BusinessAccountContent.displayName = 'BusinessAccountContent'
