import { useUserContext } from '@contexts/user'
import { useLazyService } from '@hooks/useLazyService'
import searchEmpty from '@images/search_empty.svg'
import {
  Backdrop,
  Button,
  Filter,
  Heading,
  Icon,
  InfinityScroll,
  Message,
  Searcher,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useGetProductUpdates } from '@services/productUpdates'
import { updateLastSeen } from '@services/productUpdates/productUpdates'
import { motion } from 'framer-motion'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import {
  CLOSE_PRODUCT_UPDATES_DRAWER,
  filterValues,
  NUMBER_OF_NOTIFICATIONS_PER_PAGE
} from '../constants'
import { Page } from './components/Page'
import styles from './Drawer.module.scss'

const animate = {
  close: { x: 350 },
  open: { x: 0 }
}

export const Drawer: FC = () => {
  const { t } = useTranslation()

  const { isNewNotification } = useUserContext()

  const { emphasizedNotificationMutate } = useGetProductUpdates()

  const [handleUpdateLastSeen] = useLazyService(updateLastSeen)

  const currentDate = new Date().toISOString().slice(0, 10)

  const handleCloseProductUpdates = async () => {
    if (isNewNotification) {
      await handleUpdateLastSeen(currentDate)

      emphasizedNotificationMutate({ notification: null })
    }

    return document.dispatchEvent(new Event(CLOSE_PRODUCT_UPDATES_DRAWER))
  }

  const [queryParams, setQueryParams] = useState({
    category: '',
    phrase: '',
    page: 1
  })

  const { page, category, phrase } = queryParams

  const { notificationsData, notificationsLoading } = useGetProductUpdates({
    page,
    filters: {
      category,
      phrase
    }
  })

  const isCollectionEmpty = notificationsData?.collection.length === 0

  const totalPages = notificationsData?.pagination.counter.total || 0

  const isLastPage = totalPages / NUMBER_OF_NOTIFICATIONS_PER_PAGE < page

  const handleFilter = (value: string | number) => {
    setQueryParams(prev => ({ ...prev, page: 1, category: value as string }))
  }

  const handleSearch = (value?: string) => {
    setQueryParams(prev => ({ ...prev, page: 1, phrase: value || '' }))
  }

  const handleNextPage = () => {
    setQueryParams(prev => ({ ...prev, page: prev.page + 1 }))
  }

  const pages = []

  for (let i = 0; i < page; i++) {
    pages.push(<Page index={i + 1} key={i} queryParams={queryParams} />)
  }

  return (
    <Fragment>
      <motion.div
        className={styles.wrapper}
        key='product-drawer'
        initial={animate.close}
        animate={animate.open}
        exit={animate.close}
        transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
        data-testid='product-drawer'
      >
        <Column className={styles.wrapper__top}>
          <Row justifyContent='space-between'>
            <Heading level={3} margin='none'>
              {t('word.product-updates')}
            </Heading>

            <Button variant='icon' onClick={handleCloseProductUpdates}>
              <Icon icon='icon-remove' />
            </Button>
          </Row>

          <Spacer space='small' />

          <Searcher
            i18n={{ placeholder: t('word.search') }}
            setSearchPhrase={handleSearch}
          />

          <Spacer space='tiny' />

          <Row justifyContent='flex-end'>
            <Filter
              values={filterValues}
              setValue={handleFilter}
              initialValue={category}
            />
          </Row>

          <Spacer space='small' />
        </Column>

        {queryParams.phrase && !notificationsLoading && isCollectionEmpty ? (
          <Message
            className={styles['wrapper__empty-message']}
            title={t('message.empty.search.results')}
            titleLevel={4}
            url={searchEmpty}
            height={90}
          />
        ) : null}

        <InfinityScroll
          className={styles.wrapper__notification}
          loadMore={handleNextPage}
          isLastPage={isLastPage}
        >
          {pages}
        </InfinityScroll>
      </motion.div>

      <Backdrop onClick={handleCloseProductUpdates} zIndex='8' />
    </Fragment>
  )
}

Drawer.displayName = 'ProductsUpdatesDrawer'
