import client from '@lib/http/client'

interface DuplicateLandingToAnotherAccountsRequest {
  landingUuid: string
  accountsIdentifiers: string[]
}

export const duplicateLandingToAnotherAccounts = ({
  landingUuid,
  accountsIdentifiers
}: DuplicateLandingToAnotherAccountsRequest) =>
  client.post(
    `landing-page/landings/${landingUuid}/duplicate-landing-to-other-accounts`,
    {
      accountsIdentifiers
    }
  )
