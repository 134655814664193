import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = toFormikValidationSchema(
  z.object({
    domain: z.string(),
    path: z.string().optional()
  })
)

export default schema
