import { newLandingiClient } from '@lib/http/client'
import useSWRImmutable from 'swr/immutable'

import { GetProductsResponse } from './useGetProducts'

const getAnyProducts = async (url: string) => {
  const response = await newLandingiClient.get<GetProductsResponse>(url, {
    params: {
      page: 1,
      limit: 10
    }
  })

  return response.data
}

export const useGetAnyProducts = () =>
  useSWRImmutable<GetProductsResponse>(`/ecommerce/v1/product`, getAnyProducts)
