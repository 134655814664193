const AGENCY = {
  AGENCY_DASHBOARD: '/agency/dashboard',
  AGENCY_RESOURCE_LIMITS: '/agency/resource-limits',
  AGENCY_RESOURCE_LIMITS_CREATE: '/agency/resource-limits/create',
  AGENCY_RESOURCE_LIMITS_DELETE: '/agency/resource-limits/delete/:identifier',
  AGENCY_RESOURCE_LIMITS_EDIT: '/agency/resource-limits/edit/:identifier',
  AGENCY_GALLERY: '/agency/gallery',
  AGENCY_GALLERY_IMAGE: '/agency/gallery/image/upload/:identifier',
  AGENCY_GALLERY_IMAGE_UPLOAD: '/agency/gallery/image/upload/:identifier',
  AGENCY_GALLERY_IMAGE_DELETE: '/agency/gallery/image/delete/:identifier',
  AGENCY_GALLERY_IMAGE_TAG: '/agency/gallery/image/tag/:identifier',
  AGENCY_GALLERY_FOLDER: '/agency/gallery/folder/:identifier',
  AGENCY_GALLERY_FOLDER_CREATE: '/agency/gallery/folder/create/',
  AGENCY_GALLERY_FOLDER_RENAME: '/agency/gallery/folder/rename/:identifier',
  AGENCY_GALLERY_FOLDER_DELETE: '/agency/gallery/folder/delete/:identifier',
  AGENCY_GALLERY_FOLDER_MOVE: '/agency/gallery/folder/move/:identifier',
  AGENCY_SUBACCOUNTS: '/agency/subaccounts',
  AGENCY_SUBACCOUNTS_DRAWER: '/agency/subaccounts/drawer',
  AGENCY_SUBACCOUNTS_CREATE: '/agency/subaccounts/create',
  AGENCY_SUBACCOUNTS_EXPORT: '/agency/subaccounts/export',
  AGENCY_SUBACCOUNTS_EDIT: '/agency/subaccounts/edit/:identifier',
  AGENCY_SUBACCOUNTS_DEACTIVATE: '/agency/subaccounts/deactivate/:identifier',
  AGENCY_SUBACCOUNTS_DELETE: '/agency/subaccounts/delete/:identifier',
  AGENCY_OPTIONS: 'agency/options'
}

export default AGENCY
