import { ADDONS_IDS } from '@constants/addonsIds'
import BackgroundRemover from '@images/addons/icons/BackgroundRemover.svg'
import {
  EDITOR,
  INCLUDED_IN_PLAN
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { AI, AIAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getAIBackgroundRemover: (
  t: TFunction<'translation', undefined>,
  { UNBRANDED_CONTENT_FF }: { UNBRANDED_CONTENT_FF?: boolean }
) => AIAddonTemplate = (t, { UNBRANDED_CONTENT_FF }) => ({
  type: AI,
  name: t('addons.store.ai.background.remover.name'),
  title: t('addons.store.ai.background.remover'),
  tileDescription: t('addons.store.ai.background.remover.description'),
  badges: [INCLUDED_IN_PLAN, EDITOR],
  imageSrc: BackgroundRemover,
  id: ADDONS_IDS.AI_BACKGROUND_REMOVER,
  pageDescription: t('addons.store.ai.background.remover.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  pricing: getPricing(t, UNBRANDED_CONTENT_FF),
  price: '500',
  useCases: getUseCases(t),
  faq: getFAQ(t),
  CTALink: t('addons.store.ai.background.remover.pricing.cta.link')
})
