import SelectBoxCheckbox from '@components/SelectBoxCheckbox'
import hornImg from '@images/registration/survey/questionIcons/horn.png'
import newsletterImg from '@images/registration/survey/questionIcons/newsletter.png'
import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import FooterButtons from './FooterButtons'
import styles from './Questions.module.scss'

/**
 * Fifth - stateful presentational component representing survey question
 * @param {object} values - formik values
 * @param {bool} isValid - formik isValid
 * @param {bool} isSubmitting - formik isSubmitting
 * @param {function} handleBack - function that handles clicking back arrow to go back to previous quesiton
 * @param {function} handleSkipStep - function that handles clicking skip button
 * @param {bool} isLastQuestion - information whether question is last or not
 * @param {function} validateForm - formik validateForm
 * @param {function} onBlur - callback called on blur input
 * @return {object} - children
 */
const Sixth = ({
  values,
  isValid,
  isSubmitting,
  isLastQuestion,
  setFieldValue,
  validateForm,
  onBlur,
  handleSkipStep,
  isSkippingLastQuestion
}) => {
  const {
    activeSurveyBoxesIDs,
    setActiveSurveyBoxesIDs,
    questionsSkippedInfo,
    setQuestionsSkippedInfo
  } = useSurveyContext()

  const questionsIDs = ['newsletter', 'product-updates']
  const { t } = useTranslation()

  useEffect(() => {
    validateForm()
  }, [])

  /**
   * handles clicking Next button and sets flag informing whether question was skipped or not
   * this flag is set to true if no values are chosen in last question
   * @type {function}
   */
  const handleNext = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.sixth = !(
      values.newsletter || values['product-updates']
    )

    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
  }

  /**
   * handles clicking Skip step button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleSkipStepWrapper = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    setActiveSurveyBoxesIDs(
      activeSurveyBoxesIDs.filter(id => !questionsIDs.includes(id))
    )

    questionsIDs.forEach(id => setFieldValue(id, false))

    newQuestionsSkippedInfo.sixth = true
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)

    handleSkipStep()
  }

  const handleFieldChange = event => {
    const { checked, name } = event.target

    if (checked) {
      setActiveSurveyBoxesIDs([...activeSurveyBoxesIDs, name])
    } else {
      setActiveSurveyBoxesIDs(activeSurveyBoxesIDs.filter(id => id !== name))
    }
  }

  return (
    <LayoutGroup>
      <motion.div
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Column>
          <motion.div layout>
            <Column>
              <Heading level={1} margin='none'>
                {t(
                  'registration.flow.survey.would.you.like.to.receive.updates'
                )}
              </Heading>

              <Spacer space='mini' />

              <Paragraph size={16} padding='none'>
                {t('registration.flow.survey.feel.free.to.pick.more.than.one')}
              </Paragraph>

              <Spacer space='small' />

              <Spacer space='tiny' />
            </Column>
          </motion.div>

          <div>
            <div className={styles['sixth-question-boxes']}>
              {/* product-updates */}
              <SelectBoxCheckbox
                field={{
                  name: 'product-updates',
                  value: values['product-updates'],
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='product-updates'
                i18n={{ title: t('registration.flow.survey.product.updates') }}
                iconSource={hornImg}
                onChange={handleFieldChange}
                variant='responsive'
                className={styles['sixth-question-boxes__productUpdates']}
                centerTextInActiveContent
                activeContent={
                  <Paragraph size={12} padding='none'>
                    {t('registration.flow.survey.product.updates.extend')}
                  </Paragraph>
                }
              />
              {/* newsletter */}
              <SelectBoxCheckbox
                field={{
                  name: 'newsletter',
                  value: values.newsletter,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='newsletter'
                i18n={{ title: t('registration.flow.survey.newsletter') }}
                iconSource={newsletterImg}
                onChange={handleFieldChange}
                variant='responsive'
                className={styles['sixth-question-boxes__newsletter']}
                centerTextInActiveContent
                activeContent={
                  <Paragraph size={12} padding='none'>
                    {t('registration.flow.survey.newsletter.extend')}
                  </Paragraph>
                }
              />
            </div>
          </div>

          <Spacer space='medium' />

          <Spacer space='mini' />

          <FooterButtons
            isLastQuestion={isLastQuestion}
            isSubmitting={isSubmitting}
            handleSkipStep={handleSkipStepWrapper}
            handleNext={handleNext}
            isValid={isValid}
            isSkippingLastQuestion={isSkippingLastQuestion}
          />
        </Column>
      </motion.div>
    </LayoutGroup>
  )
}

Sixth.propTypes = {
  values: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleSkipStep: PropTypes.func,
  validateForm: PropTypes.func,
  isLastQuestion: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  isSkippingLastQuestion: PropTypes.bool
}

Sixth.defaultProps = {
  isSkippingLastQuestion: false
}

export default Sixth
