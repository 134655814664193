import { exhaustiveMatchingGuard } from '@helpers/exhaustiveMatchingGuard'
import { Icon, Spreader } from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { PublicationType as PublicationTypes } from '@services/landings/list/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

interface PublicationTypeProps {
  publicationType: PublicationTypes
}

export const PublicationType: FC<PublicationTypeProps> = ({
  publicationType
}) => {
  const { t } = useTranslation()

  switch (publicationType) {
    case TYPES.TEST:
      return (
        <Row alignItems='baseline'>
          <Icon icon='icon-globe' color='warning' />

          <Spreader spread='tiny' />

          <span>{t('publishing.options.test.domain')}</span>
        </Row>
      )
    case TYPES.CUSTOM:
      return (
        <Row alignItems='baseline'>
          <Icon icon='icon-globe' />

          <Spreader spread='tiny' />

          <span>{t('publishing.options.custom.subdomain')}</span>
        </Row>
      )
    case TYPES.WORDPRESS:
      return (
        <Row alignItems='baseline'>
          <Icon icon='icon-wordpress-alt' />

          <Spreader spread='tiny' />

          <span>{t('publishing.options.wordpress')}</span>
        </Row>
      )
    case TYPES.EMBED:
      return (
        <Row alignItems='baseline'>
          <Icon icon='icon-server' />

          <Spreader spread='tiny' />

          <span>{t('publishing.options.embed')}</span>
        </Row>
      )
    case TYPES.NONE:
      return null
    default:
      return exhaustiveMatchingGuard(publicationType)
  }
}

PublicationType.displayName = 'PublishingOptionsViewsMainTablePublicationType'
