import { Icon } from '@ui-kit'
import { Trans } from 'react-i18next'

export const getFeatures = () => [
  {
    title: (
      <Trans i18nKey='addons.store.ai.content.generation.features.1.title' />
    ),
    description: (
      <Trans i18nKey='addons.store.ai.content.generation.features.1.description' />
    ),
    icon: <Icon icon='icon-edit' color='primary' size={26} />
  },
  {
    title: (
      <Trans i18nKey='addons.store.ai.content.generation.features.2.title' />
    ),
    description: (
      <Trans i18nKey='addons.store.ai.content.generation.features.2.description' />
    ),
    icon: <Icon icon='icon-icon-events' color='primary' size={26} />
  },
  {
    title: (
      <Trans i18nKey='addons.store.ai.content.generation.features.3.title' />
    ),
    description: (
      <Trans i18nKey='addons.store.ai.content.generation.features.3.description' />
    ),
    icon: <Icon icon='icon-text-ai' color='primary' size={26} />
  }
]
