import {
  ACTIVE,
  DEFAULT,
  DISABLED,
  HOVERED
} from '@ui-kit/BoxOutline/constants'

import { BoxOutlineStatusType } from '../types'

export const getStatus = ({
  isHovered,
  isActive,
  isDisabled
}: {
  isHovered: boolean
  isActive: boolean
  isDisabled?: boolean
}): BoxOutlineStatusType => {
  if (isDisabled) {
    return DISABLED
  }

  if (isActive) {
    return ACTIVE
  }

  if (isHovered) {
    return HOVERED
  }

  return DEFAULT
}
