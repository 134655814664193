// Events
export const DUPLICATE_LP_EVENT_NAME = 'Duplicate landing page'
export const TRANSLATE_LP_OPEN_EVENT_NAME = 'Translate LP - Open'
export const TRANSLATE_LP_CLOSE_EVENT_NAME = 'Translate LP - Close'
export const TRANSLATE_LP_SUBMIT_EVENT_NAME = 'Translate LP - Submit'

export const TOPBAR_HELP_CLICK_EVENT_NAME = 'Click On Help'

// Properties values
export const COPY_AND_CLOSE_PROPERTY_VALUE = 'Copy & Close'
export const COPY_AND_EDIT_PROPERTY_VALUE = 'Start editor'

// addons store
export const CLICK_ON_ADDON_STORE = 'Click on Addon Store'
export const OPEN_ADDON_PROFILE = 'Open addon profile'
export const CLICK_ON_ADDON_CTA = 'Click on addon CTA'
export const START_ADDON_TRIAL = 'Start addon trial'
export const OPEN_ADDON_SUGGEST = 'Open addon suggest'
export const SEND_ADDON_SUGGEST = 'Send addon suggest'
export const BUY_ADDON_AFTER_USED_TRIAL = 'Buy addon (trial used)'
export const REQUEST_ADDON_AFTER_USED_TRIAL = 'Request addon (trial used)'

// pricing test
export const CLICK_PRICING_INFO = 'Click Pricing Info'

// buy credits
export const CLICK_BUY_CREDITS = 'Click on Buy Credits'
export const CLICK_SELECT_PACKAGE = 'Click on Select credits package'
export const CLICK_ON_CONFIRM_OR_REQUEST = 'Click on credit package confirm'
export const CREDITS_SUCCESSFULLY_PURCHASED = 'Credits successfully purchased'

// QR code
export const CLICK_ON_DOWNLOAD_QR_CODE = 'Click on download QR code'
export const CLICK_ON_QR_CODE_MODAL = 'Click on QR Code'

// click locations
export const USAGE_DROPDOWN = 'usage dropdown'
export const TRANSLATIONS = 'translations'
export const PRICING = 'pricing'
export const PROGRAMMATIC = 'programmatic'
export const DASHBOARD = 'dashboard'
export const PUBLISHING_OPTIONS = 'publishing options'
