import { useUserContext } from '@contexts/user'
import i18next, { changeLanguage } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { FC, ReactNode, useEffect, useRef } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'

i18next.use(Backend).use(initReactI18next).use(LanguageDetector)

export const LanguageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { language, isRegistrationCompleted } = useUserContext()
  const calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    i18next.init({
      lng: isRegistrationCompleted ? language : undefined, // language should be automatically detected if registration isn't completed
      detection: {
        order: ['querystring', 'navigator', 'htmlTag', 'path', 'subdomain'],
        lookupQuerystring: 'lang'
      },
      interpolation: { prefix: '{', suffix: '}' },
      backend: {
        loadPath: '/locales/{lng}.json'
      },
      fallbackLng: 'en',
      nsSeparator: false,
      keySeparator: false,
      load: 'languageOnly',
      react: {
        transKeepBasicHtmlNodesFor: ['b', 'br']
      }
    })

    calledOnce.current = true
  }, [])

  useEffect(() => {
    if (language) {
      changeLanguage(language)
    }
  }, [language])

  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
}
