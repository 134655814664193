import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const useHead = () => {
  const { pathname } = useLocation()
  const { t, ready } = useTranslation('', { useSuspense: false })

  // if there is partner page, e. g. /register/sosv, we still want to treat it as /register page
  const splittedPathname = `/${pathname.split('/')[1]}`

  if (!ready) {
    return
  }

  if (
    splittedPathname === REGISTRATION.LOGIN.DEFAULT ||
    splittedPathname === REGISTRATION.DEFAULT
  ) {
    document.title = t('login.page.title')

    return
  }

  if (
    [REGISTRATION.REGISTER.DEFAULT, REGISTRATION.SIGNUP.DEFAULT].find(
      path => path === splittedPathname
    )
  ) {
    document.title = t('register.page.title')

    return
  }

  document.title = 'Landingi'
}
