import client from '@lib/http/client'
import { AxiosResponse } from 'axios'

interface DownloadLandingRequest {
  uuid: string
}

interface DownloadLandingResponse {
  fileUrl: string
}

export const downloadLanding = ({ uuid }: DownloadLandingRequest) =>
  client.get<AxiosResponse<DownloadLandingResponse>>(
    `landing-page/landings/${uuid}/download`,
    {
      withCredentials: true
    }
  )
