import { useStyles } from '@hooks/useStyles'
import { Image } from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './SurveyBox.module.scss'

/**
 * SurveyBox - object representing survey box
 * @param {bool} hasSurveyStarted - information whether survey started or not, used to define shape position
 * @param {object} shapePositionBeforeSurveyStart - defines position of shape (top and left coordinates) before survey start
 * @param {object} shapePositionAfterSurveyStart - defines position of shape (top and left coordinates) after survey start
 * @param {string} shapeImageSrc - src of shape image
 * @param {string} textID - text ID used for intl translation
 * @param {bool} isActive - information whether survey box is active, it is - whether relevant to it answer was chosen
 * @param {number} order - information about order of survey box in flex container containg it
 */
const SurveyBox = ({
  hasSurveyStarted,
  hasSurveyFinished,
  shapePositionBeforeSurveyStart,
  shapePositionAfterSurveyStart,
  shapePositionAfterSurveyFinish,
  shapeImageSrc,
  isActive,
  textID,
  order,
  hasFadeIn
}) => {
  const { top: topBeforeStart, left: leftBeforeStart } =
    shapePositionBeforeSurveyStart
  const { top: topAfterStart, left: leftAfterStart } =
    shapePositionAfterSurveyStart
  const boxControls = useAnimation()

  const [playedInitialAnimation, setPlayedInitialAnimation] = useState(false)
  const { surveyBoxShapeControls } = useAnimationContext()

  const { t } = useTranslation()

  /**
   * called when hasSurveyStarted is changed, to track if initialAnimation has been played
   */
  useEffect(() => {
    let timeout

    if (hasSurveyStarted) {
      timeout = setTimeout(() => setPlayedInitialAnimation(true), 800)
    }

    return () => clearTimeout(timeout)
  }, [hasSurveyStarted])

  /**
   * called when hasSurveyFinished is changed, if hasSurveyFinished is true it causes fade
   * out of box and its shape
   */
  useEffect(() => {
    if (hasSurveyFinished) {
      boxControls.start({
        backgroundColor: '#fff0',
        boxShadow: 'none'
      })

      surveyBoxShapeControls.start({
        opacity: 0
      })
    }
  }, [hasSurveyFinished])

  /**
   * @type {function}
   * @return {number} time of layout change animation duration in seconds
   */
  const getShapeLayoutChangeDuration = () => {
    if (!playedInitialAnimation) {
      return 0.8
    }

    if (shouldPlayBurstAnimation()) {
      return 1.2
    }

    return 0.5
  }

  /**
   * shouldPlayBurstAnimation - if box was active and survey finished shape inside it
   * should burst
   * @type {function}
   * @return {bool}
   */
  const shouldPlayBurstAnimation = () => hasSurveyFinished && isActive

  /**
   * it returns top shape position depending of actual state of survey
   * @type {function}
   * @return {string}
   */
  const getShapeTopPosition = () =>
    shouldPlayBurstAnimation()
      ? shapePositionAfterSurveyFinish.top
      : hasSurveyStarted
        ? topAfterStart
        : topBeforeStart

  /**
   * it returns left shape position depending of actual state of survey
   * @type {function}
   * @return {string}
   */
  const getShapeLeftPosition = () =>
    shouldPlayBurstAnimation()
      ? shapePositionAfterSurveyFinish.left
      : hasSurveyStarted
        ? leftAfterStart
        : leftBeforeStart

  const boxStyles = useStyles({
    [styles['survey-box']]: true,
    [styles['survey-box--active']]: isActive
  })

  const imageContainerStyles = useStyles({
    [styles['survey-box__image-container--active']]: isActive
  })

  return (
    <motion.div
      className={boxStyles}
      style={{
        order
      }}
      animate={boxControls}
      transition={{ duration: hasSurveyFinished ? 1 : 0 }}
    >
      {(!isActive || hasSurveyFinished) && (
        <div className={styles['survey-box__placeholder']} />
      )}
      <motion.div
        className={imageContainerStyles}
        style={{
          position: isActive && !hasSurveyFinished ? 'static' : 'absolute',
          top: getShapeTopPosition(),
          left: getShapeLeftPosition()
        }}
        transition={{
          duration: getShapeLayoutChangeDuration(),
          delay: shouldPlayBurstAnimation() ? 1.2 : 0
        }}
        initial={{ opacity: hasFadeIn ? 0 : 1 }}
        layoutDependency={
          hasSurveyStarted && !hasSurveyFinished ? isActive : null
        }
        layout
        animate={surveyBoxShapeControls}
      >
        <Image
          size={isActive ? '24px' : 'auto'}
          height={isActive ? 24 : null}
          src={shapeImageSrc}
        />
      </motion.div>

      {isActive && (
        <AnimatePresence>
          {!hasSurveyFinished && (
            <motion.span initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {t(textID)}
            </motion.span>
          )}
        </AnimatePresence>
      )}
    </motion.div>
  )
}

SurveyBox.propTypes = {
  hasSurveyStarted: PropTypes.bool,
  hasSurveyFinished: PropTypes.bool,
  shapePositionBeforeSurveyStart: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  shapePositionAfterSurveyStart: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  shapePositionAfterSurveyFinish: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  shapeImageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isActive: PropTypes.bool,
  textID: PropTypes.string,
  order: PropTypes.number,
  hasFadeIn: PropTypes.bool
}

SurveyBox.defaultProps = {
  hasFadeIn: true
}

export default SurveyBox
