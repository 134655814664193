import { PLANS } from '@constants/plans'
import { Icon, Paragraph, Spacer, Spreader, Tooltip } from '@ui-kit'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { PlanRecordKey } from '../types'

const { FREE_22, FREE_LIMITED_24, LITE_LIMITED_24, PROFESSIONAL_24 } = PLANS

export const getLimits = (planRecordKey: PlanRecordKey) => {
  const isFreePlan =
    planRecordKey === FREE_22 || planRecordKey === FREE_LIMITED_24

  const isLitePlan = planRecordKey === LITE_LIMITED_24

  const isProffesionalPlan = planRecordKey === PROFESSIONAL_24

  const getTooltipContents = () => {
    if (isFreePlan || isLitePlan) {
      return {
        visits: (
          <Trans i18nKey='compare.plans.description.limits.visits.tooltip.lite.free' />
        ),
        domains: (
          <Trans i18nKey='compare.plans.description.limits.custom.domains.tooltip.lite.free' />
        )
      }
    }

    if (isProffesionalPlan) {
      return {
        visits: (
          <Trans i18nKey='compare.plans.description.limits.visits.tooltip.professional' />
        ),
        domains: (
          <Trans i18nKey='compare.plans.description.limits.custom.domains.tooltip.professional' />
        )
      }
    }

    return {
      visits: (
        <Trans i18nKey='compare.plans.description.limits.visits.tooltip.unlimited' />
      ),
      domains: (
        <Trans i18nKey='compare.plans.description.limits.custom.domains.tooltip.unlimited' />
      )
    }
  }

  const getValues = () => {
    if (isFreePlan) {
      return {
        activeLandingPages: (
          <Trans i18nKey='compare.plans.description.limits.one.active.landing.page' />
        ),
        visits: (
          <Trans
            i18nKey='compare.plans.description.limits.visits'
            values={{
              visits: '500'
            }}
          />
        ),
        customDomains: (
          <Trans i18nKey='compare.plans.description.limits.one.custom.domain' />
        )
      }
    }

    if (isLitePlan) {
      return {
        activeLandingPages: (
          <Trans
            i18nKey='compare.plans.description.limits.active.landing.pages'
            values={{ activeLandingPages: '10' }}
          />
        ),
        visits: (
          <Trans
            i18nKey='compare.plans.description.limits.visits'
            values={{
              visits: '5 000'
            }}
          />
        ),
        customDomains: (
          <Trans i18nKey='compare.plans.description.limits.one.custom.domain' />
        )
      }
    }

    if (isProffesionalPlan) {
      return {
        activeLandingPages: (
          <Trans i18nKey='compare.plans.description.limits.unlimited.active.landing.pages' />
        ),
        visits: (
          <Trans
            i18nKey='compare.plans.description.limits.visits'
            values={{
              visits: '50 000'
            }}
          />
        ),
        customDomains: (
          <Trans
            i18nKey='compare.plans.description.limits.custom.domains'
            values={{ customDomains: '10' }}
          />
        )
      }
    }

    return {
      activeLandingPages: (
        <Trans i18nKey='compare.plans.description.limits.unlimited.active.landing.pages' />
      ),
      visits: <Trans i18nKey='compare.plans.description.unlimited.visits' />,
      customDomains: (
        <Trans
          i18nKey='compare.plans.description.limits.custom.domains'
          values={{ customDomains: '20' }}
        />
      )
    }
  }

  return (
    <Fragment>
      <Paragraph size={12}>{getValues().activeLandingPages}</Paragraph>

      <Spacer space={5} />

      <Paragraph size={12}>
        <Trans i18nKey='compare.plans.description.limits.unlimited.conversion' />
      </Paragraph>

      <Spacer space={5} />

      <Tooltip content={getTooltipContents().visits}>
        <Row>
          <Paragraph size={12}>{getValues().visits}</Paragraph>

          <Spreader spread={5} />

          <Icon icon='icon-help_outline' size={14} color='neutral-4' />
        </Row>
      </Tooltip>

      <Spacer space={5} />

      <Tooltip content={getTooltipContents().domains}>
        <Row>
          <Paragraph size={12}>{getValues().customDomains}</Paragraph>

          <Spreader spread={5} />

          <Icon icon='icon-help_outline' size={14} color='neutral-4' />
        </Row>
      </Tooltip>
    </Fragment>
  )
}
