import client from '@lib/http/client'

export const getProductUpdates = ({
  page,
  category,
  phrase
}: {
  page: number
  category?: string | number
  phrase?: string
}) =>
  client.get('/changelog/notifications', {
    params: {
      page,
      filters: {
        ...(category && {
          category
        }),
        ...(phrase && {
          phrase
        })
      }
    }
  })

export const updateLastSeen = (last_seen: string) =>
  client.put('/changelog/last-seen', {
    last_seen
  })
