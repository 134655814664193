import { AxiosError } from 'axios'
import useSWR from 'swr'

export type GetAllFontsResponse = {
  collection: {
    [key: string]: {
      css_url: string
      name: string
      type: 'generic' | 'google' | 'own'
    }
  }
}

export const useGetFontsList = () => {
  const { data, mutate } = useSWR<GetAllFontsResponse, AxiosError>(`fonts-all`)

  const dataAsArray = data ? Object.values(data.collection) : []
  return { data, dataAsArray, mutate }
}
