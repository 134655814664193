import {
  promotionBlackFridayPlansGroup1,
  PromotionBlackFridayPlansGroup1Types,
  promotionBlackFridayPlansGroup2,
  PromotionBlackFridayPlansGroup2Types
} from '@constants/promotions'

export const promotionBlackFridayLinks = (planRecordKey?: string) => {
  if (
    promotionBlackFridayPlansGroup1.includes(
      planRecordKey as PromotionBlackFridayPlansGroup1Types
    )
  ) {
    return 'black.friday.promotion.url.group.1'
  }

  if (
    promotionBlackFridayPlansGroup2.includes(
      planRecordKey as PromotionBlackFridayPlansGroup2Types
    )
  ) {
    return 'black.friday.promotion.url.group.2'
  }

  return 'black.friday.promotion.url.default'
}
