import { Toast } from '@ui-kit/Toast/toasts'
import styles from '@ui-kit/Toast/toasts/Toast.module.scss'
import type { ToastType } from '@ui-kit/Toast/types'
import { ReactNode } from 'react'
import { toast, ToastOptions } from 'react-toastify'

interface ToastPayload {
  type: ToastType
  message: ReactNode
}

const defaultToastOptions: ToastOptions = {
  position: 'bottom-left',
  autoClose: 3000
}

export const emitToast = (
  { type, message }: ToastPayload,
  options: ToastOptions = defaultToastOptions
) =>
  toast(
    ({ closeToast }) => (
      <Toast type={type} onCloseClick={closeToast}>
        {message}
      </Toast>
    ),
    {
      ...options,
      hideProgressBar: true,
      className: styles[`toast--variant-${type}`],
      bodyClassName: styles[`toastify__body--reset`],
      progressClassName: styles.toast__progressbar
    }
  )

export const emitTimingToast = (
  { type, message }: ToastPayload,
  options: ToastOptions = defaultToastOptions
) =>
  toast(
    ({ closeToast }) => (
      <Toast type={type} onCloseClick={closeToast}>
        {message}
      </Toast>
    ),
    {
      position: defaultToastOptions.position,
      ...options,
      hideProgressBar: false,
      className: styles[`toast--variant-${type}`],
      bodyClassName: styles[`toastify__body--reset`],
      // it styles only the progress bar and not its container or background
      // to style whole progressbar see Toastify__progress-bar--wrp in Toastify.scss
      progressClassName: styles.toast__progressbar
    }
  )
