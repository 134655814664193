import { formatNumeric } from '@helpers/data'
import { Tooltip } from '@landingi/landingi-ui-kit'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Credits.module.scss'

export const Credits = ({ credits }: { credits: number }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <span className={styles.credits__label}>{t('topbar.credits')}</span>

      <Row vertical='baseline' horizontal='start'>
        <span
          className={styles.credits__quantity}
          data-testid='credits-quantity'
        >
          {formatNumeric(credits)}
        </span>

        <Spreader spread={5} />

        <Tooltip content={t('topbar.credits.tooltip')}>
          <Icon icon='icon-info-circle' size={10} />
        </Tooltip>
      </Row>
    </Fragment>
  )
}

Credits.displayName = 'TopbarMyUsageCredits'
