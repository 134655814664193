import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { SPECTRUM_MARKETING_COLOR } from '@pages/Authentication/routes/WhitelabelPages/constants'
import styles from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/SpectrumMarketing.module.scss'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import TwoFactorAuthenticationForm from './Form'

/**
 * TwoFactorAuthentication - stateless presentational component
 * @return {object} An object of children
 */
const TwoFactorAuthentication = () => {
  const { t } = useTranslation()
  const { trialPeriod } = useRegisterContext()

  return (
    <Column>
      <Panel hasShadow={false}>
        <Heading level={1} bold>
          {t('two.factor.authentication.page.heading', {
            days: trialPeriod
          })}
        </Heading>

        {t('two.factor.authentication.enter.your.auth.code')}

        <Spacer space='medium' />

        <TwoFactorAuthenticationForm />
      </Panel>

      <div className={styles.spectrummarketing__paragraph}>
        <Paragraph size={12} color='accent-2' align='center' padding='none'>
          {t('two.factor.authentication.dont.have.mobile.device')}
          <Link
            style={{
              color: SPECTRUM_MARKETING_COLOR
            }}
            to={`${REGISTRATION.RECOVERY_CODE_SPECTRUM_MARKETING}`}
          >
            {t('two.factor.authentication.dont.have.mobile.device.link')}
          </Link>
        </Paragraph>
      </div>
    </Column>
  )
}

TwoFactorAuthentication.displayName = 'TwoFactorAuthentication'

export default TwoFactorAuthentication
