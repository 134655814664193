import { useStyles } from '@hooks/useStyles'
import { Close } from '@ui-kit/Close'
import { Heading } from '@ui-kit/Heading'
import { useModalContext } from '@ui-kit/Modal/context'
import { FC, HTMLAttributes, ReactNode } from 'react'

import styles from './ModalHeader.module.scss'

export interface ModalHeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  heading?: ReactNode
  className?: string | string[]
  children?: ReactNode
  hasCloser?: boolean
  'data-testid'?: string
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  heading,
  className = '',
  children,
  hasCloser = true,
  'data-testid': dataTestId = 'modal-header',
  ...restProps
}) => {
  const { isCentered, onClose } = useModalContext()

  const modalHeaderStyles = useStyles(
    {
      [styles.modalHeader]: true,
      [styles['modalHeader--centered']]: isCentered
    },
    className
  )

  return (
    <div {...restProps} className={modalHeaderStyles} data-testid={dataTestId}>
      {heading ? (
        <Heading
          level={2}
          className={styles.modalHeader__title}
          data-testid='modal-title'
        >
          {heading}
        </Heading>
      ) : null}

      <div className={styles.modalHeader__actions}>
        {children && children}

        {hasCloser && (
          <Close
            className={styles.modalHeader__closer}
            data-testid='modal-close'
            onClick={onClose}
          />
        )}
      </div>
    </div>
  )
}

ModalHeader.displayName = 'ModalHeader'
