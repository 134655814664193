import { FRESHCHAT_TOKEN } from '@config/env'
import { HTTP_200 } from '@constants/http'
import { saveRestoreId } from '@services/webAnalytics'

interface InitializeFreshChatProps {
  user: {
    identifier: string
    email: string
    name: string
    language: string
  }
  owner: {
    owner_email: string
    plan: string
    identifier: string
    is_trial: string
    lifecycle_stage: string
  }
  restoreId: string
}

const loadScript = () => {
  const id = 'freshchat-lib'

  if (document.getElementById(id) || window.fcWidget) {
    return
  }

  const script = document.createElement('script')
  script.async = true
  script.type = 'text/javascript'
  script.src = 'https://wchat.freshchat.com/js/widget.js'
  script.id = id
  document.head.appendChild(script)
}

const init = (
  userId: string,
  restoreId: string,
  userEmail: string,
  locale: string
) => {
  window.fcWidget.init({
    token: FRESHCHAT_TOKEN,
    host: 'https://wchat.freshchat.com',
    externalId: userId,
    restoreId: restoreId || null,
    email: userEmail,
    locale
  })
}

// initializes fresh chat
export const initializeFreshChat = ({
  user,
  owner,
  restoreId
}: InitializeFreshChatProps) => {
  const {
    identifier: userId,
    email: userEmail,
    name: userFirstName,
    language: locale
  } = user

  const {
    owner_email: ownerEmail,
    plan,
    identifier: accountUuid,
    is_trial: isTrial,
    lifecycle_stage: lifecycleStage
  } = owner

  loadScript()

  const interval = setInterval(() => {
    if (window.fcWidget) {
      clearInterval(interval)

      try {
        init(userId, restoreId, userEmail, locale)

        window.fcWidget.user.setProperties({
          plan,
          owner: ownerEmail,
          admin_url: `https://admin.landingi.com/accounts/${accountUuid}`,
          trial: isTrial,
          platform_stage: lifecycleStage
        })

        window.fcWidget.user.get(
          (response: { status: number; data: { restoreId: string } }) => {
            const status = response && response.status

            if (status !== HTTP_200) {
              window.fcWidget.setExternalId(userId)
              window.fcWidget.user.setFirstName(userFirstName)
              window.fcWidget.user.setEmail(userEmail)
              window.fcWidget.user.setProperties({
                plan,
                owner: ownerEmail,
                admin_url: `https://admin.landingi.com/accounts/${accountUuid}`,
                trial: isTrial,
                platform_stage: lifecycleStage
              })

              window.fcWidget.on(
                'user:created',
                async (response: {
                  status: number
                  data: { restoreId: string }
                }) => {
                  try {
                    const status = response && response.status
                    const data = response && response.data

                    if (status === HTTP_200) {
                      if (data.restoreId) {
                        const { restoreId } = data

                        await saveRestoreId(restoreId)
                      }
                    }
                  } catch {
                    // nothing happens here, application shouldn't crash, but user shouldn't see the error
                  }
                }
              )
            }
          }
        )
      } catch {
        // nothing happens here, application shouldn't crash, but user shouldn't see the error
      }
    }
  }, 1000)
}
