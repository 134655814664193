import { PROD } from '@config/env'
import NiceModal from '@ebay/nice-modal-react'
import LinkedInIcon from '@images/registration/linkedin_icon.svg'
import { Button, Image, Spreader } from '@landingi/landingi-ui-kit'
import { MODAL_WORKING_ON_IT } from '@pages/Authentication/components/WorkingOnIt'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

/**
 * SignUpButton - stateless presentantional component used as google sign up button
 * @returns {object} children
 */
const SignUpButton = ({ disabled, isLoading }) => {
  const { t } = useTranslation()

  const openModal = useCallback(() => {
    if (PROD) {
      // (it's not our api, so it has to be disabled)
      // eslint-disable-next-line no-underscore-dangle
      window?._paq?.push([
        'trackEvent',
        'Signup button',
        'LinkedIn',
        'LinkedIn'
      ])
    }

    NiceModal.show(MODAL_WORKING_ON_IT)
  }, [])

  return (
    <Button
      id='landingi-linkedin-signup'
      variant='secondary-outlined'
      size='large'
      onClick={openModal}
      disabled={disabled}
      isLoading={isLoading}
      hasIcon
    >
      <Row alignItems='center' justifyContent='center'>
        <Image src={LinkedInIcon} size={18} />

        <Spreader spread='small' />

        {t('registration.flow.sign.up.linkedin')}
      </Row>
    </Button>
  )
}

SignUpButton.displayName = 'LinkedInSignUpButton'

SignUpButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
}

SignUpButton.defaultProps = {
  disabled: false,
  isLoading: false
}

export default SignUpButton
