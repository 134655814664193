import { APP_LANDINGI_URL } from '@config/env'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { Button, CustomLink, Spacer } from '@landingi/landingi-ui-kit'
import { ACCOUNT, PAYMENTS } from '@routes/path'
import i18next from 'i18next'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Buttons.module.scss'
import {
  AccountVariantsTypes,
  FREE_TRIAL_WITH_CREDIT_CARD,
  FREE_TRIAL_WITHOUT_CREDIT_CARD,
  linkParts,
  OptionsTypes,
  PAID_ACCOUNT
} from './types'

export const Buttons = () => {
  const { t } = useTranslation()
  const { language } = i18next

  const {
    planRecordKey,
    hasFreeTrial,
    userEmail,
    hasPinnedCreditCard,
    isFreePlan,
    user
  } = useUserContext()

  const {
    plan: { record_key }
  } = user

  const isUnlimitedPlan = record_key === 'unlimited_22'
  const ADDONS_DISABLED_FF = useFeatureFlags('ADDONS_DISABLED')

  let isPlanRecordKey

  if (planRecordKey !== undefined) isPlanRecordKey = planRecordKey in linkParts

  let accountVariant: AccountVariantsTypes = PAID_ACCOUNT

  if (hasFreeTrial && hasPinnedCreditCard) {
    accountVariant = FREE_TRIAL_WITH_CREDIT_CARD
  } else if (hasFreeTrial && !hasPinnedCreditCard) {
    accountVariant = FREE_TRIAL_WITHOUT_CREDIT_CARD
  }

  const primaryButtonOptions: {
    [key in AccountVariantsTypes]: OptionsTypes
  } = {
    [FREE_TRIAL_WITH_CREDIT_CARD]: {
      text: t('word.start.subscription'),
      url: `${NEW_LANDINGI_APP_URL}${PAYMENTS.SUBSCRIPTION.SUBSCRIPTION}`
    },
    [FREE_TRIAL_WITHOUT_CREDIT_CARD]: {
      text: t('topbar.my-usage.buy-now'),
      url: `${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`
    },
    [PAID_ACCOUNT]: {
      text: isUnlimitedPlan ? '' : t('word.upgrade'),
      url: `${APP_LANDINGI_URL}${ACCOUNT.ACCOUNT_PACKAGE}`
    }
  }

  const customLinkOptions: {
    [key in AccountVariantsTypes]: OptionsTypes
  } = {
    [FREE_TRIAL_WITH_CREDIT_CARD]: {
      text: t('topbar.my-usage.change-plan'),
      url: `${APP_LANDINGI_URL}${ACCOUNT.ACCOUNT_PACKAGE}`
    },
    [FREE_TRIAL_WITHOUT_CREDIT_CARD]: {
      text: t('topbar.my-usage.change-plan'),
      url: `${APP_LANDINGI_URL}${ACCOUNT.ACCOUNT_PACKAGE}`
    },
    [PAID_ACCOUNT]: {
      text:
        isUnlimitedPlan || ADDONS_DISABLED_FF
          ? ''
          : t('topbar.my-usage.extend-limits'),
      url: t('topbar.my-usage.addon.url', {
        plan: linkParts[planRecordKey as keyof typeof linkParts],
        lang: language,
        email: userEmail,
        planRecordKey
      })
    }
  }

  return (
    <Fragment>
      {primaryButtonOptions[accountVariant].text ? (
        <Button
          className={styles.buttons__button}
          tag='a'
          size='tiny'
          href={primaryButtonOptions[accountVariant].url}
          buttonStyle
        >
          {primaryButtonOptions[accountVariant].text}
        </Button>
      ) : null}

      {!isFreePlan && isPlanRecordKey ? (
        <Fragment>
          <Spacer space='small' />

          <CustomLink
            href={customLinkOptions[accountVariant].url}
            label={customLinkOptions[accountVariant].text}
            target='_blank'
            size={12}
            underlined
          />
        </Fragment>
      ) : null}
    </Fragment>
  )
}

Buttons.displayName = 'TopbarMyUsageButtons'
