import { PROD } from '@config/env'
import { MODAL_WORKING_ON_IT } from '@constants/modals'
import NiceModal from '@ebay/nice-modal-react'
import MicrosoftIcon from '@images/registration/microsoft_icon.svg'
import { Button, Image, Spreader } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

const SignUpButton = ({ disabled, isLoading }) => {
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    if (PROD) {
      // (it's not our api, so it has to be disabled)
      // eslint-disable-next-line no-underscore-dangle
      window?._paq?.push([
        'trackEvent',
        'Signup button',
        'Microsoft',
        'Microsoft'
      ])
    }

    NiceModal.show(MODAL_WORKING_ON_IT)
  }, [])

  return (
    <Button
      id='landingi-microsoft-signup'
      variant='secondary-outlined'
      size='large'
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      hasIcon
    >
      <Row alignItems='center' justifyContent='center'>
        <Image src={MicrosoftIcon} size={18} />

        <Spreader spread='small' />

        {t('registration.flow.sign.up.microsoft')}
      </Row>
    </Button>
  )
}

SignUpButton.displayName = 'MicrosoftSignUpButton'

SignUpButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
}

SignUpButton.defaultProps = {
  disabled: false,
  isLoading: false
}

export default SignUpButton
