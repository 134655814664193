import { useStyles } from '@hooks/useStyles'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { Button, Heading, Image, Radio, Spacer, Spreader } from '@ui-kit'
import { BoxOutline, BoxOutlineProvider } from '@ui-kit/BoxOutline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import {
  optionsWithAccessToThirdStep,
  secondStepOptions
} from '../helpers/constants'
import styles from './common.module.scss'

const SecondStep: React.FC = () => {
  const { t } = useTranslation()

  const {
    formik,
    validators: { isSecondStepValid },
    setGoal
  } = useWizardContext()
  const { values } = formik
  const { topGoal } = values

  const boxOutlineStyles = useStyles({
    [styles.panel]: true
  })

  const boxOutlineContainerStyles = useStyles({
    [styles.container]: true
  })

  const gridStyles = useStyles({
    [styles.grid]: true
  })

  return (
    <Column>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.is.your.goal')}
      </Heading>

      <Spacer space={20} />

      <div className={gridStyles}>
        {secondStepOptions.map(({ name, image }) => {
          const handleClick = () => setGoal(name)
          return (
            <BoxOutlineProvider key={name} controlledActive={topGoal === name}>
              <BoxOutline
                onClick={handleClick}
                className={boxOutlineStyles}
                containerClassName={boxOutlineContainerStyles}
                data-testid={`wizard-goal-${name}`}
              >
                <Row alignItems='center'>
                  <Column className={styles['radio-container']}>
                    <Radio
                      id={name}
                      field={{
                        name,
                        value: topGoal,
                        onChange: handleClick
                      }}
                    />
                  </Column>
                  <Spreader spread={5} />
                  <Row alignItems='center'>
                    <Image src={image} height='18px' />
                    <Spreader spread={10} />
                    {t(`landings.create.wizard.goal.${name}`)}
                  </Row>
                </Row>
              </BoxOutline>
            </BoxOutlineProvider>
          )
        })}
      </div>

      <Spacer space={30} />

      <Row justifyContent='end'>
        <Button
          icon='icon-arrow-right'
          disabled={!isSecondStepValid}
          type='submit'
        >
          {optionsWithAccessToThirdStep.includes(topGoal as string)
            ? t('landings.create.wizard.landing.modal.go.to.offer')
            : t('landings.create.wizard.landing.modal.go.to.style')}
        </Button>
      </Row>

      <Spacer space={30} />
    </Column>
  )
}

export default SecondStep
