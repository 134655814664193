import { AxiosError } from 'axios'
import useSWR from 'swr'

export type AccountCreditsResponse = {
  credits_cost: number
}

export const useGetTranslationPrice = (uuid: string) => {
  const response = useSWR<AccountCreditsResponse, AxiosError>(
    `/landing-page/landing/${uuid}/translate-and-duplicate-credits-cost`
  )

  return { cost: response.data?.credits_cost, ...response }
}
