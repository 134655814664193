import {
  Button,
  emitTimingToastToggle,
  InputForm
} from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import {
  THRIVE_COACH,
  THRIVE_COACH_COLOR
} from '@pages/Authentication/routes/WhitelabelPages/constants'
import FormSchema from '@pages/Authentication/routes/WhitelabelPages/RecoveryCode/FormSchema'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const RecoveryCodeForm = () => {
  const { t } = useTranslation()
  const { signInWithRecoveryCode } = useWhitelabelPageContext()

  const initialValues = {
    code: ''
  }

  const onSubmit = async (values, { setFieldError }) => {
    try {
      const { code } = values

      await signInWithRecoveryCode(code, THRIVE_COACH)
    } catch {
      emitTimingToastToggle(
        t('two.factor.authentication.this.code.is.invalid'),
        'alert'
      )
      setFieldError(
        'code',
        t('two.factor.authentication.wrong.recovery.code.error')
      )
    }
  }

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    dirty
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: FormSchema
  })

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <Row>
          <Column flexGrow='1'>
            <InputForm
              field={{
                name: 'code',
                value: values.code,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              maxLength={36}
              name='code'
              id='code'
              i18n={{
                label: t('two.factor.authentication.10.digit.code'),
                placeholder: t('two.factor.authentication.10.digit.code')
              }}
              form={{
                errors,
                touched
              }}
            />
          </Column>
        </Row>

        <Button
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          size='large'
          customEnabledBackgroundColor={THRIVE_COACH_COLOR}
          customStyle={{ borderRadius: '4px' }}
        >
          {t('two.factor.authentication.verify')}
        </Button>
      </Column>
    </form>
  )
}

RecoveryCodeForm.displayName = 'TwoFactorAuthenticationForm'

export default RecoveryCodeForm
