import { InfobarContent } from '@components/Topbar/components/Infobars/InfobarContent'
import { useAcl } from '@contexts/acl'
import { ACCOUNT } from '@routes/path'
import { Button, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

export const FreePlans = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const handleRedirectToLP = () => {
    window.open(t('infobar.free.plans.button.schedule.demo.link'), '_blank')
  }

  const handleRedirectToPricing = () => {
    navigate(ACCOUNT.ACCOUNT_PACKAGE)
  }

  return (
    <Fragment>
      <InfobarContent>
        <Trans
          i18nKey='infobar.free.plans'
          components={{
            b: <b />,
            br: <br />
          }}
        />
      </InfobarContent>

      <Row>
        <Button variant='text-primary' onClick={handleRedirectToLP}>
          <Trans i18nKey='infobar.free.plans.button.schedule.demo' />
        </Button>

        {hasAccessToChangePlan && (
          <Fragment>
            <Spreader spread={10} />

            <Button onClick={handleRedirectToPricing}>
              <Trans i18nKey='infobar.free.plans.button.compare.plans' />
            </Button>
          </Fragment>
        )}
      </Row>
    </Fragment>
  )
}

FreePlans.displayName = 'TopbarInfobarFreePlans'
