import { useSidebarContext } from '@contexts/sidebar'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import { useStyles } from '@hooks/useStyles'
import {
  Button,
  emitCloseDropdown,
  Icon,
  List,
  ListItem,
  PerfectDropdown,
  Spreader
} from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import {
  changeUserAccount,
  toggleSubaccountActivationStatus,
  useSubaccounts
} from '@services/subaccounts'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

import { MODAL_SUBACCOUNT_DELETE } from '../Modals/ModalSubaccountDelete'
import { MODAL_SUBACCOUNT_EDIT } from '../Modals/ModalSubaccountEdit'
import styles from './WorkspaceButton.module.scss'

interface WorkspaceButtonProps {
  uuid: string
  icon: string
  name: string
  email?: string
  isActive?: boolean
  isDisabled?: boolean
  settingsBtn?: boolean
  limitUuid?: string
}

export const WorkspaceButton: FC<WorkspaceButtonProps> = ({
  uuid,
  icon,
  name,
  email,
  isActive,
  isDisabled,
  settingsBtn,
  limitUuid
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { mutate: mutateSubaccount } = useSubaccounts()

  const { accountID } = useUserContext()

  const { toggleWorkspacesOpen } = useSidebarContext()

  const containerStyles = useStyles({
    [styles.button__container]: true,
    [styles['button__container--disabled']]: isDisabled
  })

  const textStyles = useStyles({
    [styles.button__text]: true,
    [styles['button__text--active']]: isActive
  })

  const { mutate } = useSWRConfig()

  const [requestToggleSubaccountActivationStatus] = useLazyService(
    toggleSubaccountActivationStatus,
    {
      successToastText: isDisabled
        ? t('toast.subaccount.activated')
        : t('toast.subaccount.deactivated'),
      errorToastText: t('toast.error.something.went.wrong')
    }
  )

  const handleEdit = () => {
    emitCloseDropdown()

    NiceModal.show(MODAL_SUBACCOUNT_EDIT, {
      uuid,
      name,
      limitUuid
    })

    toggleWorkspacesOpen()
  }

  const handleToggleActivate = async () => {
    emitCloseDropdown()

    await requestToggleSubaccountActivationStatus(uuid)

    mutateSubaccount()
  }

  const handleDelete = () => {
    emitCloseDropdown()

    NiceModal.show(MODAL_SUBACCOUNT_DELETE, {
      uuid,
      name
    })

    toggleWorkspacesOpen()
  }

  const refreshUserAuthorizationAndInfo = () => {
    mutate(() => true, undefined, { revalidate: false })
    mutate('auth', undefined, { revalidate: true })
    mutate('account/info', undefined, { revalidate: true })
    mutate('acl/access/features', undefined, { revalidate: true })
    navigate(LANDINGS.LANDINGS)
  }

  const [requestChangeUserAccount] = useLazyService(changeUserAccount, {
    onSuccess: refreshUserAuthorizationAndInfo,
    successToastText: t('toast.change.user-account.success'),
    errorToastText: t('toast.change.user-account.error')
  })

  const handleChangeUserAccount = async () => {
    if (uuid !== accountID) {
      await requestChangeUserAccount(uuid)
    }

    toggleWorkspacesOpen()
  }

  return (
    <Row className={styles.container}>
      <button
        type='button'
        {...(isDisabled ? {} : { onClick: toggleWorkspacesOpen })}
        className={styles.button}
        onClick={handleChangeUserAccount}
        data-testid='workspace-button'
        disabled={isDisabled}
      >
        <div className={containerStyles}>
          <Icon icon={icon} />

          <Spreader spread='tiny' />

          <Column alignItems='start'>
            <span className={textStyles}>{name}</span>

            {email ? (
              <span className={styles.button__email}>{email}</span>
            ) : null}
          </Column>
        </div>
      </button>

      {settingsBtn ? (
        <div data-testid='settings-icon'>
          <PerfectDropdown
            hasArrow={false}
            icon='icon-ellipsis-v'
            size='small'
            dropdownPlacement='bottom-start'
          >
            <List>
              <ListItem variant='dropdown'>
                <Button variant='dropdown-element' onClick={handleEdit} hasIcon>
                  <Icon icon='icon-edit' size={12} />
                  {t('word.edit')}
                </Button>
              </ListItem>

              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  onClick={handleToggleActivate}
                  hasIcon
                >
                  <Icon icon='icon-block' size={12} />
                  {isDisabled
                    ? t('subaccount.activate')
                    : t('subaccount.deactivate')}
                </Button>
              </ListItem>

              <ListItem variant='dropdown'>
                <Button
                  variant='dropdown-element'
                  onClick={handleDelete}
                  hasIcon
                >
                  <Icon icon='icon-trash' size={12} />
                  {t('word.delete')}
                </Button>
              </ListItem>
            </List>
          </PerfectDropdown>
        </div>
      ) : null}
    </Row>
  )
}

WorkspaceButton.displayName = 'WorkspaceButton'
