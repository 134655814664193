import SquareOrangeTwo from '@images/registration/square_orange_2.svg'
import halfCircleGreenImg from '@images/registration/survey/half_circle_green.svg'
import TriangleBlue from '@images/registration/triangle_blue.svg'
import TrianglePinkTwo from '@images/registration/triangle_pink_2.svg'
import { Image } from '@landingi/landingi-ui-kit'
import styles from '@pages/Authentication/components/SecondColumn/SecondColumn.module.scss'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { motion } from 'framer-motion'

/**
 * Shapes container component containing shapes located on registration page
 * @return {object} An object of children
 */
const ShapesContainer = () => {
  const { shapesContainerControls } = useAnimationContext()

  return (
    <motion.div
      className={styles['shapes-container']}
      animate={shapesContainerControls}
    >
      <div className={styles['shapes-container__triangle-pink-2-1']}>
        <Image src={TrianglePinkTwo} />
      </div>

      <div className={styles['shapes-container__half-circle-green-1-2']}>
        <Image src={halfCircleGreenImg} />
      </div>

      <div className={styles['shapes-container__square-orange-2-2']}>
        <Image src={SquareOrangeTwo} />
      </div>

      <div className={styles['shapes-container__triangle-blue']}>
        <Image src={TriangleBlue} />
      </div>
    </motion.div>
  )
}

ShapesContainer.displayName = 'ShapesContainer'

export default ShapesContainer
