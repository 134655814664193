export const openWindowWithJWT = (url, target = '_self') => {
  if (window['landingi-semrush-jwt']) {
    const [urlString, paramString] = url.split('?')

    const searchParams = new URLSearchParams(paramString)

    searchParams.set('jwt', window['landingi-semrush-jwt'])

    window.open(`${urlString}?${searchParams.toString()}`, target)

    return
  }

  window.open(url, target)
}
