import { STATUS, SubscriptionStatusType } from '@constants/subscription'
import { useUserContext } from '@contexts/user'
import Qs from 'qs'

const { ACTIVE, PAST_DUE, PENDING } = STATUS

export const useSubscriptionInfoParams = (
  hasDiscount: boolean,
  source: string
) => {
  const {
    accountCurrency,
    billingPeriod,
    planRecordKey,
    subscriptionStatus,
    userEmail
  } = useUserContext()

  const activeSbscriptionStatuses: SubscriptionStatusType[] = [
    ACTIVE,
    PAST_DUE,
    PENDING
  ]

  const hasActiveSubscription = activeSbscriptionStatuses.includes(
    subscriptionStatus as SubscriptionStatusType
  )
    ? 'yes'
    : 'no'

  const discountParam = hasDiscount ? 'yes' : 'no'

  return Qs.stringify(
    {
      email: userEmail,
      currency: accountCurrency,
      package: planRecordKey,
      subscription: hasActiveSubscription,
      utm_medium: 'platform',
      utm_source: source,
      utm_content: 'link',
      discount: discountParam,
      period:
        hasActiveSubscription === 'yes' && billingPeriod === 12
          ? billingPeriod
          : undefined
    },
    { encode: false }
  )
}
