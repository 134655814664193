import RocketImage from '@images/registration/survey/questionIcons/rocket.png'
import {
  Button,
  Heading,
  Image,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import Form from '@pages/Authentication/routes/Survey/Form'
import styles from '@pages/Authentication/routes/Survey/Survey.module.scss'
import { motion } from 'framer-motion'
import { Suspense } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

const Survey = () => {
  const { hasSurveyStarted, setActiveSurveyBoxesIDs } = useSurveyContext()
  const { playSurveyStart } = useAnimationContext()
  const { t } = useTranslation()
  const { nextStep } = useStepsContext()

  /**
   * starts survey and sets first active survey box to onboarding
   * @type {function}
   */
  const handleReadyButtonClick = () => {
    playSurveyStart()
    setActiveSurveyBoxesIDs(['onboarding'])
    nextStep()
  }

  return (
    <Suspense fallback={<>...</>}>
      <Column className={styles['register-cta']}>
        {hasSurveyStarted ? (
          <Form />
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Row alignItems='center'>
              <Image src={RocketImage} size='48px' />

              <Spreader spread='small' />

              <Heading level={1} margin='none'>
                {t('registration.flow.kick.off.your.account')}
              </Heading>
            </Row>

            <Spacer space='mini' />

            <Spacer space='tiny' />

            <Paragraph line={28} size={18}>
              <Trans i18nKey='registration.flow.tell.us.a.little.bit' />
            </Paragraph>

            <Spacer space='small' />

            <Spacer space='mini' />

            <div className={styles['im-ready-button']}>
              <Button onClick={handleReadyButtonClick}>
                {t('registration.flow.i.am.ready')}
              </Button>
            </div>
          </motion.div>
        )}
      </Column>
    </Suspense>
  )
}

export default Survey
