const ACCOUNT = {
  ACCOUNT_AGENCY_USERS: '/account/agency/users',
  ACCOUNT_AGENCY_USERS_CREATE: '/account/agency/users/create',
  ACCOUNT_AGENCY_USERS_DELETE: '/account/agency/users/delete/:identifier',
  ACCOUNT_AGENCY_USERS_EDIT: '/account/agency/users/edit/:identifier',
  ACCOUNT_USERS: '/users',
  ACCOUNT_TOKENS: '/account/tokens',
  ACCOUNT_HISTORY: '/account/history',
  ACCOUNT_PACKAGE: '/account/package',
  ACCOUNT_PACKAGE_SUMMARY: '/account/package/summary'
}

export default ACCOUNT
