import type { useTabsValuesType } from '@ui-kit/Tabs/hooks/useTabs'
import { useTabs } from '@ui-kit/Tabs/hooks/useTabs'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

export type TabsProviderProps = {
  children: ReactNode
  initialTab: string
  isScrollable?: boolean
  scrollOffset?: number
}

type TabsContextValue = useTabsValuesType & {
  isScrollable: boolean
  scrollOffset: number
  canBeChangedByScrolling: boolean
  setCanBeChangedByScrolling: Dispatch<SetStateAction<boolean>>
}

const TabsContext = createContext<TabsContextValue | null>(null)

export const TabsProvider: FC<TabsProviderProps> = ({
  children,
  initialTab,
  isScrollable = false,
  scrollOffset = 0
}) => {
  const { activeTab, changeActiveTab, listOpenedTabs } = useTabs({
    initialTab,
    isScrollable
  })

  const [canBeChangedByScrolling, setCanBeChangedByScrolling] = useState(true)

  const activateTabChange = () => setCanBeChangedByScrolling(true)

  useEffect(() => {
    if (isScrollable) {
      /* it is needed to reactivate possibility to change tabs by scrolling,
      whenever scrolling ends, as it is deactivated when user scrolls by
      clicking on tab */
      window.addEventListener('scrollend', activateTabChange)
    }

    return () => window.removeEventListener('scrollend', activateTabChange)
  }, [isScrollable])

  const ctxValue = useMemo(
    () => ({
      activeTab,
      changeActiveTab,
      listOpenedTabs,
      isScrollable,
      scrollOffset,
      canBeChangedByScrolling,
      setCanBeChangedByScrolling
    }),
    [
      activeTab,
      changeActiveTab,
      listOpenedTabs,
      isScrollable,
      scrollOffset,
      canBeChangedByScrolling,
      setCanBeChangedByScrolling
    ]
  )

  return (
    <TabsContext.Provider value={ctxValue}>{children}</TabsContext.Provider>
  )
}

export const useTabsContext = () => {
  const ctxValue = useContext(TabsContext)

  if (!ctxValue) {
    throw new Error('useTabContext must be used inside TabProvider')
  }

  return ctxValue
}
