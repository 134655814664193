import { CurrencyType, PLN } from '@constants/currencies'
import {
  discount12MForPlan,
  monthsOfEventTrackerFor12MPromotion,
  numberOfCreditsFor12MPromotion,
  PROMOTION_12M_PLANS,
  promotion12MDiscountedPricesBrutto
} from '@constants/promotions'
import {
  convertPriceToNetto,
  formatPrice,
  splitPriceByFloatingPoint
} from '@helpers/payment'
import { Paragraph as OldParagraph } from '@landingi/landingi-ui-kit'
import {
  Badge,
  Icon,
  Paragraph,
  Price as PriceComponent,
  Spacer,
  Spreader,
  Tooltip
} from '@ui-kit'
import { PriceDescription } from '@ui-kit/Price/PriceDescription'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './Price.module.scss'

interface PriceProps {
  amount: number | string
  currency: CurrencyType
  canBePaidAnnually?: boolean
  recordKey: (typeof PROMOTION_12M_PLANS)[number]
  annualSaving?: number | string
}

export const Promotion12MPrice: FC<PriceProps> = ({
  amount,
  currency,
  canBePaidAnnually = true,
  recordKey,
  annualSaving
}) => {
  const { t } = useTranslation()

  const splittedPrice = splitPriceByFloatingPoint(amount)

  const discountedPrice =
    currency === PLN
      ? convertPriceToNetto(
          promotion12MDiscountedPricesBrutto[recordKey][currency]
        )
      : promotion12MDiscountedPricesBrutto[recordKey][currency]

  const discountValue = discount12MForPlan[recordKey][currency]

  const numberOfMonths = monthsOfEventTrackerFor12MPromotion[recordKey]

  const numberOfCredits = numberOfCreditsFor12MPromotion[recordKey]

  return (
    <Column>
      <PriceComponent amount={discountedPrice} currency={currency}>
        <PriceDescription>
          {currency === PLN && t('change.plan.page.net')}{' '}
          {canBePaidAnnually && t('change.plan.page.per.month')}
        </PriceDescription>
      </PriceComponent>

      <Spacer space={5} />

      <Row>
        <Tooltip content={t('change.plan.page.billed.once.annually.tooltip')}>
          <Paragraph color='neutral-6' size={14}>
            {t('change.plan.page.billed.once.annually')}

            <Spreader spread={5} />

            <Icon icon='icon-help_outline' />
          </Paragraph>
        </Tooltip>
      </Row>

      <Spacer space={10} />

      {annualSaving && (
        <div>
          <Badge variant='warning-filled' weight={600}>
            {t('change.plan.page.save')} {annualSaving} {currency}
          </Badge>
        </div>
      )}

      <Spacer space={10} />

      <Row className={styles['price-container__row']} vertical='center'>
        <OldParagraph
          size={14}
          padding='none'
          color='accent-2'
          decoration='line-through'
        >
          {splittedPrice.decimalPoint}
          {splittedPrice.floatingPoint &&
            `,${splittedPrice.floatingPoint}`}{' '}
          {currency} {currency === PLN && t('change.plan.page.net')}{' '}
        </OldParagraph>
      </Row>

      <Spacer space={5} />

      <Row className={styles['price-container__row']} vertical='center'>
        <Badge variant='neutral-filled' weight={600}>
          {t('black.friday.pricing.badge', {
            discountValue: formatPrice(discountValue),
            currency
          })}
        </Badge>
      </Row>

      <Spacer space={5} />

      <Column>
        <Paragraph color='primary' weight={600}>
          {numberOfMonths > 4
            ? t('change.plan.page.black.friday.event.tracker.gift.more', {
                numberOfMonths
              })
            : t('change.plan.page.black.friday.event.tracker.gift', {
                numberOfMonths
              })}
        </Paragraph>

        <Spacer space={5} />

        <Paragraph color='primary' weight={600}>
          {t('change.plan.page.black.friday.credits.gift', {
            numberOfCredits
          })}
        </Paragraph>
      </Column>
    </Column>
  )
}

Promotion12MPrice.displayName = 'Promotion12MPrice'
