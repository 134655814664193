import { useGetAcl } from '@services/acl'
import { createContext, FC, ReactNode, useContext } from 'react'

const AclContext = createContext<ReturnType<typeof useGetAcl> | null>(null)

type ProviderProps = {
  children: ReactNode
}

export const AclContextProvider: FC<ProviderProps> = ({ children }) => {
  const value = useGetAcl()

  return <AclContext.Provider value={value}>{children}</AclContext.Provider>
}

export const useAclContext = () => {
  const value = useContext(AclContext)

  if (!value) {
    throw new Error('useAcl must be used inside AclContextProvider')
  }

  return value
}

export const useAcl = (acl: string) => {
  const value = useContext(AclContext)

  if (!value) {
    throw new Error('useAcl must be used inside AclContextProvider')
  }

  return value.checkAccessToAcl(acl)
}
