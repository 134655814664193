import { List, ListItem } from '@landingi/landingi-ui-kit'
import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { CustomLink } from '@ui-kit/CustomLink'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.2')}
      </Row>
    ),
    content: <Paragraph size={14}>{t('addons.store.faq.answer.2')}</Paragraph>
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.faq.how.can.i.access.smart.sections')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t('addons.store.faq.you.can.access.smart.sections')}{' '}
        </Paragraph>
        <List listStyle='ordered-disc'>
          <ListItem variant='list'>
            {t('addons.store.smart.sections.list.1')}
          </ListItem>
          <ListItem variant='list'>
            {t('addons.store.smart.sections.list.2')}
          </ListItem>
        </List>
        <Spacer space={10} />
        <Paragraph size={14}>
          {t('addons.store.faq.we.ve.added.all.info')}{' '}
          <CustomLink
            href={t('addons.store.smart.sections.help.center.link')}
            target='_blank'
          >
            {t('addons.store.smart.sections.help.center')}
          </CustomLink>
          .
        </Paragraph>
      </Fragment>
    )
  }
]
