import ComposerLogo from '@images/landings/wizard/composer-logo.svg'
import SecondColumnContent from '@pages/Landings/routes/Landings/CreateLandingWizard/components/SecondColumnContent'
import useSteps from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/useSteps'
import { LANDINGS } from '@routes/path'
import { Badge, Button, Close, Image, ProgressBar, Spreader } from '@ui-kit'
import { ReactNode } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import { optionsWithAccessToThirdStep } from '../helpers/constants'
import styles from './WizardSurveyLayout.module.scss'

export const WizardSurveyLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const { stepNumber, stepsLimit, getStepUrlByNumber, previousStep } =
    useSteps()

  const { handleSubmit, formik } = useWizardContext()

  const back = () => previousStep(formik.values)
  const close = () => navigate(LANDINGS.LANDINGS)

  if (stepNumber > 1 && !formik.values.websiteName) {
    return <Navigate to={getStepUrlByNumber(1)} />
  }

  if (
    stepNumber === 3 &&
    !optionsWithAccessToThirdStep.includes(formik.values.topGoal as string)
  ) {
    return <Navigate to={getStepUrlByNumber(2)} />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row className={styles['wizard-survey-layout']} justifyContent='center'>
        <Row
          className={styles['wizard-survey-layout__header']}
          alignItems='center'
          justifyContent='space-between'
        >
          <Row alignItems='center'>
            <Button
              icon='icon-arrow-left'
              variant='text-neutral'
              onClick={back}
            />

            <Spreader spread={20} />

            <Image src={ComposerLogo} alt='Composer Logo' width='98px' />
            <Spreader spread={5} />
            <Badge weight={600} variant='almostcyan-filled'>
              ALFA
            </Badge>
          </Row>

          <Row alignItems='center'>
            <div className={styles['wizard-survey-layout__progress-bar']}>
              <ProgressBar
                variant='brand'
                quantity={stepNumber}
                limit={stepsLimit}
                size={12}
              />
            </div>
            <Spreader spread={40} />

            <Close onClick={close} />
          </Row>
        </Row>
        <Row className={styles['wizard-survey-layout__content']}>
          <Row
            className={styles['wizard-survey-layout__first-column']}
            alignItems='center'
          >
            {children}
          </Row>
          <Row className={styles['wizard-survey-layout__second-column']}>
            <Row
              className={styles['wizard-survey-layout__second-column-content']}
              alignItems='center'
            >
              <SecondColumnContent />
            </Row>
          </Row>
        </Row>
      </Row>
    </form>
  )
}
