import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { CustomLink } from '@ui-kit/CustomLink'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.2')}
      </Row>
    ),
    content: <Paragraph size={14}>{t('addons.store.faq.answer.2')}</Paragraph>
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.faq.how.do.i.add.popups')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t('addons.store.faq.how.do.i.add.popups.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph size={14}>
          {t('addons.store.faq.how.do.i.add.popups.paragraph.2')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph size={14}>
          {t('addons.store.faq.how.do.i.add.popups.paragraph.3')}{' '}
          <CustomLink
            href={t('addons.store.popups.help.center.link')}
            target='_blank'
          >
            {t('addons.store.popups.help.center')}
          </CustomLink>
        </Paragraph>
      </Fragment>
    )
  }
]
