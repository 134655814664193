import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import { useLogout } from '@hooks/useLogout'
import modalExpired from '@images/modals/modal-expired.svg'
import {
  Button,
  CustomLink,
  Heading,
  Image,
  Modal,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { PAYMENTS } from '@routes/path'
import { useGetAccountInfo } from '@services/account'
import { extendTrial } from '@services/account/extendTrial'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './ModalTrialExpired.module.scss'

export const ModalTrialExpired = NiceModal.create(() => {
  const { t } = useTranslation()

  const { remove } = useModal()

  const { mutate, data } = useGetAccountInfo()

  const handleLogoutAccount = useLogout()

  const hasExtendedTrial = useFeatureFlags('EXTENDED_TRIAL')

  // TODO - to be changed after payment release
  const hasAccessToPayments = useAcl('payments.pay')

  const handleShowAlert = () => {
    // eslint-disable-next-line no-alert
    window.alert(t('modal.expired.free-trial.button.extend.contact'))
  }

  const [handleExtendTrial, { isLoading }] = useLazyService(extendTrial, {
    onSuccess: () => {
      if (!data) {
        return
      }

      mutate(
        { ...data, statuses: { ...data.statuses, is_active: true } },
        { revalidate: false }
      )

      remove()
    },
    successToastText: t('modal.expired.free-trial.toast.success'),
    errorToastText: t('error.page.generic.title')
  })

  return (
    <Modal isActive isClosable={false} size='small' isBodyPadding='none'>
      <Spacer space='large' />

      <Spacer space='small' />

      <Image src={modalExpired} className={styles.cart} />

      <Spacer />

      <Heading level={2} align='center' margin='none'>
        {t('modal.expired.free-trial.header')}
      </Heading>

      <Spacer space='small' />

      <Heading level={5} align='center' className={styles.message}>
        <Trans
          i18nKey='modal.expired.free-trial.message'
          components={{
            br: <Spacer space='tiny' />
          }}
        />
      </Heading>

      <Spacer />

      <Column alignItems='center'>
        {hasAccessToPayments && (
          <Row justifyContent='center'>
            <Button
              variant='secondary-outlined'
              onClick={hasExtendedTrial ? handleShowAlert : handleExtendTrial}
              isLoading={isLoading}
            >
              {t('modal.expired.free-trial.button.extend')}
            </Button>

            <Spreader spread='tiny' />

            <Button
              tag='a'
              buttonStyle
              href={`${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`}
            >
              {t('modal.expired.free-trial.button')}
            </Button>
          </Row>
        )}

        <Spacer space='small' />

        <CustomLink
          size={16}
          label={t('topbar.logout')}
          onClick={handleLogoutAccount}
          className={styles.logout}
        />

        <Spacer />
      </Column>
    </Modal>
  )
})
