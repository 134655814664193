import { getRecordKeyByName } from '@config/plans'
import { sources, SOURCES_NAMES } from '@config/registrationSources'
import { VARIANTS_NAMES } from '@config/registrationVariants'
import { FREE_PLANS, PLANS, plans2024 } from '@constants/plans'
import { getBrowserLanguage } from '@helpers/i18n'
import { setLocalStorage } from '@helpers/storage'
import useQuery from '@hooks/useQuery'
import RegistrationForm from '@pages/Authentication/components/RegistrationForm'
import RegistrationFormWithCode from '@pages/Authentication/components/RegistrationFormWithCode'
import {
  BlackFridayVariant,
  ChristmasVariant,
  CyfrowaWyprawka,
  EventTrackerLoginVariant,
  FastTony,
  Leads,
  RefreshedVariant,
  Sosv,
  SummerSaleVariant,
  Templates,
  Tests,
  Wordpress
} from '@pages/Authentication/components/SecondColumn/Registration/variants'
import { useLocation, useParams } from 'react-router-dom'

const plansRecordKeys = plans2024.map(({ recordKey }) => recordKey)

const variants = {
  sosv: {
    secondColumnContent: Sosv,
    registrationForm: RegistrationFormWithCode,
    registrationMethods: []
  },
  templates: {
    secondColumnContent: Templates,
    registrationForm: RegistrationForm
  },
  leads: {
    secondColumnContent: Leads,
    registrationForm: RegistrationForm
  },
  tests: {
    secondColumnContent: Tests,
    registrationForm: RegistrationForm
  },
  wordpress: {
    secondColumnContent: Wordpress,
    registrationForm: RegistrationForm
  },
  amft: {
    secondColumnContent: FastTony,
    registrationForm: RegistrationForm,
    heading: 'registration.flow.get.access',
    subtitle: 'registration.flow.make.use.of.your.knowledge'
  },
  cyfrowawyprawka: {
    secondColumnContent: CyfrowaWyprawka,
    registrationForm: RegistrationForm,
    heading: 'registration.flow.get.access',
    subtitle: 'registration.flow.make.use.of.your.knowledge.workshops'
  },
  agency: {
    secondColumnContent: Templates,
    registrationForm: RegistrationForm,
    chosenPackage: getRecordKeyByName('Agency')
  },
  vc: {
    secondColumnContent: Templates,
    registrationForm: RegistrationFormWithCode,
    registrationMethods: []
  },
  aip: {
    secondColumnContent: Templates,
    registrationForm: RegistrationFormWithCode,
    registrationMethods: [],
    trialPeriod: 60
  },
  dsim: {
    secondColumnContent: Templates,
    registrationForm: RegistrationFormWithCode,
    registrationMethods: [],
    trialPeriod: 60
  },
  '60dt': {
    secondColumnContent: Templates,
    registrationForm: RegistrationForm,
    trialPeriod: 60
  },
  default: {
    secondColumnContent: RefreshedVariant,
    registrationForm: RegistrationForm,
    registrationMethods: ['google']
  }
}

/**
 * GetVariant - function that returns object with variant properties based on its name
 * @type {function}
 * @param {string} variantName - name of the variant
 * @return {object}
 */
const getVariant = variantName => variants[variantName]

/**
 * GetDefaultParams - returns object with default params for registration page
 * @type {function}
 * @return {object}
 */
const getDefaultParams = () => ({
  ...getVariant(VARIANTS_NAMES.DEFAULT),
  chosenPackage: PLANS.PROFESSIONAL_24,
  variantName: VARIANTS_NAMES.DEFAULT,
  source: SOURCES_NAMES.DEFAULT,
  period: 1,
  lang: getBrowserLanguage(),
  trialPeriod: 14,
  registrationMethods: ['google'],
  heading: 'registration.flow.start.trial',
  subtitle: '',
  pricing: 'no',
  newSignup: 'no'
})

/**
 * ApplyQueryParams - applies queryParams to given registrationConfig and returns new registrationConfig
 * @param {object} registrationConfig - object with registration params
 * @param {object} queryParams - URLSearchParams object with query params
 * @type {function}
 * @return {object}
 */
const applyQueryParams = (registrationConfig, queryParams, location) => {
  if (queryParams.has('package')) {
    registrationConfig.chosenPackage = queryParams.get('package')

    if (FREE_PLANS.includes(registrationConfig.chosenPackage)) {
      registrationConfig.heading =
        'registration.flow.start.trial.package.free.22'
    }
  }

  if (queryParams.has('v')) {
    const variantName = queryParams.get('v')

    registrationConfig = {
      ...registrationConfig,
      variantName,
      ...getVariant(variantName)
    }
  }

  if (queryParams.has('period')) {
    registrationConfig.period = Number(queryParams.get('period'))
  }

  if (queryParams.has('lang')) {
    registrationConfig.lang = queryParams.get('lang')
  }

  if (queryParams.has('pricing')) {
    registrationConfig.pricing = queryParams.get('pricing')
  }

  if (queryParams.has('promotion')) {
    if (
      queryParams.get('promotion') === 'bf12m' &&
      location.pathname === '/register'
    ) {
      registrationConfig.secondColumnContent = BlackFridayVariant
    }

    if (queryParams.get('promotion') === 'ch12m') {
      registrationConfig.secondColumnContent = ChristmasVariant
    }

    if (queryParams.get('promotion') === 'ss24') {
      registrationConfig.secondColumnContent = SummerSaleVariant
    }
  }

  return registrationConfig
}

/**
 * ApplyRoutingParams - applies routingParams to given registrationConfig and returns new registrationConfig
 * @param {object} registrationConfig - object with registration params
 * @param {object} routingParams - routing params object from react-router-dom useParams
 * @type {function}
 * @return {object}
 */
const applyRoutingParams = (registrationConfig, routingParams) => {
  const { variantName } = routingParams

  if (sources.includes(variantName)) {
    registrationConfig = {
      ...registrationConfig,
      variantName,
      source: variantName,
      ...getVariant(variantName)
    }
  }

  return registrationConfig
}

/**
 * UseRegistrationConfig - creates registration config with default params and then applies other params and returns config
 * @type {function}
 * @return {object}
 */
export const useRegistrationConfig = () => {
  const queryParams = useQuery()
  const routingParams = useParams()
  const location = useLocation()

  let registrationConfig = getDefaultParams()

  let isAlternativeSignupRoute

  if (location.pathname === '/signup') {
    isAlternativeSignupRoute = true

    setLocalStorage('entryRoute', 'signup')
  }

  if (location.pathname === '/register') {
    setLocalStorage('entryRoute', 'register')
  }

  registrationConfig = applyQueryParams(
    registrationConfig,
    queryParams,
    location
  )

  registrationConfig = applyRoutingParams(registrationConfig, routingParams)

  if (registrationConfig.chosenPackage === PLANS.FREE_22) {
    registrationConfig.chosenPackage = PLANS.FREE_LIMITED_24
  }

  if (registrationConfig.chosenPackage === PLANS.LITE_22) {
    registrationConfig.chosenPackage = PLANS.LITE_LIMITED_24
  }

  if (
    ![
      ...plansRecordKeys,
      PLANS.FREE_LIMITED_24,
      PLANS.LITE_LIMITED_24
    ].includes(registrationConfig.chosenPackage)
  ) {
    registrationConfig.chosenPackage = getDefaultParams().chosenPackage
  }

  if (isAlternativeSignupRoute) {
    registrationConfig.newSignup = 'yes'
  }

  if (location.pathname === '/login' || location.pathname === '/') {
    registrationConfig.secondColumnContent = EventTrackerLoginVariant
  }

  registrationConfig.searchParams = new URLSearchParams(queryParams)

  if (registrationConfig.variantName !== getDefaultParams().variantName) {
    registrationConfig.searchParams.set('v', registrationConfig.variantName)
  }

  const isDefaultRegistration = !queryParams.has('package')

  registrationConfig.isDefaultRegistration = isDefaultRegistration

  return registrationConfig
}
