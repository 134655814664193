import { useCallback, useState } from 'react'

export const useToggle = (
  initialValue: boolean = false
): [boolean, () => void] => {
  const [value, setValue] = useState<boolean>(initialValue)

  const toggle = useCallback(() => {
    setValue((data: boolean) => !data)
  }, [])

  return [value, toggle]
}
