import {
  Heading,
  PerfectDropdownSelect,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import FooterButtons from './FooterButtons'

/**
 * Third - stateful presentational component representing survey question
 * @param {object} values - formik values
 * @param {bool} isValid - formik isValid
 * @param {bool} isSubmitting - formik isSubmitting
 * @param {function} handleBack - function that handles clicking back arrow to go back to previous quesiton
 * @param {array} touched - formik touched
 * @param {function} setFieldTouched - formik setFieldTouched
 * @param {function} setFieldValue - formik setFieldValue
 * @param {function} handleSkipStep - function that handles clicking skip button
 * @param {function} validateForm - formik validateForm
 * @param {bool} isLastQuestion - information whether question is last or not
 * @return {object} - children
 */
const Third = ({
  values,
  isValid,
  isSubmitting,
  touched,
  setFieldTouched,
  setFieldValue,
  handleSkipStep,
  validateForm,
  isLastQuestion
}) => {
  const {
    activeSurveyBoxesIDs,
    setActiveSurveyBoxesIDs,
    questionsSkippedInfo,
    setQuestionsSkippedInfo
  } = useSurveyContext()
  const { t } = useTranslation()
  const [lastSelectedID, setLastSelectedID] = useState('')

  useEffect(() => {
    validateForm()

    const { industry_reg_survey } = values

    setLastSelectedID(industry_reg_survey.value)
  }, [])

  /**
   * handles clicking Next button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleNext = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.third = false
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
  }

  /**
   * handles clicking Skip step button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleSkipStepWrapper = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo
    const newActiveSurveyBoxes = activeSurveyBoxesIDs.filter(
      id => id !== lastSelectedID
    )

    setActiveSurveyBoxesIDs(newActiveSurveyBoxes)

    setLastSelectedID('')
    setFieldValue('industry_reg_survey', { value: '', label: '' })

    newQuestionsSkippedInfo.third = true
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
    handleSkipStep()
  }

  const businessInfoSelectOptions = [
    {
      label: t('registration.flow.survey.ecommerce'),
      value: 'ecommerce-retail'
    },
    {
      label: t('registration.flow.survey.education'),
      value: 'education-training'
    },
    {
      label: t('registration.flow.survey.software.and.technology'),
      value: 'software-technology'
    },
    {
      label: t('registration.flow.survey.consulting.and.coaching'),
      value: 'business-law-consulting'
    },
    {
      label: t('registration.flow.survey.advertising.and.marketing'),
      value: 'advertising-marketing-media'
    },
    {
      label: t('registration.flow.survey.finance'),
      value: 'finance-insurance'
    },
    {
      label: t('registration.flow.survey.insurance'),
      value: 'insurance'
    },
    {
      label: t('registration.flow.survey.sport'),
      value: 'sport'
    },
    {
      label: t('registration.flow.survey.health.and.beauty'),
      value: 'sport-health'
    },
    {
      label: t('registration.flow.survey.pharmaceutical'),
      value: 'medicine-healthcare'
    },
    {
      label: t('registration.flow.survey.entertainment.and.games'),
      value: 'entertainment-games'
    },
    {
      label: t('registration.flow.survey.publishing'),
      value: 'publishing'
    },
    {
      label: t('registration.flow.survey.architecture.and.design'),
      value: 'architecture-construction'
    },
    {
      label: t('registration.flow.survey.home.and.garden'),
      value: 'home-garden'
    },
    {
      label: t('registration.flow.survey.hotel.and.restaurant'),
      value: 'food-restaurant'
    },
    {
      label: t('registration.flow.survey.real.estate'),
      value: 'real-estate'
    },
    {
      label: t('registration.flow.survey.hr.and.recruitment'),
      value: 'hiring'
    },
    {
      label: t('registration.flow.survey.automotive'),
      value: 'automotive'
    },
    {
      label: t('registration.flow.survey.events'),
      value: 'event-signup'
    },
    {
      label: t('registration.flow.survey.travel.and.tourism'),
      value: 'travel-lifestyle'
    },
    {
      label: t('registration.flow.survey.other'),
      value: 'other'
    }
  ].sort((a, b) => a.label.localeCompare(b.label))

  const handleFieldChange = id => {
    let newActiveSurveyBoxes

    if (lastSelectedID) {
      newActiveSurveyBoxes = activeSurveyBoxesIDs.map(activeId =>
        lastSelectedID !== activeId ? activeId : id
      )
    } else {
      newActiveSurveyBoxes = [...activeSurveyBoxesIDs, id]
    }

    setActiveSurveyBoxesIDs(newActiveSurveyBoxes)
    setLastSelectedID(id)
  }

  return (
    <LayoutGroup>
      <motion.div
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Column>
          <motion.div layout>
            <Row>
              <Heading level={1}>
                {t('registration.flow.survey.tell.us.more.about.your.business')}
              </Heading>
            </Row>
          </motion.div>

          <PerfectDropdownSelect
            formikKey='industry_reg_survey'
            options={businessInfoSelectOptions}
            onChange={(name, value) => {
              setFieldValue(name, value)
              handleFieldChange(value)
            }}
            onBlur={setFieldTouched}
            value={values.industry_reg_survey}
            touched={touched}
            label={t('registration.flow.survey.whats.your.industry')}
            overflowStyle={{ maxHeight: '200px' }}
            hasSearcher
            liveChanges
          />

          <Spacer />

          <FooterButtons
            isLastQuestion={isLastQuestion}
            isSubmitting={isSubmitting}
            handleSkipStep={handleSkipStepWrapper}
            handleNext={handleNext}
            isValid={isValid}
          />
        </Column>
      </motion.div>
    </LayoutGroup>
  )
}

Third.propTypes = {
  values: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.instanceOf(Object),
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleSkipStep: PropTypes.func,
  validateForm: PropTypes.func,
  isLastQuestion: PropTypes.bool
}

export default Third
