import { Dot } from '@landingi/landingi-ui-kit'
import { Trans } from 'react-i18next'

export const filterValues = [
  { value: '', label: <Trans i18nKey='topbar.productupdates.type.all' /> },

  {
    value: 'feature',
    label: (
      <Dot
        label={<Trans i18nKey='topbar.productupdates.type.new-features' />}
        variant='accent-2'
      />
    )
  },
  {
    value: 'improvement',
    label: (
      <Dot
        label={<Trans i18nKey='topbar.productupdates.type.improvments' />}
        variant='accent-1'
      />
    )
  },
  {
    value: 'fix',
    label: (
      <Dot
        label={<Trans i18nKey='topbar.productupdates.type.fixes' />}
        variant='accent-3'
      />
    )
  },
  {
    value: 'deleted',
    label: (
      <Dot
        label={<Trans i18nKey='topbar.productupdates.type.deleted-features' />}
        variant='accent-7'
      />
    )
  }
]

export const filters = {
  feature: 'accent-2',
  improvement: 'accent-1',
  fix: 'accent-3',
  deleted: 'accent-7'
} as const

export const OPEN_PRODUCT_UPDATES_DRAWER = 'OPEN_PRODUCT_UPDATES_DRAWER'
export const CLOSE_PRODUCT_UPDATES_DRAWER = 'CLOSE_PRODUCT_UPDATES_DRAWER'
export const NUMBER_OF_NOTIFICATIONS_PER_PAGE = 10
