export const activationCodeMask = [/./, ' ', /./, ' ', /./, ' ', /./, ' ', /./]

export const cardNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const dateMask = [/[0-9]/, /\d/, ' ', '/', ' ', /\d/, /\d/]

export const postCodeMask = [/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/]
