import { PROD } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useGetAppcuesData } from '@services/appcues'
import moment from 'moment-timezone'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

moment.tz.setDefault('UTC')

const isAppcuesShowingPeriodFinished = (createdAt: string) => {
  const accountCreateDate = moment(createdAt)

  // add 21 days to the account creation date
  const appcuesEndDate = accountCreateDate.add(21, 'days')

  return appcuesEndDate.isBefore(moment())
}

export const useAppcues = () => {
  const calledOnce = useRef(false)
  const location = useLocation()

  const { data } = useGetAppcuesData()

  const {
    isAuth,
    userID,
    isLoading,
    isSupportMode,
    createdAt,
    expiresAt,
    isTypeOwner
  } = useUserContext()

  const hasAccessToAppcues = useFeatureFlags('APPCUES')
  const hasAccessToBasicAppcues = useFeatureFlags('BASIC_APPCUES')
  const hasAccessToSPA = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  const hasAppcuesFeatureFlag = hasAccessToBasicAppcues || hasAccessToAppcues

  useEffect(() => {
    const loadAppcuesScript = () => {
      const script = document.createElement('script')

      script.src = '//fast.appcues.com/90414.js'
      script.async = true

      document.head.appendChild(script)

      script.onload = () => {
        // @ts-ignore
        Appcues?.identify(userID, data)

        // @ts-ignore
        Appcues?.page()
      }
    }

    if (
      !PROD ||
      !isAuth ||
      isLoading ||
      !hasAppcuesFeatureFlag ||
      !createdAt ||
      !expiresAt?.formatted ||
      (hasAccessToAppcues &&
        isAppcuesShowingPeriodFinished(createdAt.formatted)) ||
      !data ||
      !hasAccessToSPA ||
      !isTypeOwner ||
      calledOnce.current
    ) {
      return
    }

    loadAppcuesScript()

    calledOnce.current = true
  }, [
    data,
    isAuth,
    userID,
    hasAppcuesFeatureFlag,
    isSupportMode,
    createdAt,
    expiresAt,
    isLoading,
    hasAccessToSPA,
    location,
    isTypeOwner,
    hasAccessToAppcues
  ])
}
