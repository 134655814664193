import { ErrorBoundaryFallback } from '@components/ErrorBoundaryFallback'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  Divider,
  Indicator,
  LimitBar,
  Paragraph,
  PerfectDropdown,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useGetAccountLimits } from '@services/limits'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Buttons } from './components/Buttons'
import styles from './MyUsage.module.scss'

export const MyUsage = () => {
  const { t } = useTranslation()

  const {
    landingPages,
    uniqueVisits,
    domains,
    subaccounts,
    isWarning,
    isAlert,
    error
  } = useGetAccountLimits()

  const { isAgency, isNormal, hasFreeTrial } = useUserContext()

  const hasAccessToSubaccountsLimit = isAgency && subaccounts
  const hasAccessToUniqueUsersLimit = (isAgency || isNormal) && uniqueVisits
  const hasAccessToDomainsLimit = (isAgency || isNormal) && domains
  const hasAccessToLandingPagesLimit =
    useFeatureFlags('PRICING_2022') && landingPages

  const freeTrialRegularLimit = {
    regularLimit: uniqueVisits?.regularLimit,
    tooltipInQuantity: t('topbar.unique-visitors.tooltip')
  }

  const indicatorVariant = () => {
    if (isAlert) {
      return 'alert'
    }

    if (isWarning) {
      return 'warning'
    }

    return undefined
  }

  return (
    <div id='topbar-my-usage'>
      <Indicator
        className={styles['my-usage__indicator']}
        variant={indicatorVariant()}
        isAlert={isWarning || isAlert}
      >
        <PerfectDropdown
          icon='icon-speed'
          label={t('topbar.my-usage')}
          size='auto'
          fontColor='color-3'
          fontWeight={600}
          hasHoverLabel
        >
          {error ? (
            <div className={styles['my-usage__error']}>
              <ErrorBoundaryFallback />
            </div>
          ) : (
            <div className={styles['my-usage']}>
              <Paragraph color='accent-2' padding='none'>
                {t('topbar.my-usage')}
              </Paragraph>

              <Spacer space='tiny' />

              <Divider variant='dropdown' />

              <Spacer space='small' />

              {hasAccessToDomainsLimit ? (
                <Fragment>
                  <LimitBar
                    limitText={t('topbar.custom-domains')}
                    limit={domains.domainsLimit}
                    quantity={domains.activeOwnDomainsCount}
                    tooltipInQuantity={t('topbar.custom-domains.tooltip')}
                    padding='none'
                  />

                  <Spacer space='small' />
                </Fragment>
              ) : null}

              {hasAccessToUniqueUsersLimit ? (
                <Fragment>
                  <LimitBar
                    limitText={t('topbar.unique-visitors')}
                    limit={uniqueVisits.uniqueVisitsLimit}
                    quantity={uniqueVisits.uniqueVisitsCount}
                    padding='none'
                    {...(hasFreeTrial && freeTrialRegularLimit)}
                  />

                  <Spacer space='small' />
                </Fragment>
              ) : null}

              {hasAccessToLandingPagesLimit ? (
                <Fragment>
                  <LimitBar
                    limitText={t('topbar.published-landing-pages')}
                    limit={landingPages.landingPagePublicationLimit}
                    quantity={landingPages.landingPagePublicationCount}
                    padding='none'
                  />

                  <Spacer space='small' />
                </Fragment>
              ) : null}

              {hasAccessToSubaccountsLimit ? (
                <Fragment>
                  <LimitBar
                    limitText={t('topbar.active-subaccounts')}
                    limit={subaccounts.subaccountsActiveLimit}
                    quantity={subaccounts.subaccountsActiveCount}
                    padding='none'
                  />

                  <Spacer space='small' />
                </Fragment>
              ) : null}

              <Buttons />
            </div>
          )}
        </PerfectDropdown>
      </Indicator>
    </div>
  )
}

MyUsage.displayName = 'TopbarMyUsage'
