import { useRegistrationConfig } from '@pages/Authentication/helpers/useRegistrationConfig'
import PropTypes from 'prop-types'
import { createContext, useContext } from 'react'

const RegisterContext = createContext(null)

export const RegisterProvider = ({ children }) => {
  const value = useRegistrationConfig()

  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  )
}

RegisterProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useRegisterContext = () => {
  const register = useContext(RegisterContext)

  if (!register) {
    throw new Error('useRegisterContext must be used inside RegisterProvider')
  }

  return register
}
