import { PLN } from '@constants/currencies'
import {
  CLICK_ON_CONFIRM_OR_REQUEST,
  CREDITS_SUCCESSFULLY_PURCHASED
} from '@constants/mixpanel'
import { useUserContext } from '@contexts/user'
import { useModal } from '@ebay/nice-modal-react'
import { formatNumeric } from '@helpers/data'
import { getPrice } from '@helpers/getPrice'
import { useLazyService } from '@hooks/useLazyService'
import { purchaseCredits } from '@services/credits/purchaseCredits'
import { Price } from '@services/credits/useGetCreditsPackage'
import { mixpanelEvent } from '@services/mixpanel'
import { Alert } from '@ui-kit/Alert'
import { Button } from '@ui-kit/Button'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { SummaryRow } from '@ui-kit/SummaryRow'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'
import { mutate } from 'swr'

import styles from './ModalBuyCredits.module.scss'

const Heading = ({
  title,
  icon,
  weight = 400
}: {
  title: string
  icon: string
  weight?: 400 | 600
}) => (
  <Row alignItems='center'>
    <div className={styles.icon}>
      <Icon icon={icon} className={styles.icon} />
    </div>
    <Spreader />
    <Paragraph size={16} weight={weight}>
      {title}
    </Paragraph>
  </Row>
)

type Props = {
  data: {
    amount: number
    price: Price
  }
  properties: {
    click_location: string
    type: string
  }
}

export const ModalBuyCreditsContent: FC<Props> = ({ data, properties }) => {
  const { t } = useTranslation()

  const { subscriptionStatus } = useUserContext()
  const { remove } = useModal()

  const canBuyCredits = subscriptionStatus !== null

  const {
    amount,
    price: { amount_net, currency }
  } = data

  const ChosenFeatureHeading = (
    <Heading
      title={t('modals.buyCredits.chosenFeature')}
      icon='icon-check-circle-outline'
    />
  )

  const ChosenFeatureContent = (
    <Paragraph size={16} weight={600}>
      {formatNumeric(amount)} {t('word.credits')}
    </Paragraph>
  )

  const TimePeriodHeading = (
    <Heading
      title={t('modals.buyCredits.timePeriod')}
      icon='icon-calendar-check-outline'
    />
  )

  const TotalDueHeading = (
    <Heading
      title={t('modals.buyCredits.totalDue')}
      icon='icon-credit-card-outline'
      weight={600}
    />
  )

  const { priceGrossWithCurrency, priceNetWithCurrency } = getPrice({
    price: amount_net,
    accountCurrency: currency
  })

  const price = (
    <Fragment>
      <Paragraph size={16}>{priceGrossWithCurrency}</Paragraph>
      <Paragraph size={14} color='neutral-4'>
        {currency === PLN && `${priceNetWithCurrency} + 23% VAT`}
      </Paragraph>
    </Fragment>
  )

  const purchaseAndRefreshCredits = async () => {
    mixpanelEvent({
      name: CLICK_ON_CONFIRM_OR_REQUEST,
      properties: {
        button_label: canBuyCredits ? 'Confirm&Pay' : 'Request add-on',
        ...properties
      }
    })

    const credits = await purchaseCredits(amount)

    await mutate('credits/balance')

    return credits
  }

  const handleSuccess = () => {
    if (canBuyCredits) {
      mixpanelEvent({
        name: CREDITS_SUCCESSFULLY_PURCHASED
      })
    }

    remove()
  }

  const [handleBuyCredits, { isLoading }] = useLazyService(
    purchaseAndRefreshCredits,
    {
      onSuccess: handleSuccess,
      successToastText: canBuyCredits
        ? t('modals.buyCredits.payment.successful')
        : t('modals.buyCredits.payment.successful.no.sub'),
      errorToastText: canBuyCredits
        ? t('modals.buyCredits.payment.error')
        : t('toast.error.something.went.wrong')
    }
  )

  return (
    <Fragment>
      <SummaryRow
        left={ChosenFeatureHeading}
        right={ChosenFeatureContent}
        leftSideWidth='271px'
      />

      <SummaryRow
        left={TimePeriodHeading}
        right={t('modals.buyCredits.period')}
        leftSideWidth='271px'
      />

      <SummaryRow
        left={TotalDueHeading}
        right={price}
        leftSideWidth='271px'
        withDivider={false}
      />

      {canBuyCredits ? (
        <Spacer space={30} />
      ) : (
        <Fragment>
          <Spacer space={20} />
          <Alert variant='info'>
            {t('modals.buyCredits.missing.subscription.alert')}
          </Alert>
          <Spacer space={20} />
        </Fragment>
      )}

      <Row>
        <Icon icon='icon-shield-info' color='success' />

        <Spreader spread={10} />

        <Paragraph size={12} color='neutral-6'>
          {t('registration.flow.credit.card.step.30.day.money.back')}
        </Paragraph>
      </Row>

      <Spacer space={20} />

      <Row justifyContent='flex-end' alignItems='center'>
        <Button variant='text-primary' onClick={remove}>
          {t('word.cancel')}
        </Button>

        <Spreader />

        <Button
          onClick={handleBuyCredits}
          isLoading={isLoading}
          disabled={isLoading}
        >
          {canBuyCredits
            ? t('modals.buyCredits.confirm')
            : t('modals.buyCredits.request')}
        </Button>
      </Row>
    </Fragment>
  )
}
