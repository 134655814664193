import { useStyles } from '@hooks/useStyles'
import { Image } from '@ui-kit/Image'
import { Panel } from '@ui-kit/Panel'
import { FC, ReactNode } from 'react'

import styles from './BlockSection.module.scss'

interface BlockSectionProps {
  className?: string | string[]
  reverse?: boolean
  imageSrc: string
  children: ReactNode
}

export const BlockSection: FC<BlockSectionProps> = ({
  className = '',
  reverse = false,
  imageSrc,
  children
}) => {
  const wrapperClasses = useStyles(
    {
      [styles['block-section']]: true
    },
    className
  )

  const elementClasses = useStyles({
    [styles['block-section__panel']]: true,
    [styles['block-section__panel--reverse']]: reverse
  })

  return (
    <div className={wrapperClasses}>
      <Panel padding={0}>
        <div data-testid='block-section-panel' className={elementClasses}>
          <div className={styles['block-section__panel--content']}>
            {children}
          </div>
          <Image
            src={imageSrc}
            className={styles['block-section__panel--image']}
          />
        </div>
      </Panel>
    </div>
  )
}

BlockSection.displayName = 'BlockSection'
