import { t } from 'i18next'
import { object, string } from 'yup'

const FormSchema = () =>
  object().shape({
    code: string()
      .required(t('form.validation.isRequired'))
      .length(6, t('two.factor.authentication.code.error'))
  })

export default FormSchema
