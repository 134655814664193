import type { TabsProviderProps } from '@ui-kit/Tabs/context'
import { useCallback, useMemo, useState } from 'react'

export const useTabs = ({
  initialTab
}: {
  initialTab: TabsProviderProps['initialTab']
  isScrollable?: boolean
}) => {
  const [activeTab, setActiveTab] = useState(initialTab)
  const [listOpenedTabs, setListOpenedTabs] = useState([initialTab])

  const changeActiveTab = useCallback(
    (nextActiveTabName: string) => {
      setActiveTab(nextActiveTabName)

      setListOpenedTabs(prevOpenedTabs =>
        !prevOpenedTabs.includes(nextActiveTabName)
          ? [...listOpenedTabs, nextActiveTabName]
          : listOpenedTabs
      )
    },
    [listOpenedTabs]
  )

  return useMemo(
    () => ({
      activeTab,
      changeActiveTab,
      listOpenedTabs
    }),
    [activeTab, changeActiveTab, listOpenedTabs]
  )
}

export type useTabsValuesType = ReturnType<typeof useTabs>
