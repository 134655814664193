import { BillingPeriodType } from '@constants/billingPeriods'
import { useGetAuth } from '@services/authentication'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export type Discount = {
  identifier: string
  name: string
  is_used: boolean
  is_expired: boolean
  period: BillingPeriodType
  package: string
  pricing: number
  billing_cycles: number
  uses_count: number
  discount_added_at: string
}

export type DiscountsResponse = Discount[]

export const useGetDiscounts = () => {
  const { isAuth } = useGetAuth()

  const { data, isLoading, error } = useSWR<DiscountsResponse, AxiosError>(
    isAuth ? `payments/discount` : null,
    {
      onErrorRetry: error => {
        if (error) return
      }
    }
  )

  return { data, isLoading, error }
}
