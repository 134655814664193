import { setLocalStorage } from '@helpers/storage'
import ComposerLogo from '@images/landings/wizard/composer-logo-white.svg'
import { LANDINGS } from '@routes/path'
import {
  Badge,
  Button,
  Checkbox,
  Heading,
  Icon,
  Image,
  Paragraph,
  Spacer,
  Spreader,
  Text
} from '@ui-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import styles from './WizardCoomingSoonLayout.module.scss'

export const WizardCoomingSoonLayout = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const { handleSubmit, formik } = useWizardContext()

  const { t } = useTranslation()

  if (!formik.values.websiteName) {
    return <Navigate to={LANDINGS.LANDINGS_CREATE_WIZARD_FIRST} />
  }

  const handleClick = () => {
    if (isCheckboxChecked) {
      setLocalStorage('dont-show-ai-text-advertise', 'true')
    }

    handleSubmit()
  }

  return (
    <Row className={styles['wizard-survey-layout']} justifyContent='center'>
      <Row
        className={styles['wizard-survey-layout__header']}
        alignItems='center'
        justifyContent='space-between'
      >
        <Row alignItems='center'>
          <Image src={ComposerLogo} alt='Composer Logo' width='98px' />
          <Spreader spread={5} />
          <Badge weight={600} variant='almostcyan-filled'>
            ALFA
          </Badge>
        </Row>

        <Button
          variant='white'
          isSquare
          icon='icon-remove'
          onClick={handleClick}
        />
      </Row>
      <Row className={styles['wizard-survey-layout__content']}>
        <Column
          className={styles['wizard-survey-layout__first-column']}
          justifyContent='center'
          alignItems='start'
        >
          <Badge weight={600} variant='info-border'>
            <Icon icon='icon-ai-option' color='info' />
            <Spreader spread={5} />
            {t('wizard.ai.advertisement.badge.ai')}
          </Badge>

          <Spacer space={20} />

          <Heading level={3} weight={600} color='warning'>
            {t('wizard.ai.advertisement.coming.soon')}
          </Heading>

          <Spacer space={10} />

          <Heading size='large' weight={600} color='white'>
            {t('wizard.ai.advertisement.heading')}
          </Heading>

          <Spacer space={20} />

          <Row>
            <Icon icon='icon-web' color='white' />
            <Spreader spread={10} />
            <Paragraph size={16} color='white'>
              {t('wizard.ai.advertisement.bullet.1')}
            </Paragraph>
          </Row>

          <Spacer space={15} />

          <Row>
            <Icon icon='icon-stack' color='white' />
            <Spreader spread={10} />
            <Paragraph size={16} color='white'>
              {t('wizard.ai.advertisement.bullet.2')}
            </Paragraph>
          </Row>

          <Spacer space={15} />

          <Row>
            <Icon icon='icon-time' color='white' />
            <Spreader spread={10} />
            <Paragraph size={16} color='white'>
              {t('wizard.ai.advertisement.bullet.3')}
            </Paragraph>
          </Row>

          <Spacer space={15} />
          <Spacer space={20} />

          <Row
            justifyContent='space-between'
            className={styles['wizard-survey-layout__first-column--footer']}
          >
            <Row alignItems='center'>
              <Checkbox
                id='show-ai-text-advertise'
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked(prev => !prev)}
              >
                <Text color='white' size={16}>
                  {t('wizard.ai.advertisement.dont.show')}
                </Text>
              </Checkbox>
            </Row>
            <Row>
              <Button onClick={handleClick} variant='white-solid'>
                {t('wizard.ai.advertisement.ok.great')}
              </Button>
              <Spreader spread={10} />
              <Button disabled variant='white-solid' icon='icon-arrow-right'>
                {t('wizard.ai.advertisement.lets.go')}
              </Button>
            </Row>
          </Row>
          <Row
            className={styles['wizard-survey-layout__first-column--footer']}
            justifyContent='flex-end'
          >
            <Paragraph size={10} color='white-6'>
              {t('wizard.ai.advertisement.available.in.beta')}
            </Paragraph>
          </Row>
        </Column>
        <Row className={styles['wizard-survey-layout__second-column']}>
          <Row
            className={styles['wizard-survey-layout__second-column-content']}
            alignItems='center'
            justifyContent='center'
          >
            <div
              style={{
                width: '540px',
                height: '410px',
                position: 'relative',
                background: '#1f1f1f'
              }}
            >
              <div className={styles.gradient} />
              <div
                style={{
                  width: '489px',
                  height: '366px',
                  left: '27px',
                  top: '20px',
                  position: 'absolute'
                }}
              >
                <div className={styles.box} />
                <img
                  style={{
                    width: '202px',
                    height: '300px',
                    left: '259px',
                    top: '32px',
                    position: 'absolute',
                    opacity: 0.5,
                    borderRadius: '10px'
                  }}
                  src='https://images.assets-landingi.com/uc/fd90419b-8bf6-4b6d-91ea-64ea6f8ec841/iamge_ai.png'
                  alt='ai'
                />
                <div
                  className={styles['text-animation']}
                  style={{
                    width: '193px',
                    left: '32px',
                    top: '52px',
                    position: 'absolute',
                    color: 'rgba(255, 255, 255, 0.40)',
                    fontSize: '34px',
                    fontFamily: 'Inter',
                    fontWeight: 700,
                    lineHeight: '37.40px',
                    wordWrap: 'break-word'
                  }}
                >
                  Actionable Tips, Proven Strategies
                </div>
                <div
                  style={{
                    width: '156px',
                    height: '10px',
                    left: '32px',
                    top: '232px',
                    position: 'absolute',
                    background: 'rgba(255, 255, 255, 0.10)',
                    borderRadius: '250px'
                  }}
                />
                <div
                  style={{
                    width: '185px',
                    height: '10px',
                    left: '32px',
                    top: '254px',
                    position: 'absolute',
                    background: 'rgba(255, 255, 255, 0.10)',
                    borderRadius: '250px'
                  }}
                />
                <div
                  style={{
                    width: '168px',
                    height: '10px',
                    left: '32px',
                    top: '276px',
                    position: 'absolute',
                    background: 'rgba(255, 255, 255, 0.10)',
                    borderRadius: '250px'
                  }}
                />
                <div
                  style={{
                    width: '185px',
                    height: '10px',
                    left: '32px',
                    top: '298px',
                    position: 'absolute',
                    background: 'rgba(255, 255, 255, 0.10)',
                    borderRadius: '250px'
                  }}
                />
              </div>
            </div>
          </Row>
        </Row>
      </Row>
    </Row>
  )
}
