import { isEmpty } from '@helpers/data'
import type { ModalProps } from '@ui-kit/Modal'
import { createContext, useContext } from 'react'

type ModalContextType = Pick<ModalProps, 'isCentered' | 'onClose'> & {
  isFullscreen: boolean
}

export const ModalContext = createContext({} as Required<ModalContextType>)

export const useModalContext = () => {
  const ctxValue = useContext(ModalContext)

  if (isEmpty(ctxValue)) {
    throw new Error(
      'useModalContext can not be used outside ModalContext.Provider'
    )
  }

  return ctxValue
}
