import { COMPANY, INDIVIDUAL } from '@constants/customerTypes'
import { CREDIT_CARD_METHOD } from '@constants/paymentMethods'
import { parseDateString } from '@helpers/date'
import { getCard } from '@helpers/getCard'
import { CustomLink } from '@landingi/landingi-ui-kit'
import {
  doesCardExist,
  getCvvMaxLength,
  isCVVLengthValid
} from '@validations/CreditCard'
import { t } from 'i18next'
import { date, object, string } from 'yup'

const errorPostCode = () => t('word.message.credit.card.step.post.code.error')

const creditCardStepValidationSchema = () =>
  object().shape({
    customerType: string().required(t('form.validation.isRequired')),
    companyName: string().when('customerType', (customerType, schema) => {
      return customerType === COMPANY
        ? schema.required(t('form.validation.isRequired'))
        : schema
    }),
    nameSurname: string().when('customerType', (customerType, schema) => {
      return customerType === INDIVIDUAL
        ? schema.required(t('form.validation.isRequired'))
        : schema
    }),
    vat: string().when(
      ['country', 'customerType'],
      (country, customerType, schema) => {
        const { value } = country

        return value === 'PL' && customerType === COMPANY
          ? schema.required(t('form.validation.isRequired'))
          : schema
      }
    ),
    country: object({
      value: string().required(t('form.validation.isRequired'))
    }),
    address: string().required(t('form.validation.isRequired')),
    city: string().required(t('form.validation.isRequired')),
    postCode: string()
      .matches()
      .when(['country'], {
        is: country => country === 'PL',
        then: string()
          .matches(/\d{2}-\d{3}/g, errorPostCode)
          .required(t('form.validation.isRequired'))
      })
      .required(t('form.validation.isRequired')),
    cardNumber: string().when('method', {
      is: CREDIT_CARD_METHOD,
      then: string()
        .min(18, t('form.validation.isRequired'))
        .max(19, t('form.validation.isRequired'))
        .required(t('form.validation.isRequired'))
        .test(
          'doesCardExist',
          <>
            {t('word.message.credit.card.step.error.no.support')}
            <CustomLink
              label='support@landingi.com'
              href='mailto:support@landingi.com'
              size={12}
            />
          </>,
          doesCardExist
        )
    }),
    expirationDate: date()
      .transform(parseDateString)
      .when('method', {
        is: CREDIT_CARD_METHOD,
        then: date()
          .transform(parseDateString)
          .typeError(' ')
          .min(new Date(), ' ')
          .required(t('form.validation.isRequired'))
      }),
    cvv: string().when('method', {
      is: CREDIT_CARD_METHOD,
      then: string().when(['cardNumber'], (cardNumber, schema) =>
        schema
          .test('isCVVLengthValid', '', cvv =>
            isCVVLengthValid(
              cvv,
              cardNumber ? getCvvMaxLength(getCard(cardNumber)) : 3
            )
          )
          .required(t('form.validation.isRequired'))
      )
    })
  })

export default creditCardStepValidationSchema
