import { useFeatureFlags } from '@contexts/featureFlags'
import { Button, Spreader } from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { LANDINGS } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

export const Footer = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    uuid,
    landingPageUrls: { handleDoneAndVerifyWordpress }
  } = usePublishingOptionsContext()

  const handleBackToMainView = () =>
    navigate(
      generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
        identifier: uuid
      })
    )

  const isUnbrandedContent = useFeatureFlags('UNBRANDED_CONTENT')

  return (
    <Row
      vertical='center'
      horizontal={isUnbrandedContent ? 'end' : 'space-between'}
    >
      {!isUnbrandedContent ? (
        <Button
          tag='a'
          variant='transparent'
          href={t('publishing.options.wordpress.instruction.learn.more.url')}
          target='_blank'
          buttonStyle
        >
          {t('tour.gtm.learn.more')}
        </Button>
      ) : null}

      <Row vertical='center'>
        <Button variant='secondary' onClick={handleBackToMainView}>
          {t('word.cancel')}
        </Button>

        <Spreader spread='tiny' />

        <Button onClick={handleDoneAndVerifyWordpress}>
          {t('publishing.options.wordpress.instruction.button')}
        </Button>
      </Row>
    </Row>
  )
}

Footer.displayName = 'PublishingOptionsViewsWordpressFooter'
