import client from '@lib/http/client'

interface DuplicateLandingRequest {
  uuid: string
  name: string
}

export const duplicateLanding = ({ uuid, name }: DuplicateLandingRequest) =>
  client.post(`landing-page/landings/${uuid}/duplicate`, {
    nameForNewLanding: name
  })
