export const contactSupport = (message = '') => {
  // open mail
  const openMail = (mail: string) => window.open(`mailto:${mail}`, '_blank')

  // open FreshChat
  const openFreshChat = () => window.fcWidget?.open({ replyText: message })

  // open FreshDesk
  const openFreshDesk = () => {
    const { FreshworksWidget } = window

    FreshworksWidget('open')
    FreshworksWidget('prefill', 'ticketForm', {
      subject: '',
      description: ''
    })
  }

  const isFreshChat = !!window.fcWidget
  const isFreshDesk = !!window.FreshworksWidget

  if (isFreshChat) {
    openFreshChat()

    return
  }

  if (isFreshDesk) {
    openFreshDesk()

    return
  }

  openMail('support@landingi.com')
}
