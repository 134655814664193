import {
  calculateColorTints,
  convertHexColorToRgba,
  convertRgbaToHex
} from '@helpers/color'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { Heading, Spacer } from '@ui-kit'
import { ColorTintsTile } from '@ui-kit/ColorTintsTile'
import { RGBColor } from '@ui-kit/types/color'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export const CustomColor = () => {
  const { t } = useTranslation()

  const {
    formik: {
      setFieldValue,
      values: {
        customPalette: { mainColors, colors }
      }
    }
  } = useWizardContext()

  const handleColorChange = (index: number) => (color: RGBColor) => {
    const hexColor = convertRgbaToHex(color)

    const mainColorsCopy = [...mainColors]
    const colorsCopy = [...colors]

    mainColorsCopy[index] = hexColor
    colorsCopy[index] = calculateColorTints(color).map(color =>
      convertRgbaToHex(color)
    )

    setFieldValue('customPalette.mainColors', mainColorsCopy)
    setFieldValue('customPalette.colors', colorsCopy)
  }

  return (
    <Fragment>
      <div>
        <Heading weight={600} level={5}>
          {t('landings.create.wizard.landing.modal.custom.style.primary.title')}
        </Heading>
        <Spacer space={15} />
        <ColorTintsTile
          colorValue={convertHexColorToRgba(mainColors[0])}
          includeAlpha={false}
          onColorChange={handleColorChange(0)}
        />
      </div>
      <div>
        <Heading weight={600} level={5}>
          {t(
            'landings.create.wizard.landing.modal.custom.style.secondary.title'
          )}
        </Heading>
        <Spacer space={15} />
        <ColorTintsTile
          colorValue={convertHexColorToRgba(mainColors[1])}
          includeAlpha={false}
          onColorChange={handleColorChange(1)}
        />
      </div>
      <div>
        <Heading weight={600} level={5}>
          {t('landings.create.wizard.landing.modal.custom.style.bg.title')}
        </Heading>
        <Spacer space={15} />
        <ColorTintsTile
          colorValue={convertHexColorToRgba(mainColors[2])}
          includeAlpha={false}
          onColorChange={handleColorChange(2)}
        />
      </div>
    </Fragment>
  )
}
