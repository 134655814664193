import { useStyles } from '@hooks/useStyles'
import { FC, ReactNode } from 'react'

import styles from './Layout.module.scss'

export interface LayoutProps {
  className?: string | string[]
  children: ReactNode
  width?: 1070
  'data-testid'?: string
}

export const Layout: FC<LayoutProps> = ({
  className = '',
  children,
  width = 1070,
  'data-testid': dataTestId = 'layout'
}) => {
  const elementClasses = useStyles(
    {
      [styles.layout]: true,
      [styles[`layout--width-${width}`]]: width
    },
    className
  )

  return (
    <div data-testid={dataTestId} className={elementClasses}>
      {children}
    </div>
  )
}

Layout.displayName = 'Layout'
