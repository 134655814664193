import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { LANDINGS } from '@routes/path'
import {
  getLandingPageExternalUrls,
  getLandingPageUrls,
  UrlType,
  useGetLandingPageUrls
} from '@services/landings/publishingOptions'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

const findDifference = (array1: UrlType[], array2: UrlType[]) => {
  const differenceArray = []

  for (const item1 of array1) {
    if (!array2.some(item2 => item2.identifier === item1.identifier)) {
      differenceArray.push(item1)
    }
  }

  return differenceArray
}

interface UseUrlsCollectionArgs {
  uuid: string
}

export const useUrlsCollection = ({ uuid }: UseUrlsCollectionArgs) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { data, isLoading, mutate } = useGetLandingPageUrls({ uuid })

  const navigateToMain = () => {
    navigate(
      generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
        identifier: uuid
      })
    )
  }

  const handleDoneAndVerifyWordpress = () => {
    if (
      data &&
      data.collection.filter(
        ({ publicationType, isFake }) =>
          publicationType === TYPES.WORDPRESS && isFake
      ).length > 0
    ) {
      navigateToMain()

      return
    }

    if (!data) return

    emitTimingToastToggle(
      t('publishing.options.table.done.and.verify.toast.info'),
      'info',
      7000
    )

    mutate(
      {
        ...data,
        collection: [
          ...data.collection,
          {
            identifier: `${TYPES.WORDPRESS.toLowerCase()}-${
              data.collection.filter(
                ({ publicationType }) => publicationType === TYPES.WORDPRESS
              ).length
            }`,
            domain: '',
            path: '',
            isMain: false,
            publicationType: TYPES.WORDPRESS,
            url: '',
            isFake: true
          }
        ]
      },
      {
        revalidate: false
      }
    )

    navigateToMain()
  }

  const handleDoneAndVerifyEmbed = () => {
    if (
      data &&
      data.collection.filter(
        ({ publicationType, isFake }) =>
          publicationType === TYPES.EMBED && isFake
      ).length > 0
    ) {
      navigateToMain()

      return
    }

    if (!data) return

    emitTimingToastToggle(
      t('publishing.options.table.done.and.verify.toast.info'),
      'info',
      7000
    )

    mutate(
      {
        ...data,
        collection: [
          ...data.collection,
          {
            identifier: `${TYPES.EMBED.toLowerCase()}-${
              data.collection.filter(
                ({ publicationType }) => publicationType === TYPES.EMBED
              ).length
            }`,
            domain: '',
            path: '',
            isMain: false,
            publicationType: TYPES.EMBED,
            url: '',
            isFake: true
          }
        ]
      },
      {
        revalidate: false
      }
    )

    navigateToMain()
  }

  const handleRefreshlUrls = async ({
    cleanRefresh = false
  }: {
    cleanRefresh?: boolean
  } = {}) => {
    if (cleanRefresh) {
      // NOTE: undefined is required to show loading state
      mutate(undefined)

      mutate()

      return
    }

    if (!data) return

    try {
      const newList = await getLandingPageUrls({ uuid })

      const newExternalUrls = newList.data?.collection.filter(
        ({ publicationType }) =>
          publicationType === TYPES.WORDPRESS || publicationType === TYPES.EMBED
      )

      const externalUrls = data.collection.filter(
        ({ publicationType }) =>
          publicationType === TYPES.WORDPRESS || publicationType === TYPES.EMBED
      )

      const difference = findDifference(externalUrls, newExternalUrls)

      // NOTE: undefined is required to show loading state
      mutate(undefined)

      mutate(
        {
          ...data,
          collection: [
            ...newList.data.collection.map(({ url, identifier, ...rest }) => ({
              identifier: identifier || url,
              url,
              ...rest
            })),
            ...difference
          ]
        },
        {
          revalidate: false
        }
      )
    } catch (error) {
      emitTimingToastToggle('error.page.generic.title', 'alert')
    }
  }

  const handleRefreshExternalUrls = async () => {
    if (!data) return

    try {
      const externalUrlData = await getLandingPageExternalUrls({ uuid })

      const newExternalUrls = externalUrlData.data.collection.filter(
        ({ publicationType }) =>
          publicationType === TYPES.WORDPRESS || publicationType === TYPES.EMBED
      )

      const externalUrls = data.collection.filter(
        ({ publicationType }) =>
          publicationType === TYPES.WORDPRESS || publicationType === TYPES.EMBED
      )

      const difference = findDifference(newExternalUrls, externalUrls)

      if (difference) {
        // NOTE: undefined is required to show loading state
        mutate(undefined)

        mutate(
          {
            ...data,
            collection: data.collection.map((item: UrlType) => {
              const { publicationType, isFake } = item

              if (publicationType === TYPES.WORDPRESS && isFake) {
                return (
                  difference.find(
                    ({ publicationType }) => publicationType === TYPES.WORDPRESS
                  ) || item
                )
              }

              if (publicationType === TYPES.EMBED && isFake) {
                return (
                  difference.find(
                    ({ publicationType }) => publicationType === TYPES.EMBED
                  ) || item
                )
              }

              return item
            })
          },
          {
            revalidate: false
          }
        )
      }
    } catch {
      emitTimingToastToggle('error.page.generic.title', 'alert')
    }
  }

  return {
    isLoading,
    data: {
      collection:
        data?.collection.sort((a, b) => Number(b.isMain) - Number(a.isMain)) ||
        []
    },
    isCustomDomain: data?.collection?.some(
      url => url.publicationType === TYPES.CUSTOM
    ),
    handleRefreshlUrls,
    handleRefreshExternalUrls,
    handleDoneAndVerifyEmbed,
    handleDoneAndVerifyWordpress
  }
}
