import ThriveCoachImage2 from '@images/registration/exwhitelabels/thrivecoach/thrivecoach-image-1-v2.svg'
import ThriveCoachImage1 from '@images/registration/exwhitelabels/thrivecoach/thrivecoach-image-2.svg'
import ThriveCoachLogo from '@images/registration/exwhitelabels/thrivecoach/thrivecoach-logo-v2.png'
import { Image } from '@landingi/landingi-ui-kit'
import { WhitelabelPageProvider } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Row } from 'simple-flexbox'

import styles from './ThriveCoach.module.scss'

const ThriveCoach = ({ children }) => (
  <Row className={styles.whitelabelLayout}>
    <div className={styles.thriveCoach__firstColumn}>
      <div className={styles.whitelabelLayout__content}>
        <Image
          className={styles.thriveCoach__logo}
          size={250}
          height={60}
          src={ThriveCoachLogo}
        />

        <motion.div className={styles.whitelabelLayout__form}>
          {children}
        </motion.div>
      </div>
    </div>
    <div className={styles.thriveCoach__secondColumn}>
      <Image
        className={styles['thriveCoach__image-2']}
        size='35%'
        src={ThriveCoachImage1}
      />

      <Image
        size='75%'
        className={styles.thriveCoach__image}
        src={ThriveCoachImage2}
      />
    </div>
  </Row>
)

ThriveCoach.displayName = 'ThriveCoach'

ThriveCoach.propTypes = {
  children: PropTypes.node.isRequired
}

const ThriveCoachWrapper = ({ children }) => (
  <WhitelabelPageProvider>
    <ThriveCoach>{children}</ThriveCoach>
  </WhitelabelPageProvider>
)

ThriveCoachWrapper.displayName = 'ThriveCoachWrapper'

ThriveCoachWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(ThriveCoachWrapper)
