import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { useSearchTopics } from '@services/landings/wizard'
import {
  Button,
  Heading,
  Loader,
  Message,
  Paragraph,
  Search,
  Spacer,
  Spreader
} from '@ui-kit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { StyleBox } from './components/StyleBox'
import styles from './Sixth.module.scss'
import { topics } from './topics'

export const SixthStep: React.FC = () => {
  const { t } = useTranslation()
  const { validators, formik, setTopic } = useWizardContext()
  const { isSubmitting } = formik
  const { isSixthStepValid } = validators
  const [searchPhrase, setSearchPhrase] = useState('')

  const { data, isLoading } = useSearchTopics(searchPhrase)

  const searchResults = data?.results.length ? (
    <div className={styles['sixth-step__grid']}>
      {topics
        .filter(({ name }) => data?.results.includes(name))
        .sort((a, b) =>
          searchPhrase
            ? 0 /* no sort */
            : t(`wizard.topics.list.${a.name}`).localeCompare(
                t(`wizard.topics.list.${b.name}`)
              )
        )
        .map(({ name, image }) => (
          <StyleBox key={name} topic={name} image={image} />
        ))}
    </div>
  ) : (
    <Message type='not-found' size='medium' />
  )

  return (
    <div className={styles['sixth-step__container']}>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.find.a.topic')}
      </Heading>
      <Spacer space={20} />
      <Paragraph size={14} color='neutral-6'>
        {t('landings.create.wizard.landing.modal.enter.a.keyword')}
      </Paragraph>
      <Spacer space={20} />
      <Search
        setSearchPhrase={setSearchPhrase}
        searchPhrase={searchPhrase}
        autoFocus
        i18n={{ placeholder: t('landings.create.wizard.landing.modal.search') }}
        tag='div'
        data-testid='wizard-search-topic'
      />
      <Spacer space={20} />

      {isLoading ? <Loader /> : searchResults}

      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button
          variant='text-neutral'
          disabled={isSubmitting}
          onClick={() => {
            setTopic(undefined)
          }}
          type='submit'
          isLoading={isSubmitting}
        >
          {t('landings.create.wizard.landing.modal.skip.this.step')}
        </Button>

        <Spreader spread={10} />

        <Button
          icon='icon-arrow-right'
          disabled={!isSixthStepValid || isSubmitting}
          type='submit'
          isLoading={isSubmitting}
        >
          {t('landings.create.wizard.landing.modal.finish')}
        </Button>
      </Row>
    </div>
  )
}
