import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { memo } from 'react'

/**
 * Second column of registration page - stateless presentational component
 * @param {object} props - props
 * @return {object} An object of children
 */
const SecondColumn = () => {
  const { secondColumnContent } = useRegisterContext()

  const SecondColumnContent = secondColumnContent

  return secondColumnContent && <SecondColumnContent />
}

SecondColumn.displayName = 'Second Column'

export default memo(SecondColumn)
