const HTTP_200 = 200
const HTTP_204 = 204
const HTTP_400 = 400
const HTTP_401 = 401
const HTTP_404 = 404
const HTTP_500 = 500
const HTTP_503 = 503
const BRAINTREE_ERROR = 'BraintreeError'

export {
  BRAINTREE_ERROR,
  HTTP_200,
  HTTP_204,
  HTTP_400,
  HTTP_401,
  HTTP_404,
  HTTP_500,
  HTTP_503
}
