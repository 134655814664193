import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import { Check, Spacer, Spreader } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

/**
 * Checks - renders checks components
 * @param {string} props.password - password the checks positive value will depend on
 */
const Checks = ({ password }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Column>
        <Check positive={hasLowerCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.lowercase')}
        </Check>

        <Spacer space='mini' />

        <Check positive={hasUpperCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.uppercase')}
        </Check>
      </Column>

      <Spreader spread='large' />

      <Column justifyContent='space-between'>
        <Check positive={hasNumber(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.number')}
        </Check>

        <Spacer space='mini' />

        <Check positive={password?.length > 9} crossedOutOnPositive>
          {t('registration.flow.at.least.10')}
        </Check>
      </Column>
    </Row>
  )
}

Checks.displayName = 'Checks'

Checks.propTypes = {
  password: PropTypes.string.isRequired
}

export default Checks
