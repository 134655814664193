import { NEW_LANDINGI_URL } from '@config/env'
import { KeyboardKey } from '@constants/keyboardKey'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { useGetFontsList } from '@services/fonts/useGetFontsList'
import {
  Badge,
  ButtonLink,
  Dropdown,
  DropdownElement,
  Heading,
  Icon,
  Input,
  Message,
  Paragraph,
  Spacer,
  Spreader
} from '@ui-kit'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './CustomFont.module.scss'

const fontInputId = 'fontSearchInput'

export const CustomFont = () => {
  const { t } = useTranslation()

  const { formik } = useWizardContext()
  const { values, setFieldValue } = formik

  const { dataAsArray, mutate } = useGetFontsList()

  const [inputValue, setInputValue] = useState(values.customPalette.font)

  const filteredFonts = dataAsArray
    .filter(font => font.name.toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a, b) => {
      const aStartsWith = a.name
        .toLowerCase()
        .startsWith(inputValue.toLowerCase())
      const bStartsWith = b.name
        .toLowerCase()
        .startsWith(inputValue.toLowerCase())

      if (aStartsWith && !bStartsWith) return -1
      if (!aStartsWith && bStartsWith) return 1
      return a.name.localeCompare(b.name)
    })

  const handleValueChange = (value: string) => {
    setInputValue(value)
    setFieldValue('customPalette.font', value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardKey.Enter) {
      event.preventDefault()
      if (filteredFonts.length) {
        handleValueChange(filteredFonts[0].name)
      }
    }
  }

  const customTrigger = ({ isOpen }: { isOpen: boolean }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
    }

    return (
      <Row>
        <Input
          id={fontInputId}
          className={styles.input}
          value={inputValue}
          onChange={handleChange}
          autoFocus={isOpen}
          onKeyDown={handleKeyDown}
        />
        <Icon
          icon={`icon-caret-${isOpen ? 'up' : 'down'}`}
          color='neutral-6'
          size={16}
        />
      </Row>
    )
  }

  return (
    <Column>
      {dataAsArray.map(font => {
        return <link href={font.css_url} rel='stylesheet' key={font.name} />
      })}
      <Heading weight={600} level={5}>
        {t('landings.create.wizard.landing.modal.custom.style.font.title')}
      </Heading>

      <Spacer space={15} />

      <Column className={styles.fontBox}>
        <Column
          className={styles.preview}
          justifyContent='center'
          alignItems='center'
        >
          <span
            style={{
              fontFamily: values.customPalette.font
            }}
          >
            {values.customPalette.font}
          </span>
        </Column>
        <div className={styles.dropdownBox}>
          <Dropdown
            placement='top-start'
            trigger={customTrigger}
            onOutsideClick={() => {
              setInputValue(values.customPalette.font)
            }}
            onOpen={() => {
              mutate()
              setInputValue('')
            }}
          >
            {({ close }) => (
              <Fragment>
                <div className={styles.dropdownContent}>
                  {filteredFonts.length === 0 && (
                    <Row>
                      <Spreader spread={10} />
                      <Message type='not-found' size='small' />
                      <Spreader spread={10} />
                    </Row>
                  )}
                  {filteredFonts.map(font => {
                    return (
                      <DropdownElement
                        className={styles.dropdownElement}
                        key={font.name}
                        isActive={font.name === values.customPalette.font}
                        onClick={() => {
                          handleValueChange(font.name)
                          close()
                        }}
                      >
                        <Row
                          justifyContent='space-between'
                          className={styles.row}
                        >
                          <span
                            style={{
                              fontFamily: font.name
                            }}
                          >
                            {font.name}
                          </span>
                          <Spreader spread={5} />
                          {font.type === 'own' && (
                            <Badge variant='info-border'>
                              {t(
                                'landings.create.wizard.landing.modal.custom.style.custom.badge'
                              )}
                            </Badge>
                          )}
                        </Row>
                      </DropdownElement>
                    )
                  })}
                </div>

                <Row
                  className={styles.addNewFont}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Paragraph size={10}>
                    {t(
                      'landings.create.wizard.landing.modal.custom.style.add.font.desc'
                    )}
                  </Paragraph>

                  <ButtonLink
                    variant='text-primary'
                    icon='icon-cloud-upload'
                    size={12}
                    href={`https://${NEW_LANDINGI_URL}/fonts/list?addFontsModal=true`}
                    target='_blank'
                    onClick={close}
                  >
                    {t(
                      'landings.create.wizard.landing.modal.custom.style.add.font.button'
                    )}
                  </ButtonLink>
                </Row>
              </Fragment>
            )}
          </Dropdown>
        </div>
      </Column>
    </Column>
  )
}
