import { useStyles } from '@hooks/useStyles'
import { Button } from '@ui-kit/Button'
import { ButtonSize } from '@ui-kit/Button/types'
import { FC, MouseEventHandler } from 'react'

interface CloseProps {
  className?: string | string[]
  onClick?: MouseEventHandler<HTMLSpanElement>
  size?: ButtonSize
  'data-testid'?: string
}

export const Close: FC<CloseProps> = ({
  className = '',
  onClick = () => null,
  size = 14,
  'data-testid': dataTestId = 'close-component-button'
}) => {
  const elementStyles = useStyles({}, className)

  return (
    <span className={elementStyles} onClick={onClick} data-testid={dataTestId}>
      <Button size={size} variant='text-neutral' isSquare icon='icon-remove' />
    </span>
  )
}

Close.displayName = 'Close'
