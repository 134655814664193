import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import {
  Button,
  Modal,
  Searcher,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { duplicateLandingToAnotherAccounts } from '@services/landings/duplicateToAccounts'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import { DuplicateToAnotherAccountsTable } from './DuplicateToAnotherAccountsTable'

interface ModalDuplicateToAccountsProps {
  uuid: string
  name: string
}

export const ModalDuplicateToAccounts =
  NiceModal.create<ModalDuplicateToAccountsProps>(({ uuid, name }) => {
    const { t } = useTranslation()

    const [targetAccounts, setTargetAccounts] = useState<string[]>([])
    const [params, setParams] = useState<{
      searchPhrase: string
      currentPage: number
    }>({ searchPhrase: '', currentPage: 1 })

    const { remove } = useModal()

    const [requestDuplicateToAccounts, { isLoading }] = useLazyService(
      duplicateLandingToAnotherAccounts,
      {
        successToastText: t('modal.landings.duplicate.toast'),
        errorToastText: t('error.page.generic.title'),
        onSuccess: remove
      }
    )

    const handleDuplicateToAnotherAccounts = () => {
      if (uuid && targetAccounts) {
        requestDuplicateToAccounts({
          landingUuid: uuid,
          accountsIdentifiers: targetAccounts
        })
      }
    }

    return (
      <Modal
        isActive
        i18n={{
          title: t('modal.landing.duplicateToAccount.title')
        }}
        onClick={remove}
      >
        <Column>
          {t('modal.landings.duplicateToAccount.content', { name })}

          <Spacer />

          <Searcher
            i18n={{ placeholder: t('word.landings.duplicate.search') }}
            setSearchPhrase={value =>
              setParams({ currentPage: 1, searchPhrase: value || '' })
            }
          />

          <Spacer space='small' />

          <DuplicateToAnotherAccountsTable
            params={params}
            setParams={setParams}
            setTargetAccounts={setTargetAccounts}
          />

          <Spacer />

          <Row justifyContent='flex-end'>
            <Button variant='secondary' onClick={remove}>
              {t('label.cancel')}
            </Button>

            <Spreader />

            <Button
              isDisabled={!targetAccounts || isLoading}
              onClick={handleDuplicateToAnotherAccounts}
              isLoading={isLoading}
            >
              {t('word.duplicate')}
            </Button>
          </Row>
        </Column>
      </Modal>
    )
  })

ModalDuplicateToAccounts.displayName = 'ModalDuplicateToAccounts'
