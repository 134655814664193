import { useStyles } from '@hooks/useStyles'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { Button, Heading, Image, Radio, Spacer, Spreader } from '@ui-kit'
import { BoxOutline, BoxOutlineProvider } from '@ui-kit/BoxOutline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import { thirdStepOptions } from '../helpers/constants'
import styles from './common.module.scss'

const ThirdStep: React.FC = () => {
  const { t } = useTranslation()

  const {
    formik,
    validators: { isThirdStepValid }
  } = useWizardContext()
  const { values, setFieldValue } = formik
  const { websiteOffer } = values

  const boxOutlineStyles = useStyles({
    [styles.panel]: true
  })

  const gridStyles = useStyles({
    [styles.grid]: true
  })

  return (
    <Column>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.is.your.offer')}
      </Heading>

      <Spacer space={20} />

      <div className={gridStyles}>
        {thirdStepOptions.map(({ name, image }) => {
          const handleClick = () => {
            setFieldValue('websiteOffer', name)
          }

          return (
            <BoxOutlineProvider
              key={name}
              controlledActive={websiteOffer === name}
            >
              <BoxOutline onClick={handleClick} className={boxOutlineStyles}>
                <Row alignItems='center'>
                  <Column className={styles['radio-container']}>
                    <Radio
                      id={name}
                      field={{
                        name,
                        value: websiteOffer,
                        onChange: handleClick
                      }}
                    />
                  </Column>
                  <Spreader spread={10} />
                  <Row>
                    <Image src={image} height='18px' />
                    <Spreader spread={10} />
                    {t(`landings.create.wizard.goal.${name}`)}
                  </Row>
                </Row>
              </BoxOutline>
            </BoxOutlineProvider>
          )
        })}
      </div>

      <Spacer space={30} />

      <Row justifyContent='end'>
        <Button
          icon='icon-arrow-right'
          disabled={!isThirdStepValid}
          type='submit'
        >
          {t('landings.create.wizard.landing.modal.go.to.style')}
        </Button>
      </Row>

      <Spacer space={30} />
    </Column>
  )
}

export default ThirdStep
