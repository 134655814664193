import { useFeatureFlags } from '@contexts/featureFlags'
import { useLazyService } from '@hooks/useLazyService'
import { Alert, Button, Instruction } from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { LANDINGS } from '@routes/path'
import { getLandingPageExportPHP } from '@services/landings/publishingOptions'
import { Heading, Icon, Paragraph, Spacer, Spreader } from '@ui-kit'
import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

export const Embed = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    uuid,
    landingPageUrls: { handleDoneAndVerifyEmbed },
    landingName
  } = usePublishingOptionsContext()

  const handleBackToMainView = () =>
    navigate(
      generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
        identifier: uuid
      })
    )
  const isUnbrandedContent = useFeatureFlags('UNBRANDED_CONTENT')

  const [getLandingPageExportPHPRequest, { isLoading }] = useLazyService(
    getLandingPageExportPHP,
    {
      onSuccess: ({ data }) => {
        if (data.fileUrl) {
          window.open(data.fileUrl, '_self')
        }
      },
      errorToastText: t('toast.error.something.went.wrong')
    }
  )

  const handleDownloadEmbedFile = useCallback(async () => {
    await getLandingPageExportPHPRequest({ uuid })
  }, [getLandingPageExportPHPRequest, uuid])

  const data = useMemo(
    () =>
      [
        <Column key='download-php-file' alignItems='start'>
          <Paragraph size={16} weight={400}>
            {t('publishing.options.embed.instruction.1.title')}
          </Paragraph>

          <Button
            onClick={handleDownloadEmbedFile}
            isLoading={isLoading}
            isDisabled={isLoading}
            hasIcon={!isLoading}
          >
            <Icon size={12} icon='icon-download' color='primary' />

            {t('publishing.options.embed.instruction.1.button')}
          </Button>
        </Column>,
        <Paragraph key='place-on-server' size={16}>
          {t('publishing.options.embed.instruction.2.title')}
        </Paragraph>,
        <Paragraph key='additional-instruction' size={16}>
          {t('publishing.options.embed.instruction.3.title')}
        </Paragraph>
      ]
        .filter(Boolean)
        .map(content => ({
          content
        })),
    [isLoading, handleDownloadEmbedFile, t]
  )

  return (
    <Fragment>
      <Fragment>
        <Row>
          <Paragraph size={16} color='neutral-6'>
            {landingName}
          </Paragraph>
        </Row>

        <Spacer space={10} />
      </Fragment>

      <Row alignItems='center'>
        <Icon size={26} icon='icon-server' color='primary' />

        <Spreader spread={10} />

        <Heading level={1}>{t('publishing.options.embed.title')}</Heading>
      </Row>

      <Spacer space={30} />

      <Paragraph size={16}>
        {t('publishing.options.embed.description')}
      </Paragraph>

      <Instruction data={data} />

      <Spacer space={20} />

      <Alert type='info'>{t('publishing.options.embed.alert')}</Alert>

      <Spacer space={30} />

      <Row justifyContent={isUnbrandedContent ? 'flex-end' : 'space-between'}>
        {isUnbrandedContent ? null : (
          <Button
            tag='a'
            variant='transparent'
            href={t('publishing.options.embed.learn.more.url')}
            target='_blank'
            buttonStyle
          >
            {t('tour.gtm.learn.more')}
          </Button>
        )}

        <Row>
          <Button variant='secondary' onClick={handleBackToMainView}>
            {t('word.cancel')}
          </Button>

          <Spreader spread={10} />

          <Button onClick={handleDoneAndVerifyEmbed}>
            {t('publishing.options.done.and.verify')}
          </Button>
        </Row>
      </Row>
    </Fragment>
  )
}

Embed.displayName = 'PublishingOptionsViewsEmbed'
