// hasLowerCase - check if string include any lowercase character
export const hasLowerCase = (str: string) =>
  Boolean(str && str.toUpperCase() !== str && str.length > 0)

// hasUpperCase - check if string include any uppercase character
export const hasUpperCase = (str: string) =>
  Boolean(str && str.toLowerCase() !== str && str.length > 0)

// hasNumber - check if string include any number character
export const hasNumber = (str: string) => Boolean(str && /\d/u.test(str))
