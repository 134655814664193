import {
  europeanUnionCountries,
  mapCountryCodeToCurrency,
  PLN
} from '@constants/currencies'
import i18next from 'i18next'

export const isEuropeanUnionCountry = code =>
  europeanUnionCountries.includes(code)

export const setActiveAccountCurrency = code => {
  if (isEuropeanUnionCountry(code) && code !== 'PL' && code !== 'GB') {
    return mapCountryCodeToCurrency.EU
  }

  if (!isEuropeanUnionCountry(code) && code !== 'PL' && code !== 'GB') {
    return mapCountryCodeToCurrency.REST
  }

  return mapCountryCodeToCurrency[code]
}

export const getPackageName = (packages, packageRecordKey) =>
  packages?.find(({ record_key }) => record_key === packageRecordKey)?.name

export const getPackagePrice = ({
  packages,
  periodToFind,
  currencyToFind,
  packageRecordKey
}) =>
  packages
    ?.find(({ record_key }) => record_key === packageRecordKey)
    ?.tariffs.find(({ period }) => period === periodToFind)
    ?.pricing?.find(({ currency }) => currency === currencyToFind)?.price

export const convertPriceToNetto = bruttoPrice => bruttoPrice / 1.23

export const formatPrice = value =>
  new Intl.NumberFormat(i18next.language, {
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2
  }).format(value)

export const splitPriceByFloatingPoint = price => {
  const formattedPrice = formatPrice(price)

  const splitSign = i18next.language === 'en' ? '.' : ','

  const splittedPrice = formattedPrice.split(splitSign)

  return {
    decimalPoint: splittedPrice[0],
    floatingPoint: splittedPrice[1] ? `${splitSign}${splittedPrice[1]}` : null
  }
}

export const convertPriceToBrutto = nettoPrice => nettoPrice * 1.23

export const convertPriceToBruttoWhenNeeded = (nettoPrice, currency) =>
  currency === PLN ? convertPriceToBrutto(nettoPrice) : nettoPrice

export const getActiveDiscountForPlanAndPeriod = (
  discounts,
  planRecordKey,
  period
) => {
  if (!discounts) {
    return null
  }

  return discounts.find(
    discount =>
      !discount.is_used &&
      !discount.is_expired &&
      discount.package === planRecordKey &&
      discount.period === period
  )
}

const annualDiscountNames = [
  'discount_specialoffer_50_usd',
  'discount_specialoffer_246_pln',
  'discount_specialoffer_50_eur',
  'discount_specialoffer_40_gbp',
  'discount_specialoffer_200_brl',
  'discount_specialoffer_100_usd',
  'discount_specialoffer_492_pln',
  'discount_specialoffer_100_eur',
  'discount_specialoffer_80_gbp',
  'discount_specialoffer_400_brl',
  'discount_specialoffer_300_usd',
  'discount_specialoffer_1476_pln',
  'discount_specialoffer_300_eur',
  'discount_specialoffer_240_gbp',
  'discount_specialoffer_1200_brl'
]

const isWithinLastTwoMonths = dateString => {
  const date = new Date(dateString.replace(' ', 'T'))
  const threeMonthsAgo = new Date()
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 2)
  return date > threeMonthsAgo
}

export const getIsPromotion12MonthUsed = discounts => {
  if (!discounts) {
    return false
  }

  const discountNames = discounts.map(({ name }) => name)

  const promotion12MDiscountName = annualDiscountNames.find(discount =>
    discountNames.includes(discount)
  )

  return !!discounts.find(
    discount =>
      discount.name === promotion12MDiscountName &&
      discount.is_used &&
      isWithinLastTwoMonths(discount.discount_added_at)
  )?.is_used
}

export const getHas12MonthSaleDiscount = discounts => {
  if (!discounts) {
    return false
  }

  const discountNames = discounts.map(({ name }) => name)

  const promotion12MDiscountName = annualDiscountNames.find(discount =>
    discountNames.includes(discount)
  )

  return !!discounts.find(
    discount =>
      discount.name === promotion12MDiscountName &&
      !discount.is_expired &&
      isWithinLastTwoMonths(discount.discount_added_at)
  )
}
