import { useSidebarContext } from '@contexts/sidebar'
import { Icon } from '@landingi/landingi-ui-kit'

import styles from './ToggleButton.module.scss'

export const ToggleButton = () => {
  const { isSidebarMobileOpen, toggleSidebarMobileOpen } = useSidebarContext()

  return (
    <button
      type='button'
      className={styles['toggle-button']}
      onClick={toggleSidebarMobileOpen}
    >
      <Icon icon={`icon-${isSidebarMobileOpen ? 'remove' : 'menu'}`} />
    </button>
  )
}

ToggleButton.displayName = 'SidebarToggleButton'
