import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { setSignInDate } from '@services/account/setSignInDate'
import { useGetSignInDate } from '@services/account/useGetSignInDate'
import moment from 'moment-timezone'
import { mutate } from 'swr'

import { useLazyService } from './useLazyService'
import { useUpdateEffect } from './useUpdateEffect'

const PROMOTION_FOR_FREE_PLAN = 'PROMOTION_FOR_FREE_PLAN'

export const useCalcDatesForPromotion = () => {
  const { user } = useUserContext()

  const hasAccessToPromotionForFreePlanFeatureFlag = useFeatureFlags(
    'PROMOTION_FOR_FREE_PLAN'
  )

  const isInSQLStage = user?.platform_stage === 'SQL'

  const hasAccessToPromotionForFreePlan =
    hasAccessToPromotionForFreePlanFeatureFlag && isInSQLStage

  const [setSignInDateRequest, { isLoading: isLoadingSetDate }] =
    useLazyService(setSignInDate, {
      onSuccess: () => {
        mutate(`auth/sign-in-date/get-date?action=${PROMOTION_FOR_FREE_PLAN}`)
      }
    })

  const {
    data,
    isLoading: isLoadingGetDate,
    isValidating: isValidatingGetDate,
    error: errorGetDate
  } = useGetSignInDate({
    action: PROMOTION_FOR_FREE_PLAN,
    enabled: hasAccessToPromotionForFreePlan
  })

  const isLoading = isLoadingSetDate || isLoadingGetDate || isValidatingGetDate

  const handleSetSignInDate = async () => {
    if (data && !data.date && hasAccessToPromotionForFreePlan) {
      await setSignInDateRequest({ action: PROMOTION_FOR_FREE_PLAN })
    }
  }

  useUpdateEffect(() => {
    handleSetSignInDate()
  }, [data])

  const promotionIsVisible = !isLoading && !errorGetDate

  if (!data || !data.date || errorGetDate) {
    return {
      date: {
        startDate: '',
        endDate: ''
      },
      promotionIsLoading: isLoading,
      promotionIsVisible
    }
  }

  return {
    date: {
      startDate: moment(data.date).format('YYYY-MM-DD'),
      endDate: moment(data.date).add(7, 'days').format('YYYY-MM-DD')
    },
    promotionIsLoading: isLoading,
    promotionIsVisible
  }
}
