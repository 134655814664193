import { ANNUALLY, MONTHLY } from '@constants/billingPeriods'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useStyles } from '@hooks/useStyles'
import ArrowImage from '@images/pricing/landingi-pricing-arrow.svg'
import styles from '@pages/Authentication/routes/ComparePlans/ComparePlans.module.scss'
import { REGISTRATION } from '@routes/path'
import { useGetAccountInfo } from '@services/account'
import { Badge, Close, Heading, Icon, Image, Spreader, Toggle } from '@ui-kit'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

interface BillingPerionProps {
  billingPeriod: number
  setBillingPeriod: (value: number) => void
}

export const BillingPeriod = ({
  billingPeriod,
  setBillingPeriod
}: BillingPerionProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { hasCustomOffer } = useGetAccountInfo()

  const hasAccessToPromotion12MFF = useFeatureFlags('PROMOTION_12M')

  const hasAccessToPromotion12M = hasAccessToPromotion12MFF && !hasCustomOffer

  const annualSavingStyles = useStyles({
    [styles['annual-saving']]: true,
    [styles[`annual-saving--${i18next.resolvedLanguage}`]]:
      i18next.resolvedLanguage
  })

  const isChecked = billingPeriod === ANNUALLY

  const handleClose = () => {
    navigate(REGISTRATION.CREDIT_CARD_STEP.DEFAULT)
  }

  return (
    <Row justifyContent='center' className={styles.header}>
      <Close className={styles.close} onClick={handleClose} />

      <Heading
        level={3}
        weight={isChecked ? 400 : 600}
        color={isChecked ? 'neutral-4' : 'neutral'}
      >
        {t('credit.card.step.compare.plans.monthly')}
      </Heading>

      <Spreader spread={20} />

      <Toggle
        id='billing-period-toggle'
        checked={isChecked}
        onChange={() => setBillingPeriod(isChecked ? MONTHLY : ANNUALLY)}
        color='primary'
      />

      <Spreader spread={20} />

      <div style={{ position: 'relative' }}>
        <Heading
          level={3}
          weight={isChecked ? 600 : 400}
          color={isChecked ? 'neutral' : 'neutral-4'}
        >
          {t('credit.card.step.compare.plans.annualy')}
        </Heading>

        {hasAccessToPromotion12M ? (
          <div className={annualSavingStyles}>
            <Image
              src={ArrowImage}
              className={styles['annual-saving__image']}
            />

            <Badge variant='neutral-filled' weight={600}>
              <Icon icon='icon-gift' size={12} color='white' />

              <Spreader spread={5} />

              {t('change.plan.page.badge.black.firday')}
            </Badge>
          </div>
        ) : (
          <div className={annualSavingStyles}>
            <Badge
              variant={`${!isChecked ? 'warning-border' : 'warning-filled'}`}
            >
              {t('credit.card.step.compare.plans.badge')}
            </Badge>
          </div>
        )}
      </div>
    </Row>
  )
}
