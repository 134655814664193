import { useUserContext } from '@contexts/user'
import Cookies from 'js-cookie'
import useSWR from 'swr'

interface SupportMessageResponse {
  identifier: number
  message: string
  category: 'info' | 'error'
}

export const useGetSupportMessage = () => {
  const { data, ...rest } = useSWR<SupportMessageResponse>(
    'technical-measures/support-message',
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 3) return
      }
    }
  )

  const { isSubaccount } = useUserContext()

  const closeTechMsg = Cookies.get('closeTechMsg')

  if (
    isSubaccount ||
    (closeTechMsg && Number(closeTechMsg) === data?.identifier)
  ) {
    return {
      data: undefined,
      ...rest
    }
  }

  return {
    data,
    ...rest
  }
}
