import { CurrencyType } from '@constants/currencies'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export interface Price {
  amount_net: number
  currency: CurrencyType
}

export type PackagesResponse = {
  name: string
  amount: number
  price: Price
}[]

export const useGetCreditsPackage = () => {
  const response = useSWR<PackagesResponse, AxiosError>('credits/packages')
  const data = response.data?.[0]

  return data
}
