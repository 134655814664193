import { Heading, Panel, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import TwoFactorAuthenticationForm from './Form'

const TwoFactorAuthentication = () => {
  const { t } = useTranslation()
  const { trialPeriod } = useRegisterContext()

  return (
    <Column>
      <Panel borderRadius='10px'>
        <Heading level={1} bold>
          {t('two.factor.authentication.page.heading', {
            days: trialPeriod
          })}
        </Heading>

        {t('two.factor.authentication.enter.your.auth.code')}

        <Spacer space='medium' />

        <TwoFactorAuthenticationForm />
      </Panel>

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Paragraph size={12} color='white' align='center' padding='none'>
        {t('two.factor.authentication.dont.have.mobile.device')}
        <Link
          style={{ color: 'white', textDecoration: 'underline' }}
          to={`${REGISTRATION.RECOVERY_CODE_WEB_TO_LEARN}`}
        >
          {t('two.factor.authentication.dont.have.mobile.device.link')}
        </Link>
      </Paragraph>
    </Column>
  )
}

TwoFactorAuthentication.displayName = 'TwoFactorAuthentication'

export default TwoFactorAuthentication
