import { isEmpty } from '@helpers/data'
import { getCard } from '@helpers/getCard'
import amex_tooltip from '@images/registration/creditCard/amex_tooltip.svg'
import card_tooltip from '@images/registration/creditCard/card_tooltip.svg'

/**
 * @function doesCardExist - checks if card of given number is supported
 * @param {string} card_data - card number
 */
export const doesCardExist = card_data =>
  card_data && !isEmpty(getCard(card_data))

/**
 * @function isCVVLengthValid - checks if cvv length is valid, used for yup validation
 * @param {string} cvv
 * @param {number} length
 */
export const isCVVLengthValid = (cvv, length) =>
  !cvv || (cvv && cvv.toString().length === length)

/**
 * @param activeCard - current active card
 * @function getCvvMaxLength - returns CVV max length
 */
export const getCvvMaxLength = activeCard =>
  !isEmpty(activeCard) ? activeCard.cvvLength : 3

/**
 * @param {object} card - card object, should contain cvvLenght property
 * @return {string} translation id for placeholder
 * getCvvPlaceholderTranslation - returns translation for cvv input placeholder based on card CVV length
 */
export const getCvvPlaceholderTranslationID = card =>
  isEmpty(card)
    ? 'subscription.cvv.placeholder.3'
    : `subscription.cvv.placeholder.${card.cvvLength}`

/**
 * @param card -  card object
 * @function getTooltipImageURL - returns tooltip image url
 */
export const getTooltipImageURL = card => {
  if (isEmpty(card)) {
    return card_tooltip
  }

  const { name } = card

  if (name === 'Amex') {
    return amex_tooltip
  }

  return card_tooltip
}
