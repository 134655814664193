import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

export const getPricing = (t: TFunction<'translation', undefined>) => ({
  list: [
    <Trans i18nKey='addons.store.e.commerce.pricing.list.1' />,
    <Trans i18nKey='addons.store.e.commerce.pricing.list.2' />,
    <Trans i18nKey='addons.store.e.commerce.pricing.list.3' />
  ],
  billedAnnuallyTooltip: t('addons.store.secure.access.to.your.ecommerce')
})
