import { AxiosError } from 'axios'
import useSWR from 'swr'

export type LimitsResponse = {
  collection: [
    {
      limit_uuid: string
      limit_name: string
      landing_pages: number
      domains: number
      unique_visitors: number
    }
  ]
  pagination: {
    filters: [string]
    counter: {
      total: number
      current: number
    }
  }
}

export const useLimits = ({ page, limit }: { page: number; limit: number }) => {
  const searchParams = new URLSearchParams()

  if (limit) {
    searchParams.append('limit', String(limit))
  }

  if (page) {
    searchParams.append('page', String(page))
  }

  return useSWR<LimitsResponse, AxiosError>(
    `agency-resource-limits?${searchParams.toString()}`,
    {
      revalidateOnMount: true
    }
  )
}
