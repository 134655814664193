import client from '@lib/http/client'
import { useState } from 'react'
import useSWRInfinite from 'swr/infinite'

type Domain = {
  createdAt: number
  id: number
  name: string
  status: string
  uuid: string
  url: string
}

export interface DomainListRequest {
  page?: number
  limit?: number
  filters?: {
    name?: string
  }
  sort?: {
    name?: 'name' | '-name'
    status?: 'status' | '-status'
    created_at?: 'created_at' | '-created_at'
  }
}

export const useGetDomainsListInfinite = ({
  filters = undefined,
  limit = 10
}: DomainListRequest = {}) => {
  const [total, setTotal] = useState<number>(0)

  const getKey = (page: number) => {
    const searchParams = new URLSearchParams({
      ...(page ? { page: String(page + 1) } : {}),
      ...(limit ? { limit: String(limit) } : {})
    })

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          searchParams.append(`filters[${key}]`, String(value))
        }
      })
    }

    return `domains?${searchParams.toString()}`
  }

  return {
    ...useSWRInfinite<Domain[]>(getKey, (url: string) =>
      client.get(url).then(res => {
        if (res.data.pagination) {
          setTotal(res.data.pagination.counter.total)
        }

        return res.data.collection
      })
    ),
    total
  }
}
