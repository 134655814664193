import { CurrencyType } from '@constants/currencies'
import { PLANS } from '@constants/plans'

const { LITE_LIMITED_24, PROFESSIONAL_24, UNLIMITED_22 } = PLANS

type Plan =
  | typeof LITE_LIMITED_24
  | typeof PROFESSIONAL_24
  | typeof UNLIMITED_22

type AnnualSaveValueForPlan = {
  [key in Plan]: {
    [key in CurrencyType]: number
  }
}

const annualSaveValueForPlan: AnnualSaveValueForPlan = {
  [LITE_LIMITED_24]: {
    PLN: 240,
    USD: 60,
    EUR: 60,
    GBP: 60,
    BRL: 240
  },
  [PROFESSIONAL_24]: {
    PLN: 600,
    USD: 144,
    EUR: 144,
    GBP: 120,
    BRL: 600
  },
  [UNLIMITED_22]: {
    PLN: 8400,
    USD: 2040,
    EUR: 2040,
    GBP: 1800,
    BRL: 9600
  }
}

export const getSaveValue = (planRecordKey: Plan, currency: CurrencyType) => {
  return annualSaveValueForPlan[planRecordKey][currency]
}
