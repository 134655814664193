import styles from '@ui-kit/Toast/toasts/Toast.module.scss'
import { FC } from 'react'
import {
  Slide,
  ToastContainer as Container,
  ToastContainerProps
} from 'react-toastify'

interface ToastProps extends ToastContainerProps {}

export const ToastContainer: FC<ToastProps> = ({
  hideProgressBar = true,
  draggable = false,
  closeOnClick = false,
  ...restProps
}) => (
  <Container
    {...restProps}
    hideProgressBar={hideProgressBar}
    draggable={draggable}
    closeOnClick={closeOnClick}
    closeButton={false}
    data-testid='toastContainer'
    icon={false}
    className={styles['toast-container']}
    transition={Slide}
  />
)

ToastContainer.displayName = 'ToastContainer'
