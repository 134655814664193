import { Paragraph } from '@ui-kit'
import { Column } from 'simple-flexbox'

export const HoverContent = ({
  description,
  paragraphColor
}: {
  description: string
  paragraphColor: 'neutral' | 'neutral-4'
}) => {
  return (
    <Column
      justifyContent='center'
      alignItems='center'
      style={{
        height: '100%'
      }}
    >
      <Paragraph align='center' color={paragraphColor} size={12}>
        {description}
      </Paragraph>
    </Column>
  )
}
