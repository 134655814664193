import { Notification } from '@components/ProductUpdates/Notification'
import { generateFakeUuid } from '@helpers/data'
import { useGetProductUpdates } from '@services/productUpdates'
import i18next from 'i18next'
import { FC, Fragment } from 'react'

interface PageProps {
  index: number
  queryParams: {
    category: string
    phrase: string
    page: number
  }
}

export const Page: FC<PageProps> = ({ index, queryParams }) => {
  const { category, phrase } = queryParams
  const { language } = i18next

  const { notificationsData, lastSeenData } = useGetProductUpdates({
    page: index,
    filters: {
      category,
      phrase
    }
  })

  return (
    <Fragment key={index}>
      {notificationsData?.collection.map(notification => {
        const lastSeen = lastSeenData?.last_seen

        const lastSeenDate = new Date(lastSeen)
        const publishedDate = new Date(notification.published_at)

        const isNewNotification = !lastSeen || lastSeenDate < publishedDate

        return (
          <Notification
            key={notification.uuid || generateFakeUuid()}
            content={
              notification[
                `content_${language}` as 'content_en' | 'content_pl'
              ] ?? notification.content_en
            }
            published_at={notification.published_at}
            category={notification.category}
            isNewNotification={isNewNotification}
          />
        )
      })}
    </Fragment>
  )
}
