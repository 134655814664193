/**
 * converts price to netto
 * @param {number} price - price to be converted
 * @return price converted to netto
 * @typ function
 */
export const convertPriceToNetto = price => price / 1.23

export const mapCountries = countries =>
  countries.map(({ iso, name }) => ({
    value: iso,
    label: name
  }))

export const currentPackages = (packages, plans) =>
  packages.filter(accountPackage =>
    plans.some(plan => plan.recordKey === accountPackage.record_key)
  )
