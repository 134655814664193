import { CLICK_ON_DOWNLOAD_QR_CODE } from '@constants/mixpanel'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  Panel,
  Paragraph,
  Select,
  Spacer,
  Spreader
} from '@ui-kit'
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import { EXTENSION_JPEG, EXTENSION_SVG, extensionOptions } from './constants'
import { downloadStringAsFile } from './helpers'
import styles from './ModalGenerateQRCode.module.scss'

interface ModalBuyCreditsProps {
  url: string
  clickLocation: string
}

export const ModalGenerateQRCode = NiceModal.create<ModalBuyCreditsProps>(
  ({ url, clickLocation }) => {
    const { t } = useTranslation()
    const { remove } = useModal()

    const [extension, setExtension] = useState(EXTENSION_JPEG)

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const svgRef = useRef<SVGSVGElement>(null)

    const handleChange = (value: string) => {
      setExtension(value)
    }

    const handleDownloadQRCode = () => {
      mixpanelEvent({
        name: CLICK_ON_DOWNLOAD_QR_CODE,
        properties: {
          click_location: clickLocation
        }
      })

      const node =
        extension === EXTENSION_SVG ? svgRef.current : canvasRef.current
      if (!node) return

      const fileName = `qrcode.${extension}`

      if (extension === EXTENSION_SVG) {
        const serializer = new XMLSerializer()

        const fileURI = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          `<?xml version="1.0" standalone="no"?>${serializer.serializeToString(node)}`
        )}`

        downloadStringAsFile(fileURI, fileName)
      } else {
        const canvas = node as HTMLCanvasElement

        const fileURI = canvas.toDataURL(`image/${extension}`)

        downloadStringAsFile(fileURI, fileName)
      }
    }

    return (
      <Modal size={420} onClose={remove} level={2}>
        <ModalHeader heading={t('modal.generate.qr.code.title')} />

        <ModalBody>
          <Paragraph>{t('modal.generate.qr.code.description')}</Paragraph>

          <Spacer space={15} />

          <Panel surface={0} border='neutral-2' padding={40}>
            {extension === EXTENSION_SVG ? (
              <QRCodeSVG
                ref={svgRef}
                className={styles['modal-qr-code__img']}
                value={url}
                size={520}
              />
            ) : (
              <QRCodeCanvas
                ref={canvasRef}
                className={styles['modal-qr-code__img']}
                value={url}
                size={520}
              />
            )}
          </Panel>

          <Spacer space={30} />

          <Row vertical='center' horizontal='end'>
            <Column className={styles['modal-qr-code__select']}>
              <Select
                value={extension}
                options={extensionOptions}
                onChange={handleChange}
                level={1}
                overflowStyle={{ overflow: 'auto' }}
              />
            </Column>

            <Spreader spread={15} />

            <Button onClick={handleDownloadQRCode} variant='primary'>
              <Icon icon='icon-cloud-download-alt' />

              <Spreader spread={10} />

              {t('word.download')}
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
)
