import { PROD } from '@config/env'
import { useAcl } from '@contexts/acl'
import { initializeFreshChat } from '@helpers/support/freshchat'
import { initializeFreshDesk } from '@helpers/support/freshdesk'
import { useUpdateEffect } from '@hooks/useUpdateEffect'
import { useGetAuth } from '@services/authentication'
import useSWR from 'swr'

/**
 * checks ACL to find out if freshchat or freshdesk widget should be shown
 * and if so initializes those widgets according to their documentation
 */
export const useSupportContact = () => {
  const { isAuth } = useGetAuth()
  const hasAccessToFreshchat = useAcl('web_analytic_tools.freshchat')
  const hasAccessToFreshdesk = useAcl('web_analytic_tools.freshdesk')

  const shouldShowFreschat = isAuth && hasAccessToFreshchat
  const shouldShowFreshdesk = isAuth && hasAccessToFreshdesk

  const { data: freshchatData } = useSWR(
    shouldShowFreschat ? 'web-analytic-tools/v1/freshchat' : null,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 3) return
      }
    }
  )

  const { data: freshdeskData } = useSWR(
    shouldShowFreshdesk ? 'web-analytic-tools/v1/freshdesk' : null,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (retryCount >= 3) return
      }
    }
  )

  useUpdateEffect(() => {
    try {
      if (PROD) {
        if (freshchatData) {
          const { user, account, restoreId } = freshchatData

          initializeFreshChat({ user, owner: account, restoreId })
        }

        if (shouldShowFreshdesk) {
          const { user, account } = freshdeskData

          initializeFreshDesk({ user, owner: account })
        }
      }
    } catch {
      // nothing happens here, application shouldn't crash, but user shouldn't see the error
    }
  }, [freshchatData, freshdeskData])
}
