import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import {
  Alert,
  Button,
  Check,
  InputForm,
  ShowPassword,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import {
  WEB_TO_LEARN,
  WEB_TO_LEARN_COLOR
} from '@pages/Authentication/routes/WhitelabelPages/constants'
import grantNewPasswordSchema from '@pages/Authentication/routes/WhitelabelPages/GrantNewPassword/FormSchema'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

const GrantNewPasswordForm = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const { token } = useParams()
  const { changePassword } = useWhitelabelPageContext()
  const { t } = useTranslation()

  const renderChecks = password => (
    <Row>
      <Column>
        <Check positive={hasLowerCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.lowercase')}
        </Check>

        <Spacer space='mini' />

        <Check positive={hasUpperCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.uppercase')}
        </Check>
      </Column>

      <Spreader spread='large' />

      <Column justifyContent='space-between'>
        <Check positive={hasNumber(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.number')}
        </Check>

        <Spacer space='mini' />

        <Check positive={password?.length > 9} crossedOutOnPositive>
          {t('registration.flow.at.least.10')}
        </Check>
      </Column>
    </Row>
  )

  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )

  const onSubmit = useCallback(async values => {
    const { password } = values

    await changePassword(token, password, WEB_TO_LEARN)
  }, [])

  const initialValues = {
    password: ''
  }

  const {
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    values,
    errors,
    touched,
    handleChange,
    handleBlur
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema: grantNewPasswordSchema
  })

  const { password } = values

  const isPasswordValid = password =>
    hasLowerCase(password || '') &&
    hasUpperCase(password || '') &&
    hasNumber(password || '') &&
    password?.length > 9

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <Row>
          <Column flexGrow='1'>
            <InputForm
              id='password'
              type={passwordInputType}
              field={{
                name: 'password',
                value: values.password,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              i18n={{
                label: t('registration.flow.password'),
                placeholder: t('registration.flow.password')
              }}
              form={{
                errors,
                touched
              }}
            />
          </Column>

          <Spreader spread='tiny' />

          <Column>
            <ShowPassword setHidden={handleInputTypeState} />
          </Column>
        </Row>

        {isPasswordValid(password) ? (
          <Alert type='success'>{t('registration.flow.password.alert')}</Alert>
        ) : (
          renderChecks(password)
        )}

        <Spacer space='mini' />

        <Spacer space='tiny' />

        <Button
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          size='large'
          customEnabledBackgroundColor={WEB_TO_LEARN_COLOR}
        >
          {t('registration.flow.reset.password')}
        </Button>
      </Column>
    </form>
  )
}

GrantNewPasswordForm.displayName = 'Enhanced Grant New Password Form'

export default GrantNewPasswordForm
