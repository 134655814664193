/**
 * Wait - promise time out
 * @function wait
 * @param {number} ms
 * @return {promise} return promise
 */
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

/**
 * if promise throws error it is retried until it succeeds or
 * amount of retries given in parameter is exceeded
 * @param {number} callback - callback to retry in case of failure
 * @param {number} retries - amount of retries before giving up
 * @return callback result
 */
export const retryRequest = async (callback, retries = 1) => {
  let remainingRetries = retries
  let modifier = 0

  while (remainingRetries > 0) {
    try {
      await wait(modifier * 1000)
      remainingRetries--
      modifier = (retries - remainingRetries) ** 2
      return await callback()
    } catch {
      // nothing happens here, application shouldn't crash, but user shouldn't see the error
    }
  }
}
