export const getLocalStorage = data => localStorage.getItem(data)

export const setLocalStorage = (key, value) => localStorage.setItem(key, value)

export const removeLocalStorage = data => localStorage.removeItem(data)

export const parseStorage = value => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    case 'null':
      return null
    case 'undefined':
      return undefined
    default:
      return value
  }
}

/**
 * Returns value when it is in array or undefined when it is not - used for filters and sorters
 * @returns {value | undefinded}
 */
export const parseValue = (value, array) => {
  if (array.some(item => item.value === value)) {
    return value
  }

  return undefined
}
