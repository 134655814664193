import { useStyles } from '@hooks/useStyles'
import { Spacer } from '@ui-kit/Spacer'
import { FC, Fragment } from 'react'

import styles from './ProgressBar.module.scss'

export interface ProgressBarProps {
  size?: 12 | 14
  variant: 'success' | 'warning' | 'alert' | 'progress' | 'brand'
  quantity: number
  limit?: number
  showValue?: boolean
}

export const ProgressBar: FC<ProgressBarProps> = ({
  size = 12,
  variant,
  limit = 100,
  quantity,
  showValue = false
}) => {
  const containerStyles = useStyles({
    [styles.container]: true,
    [styles[`container--size-${size}`]]: true
  })

  const backgroundStyles = useStyles({
    [styles.bar]: true,
    [styles[`bar--size-${size}`]]: true,
    [styles.bar__background]: true,
    [styles[`bar--variant-${variant}`]]: true
  })

  const fulfillmentStyles = useStyles({
    [styles.bar]: true,
    [styles[`bar--size-${size}`]]: true,
    [styles.bar__fulfillment]: true,
    [styles[`bar--variant-${variant}`]]: true
  })

  const quantityStyles = useStyles({
    [styles.values__quantity]: true,
    [styles[`values__quantity--${variant}`]]: true
  })

  const valuesStyles = useStyles({
    [styles.values]: true,
    [styles[`values--size-${size}`]]: true
  })

  return (
    <Fragment>
      <div className={containerStyles}>
        <span data-testid='background' className={backgroundStyles} />
        <span
          data-testid='fulfillment'
          className={fulfillmentStyles}
          style={{
            width: `${quantity > limit ? 100 : (quantity / limit) * 100}%`
          }}
        />
      </div>

      {showValue && (
        <Fragment>
          <Spacer space={5} />

          <p className={valuesStyles} data-testid='color-number'>
            <span className={quantityStyles}>{quantity}</span> / {limit}
          </p>
        </Fragment>
      )}
    </Fragment>
  )
}

ProgressBar.displayName = 'ProgressBar'
