/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import { PROD } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'

export const useSurvicate = () => {
  const calledOnce = useRef(false)

  const { ownerEmail, isLoading, isSupportMode, user, isSubaccount } =
    useUserContext()

  const isPayment = user?.payment.has_first_payment_more_ore_equal_one_month

  const hasAccessToNo3RdPartySync = useFeatureFlags('NO_3RD_PARTY_SYNC')
  const hasAccessToSPA = useFeatureFlags('SPA_TOPBAR_SIDEBAR')
  const DISABLE_SURVICATE_FF = useFeatureFlags('DISABLE_SURVICATE')

  const cookieName = `NPS-Q${Math.ceil(
    (new Date().getMonth() + 1) / 3
  )}-${new Date().getFullYear()}`

  const isCookieSet = (name: string) => !!Cookies.get(name)

  useEffect(() => {
    if (
      !PROD ||
      isLoading ||
      isSupportMode ||
      isSubaccount ||
      hasAccessToNo3RdPartySync ||
      !hasAccessToSPA ||
      !isPayment ||
      DISABLE_SURVICATE_FF ||
      calledOnce.current
    ) {
      return
    }

    ;(function () {
      const s = document.createElement('script')
      s.src =
        '//survey.survicate.com/workspaces/0133ed4e24c4e4f008fea553727d8395/web_surveys.js'
      s.async = true
      const e = document.getElementsByTagName('script')[0]
      // @ts-ignore
      e.parentNode.insertBefore(s, e)
    })()

    const cookieVal = (name: string) => {
      return isCookieSet(name) ? '1' : '0'
    }

    ;(function (opts) {
      opts.traits = {
        email: ownerEmail,
        [cookieName]: cookieVal(cookieName)
      }
      // @ts-ignore
    })((window._sva = window._sva || {}))

    window.addEventListener('SurvicateReady', function () {
      // @ts-ignore
      _sva.addEventListener('survey_displayed', function (surveyId) {
        if (surveyId === 'af252b7d2a6365a2') {
          if (!isCookieSet(cookieName)) {
            Cookies.set(cookieName, '1', { expires: 365 })
          }
        }
      })
    })

    window.addEventListener('SurvicateReady', function () {
      if (!isCookieSet(cookieName)) {
        // @ts-ignore
        _sva.showSurvey('af252b7d2a6365a2', { displayMethod: 'immediately' })
      }
    })

    calledOnce.current = true
  }, [
    isLoading,
    isPayment,
    isSupportMode,
    ownerEmail,
    cookieName,
    hasAccessToNo3RdPartySync,
    isSubaccount,
    hasAccessToSPA
  ])
}
