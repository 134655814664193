import { AxiosError } from 'axios'
import useSWR from 'swr'

export type AccountCreditsResponse = {
  credits: number
}

export const useGetCredits = () => {
  const response = useSWR<AccountCreditsResponse, AxiosError>('credits/balance')

  return { credits: response.data?.credits, ...response }
}
