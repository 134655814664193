import client from '@lib/http/client'

/**
 * @apiVersion 1.0.0
 * @api {get} /api/packages
 * @apiGroup Payments
 */
export const getPackages = () =>
  client.get(`packages`, { withCredentials: true })

/**
 * @apiVersion 1.0.0
 * @api {get} /api/payments/create-braintree-token
 * @apiGroup Braintree API
 *
 * @apiParam {object} company - company data containing name, street, city, country, tax_identifier and postal_code
 */
export const saveInvoiceDataCompany = ({
  name,
  street,
  city,
  country,
  postal_code,
  tax_identifier
}) =>
  client.post(
    `v1/payments/customers`,
    {
      name,
      street,
      city,
      country,
      postal_code,
      tax_identifier
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 1.0.0
 * @api {post} /api/v1/payments/customers
 * @apiName Customers
 * @apiGroup Braintree API
 *
 * @apiParam {object} individual - individual data containing name, street, city, country and postal_code
 */
export const saveInvoiceDataIndividual = ({
  name,
  street,
  city,
  country,
  postal_code
}) =>
  client.post(
    `v1/payments/customers`,
    {
      name,
      street,
      city,
      country,
      postal_code
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 1.0.0
 * @api {get} /api/payments/create-braintree-token
 * @apiGroup Braintree API
 */
export const createBraintreeToken = () =>
  client.post(
    `payments/create-braintree-token`,
    {},
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 1.0.0
 * @api {post} /api/payments/create-braintree-nonce
 * @apiGroup Braintree API
 *
 * @apiParam {string} token - token from /payments/payment-methods
 */
export const createBraintreeNonce = token =>
  client.post(
    `payments/create-braintree-nonce`,
    { token },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 1.0.0
 * @api {get} /api/payments
 * @apiGroup Payments
 */
export const getPayments = () =>
  client.get(`payments`, { withCredentials: true })

/**
 * @apiVersion 1.0.0
 * @api {post} /api/payments/payment-methods
 * @apiGroup Payments
 *
 * @apiParam {string} nonce - nonce from braintree /payment_methods/credit_cards endpoint
 */
export const postPaymentMethods = nonce =>
  client.post(`payments/payment-methods`, { nonce }, { withCredentials: true })

/**
 * @apiVersion 1.0.0
 * @api {post} /api/v1/payments/subscriptions
 * @apiGroup Subscriptions
 *
 * @apiParam {string} nonce - token from /payments/create-braintree-nonce
 * @apiParam {number} period - billing period
 * @apiParam {string} packageIdentifier - identifier of package got from array from /packages endpoiint
 */
export const postSubscriptions = (nonce, period, packageIdentifier) =>
  client.post(
    `v1/payments/subscriptions`,
    {
      payment_method_name: 'braintree',
      period,
      package: {
        identifier: packageIdentifier
      }
    },
    { withCredentials: true, headers: { 'braintree-token': nonce } }
  )

/**
 * @api {get} /api/payments/countries
 * @apiName Countries
 * @apiGroup Payments
 *
 * @apiParam {string} lang - language of countries to fetch, 'pl' or 'en'
 */
export const getCountries = lang =>
  client.get(`payments/countries?lang=${lang}`, {
    withCredentials: true
  })

/**
 * @api {post} /api/payments/change-package
 * @apiName Change Package
 * @apiGroup Payments
 *
 * @apiParam {*} period - subscription period to change to
 * @apioParam {*} packageIdentifier - identifier of package to change to
 */
export const changePackage = ({
  period,
  packageIdentifier,
  paymentMethodName
}) =>
  client.post(
    `payments/change-package`,
    {
      payment_method_name: paymentMethodName,
      period,
      package: {
        identifier: packageIdentifier
      }
    },
    { withCredentials: true }
  )

/**
 * @apiVersion 1.0.0
 * @api {put} /api/v1/payments/customers
 * @apiGroup Braintree API
 *
 * @apiParam {object} company - company data containing name, street, city, country, tax_identifier and postal_code
 */
export const updateInvoiceDataCompany = ({
  name,
  street,
  city,
  country,
  postal_code,
  tax_identifier
}) =>
  client.put(
    `v1/payments/customers`,
    {
      name,
      street,
      city,
      country,
      postal_code,
      tax_identifier
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 1.0.0
 * @api {put} /api/v1/payments/customers
 * @apiName Customers
 * @apiGroup Braintree API
 *
 * @apiParam {object} individual - individual data containing name, street, city, country and postal_code
 */
export const updateInvoiceDataIndividual = ({
  name,
  street,
  city,
  country,
  postal_code
}) =>
  client.put(
    `v1/payments/customers`,
    {
      name,
      street,
      city,
      country,
      postal_code
    },
    {
      withCredentials: true
    }
  )

/**
 * @apiVersion 1.0.0
 * @api {post} /api/v1/payments/subscriptions
 * @apiGroup Subscriptions
 *
 * @apiParam {number} period - billing period
 * @apiParam {string} packageIdentifier - identifier of package got from array from /packages endpoiint
 */
export const postSubscriptionsPaypal = (period, packageIdentifier) =>
  client.post(
    `v1/payments/subscriptions`,
    {
      payment_method_name: 'paypal',
      period,
      package: {
        identifier: packageIdentifier
      }
    },
    { withCredentials: true }
  )

export const getCreditCardInfo = () =>
  client.get('payments/credit-card', { withCredentials: true })

export const activateFreeAccount = paymentMethod =>
  client.post(
    'auth/activate-free-account',
    { payment_method_name: paymentMethod },
    { withCredentials: true }
  )
