import { Text } from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import styles from './StyleBox.module.scss'

export const CustomStyleBox = () => {
  const { t } = useTranslation()

  return (
    <Column
      alignItems='center'
      justifyContent='center'
      className={styles.panel}
    >
      <Text color='primary' weight={600} size={14}>
        {t('landings.create.wizard.landing.modal.custom.style.title')}
      </Text>
    </Column>
  )
}
