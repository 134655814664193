export const FREE_PLAN_RECORD_KEY = 'free_22'
export const PROFESSIONAL_PLAN_RECORD_KEY = 'professional_22'
export const LITE_RECORD_KEY = 'lite_22'
export const AGENCY_PLAN_RECORD_KEY = 'agency_22'
export const UNLIMITED_22 = 'unlimited_22'

export const plans2022 = [
  {
    name: 'Free',
    recordKey: FREE_PLAN_RECORD_KEY
  },
  {
    name: 'Lite',
    recordKey: LITE_RECORD_KEY
  },
  {
    name: 'Professional',
    recordKey: PROFESSIONAL_PLAN_RECORD_KEY
  },
  {
    name: 'Agency',
    recordKey: AGENCY_PLAN_RECORD_KEY
  },
  {
    name: 'Unlimited',
    recordKey: UNLIMITED_22
  }
]

export const defaultPlan = {
  name: 'Professional',
  recordKey: PROFESSIONAL_PLAN_RECORD_KEY
}

/**
 * finds and returns record key of plan by its name
 * @param {string} planName - plan name
 * @return record key
 */
export const getRecordKeyByName = (planName: string) =>
  plans2022.find(({ name }) => name === planName)?.recordKey
