import squareOrangeImg2 from '@images/registration/square_orange_2.svg'
import circleOrangeImg from '@images/registration/survey/circle_orange.svg'
import halfCircleGreenImg from '@images/registration/survey/half_circle_green.svg'
import squareBlueImg from '@images/registration/survey/square_blue.svg'
import squareGreenImg from '@images/registration/survey/square_green.svg'
import triangleBlueImg from '@images/registration/survey/triangle_blue.svg'
import triangleGreenImg from '@images/registration/survey/triangle_green.svg'
import trianglePinkImg from '@images/registration/survey/triangle_pink.svg'
import trianglePink2 from '@images/registration/survey/triangle_pink_2.svg'

const textIDPrefix = 'registration.flow.survey.box'

export const shapePositionsByOrderAfterSurveyFinish = [
  {
    top: '-700px',
    left: '-1000px'
  },
  {
    top: '-700px',
    left: '1000px'
  },
  {
    top: '-20px',
    left: '-1000px'
  },
  {
    top: '-220px',
    left: '1000px'
  },
  {
    top: '60px',
    left: '-1000px'
  },
  {
    top: '60px',
    left: '1000px'
  },
  {
    top: '440px',
    left: '-1000px'
  },
  {
    top: '460px',
    left: '1000px'
  },
  {
    top: '660px',
    left: '-1000px'
  },
  {
    top: '860px',
    left: '1000px'
  },
  {
    top: '2000px',
    left: '-1000px'
  }
]

export const surveyBoxes = [
  {
    textIDs: { 'social-media': `${textIDPrefix}.tips.conversions` },
    shapeImageSrc: triangleGreenImg,
    ids: ['social-media'],
    shapePositionBeforeSurveyStart: {
      top: '120px',
      left: '339px'
    },
    shapePositionAfterSurveyStart: { top: '503px', left: '108px' }
  },
  {
    textIDs: { onboarding: `${textIDPrefix}.onboarding` },
    shapeImageSrc: triangleBlueImg,
    ids: ['onboarding'],
    shapePositionBeforeSurveyStart: {
      top: '285px',
      left: '190px'
    },
    shapePositionAfterSurveyStart: { top: '270px', left: '185px' }
  },
  {
    textIDs: { landingPageTips: `${textIDPrefix}.landing.page.tips` },
    shapeImageSrc: halfCircleGreenImg,
    ids: ['landingPageTips'],
    shapePositionBeforeSurveyStart: {
      top: '40px',
      left: '245px'
    },
    shapePositionAfterSurveyStart: { top: '455px', left: '185px' }
  },
  {
    textIDs: {
      marketingTips: `${textIDPrefix}.marketing.tips`,
      tipsForManagersAndOwners: `${textIDPrefix}.for.managers.owners`,
      tipsForFreelancers: `${textIDPrefix}.for.freelancers`,
      tipsForAgencies: `${textIDPrefix}.for.agencies`
    },
    shapeImageSrc: squareOrangeImg2,
    ids: [
      'marketingTips',
      'tipsForManagersAndOwners',
      'tipsForFreelancers',
      'tipsForAgencies'
    ],
    shapePositionBeforeSurveyStart: {
      top: '360px',
      left: '247px'
    },
    shapePositionAfterSurveyStart: { top: '455px', left: '251px' }
  },
  {
    textIDs: {
      'ecommerce-retail': `registration.flow.survey.your.industry.inspiration`,
      'education-training': `registration.flow.survey.your.industry.inspiration`,
      'software-technology': `registration.flow.survey.your.industry.inspiration`,
      'business-law-consulting': `registration.flow.survey.your.industry.inspiration`,
      'advertising-marketing-media': `registration.flow.survey.your.industry.inspiration`,
      'finance-insurance': `registration.flow.survey.your.industry.inspiration`,
      insurance: `registration.flow.survey.your.industry.inspiration`,
      sport: `registration.flow.survey.your.industry.inspiration`,
      'sport-health': `registration.flow.survey.your.industry.inspiration`,
      'medicine-healthcare': `registration.flow.survey.your.industry.inspiration`,
      'entertainment-games': `registration.flow.survey.your.industry.inspiration`,
      publishing: `registration.flow.survey.your.industry.inspiration`,
      'architecture-construction': `registration.flow.survey.your.industry.inspiration`,
      'home-garden': `registration.flow.survey.your.industry.inspiration`,
      'food-restaurant': `registration.flow.survey.your.industry.inspiration`,
      'real-estate': `registration.flow.survey.your.industry.inspiration`,
      hiring: `registration.flow.survey.your.industry.inspiration`,
      automotive: `registration.flow.survey.your.industry.inspiration`,
      'event-signup': `registration.flow.survey.your.industry.inspiration`,
      'travel-lifestyle': `registration.flow.survey.your.industry.inspiration`,
      other: `registration.flow.survey.box.quality.templates`
    },
    shapeImageSrc: trianglePink2,
    ids: [
      'ecommerce-retail',
      'education-training',
      'software-technology',
      'business-law-consulting',
      'advertising-marketing-media',
      'finance-insurance',
      'insurance',
      'sport',
      'sport-health',
      'medicine-healthcare',
      'entertainment-games',
      'publishing',
      'architecture-construction',
      'home-garden',
      'food-restaurant',
      'real-estate',
      'hiring',
      'automotive',
      'event-signup',
      'travel-lifestyle',
      'other'
    ],
    shapePositionBeforeSurveyStart: {
      top: '-54px',
      left: '110px'
    },
    shapePositionAfterSurveyStart: { top: '463px', left: '110px' }
  },
  {
    textIDs: { ads: `${textIDPrefix}.tips.ads` },
    shapeImageSrc: triangleBlueImg,
    ids: ['ads'],
    shapePositionBeforeSurveyStart: {
      top: '-287px',
      left: '190px'
    },
    shapePositionAfterSurveyStart: { top: '476px', left: '70px' }
  },
  {
    textIDs: { 'direct-sales': `${textIDPrefix}.tips.sales` },
    shapeImageSrc: trianglePinkImg,
    ids: ['direct-sales'],
    shapePositionBeforeSurveyStart: {
      top: '471px',
      left: '134px'
    },
    shapePositionAfterSurveyStart: { top: '495px', left: '330px' }
  },
  {
    textIDs: { 'own-process-optimisation': `${textIDPrefix}.tips.workflow` },
    shapeImageSrc: squareBlueImg,
    ids: ['own-process-optimisation'],
    shapePositionBeforeSurveyStart: {
      top: '-144px',
      left: '64px'
    },
    shapePositionAfterSurveyStart: { top: '460px', left: '312px' }
  },
  {
    textIDs: {
      'instructions-for-marketing-stock': `${textIDPrefix}.instructions.for.marketing.stock`
    },
    shapeImageSrc: circleOrangeImg,
    ids: ['instructions-for-marketing-stock'],
    shapePositionBeforeSurveyStart: {
      top: '30px',
      left: '420px'
    },
    shapePositionAfterSurveyStart: { top: '530px', left: '185px' }
  },
  {
    textIDs: { 'product-updates': `${textIDPrefix}.product.updates` },
    shapeImageSrc: squareGreenImg,
    ids: ['product-updates'],
    shapePositionBeforeSurveyStart: {
      top: '293px',
      left: '389px'
    },
    shapePositionAfterSurveyStart: { top: '510px', left: '275px' }
  },
  {
    textIDs: { newsletter: `${textIDPrefix}.newsletter` },
    shapeImageSrc: triangleBlueImg,
    ids: ['newsletter'],
    shapePositionBeforeSurveyStart: {
      top: '162px',
      left: '100px'
    },
    shapePositionAfterSurveyStart: { top: '525px', left: '228px' }
  }
]
