import { lazy } from 'react'

const DarkHero1 = lazy(() =>
  import('./dark').then(({ dark_hero_1 }) => ({
    default: dark_hero_1
  }))
)
const DarkHero2 = lazy(() =>
  import('./dark').then(({ dark_hero_2 }) => ({
    default: dark_hero_2
  }))
)
const DarkHero3 = lazy(() =>
  import('./dark').then(({ dark_hero_3 }) => ({
    default: dark_hero_3
  }))
)
const DarkHero4 = lazy(() =>
  import('./dark').then(({ dark_hero_4 }) => ({
    default: dark_hero_4
  }))
)
const DarkHero5 = lazy(() =>
  import('./dark').then(({ dark_hero_5 }) => ({
    default: dark_hero_5
  }))
)
const DarkHero6 = lazy(() =>
  import('./dark').then(({ dark_hero_6 }) => ({
    default: dark_hero_6
  }))
)
const DarkHero7 = lazy(() =>
  import('./dark').then(({ dark_hero_7 }) => ({
    default: dark_hero_7
  }))
)
const DarkHero8 = lazy(() =>
  import('./dark').then(({ dark_hero_8 }) => ({
    default: dark_hero_8
  }))
)
const DarkHero9 = lazy(() =>
  import('./dark').then(({ dark_hero_9 }) => ({
    default: dark_hero_9
  }))
)
const DarkHero10 = lazy(() =>
  import('./dark').then(({ dark_hero_10 }) => ({
    default: dark_hero_10
  }))
)
const DarkHero11 = lazy(() =>
  import('./dark').then(({ dark_hero_11 }) => ({
    default: dark_hero_11
  }))
)
const DarkHero12 = lazy(() =>
  import('./dark').then(({ dark_hero_12 }) => ({
    default: dark_hero_12
  }))
)
const DarkHero13 = lazy(() =>
  import('./dark').then(({ dark_hero_13 }) => ({
    default: dark_hero_13
  }))
)
const DarkHero14 = lazy(() =>
  import('./dark').then(({ dark_hero_14 }) => ({
    default: dark_hero_14
  }))
)
const DarkHero15 = lazy(() =>
  import('./dark').then(({ dark_hero_15 }) => ({
    default: dark_hero_15
  }))
)
const DarkHero16 = lazy(() =>
  import('./dark').then(({ dark_hero_16 }) => ({
    default: dark_hero_16
  }))
)
const DarkHero17 = lazy(() =>
  import('./dark').then(({ dark_hero_17 }) => ({
    default: dark_hero_17
  }))
)
const DarkHero18 = lazy(() =>
  import('./dark').then(({ dark_hero_18 }) => ({
    default: dark_hero_18
  }))
)
const DarkHero19 = lazy(() =>
  import('./dark').then(({ dark_hero_19 }) => ({
    default: dark_hero_19
  }))
)
const DarkHero20 = lazy(() =>
  import('./dark').then(({ dark_hero_20 }) => ({
    default: dark_hero_20
  }))
)
const DarkHero21 = lazy(() =>
  import('./dark').then(({ dark_hero_21 }) => ({
    default: dark_hero_21
  }))
)
const DarkHero22 = lazy(() =>
  import('./dark').then(({ dark_hero_22 }) => ({
    default: dark_hero_22
  }))
)

const MinimalHero1 = lazy(() =>
  import('./minimal').then(({ minimal_hero_1 }) => ({
    default: minimal_hero_1
  }))
)

const MinimalHero2 = lazy(() =>
  import('./minimal').then(({ minimal_hero_2 }) => ({
    default: minimal_hero_2
  }))
)

const MinimalHero3 = lazy(() =>
  import('./minimal').then(({ minimal_hero_3 }) => ({
    default: minimal_hero_3
  }))
)

const MinimalHero4 = lazy(() =>
  import('./minimal').then(({ minimal_hero_4 }) => ({
    default: minimal_hero_4
  }))
)

const MinimalHero5 = lazy(() =>
  import('./minimal').then(({ minimal_hero_5 }) => ({
    default: minimal_hero_5
  }))
)

const MinimalHero6 = lazy(() =>
  import('./minimal').then(({ minimal_hero_6 }) => ({
    default: minimal_hero_6
  }))
)

const MinimalHero7 = lazy(() =>
  import('./minimal').then(({ minimal_hero_7 }) => ({
    default: minimal_hero_7
  }))
)

const MinimalHero8 = lazy(() =>
  import('./minimal').then(({ minimal_hero_8 }) => ({
    default: minimal_hero_8
  }))
)

const MinimalHero9 = lazy(() =>
  import('./minimal').then(({ minimal_hero_9 }) => ({
    default: minimal_hero_9
  }))
)

const MinimalHero10 = lazy(() =>
  import('./minimal').then(({ minimal_hero_10 }) => ({
    default: minimal_hero_10
  }))
)

const MinimalHero11 = lazy(() =>
  import('./minimal').then(({ minimal_hero_11 }) => ({
    default: minimal_hero_11
  }))
)

const MinimalHero12 = lazy(() =>
  import('./minimal').then(({ minimal_hero_12 }) => ({
    default: minimal_hero_12
  }))
)

const MinimalHero13 = lazy(() =>
  import('./minimal').then(({ minimal_hero_13 }) => ({
    default: minimal_hero_13
  }))
)

const MinimalHero14 = lazy(() =>
  import('./minimal').then(({ minimal_hero_14 }) => ({
    default: minimal_hero_14
  }))
)

const MinimalHero15 = lazy(() =>
  import('./minimal').then(({ minimal_hero_15 }) => ({
    default: minimal_hero_15
  }))
)

const MinimalHero16 = lazy(() =>
  import('./minimal').then(({ minimal_hero_16 }) => ({
    default: minimal_hero_16
  }))
)

const MinimalHero17 = lazy(() =>
  import('./minimal').then(({ minimal_hero_17 }) => ({
    default: minimal_hero_17
  }))
)

const MinimalHero18 = lazy(() =>
  import('./minimal').then(({ minimal_hero_18 }) => ({
    default: minimal_hero_18
  }))
)

const MinimalHero19 = lazy(() =>
  import('./minimal').then(({ minimal_hero_19 }) => ({
    default: minimal_hero_19
  }))
)

const MinimalHero20 = lazy(() =>
  import('./minimal').then(({ minimal_hero_20 }) => ({
    default: minimal_hero_20
  }))
)

const MinimalHero21 = lazy(() =>
  import('./minimal').then(({ minimal_hero_21 }) => ({
    default: minimal_hero_21
  }))
)

const MinimalHero22 = lazy(() =>
  import('./minimal').then(({ minimal_hero_22 }) => ({
    default: minimal_hero_22
  }))
)

const MinimalHero23 = lazy(() =>
  import('./minimal').then(({ minimal_hero_23 }) => ({
    default: minimal_hero_23
  }))
)

const MinimalHero24 = lazy(() =>
  import('./minimal').then(({ minimal_hero_24 }) => ({
    default: minimal_hero_24
  }))
)

const ElegantHero1 = lazy(() =>
  import('./elegant/1').then(({ elegant_hero_1 }) => ({
    default: elegant_hero_1
  }))
)
const ElegantHero2 = lazy(() =>
  import('./elegant/2').then(({ elegant_hero_2 }) => ({
    default: elegant_hero_2
  }))
)
const ElegantHero3 = lazy(() =>
  import('./elegant/3').then(({ elegant_hero_3 }) => ({
    default: elegant_hero_3
  }))
)
const ElegantHero4 = lazy(() =>
  import('./elegant/4').then(({ elegant_hero_4 }) => ({
    default: elegant_hero_4
  }))
)
const ElegantHero5 = lazy(() =>
  import('./elegant/5').then(({ elegant_hero_5 }) => ({
    default: elegant_hero_5
  }))
)
const ElegantHero6 = lazy(() =>
  import('./elegant/6').then(({ elegant_hero_6 }) => ({
    default: elegant_hero_6
  }))
)
const ElegantHero7 = lazy(() =>
  import('./elegant/7').then(({ elegant_hero_7 }) => ({
    default: elegant_hero_7
  }))
)
const ElegantHero8 = lazy(() =>
  import('./elegant/8').then(({ elegant_hero_8 }) => ({
    default: elegant_hero_8
  }))
)
const ElegantHero9 = lazy(() =>
  import('./elegant/9').then(({ elegant_hero_9 }) => ({
    default: elegant_hero_9
  }))
)
const ElegantHero10 = lazy(() =>
  import('./elegant/10').then(({ elegant_hero_10 }) => ({
    default: elegant_hero_10
  }))
)
const ElegantHero11 = lazy(() =>
  import('./elegant/11').then(({ elegant_hero_11 }) => ({
    default: elegant_hero_11
  }))
)
const ElegantHero12 = lazy(() =>
  import('./elegant/12').then(({ elegant_hero_12 }) => ({
    default: elegant_hero_12
  }))
)
const ElegantHero13 = lazy(() =>
  import('./elegant/13').then(({ elegant_hero_13 }) => ({
    default: elegant_hero_13
  }))
)
const ElegantHero14 = lazy(() =>
  import('./elegant/14').then(({ elegant_hero_14 }) => ({
    default: elegant_hero_14
  }))
)
const ElegantHero15 = lazy(() =>
  import('./elegant/15').then(({ elegant_hero_15 }) => ({
    default: elegant_hero_15
  }))
)
const ElegantHero16 = lazy(() =>
  import('./elegant/16').then(({ elegant_hero_16 }) => ({
    default: elegant_hero_16
  }))
)
const ElegantHero17 = lazy(() =>
  import('./elegant/17').then(({ elegant_hero_17 }) => ({
    default: elegant_hero_17
  }))
)
const ElegantHero18 = lazy(() =>
  import('./elegant/18').then(({ elegant_hero_18 }) => ({
    default: elegant_hero_18
  }))
)
const ElegantHero19 = lazy(() =>
  import('./elegant/19').then(({ elegant_hero_19 }) => ({
    default: elegant_hero_19
  }))
)
const ElegantHero20 = lazy(() =>
  import('./elegant/20').then(({ elegant_hero_20 }) => ({
    default: elegant_hero_20
  }))
)
const ElegantHero21 = lazy(() =>
  import('./elegant/21').then(({ elegant_hero_21 }) => ({
    default: elegant_hero_21
  }))
)
const ElegantHero22 = lazy(() =>
  import('./elegant/22').then(({ elegant_hero_22 }) => ({
    default: elegant_hero_22
  }))
)
const ElegantHero23 = lazy(() =>
  import('./elegant/23').then(({ elegant_hero_23 }) => ({
    default: elegant_hero_23
  }))
)

const ModernHero1 = lazy(() =>
  import('./modern').then(({ modern_hero_1 }) => ({
    default: modern_hero_1
  }))
)
const ModernHero2 = lazy(() =>
  import('./modern').then(({ modern_hero_2 }) => ({
    default: modern_hero_2
  }))
)
const ModernHero3 = lazy(() =>
  import('./modern').then(({ modern_hero_3 }) => ({
    default: modern_hero_3
  }))
)
const ModernHero4 = lazy(() =>
  import('./modern').then(({ modern_hero_4 }) => ({
    default: modern_hero_4
  }))
)
const ModernHero5 = lazy(() =>
  import('./modern').then(({ modern_hero_5 }) => ({
    default: modern_hero_5
  }))
)
const ModernHero6 = lazy(() =>
  import('./modern').then(({ modern_hero_6 }) => ({
    default: modern_hero_6
  }))
)
const ModernHero7 = lazy(() =>
  import('./modern').then(({ modern_hero_7 }) => ({
    default: modern_hero_7
  }))
)
const ModernHero8 = lazy(() =>
  import('./modern').then(({ modern_hero_8 }) => ({
    default: modern_hero_8
  }))
)
const ModernHero9 = lazy(() =>
  import('./modern').then(({ modern_hero_9 }) => ({
    default: modern_hero_9
  }))
)
const ModernHero10 = lazy(() =>
  import('./modern').then(({ modern_hero_10 }) => ({
    default: modern_hero_10
  }))
)
const ModernHero11 = lazy(() =>
  import('./modern').then(({ modern_hero_11 }) => ({
    default: modern_hero_11
  }))
)
const ModernHero12 = lazy(() =>
  import('./modern').then(({ modern_hero_12 }) => ({
    default: modern_hero_12
  }))
)
const ModernHero13 = lazy(() =>
  import('./modern').then(({ modern_hero_13 }) => ({
    default: modern_hero_13
  }))
)
const ModernHero14 = lazy(() =>
  import('./modern').then(({ modern_hero_14 }) => ({
    default: modern_hero_14
  }))
)
const ModernHero15 = lazy(() =>
  import('./modern').then(({ modern_hero_15 }) => ({
    default: modern_hero_15
  }))
)

export const getPreview = ({
  id,
  data,
  selectedTopicImageNumbers,
  topic
}: {
  id: string
  data: { colors: string[][]; font: string }
  selectedTopicImageNumbers?: number[]
  topic?: string
}) => {
  const colors = {
    primary: data.colors[0],
    secondary: data.colors[1],
    tertiary: data.colors[2]
  }

  const viewData = {
    colors,
    font: data.font,
    selectedTopicImageNumbers,
    topic
  }

  switch (id) {
    case 'modern_hero_1':
      return <ModernHero1 {...viewData} />
    case 'modern_hero_2':
      return <ModernHero2 {...viewData} />
    case 'modern_hero_3':
      return <ModernHero3 {...viewData} />
    case 'modern_hero_4':
      return <ModernHero4 {...viewData} />
    case 'modern_hero_5':
      return <ModernHero5 {...viewData} />
    case 'modern_hero_6':
      return <ModernHero6 {...viewData} />
    case 'modern_hero_7':
      return <ModernHero7 {...viewData} />
    case 'modern_hero_8':
      return <ModernHero8 {...viewData} />
    case 'modern_hero_9':
      return <ModernHero9 {...viewData} />
    case 'modern_hero_10':
      return <ModernHero10 {...viewData} />
    case 'modern_hero_11':
      return <ModernHero11 {...viewData} />
    case 'modern_hero_12':
      return <ModernHero12 {...viewData} />
    case 'modern_hero_13':
      return <ModernHero13 {...viewData} />
    case 'modern_hero_14':
      return <ModernHero14 {...viewData} />
    case 'modern_hero_15':
      return <ModernHero15 {...viewData} />
    case 'minimal_hero_1':
      return <MinimalHero1 {...viewData} />
    case 'minimal_hero_2':
      return <MinimalHero2 {...viewData} />
    case 'minimal_hero_3':
      return <MinimalHero3 {...viewData} />
    case 'minimal_hero_4':
      return <MinimalHero4 {...viewData} />
    case 'minimal_hero_5':
      return <MinimalHero5 {...viewData} />
    case 'minimal_hero_6':
      return <MinimalHero6 {...viewData} />
    case 'minimal_hero_7':
      return <MinimalHero7 {...viewData} />
    case 'minimal_hero_8':
      return <MinimalHero8 {...viewData} />
    case 'minimal_hero_9':
      return <MinimalHero9 {...viewData} />
    case 'minimal_hero_10':
      return <MinimalHero10 {...viewData} />
    case 'minimal_hero_11':
      return <MinimalHero11 {...viewData} />
    case 'minimal_hero_12':
      return <MinimalHero12 {...viewData} />
    case 'minimal_hero_13':
      return <MinimalHero13 {...viewData} />
    case 'minimal_hero_14':
      return <MinimalHero14 {...viewData} />
    case 'minimal_hero_15':
      return <MinimalHero15 {...viewData} />
    case 'minimal_hero_16':
      return <MinimalHero16 {...viewData} />
    case 'minimal_hero_17':
      return <MinimalHero17 {...viewData} />
    case 'minimal_hero_18':
      return <MinimalHero18 {...viewData} />
    case 'minimal_hero_19':
      return <MinimalHero19 {...viewData} />
    case 'minimal_hero_20':
      return <MinimalHero20 {...viewData} />
    case 'minimal_hero_21':
      return <MinimalHero21 {...viewData} />
    case 'minimal_hero_22':
      return <MinimalHero22 {...viewData} />
    case 'minimal_hero_23':
      return <MinimalHero23 {...viewData} />
    case 'minimal_hero_24':
      return <MinimalHero24 {...viewData} />
    case 'elegant_hero_1':
      return <ElegantHero1 {...viewData} />
    case 'elegant_hero_2':
      return <ElegantHero2 {...viewData} />
    case 'elegant_hero_3':
      return <ElegantHero3 {...viewData} />
    case 'elegant_hero_4':
      return <ElegantHero4 {...viewData} />
    case 'elegant_hero_5':
      return <ElegantHero5 {...viewData} />
    case 'elegant_hero_6':
      return <ElegantHero6 {...viewData} />
    case 'elegant_hero_7':
      return <ElegantHero7 {...viewData} />
    case 'elegant_hero_8':
      return <ElegantHero8 {...viewData} />
    case 'elegant_hero_9':
      return <ElegantHero9 {...viewData} />
    case 'elegant_hero_10':
      return <ElegantHero10 {...viewData} />
    case 'elegant_hero_11':
      return <ElegantHero11 {...viewData} />
    case 'elegant_hero_12':
      return <ElegantHero12 {...viewData} />
    case 'elegant_hero_13':
      return <ElegantHero13 {...viewData} />
    case 'elegant_hero_14':
      return <ElegantHero14 {...viewData} />
    case 'elegant_hero_15':
      return <ElegantHero15 {...viewData} />
    case 'elegant_hero_16':
      return <ElegantHero16 {...viewData} />
    case 'elegant_hero_17':
      return <ElegantHero17 {...viewData} />
    case 'elegant_hero_18':
      return <ElegantHero18 {...viewData} />
    case 'elegant_hero_19':
      return <ElegantHero19 {...viewData} />
    case 'elegant_hero_20':
      return <ElegantHero20 {...viewData} />
    case 'elegant_hero_21':
      return <ElegantHero21 {...viewData} />
    case 'elegant_hero_22':
      return <ElegantHero22 {...viewData} />
    case 'elegant_hero_23':
      return <ElegantHero23 {...viewData} />
    case 'dark_hero_1':
      return <DarkHero1 {...viewData} />
    case 'dark_hero_2':
      return <DarkHero2 {...viewData} />
    case 'dark_hero_3':
      return <DarkHero3 {...viewData} />
    case 'dark_hero_4':
      return <DarkHero4 {...viewData} />
    case 'dark_hero_5':
      return <DarkHero5 {...viewData} />
    case 'dark_hero_6':
      return <DarkHero6 {...viewData} />
    case 'dark_hero_7':
      return <DarkHero7 {...viewData} />
    case 'dark_hero_8':
      return <DarkHero8 {...viewData} />
    case 'dark_hero_9':
      return <DarkHero9 {...viewData} />
    case 'dark_hero_10':
      return <DarkHero10 {...viewData} />
    case 'dark_hero_11':
      return <DarkHero11 {...viewData} />
    case 'dark_hero_12':
      return <DarkHero12 {...viewData} />
    case 'dark_hero_13':
      return <DarkHero13 {...viewData} />
    case 'dark_hero_14':
      return <DarkHero14 {...viewData} />
    case 'dark_hero_15':
      return <DarkHero15 {...viewData} />
    case 'dark_hero_16':
      return <DarkHero16 {...viewData} />
    case 'dark_hero_17':
      return <DarkHero17 {...viewData} />
    case 'dark_hero_18':
      return <DarkHero18 {...viewData} />
    case 'dark_hero_19':
      return <DarkHero19 {...viewData} />
    case 'dark_hero_20':
      return <DarkHero20 {...viewData} />
    case 'dark_hero_21':
      return <DarkHero21 {...viewData} />
    case 'dark_hero_22':
      return <DarkHero22 {...viewData} />
    default:
      return null
  }
}
