import { STATUS } from '@constants/subscription'
import { useUserContext } from '@contexts/user'

import { PaymentsCardExpired } from '../components/PaymentsCardExpired'
import { PaymentsCardExpiredAndUnpaid } from '../components/PaymentsCardExpiredAndUnpaid'
import { PaymentsCardExpiredIn } from '../components/PaymentsCardExpiredIn'
import { AlertTypes, INFOBAR_TYPE } from '../constants'

export const useGetCreditCardExpiresAtType = (): {
  type: AlertTypes
  component: () => JSX.Element
} | null => {
  const {
    expiresAt,
    isSubaccount,
    isFreePlan,
    hasSubscription,
    creditCardExpire,
    subscription
  } = useUserContext()

  const isCardExpired = creditCardExpire?.is_expired
  const accountHasValidSubscription =
    hasSubscription &&
    (subscription?.status === STATUS.ACTIVE ||
      subscription?.status === STATUS.PENDING)
  const daysToExpire = creditCardExpire?.days_to_expire

  if (isFreePlan || isSubaccount || !hasSubscription || expiresAt === null) {
    return null
  }

  if (isCardExpired) {
    if (accountHasValidSubscription) {
      return {
        type: INFOBAR_TYPE.ALERT,
        component: PaymentsCardExpired
      }
    }

    return {
      type: INFOBAR_TYPE.ALERT,
      component: PaymentsCardExpiredAndUnpaid
    }
  }

  if (daysToExpire && daysToExpire < 31) {
    return {
      type: INFOBAR_TYPE.WARNING,
      component: () => <PaymentsCardExpiredIn days={daysToExpire} />
    }
  }

  return null
}
