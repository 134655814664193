import { Icon } from '@ui-kit'
import { Trans } from 'react-i18next'

export const getServices = () => [
  {
    title: (
      <Trans i18nKey='addons.store.landing.page.import.services.title.1' />
    ),
    description: (
      <Trans i18nKey='addons.store.landing.page.import.services.description.1' />
    ),
    icon: <Icon icon='icon-file-alt' color='primary' size={26} />
  },
  {
    title: (
      <Trans i18nKey='addons.store.landing.page.import.services.title.2' />
    ),
    description: (
      <Trans i18nKey='addons.store.landing.page.import.services.description.2' />
    ),
    icon: <Icon icon='icon-download' color='primary' size={26} />
  },
  {
    title: (
      <Trans i18nKey='addons.store.landing.page.import.services.title.3' />
    ),
    description: (
      <Trans i18nKey='addons.store.landing.page.import.services.description.3' />
    ),
    icon: <Icon icon='icon-consultation' color='primary' size={26} />
  }
]
