import { t } from 'i18next'
import { object, string } from 'yup'

const FormSchema = () =>
  object().shape({
    code: string()
      .required(t('form.validation.isRequired'))
      .min(36, t('two.factor.authentication.code.too.short'))
  })

export default FormSchema
