import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { Heading, Icon, Paragraph, Spacer, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const Header = () => {
  const { t } = useTranslation()

  const { landingName } = usePublishingOptionsContext()

  return (
    <Fragment>
      <Fragment>
        <Row>
          <Paragraph size={16} color='neutral-6'>
            {landingName}
          </Paragraph>
        </Row>

        <Spacer space={10} />
      </Fragment>

      <Row vertical='center'>
        <Icon icon='icon-globe' size={26} color='primary' />

        <Spreader spread={15} />

        <Heading level={1}>
          {t('publishing.options.boxes.box.custom_domain.name')}
        </Heading>
      </Row>

      <Spacer space={30} />

      <Paragraph size={16}>
        {t('publishing.options.change.url.description')}
      </Paragraph>
    </Fragment>
  )
}

Header.displayName = 'PublishingOptionsViewsChangeUrlHeader'
