import { Fragment, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

const CleanLayout = ({ children }: { children?: ReactNode }) => (
  <Fragment>
    {children} <Outlet />
  </Fragment>
)

CleanLayout.displayName = 'CleanLayout'

export default CleanLayout
