const SMART_SECTIONS = {
  SMART_SECTIONS: '/smart-sections',
  SMART_SECTIONS_CREATE: '/smart-sections/create',
  SMART_SECTIONS_DELETE: '/smart-sections/delete/:identifier',
  SMART_SECTIONS_DUPLICATE: '/smart-sections/duplicate/:identifier',
  SMART_SECTIONS_PREVIEW: '/smart-sections/preview/:identifier',
  SMART_SECTIONS_PREVIEW_DESKTOP: '/smart-sections/preview/:identifier/desktop',
  SMART_SECTIONS_PREVIEW_MOBILE: '/smart-sections/preview/:identifier/mobile',
  SMART_SECTIONS_RENAME: '/smart-sections/rename/:identifier',
  SMART_SECTIONS_GROUP: '/smart-sections/group/:identifier'
}

export default SMART_SECTIONS
