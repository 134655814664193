import client from '@lib/http/client'

export const moveLanding = ({ accountUuid, hash }: any) =>
  client.post('v2/move-landing', {
    accountUuid,
    hash
  })

export interface MoveLandingToAnotherAccountRequest {
  landingUuid: string
  accountIdentifier: string
}

export const moveLandingToAnotherAccount = ({
  landingUuid,
  accountIdentifier
}: MoveLandingToAnotherAccountRequest) =>
  client.put(
    `landing-page/landings/${landingUuid}/move-landing-to-another-account`,
    {
      accountIdentifier
    }
  )
