import PulsingLogo from '@components/PulsingLogo'
import { useStyles } from '@hooks/useStyles'
import Header from '@pages/Authentication/components/Header'
import SurveySecondColumn from '@pages/Authentication/components/SecondColumn/Survey'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import styles from '@pages/Authentication/Layouts/SurveyLayout.module.scss'
import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'
import { Column, Row } from 'simple-flexbox'

/**
 * Survey Layout component - stateless presentational component
 * @param {node} props.children - children
 * @return {object} An object of children
 */
const SurveyLayout = ({ children }) => {
  const { hasSurveyStarted, hasSurveyFinished } = useSurveyContext()
  const { playSurveyEnter, backgroundControls, shouldPlayFinalAnimation } =
    useAnimationContext()

  const columnStyles = useStyles({
    [styles['mainLayout__first-column']]: true
  })

  useEffect(() => {
    playSurveyEnter()
  }, [])

  return shouldPlayFinalAnimation ? (
    <motion.div
      className={styles['final-animation-layout']}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <PulsingLogo />
    </motion.div>
  ) : (
    <Row className={styles.mainLayout}>
      <motion.div
        className={columnStyles}
        initial={{ backgroundColor: hasSurveyStarted ? '#fff' : '#f9f9f9' }}
        animate={backgroundControls}
      >
        <Column className={styles['first-column__content']}>
          <AnimatePresence>
            {!hasSurveyFinished && (
              <Fragment>
                <motion.div
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <Header className={styles['first-column__header--survey']} />
                </motion.div>
                {children}
              </Fragment>
            )}
          </AnimatePresence>
        </Column>
      </motion.div>

      <AnimatePresence>
        {!hasSurveyFinished && (
          <motion.div
            className={styles['mainLayout__second-column']}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <SurveySecondColumn />
          </motion.div>
        )}
      </AnimatePresence>
    </Row>
  )
}

SurveyLayout.displayName = 'SurveyLayout'

SurveyLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default SurveyLayout
