import { APP_LANDINGI_URL } from '@config/env'
import { ADDONS_IDS } from '@constants/addonsIds'
import ProgrammaticLP from '@images/addons/icons/ProgrammaticLP.svg'
import {
  FEATURE,
  INCLUDED_IN_PLAN,
  NEW_NO_ICON
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { AI, AIAddonTemplate } from '@pages/AddonsStore/routes/types'
import { ACCOUNT, PROGRAMMATIC } from '@routes/path'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getProgrammaticLPAddon: (
  t: TFunction<'translation', undefined>,
  hasAccessToProgrammaticLP: boolean,
  { UNBRANDED_CONTENT_FF }: { UNBRANDED_CONTENT_FF?: boolean }
) => AIAddonTemplate = (
  t,
  hasAccessToProgrammaticLP,
  { UNBRANDED_CONTENT_FF }
) => ({
  type: AI,
  name: t('addons.store.programmatic.lp.name'),
  title: t('addons.store.programmatic.lp'),
  tileDescription: <Trans i18nKey='addons.store.programmatic.lp.description' />,
  pageDescription: t('addons.store.programmatic.lp.page.description'),
  badges: hasAccessToProgrammaticLP
    ? [NEW_NO_ICON, FEATURE, INCLUDED_IN_PLAN]
    : [NEW_NO_ICON, FEATURE],
  imageSrc: ProgrammaticLP,
  id: ADDONS_IDS.PROGRAMMATIC_LP,
  uuid: 'TODO',
  overview: getOverview(t),
  features: getFeatures(),
  useCases: getUseCases(t),
  price: '100',
  pricing: getPricing(t, UNBRANDED_CONTENT_FF),
  faq: getFAQ(t),
  CTALink: hasAccessToProgrammaticLP
    ? `${APP_LANDINGI_URL}${PROGRAMMATIC.PROGRAMMATIC}`
    : `${APP_LANDINGI_URL}${ACCOUNT.ACCOUNT_PACKAGE}`,
  customButtonLabel: hasAccessToProgrammaticLP
    ? 'programmatic.lp.addon.custom.button.label'
    : 'programmatic.lp.addon.custom.button.label.no.access.to.programmatic.lp'
})
