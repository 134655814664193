import { useUserContext } from '@contexts/user'

// change whenever new iteration of addon store is released or whenever it is needed to show
// indicator informing user that something new is available in Addon Store
// ISO 8601 (YYYY-MM-DD) format
const ADDON_STORE_UPDATE_DATE = '2024-08-01'

// returns true if user has seen addon store after ADDON_STORE_UPDATE_DATE
export const useAddonStoreHasBeenSeen = () => {
  const { addonStoreLastSeenDate } = useUserContext()

  return (
    new Date(addonStoreLastSeenDate) >= new Date(ADDON_STORE_UPDATE_DATE) &&
    !!addonStoreLastSeenDate // if user never seen addon store, addonStoreLastSeenDate is empty, so we should also show indicator
  )
}
