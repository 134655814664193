export const domainsAddonPrices = {
  1: {
    USD: 10,
    PLN: 50,
    BRL: 60,
    EUR: 10,
    GBP: 8
  },
  12: {
    USD: 8.33,
    PLN: 41.66,
    BRL: 50,
    EUR: 8.33,
    GBP: 6.66
  }
}

export const domainAddonForOldPlansPrices = {
  1: {
    USD: 5,
    PLN: 15,
    BRL: 25,
    EUR: 5,
    GBP: 5
  },
  12: {
    USD: 4,
    PLN: 12,
    BRL: 20,
    EUR: 4,
    GBP: 4
  }
}

export const domainAddonForOldPlansAnnualyDiscount = {
  USD: 12,
  PLN: 36,
  BRL: 60,
  EUR: 12,
  GBP: 12
}

export const uniqueVisitsAddonForOldPlans = {
  1: {
    USD: 5,
    PLN: 15,
    BRL: 25,
    EUR: 5,
    GBP: 5
  },
  12: {
    USD: 4,
    PLN: 12,
    BRL: 20,
    EUR: 4,
    GBP: 4
  }
}

export const uniqueVisitsAddonForOldPlansAnnualyDiscount = {
  USD: 12,
  PLN: 36,
  BRL: 60,
  EUR: 12,
  GBP: 12
}

export const presaleAgencyEventTrackerPrices = {
  1: {
    USD: 49,
    PLN: 187,
    BRL: 199,
    EUR: 49,
    GBP: 45
  },
  12: {
    USD: 40.83,
    PLN: 155.83,
    BRL: 165.83,
    EUR: 40.83,
    GBP: 37.5
  }
}

export const presaleBusinessEventTrackerPrices = {
  1: {
    USD: 25,
    PLN: 99,
    BRL: 99,
    EUR: 25,
    GBP: 25
  },
  12: {
    USD: 20.83,
    PLN: 82.5,
    BRL: 83.25,
    EUR: 20.83,
    GBP: 20.83
  }
}

export const fullAgencyEventTrackerPrices = {
  1: {
    USD: 99,
    PLN: 374,
    BRL: 399,
    EUR: 99,
    GBP: 90
  },
  12: {
    USD: 82,
    PLN: 310,
    BRL: 330,
    EUR: 82,
    GBP: 75
  }
}

export const popupsPrices = {
  1: {
    USD: 10,
    PLN: 49,
    BRL: 49,
    EUR: 10,
    GBP: 10
  },
  12: {
    USD: 8,
    PLN: 40,
    BRL: 40,
    EUR: 8,
    GBP: 8
  }
}

export const fullBusinessEventTrackerPrices = {
  1: {
    USD: 49,
    PLN: 187,
    BRL: 199,
    EUR: 49,
    GBP: 45
  },
  12: {
    USD: 40,
    PLN: 155,
    BRL: 165,
    EUR: 40,
    GBP: 37
  }
}

export const fullECommercePrices = {
  1: {
    USD: 25,
    PLN: 79,
    BRL: 79,
    EUR: 25,
    GBP: 20
  },
  12: {
    USD: 20,
    PLN: 65,
    BRL: 65,
    EUR: 20,
    GBP: 16
  }
}

export const smartSectionsPrices = {
  1: {
    USD: 25,
    PLN: 79,
    BRL: 79,
    EUR: 25,
    GBP: 20
  },
  12: {
    USD: 20,
    PLN: 65,
    BRL: 65,
    EUR: 20,
    GBP: 16
  }
}

export const creditsPrices = {
  USD: 14,
  PLN: 49,
  BRL: 49,
  EUR: 14,
  GBP: 12
}

export const importLandingPagePrices = {
  USD: 239,
  PLN: 1000,
  BRL: 1199,
  EUR: 219,
  GBP: 209
}

export const singleLandingPageDesignPrices = {
  USD: 649,
  PLN: 2500,
  BRL: 2999,
  EUR: 599,
  GBP: 549
}

export const abTestsAddonPrices = {
  1: {
    USD: 25,
    PLN: 79,
    BRL: 79,
    EUR: 25,
    GBP: 20
  },
  12: {
    USD: 20,
    PLN: 65,
    BRL: 65,
    EUR: 20,
    GBP: 16
  }
}
