import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import { t } from 'i18next'
import { object, string } from 'yup'

const parseCode = (value, originalValue) => {
  const parsedCode = originalValue.replace(/\s|_/g, '')

  return parsedCode
}

const RegistrationFormWitchCodeSchema = () =>
  object().shape({
    firstName: string()
      .trim()
      .max(250)
      .required(t('form.validation.isRequired')),
    email: string()
      .email(t('registration.flow.sign.up.invalid.mail'))
      .max(250)
      .required(t('form.validation.isRequired')),
    password: string()
      .test('contain lowercase', ' ', value => hasLowerCase(value))
      .test('contain uppercase', ' ', value => hasUpperCase(value))
      .test('contain number', ' ', value => hasNumber(value))
      .min(10, ' ')
      .required(t('form.validation.isRequired')),
    code: string()
      .transform(parseCode)
      .min(5, ' ')
      .required(t('form.validation.isRequired'))
  })

export default RegistrationFormWitchCodeSchema
