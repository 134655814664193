import { useStyles } from '@hooks/useStyles'
import { Paragraph } from '@ui-kit/Paragraph'
import { FC } from 'react'

import styles from './List.module.scss'

interface ListItemProps {
  description: string
  className?: string | string[]
  size?: 12 | 14 | 16 | 18
}

export const ListItem: FC<ListItemProps> = ({
  description,
  className = '',
  size = 12
}) => {
  const elementClasses = useStyles(
    {
      [styles.list__item]: true,
      [styles[`list__item--size-${size}`]]: true
    },
    className
  )

  return (
    <li className={elementClasses}>
      <Paragraph size={size}>{description}</Paragraph>
    </li>
  )
}

ListItem.displayName = 'ListItem'
