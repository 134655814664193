export type Ratio = '16x9' | '3x2' | '1x1' | '2x3' | 'background'

export const wizardRatioMap = {
  '1200x675': '16x9',
  '1200x800': '3x2',
  '1200x1200': '1x1',
  '1200x1800': '2x3'
} as const

export type WizardSupportedResolutions = keyof typeof wizardRatioMap
