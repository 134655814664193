import { useUpdateEffect } from '@hooks/useUpdateEffect'
import ImageSearchEmpty from '@images/search_empty.svg'
import { Message, Overflow, useTable } from '@landingi/landingi-ui-kit'
import { useGetAccountsList } from '@services/subaccounts'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { columns } from './Columns'
import styles from './Table.module.scss'

interface DuplicateToAnotherAccountsTableProps {
  params: { searchPhrase: string; currentPage: number }
  setParams: Dispatch<
    SetStateAction<{
      searchPhrase: string
      currentPage: number
    }>
  >
  setTargetAccounts: Dispatch<SetStateAction<string[]>>
}

export const DuplicateToAnotherAccountsTable: FC<
  DuplicateToAnotherAccountsTableProps
> = ({ params, setParams, setTargetAccounts }) => {
  const { t } = useTranslation()

  const { searchPhrase, currentPage } = params

  const { data: mainAccount, isLoading: mainAccountIsLoading } =
    useGetAccountsList({
      filters: {
        only_main_account: true
      }
    })

  const mainAccountCollection = useMemo(
    () => mainAccount?.collection ?? [],
    [mainAccount]
  )

  const { data: withoutMainAccount, isLoading: isSubaccountsLoading } =
    useGetAccountsList({
      page: currentPage,
      filters: {
        without_main_account: true,
        name: searchPhrase
      }
    })

  const subaccountsCollection = useMemo(
    () => withoutMainAccount?.collection || [],
    [withoutMainAccount]
  )

  const handlePageChange = (currentPage: number) => {
    setParams(prev => ({ ...prev, currentPage }))
  }

  const tableData = () => {
    const mainAccount = mainAccountCollection.map(({ identifier }) => ({
      landingsduplicate_info: (
        <Row alignItems='center'>{t('word.main-account')}</Row>
      ),
      subaccount_uuid: identifier,
      identifier
    }))

    const subaccounts = subaccountsCollection.map(({ name, identifier }) => ({
      landingsduplicate_info: <Row alignItems='center'>{name}</Row>,
      subaccount_uuid: identifier,
      identifier
    }))

    return [...(searchPhrase ? [] : mainAccount), ...subaccounts]
  }

  const emptyMessage = () => (
    <Message
      title={t('message.empty.search.results')}
      message={t('message.empty.search.results.small')}
      url={ImageSearchEmpty}
      height={150}
    />
  )

  const isLoading = mainAccountIsLoading || isSubaccountsLoading

  const { Table, selected } = useTable({
    name: 'duplicateLandingsList',
    columns,
    emptyMessage,
    data: tableData(),
    pagination: {
      total: withoutMainAccount?.pagination.counter.total || 0,
      current: currentPage,
      handlePageChange
    },
    isLoading,
    i18n: {
      selected: t('word.selected'),
      first: t('pagination.word.first'),
      last: t('pagination.word.last')
    },
    constantPageLimit: 10,
    externalBorder: true,
    options: () => null
  })

  useUpdateEffect(() => {
    setTargetAccounts(selected)
  }, [selected])

  return (
    <div className={styles.wrapper}>
      <Overflow>
        <Table />
      </Overflow>
    </div>
  )
}

DuplicateToAnotherAccountsTable.displayName = 'DuplicateToAnotherAccountsTable'
