import {
  BoxOutline,
  BoxOutlineProvider,
  Button,
  Heading,
  Image,
  Paragraph,
  Spacer
} from '@ui-kit'
import { ReactNode } from 'react'
import { Column, Row } from 'simple-flexbox'

import styles from '../CreateLanding.module.scss'

export const ChooseMethodBoxOutline = ({
  imageSrc,
  heading,
  paragraph,
  buttonContent,
  onClick,
  isLoading,
  disabled = false,
  badges,
  'data-testid': dataTestId = 'box-outline'
}: {
  imageSrc: string
  heading: ReactNode
  paragraph: ReactNode
  buttonContent: ReactNode
  isLoading?: boolean
  disabled?: boolean
  onClick?: () => void
  badges?: ReactNode[]
  'data-testid'?: string
}) => (
  <BoxOutlineProvider disabled={disabled || isLoading}>
    <BoxOutline
      onClick={onClick}
      className={styles['box-outline-height']}
      data-testid={dataTestId}
    >
      <Row alignItems='center' justifyContent='space-between'>
        <Row alignItems='center'>
          <Column>
            <Image src={imageSrc} width='320px' height='140px' />

            <Spacer space={10} />

            {badges ? (
              <Row className={styles.badges}>{badges}</Row>
            ) : (
              <Spacer space={20} />
            )}

            <Spacer space={10} />

            <Heading level={3}>{heading}</Heading>

            <Spacer space={10} />

            <Paragraph size={14} color='neutral-6'>
              {paragraph}
            </Paragraph>

            <Spacer space={15} />

            <Row justifyContent='end'>
              <Button variant='text-primary' icon='icon-arrow-right'>
                {buttonContent}
              </Button>
            </Row>
          </Column>
        </Row>
      </Row>
    </BoxOutline>
  </BoxOutlineProvider>
)
