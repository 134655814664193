import { PLN } from '@constants/currencies'
import { COMPANY, INDIVIDUAL } from '@constants/customerTypes'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import {
  CREDIT_CARD_METHOD,
  INVOICE_METHOD,
  PAYPAL,
  PAYPAL_METHOD,
  PRZELEWY24_METHOD
} from '@constants/paymentMethods'
import { FREE_PLANS, PLANS, usePlans } from '@constants/plans'
import { COMPLETED, CREDIT_CARD } from '@constants/registrationSteps'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { isEmpty } from '@helpers/data'
import { waitForElementVisibleInDOM } from '@helpers/dom'
import { getCreditCardStepErrorMessage } from '@helpers/errors'
import { formatPrice } from '@helpers/payment'
import { useLazyService } from '@hooks/useLazyService'
import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { PayPalModal } from '@pages/Authentication/components/PayPal/PayPal'
import { MODAL_SKIP_CREDIT_CARD } from '@pages/Authentication/components/SkipCreditStep'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { verifyWith3DS } from '@pages/Authentication/helpers/braintree'
import {
  convertPriceToNetto,
  currentPackages,
  mapCountries
} from '@pages/Authentication/helpers/creditCardStep'
import { isLastStep } from '@pages/Authentication/helpers/steps'
import { useRegistrationConfig } from '@pages/Authentication/helpers/useRegistrationConfig'
import CreditCardStepSchema from '@pages/Authentication/routes/CreditCardStep/CreditCardStepSchema'
import { LANDINGS, REGISTRATION } from '@routes/path'
import { useGetAccountInfo } from '@services/account'
import { changeRegistrationStep, getAuthInfo } from '@services/authentication'
import { mixpanelEvent } from '@services/mixpanel'
import {
  changePackage,
  createBraintreeNonce,
  createBraintreeToken,
  getCountries,
  getPackages,
  getPayments,
  postPaymentMethods,
  postSubscriptions,
  postSubscriptionsPaypal,
  saveInvoiceDataCompany,
  saveInvoiceDataIndividual,
  updateInvoiceDataCompany,
  updateInvoiceDataIndividual
} from '@services/payments'
import { activateFreeAccount } from '@services/payments/payments'
import { Paragraph } from '@ui-kit'
import { client, paypalCheckout } from 'braintree-web'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

const CreditCardStepContext = createContext(null)

const initialCreditCardStepInfo = {
  data: {
    mappedCountries: [],
    packages: [],
    paymentsInfo: {},
    countries: []
  },
  isLoading: false,
  isLoaded: false,
  error: false
}

export const CreditCardStepProvider = ({ children }) => {
  const [creditCardStepInfo, setCreditCardStepInfo] = useState(
    initialCreditCardStepInfo
  )
  const { finishRegistration } = useAnimationContext()

  const { user, planRecordKey } = useUserContext()

  const { hasCustomOffer } = useGetAccountInfo()

  const accountCountryCode = user?.profile.country

  const navigate = useNavigate()

  const {
    data: { mappedCountries, packages, paymentsInfo, countries }
  } = creditCardStepInfo

  const { period: periodConfig, lang } = useRegistrationConfig()

  const hasOctoberSaleFF = useFeatureFlags('OCTOBER_SALE_3M_2023')
  const hasAccessTo1MonthSaleFF = useFeatureFlags('PROMOTION_1M')
  const hasAccessTo12MonthSaleFF = useFeatureFlags('PROMOTION_12M')

  const currentPlans = usePlans(planRecordKey)

  const professionalPlan = currentPlans.find(
    plan => plan.name === 'Professional'
  ).recordKey

  const [getCountriesRequest] = useLazyService(getCountries, {
    data: { lang },
    forwardError: true
  })

  const [getPackagesRequest] = useLazyService(getPackages, {
    forwardError: true
  })

  const [getPaymentsRequest] = useLazyService(getPayments, {
    forwardError: true
  })

  const [createBraintreeTokenRequest] = useLazyService(createBraintreeToken, {
    forwardError: true
  })

  const getCreditCardStepInfo = async () => {
    setCreditCardStepInfo({ ...initialCreditCardStepInfo, isLoading: true })

    try {
      const { countries } = await getCountriesRequest(lang)
      const packages = await getPackagesRequest()
      const paymentsInfo = await getPaymentsRequest()

      setCreditCardStepInfo({
        data: {
          mappedCountries: mapCountries(countries),
          packages: currentPackages(packages, currentPlans),
          paymentsInfo,
          countries
        },
        isLoading: false,
        isLoaded: true
      })
    } catch (error) {
      setCreditCardStepInfo({
        ...initialCreditCardStepInfo,
        isLoading: false,
        error: true,
        isLoaded: true
      })

      emitTimingToastToggle(t('error.page.generic.title'), 'alert')
    }
  }

  useEffect(() => {
    getCreditCardStepInfo()
  }, [])

  const { t } = useTranslation()

  const customerTypeSelectOptions = [
    {
      label: t('creditcardstep.form.company'),
      value: COMPANY
    },
    {
      label: t('creditcardstep.form.individual'),
      value: INDIVIDUAL
    }
  ]

  const billingPeriods = [
    {
      label: t('registration.flow.billing.period.1.month'),
      value: 1
    },
    {
      label: t('registration.flow.billing.period.12.months'),
      value: 12
    }
  ]

  const billingPeriodsOctoberSale = [
    {
      label: (
        <Paragraph color='warning' size={16}>
          {t('registration.flow.billing.period.1.month')}
        </Paragraph>
      ),
      value: 1
    },
    {
      label: t('registration.flow.billing.period.12.months'),
      value: 12
    }
  ]

  const billingPeriodsAnnualSale = [
    {
      label: t('registration.flow.billing.period.1.month'),
      value: 1
    },
    {
      label: (
        <Paragraph color='success' size={16}>
          {t('registration.flow.billing.period.12.months')}
        </Paragraph>
      ),
      value: 12
    }
  ]

  let plansSelectOptions = currentPlans.map(({ name, recordKey }) => ({
    label: name,
    description: '',
    value: recordKey
  }))

  /**
   * returns record key of plan with given identifier
   * @param {string} identifier - plan identifier
   * @return record key of plan with given identifier
   */
  const getPackageRecordKeyByIdentifier = identifier =>
    packages?.find(accountPackage => accountPackage.identifier === identifier)
      ?.record_key

  const { customers } = paymentsInfo
  const customerInfo = !isEmpty(customers) && customers[0]

  const initialValues = {
    method: CREDIT_CARD_METHOD,
    country: mappedCountries.find(
      ({ value }) => value === accountCountryCode
    ) ?? { value: 'PL', label: 'Poland' },
    customerType: customerTypeSelectOptions[0].value,
    period: billingPeriods.find(
      billingPeriod => billingPeriod.value === periodConfig
    ),
    plan:
      plansSelectOptions.find(
        plan =>
          plan.value ===
          getPackageRecordKeyByIdentifier(paymentsInfo?.package?.identifier)
      ) || plansSelectOptions.find(plan => plan.value === professionalPlan),
    postCode: customerInfo?.postal_code || '',
    address: customerInfo?.street || '',
    city: customerInfo?.city || '',
    nameSurname: customerInfo?.name || '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    companyName: '',
    vat: ''
  }

  const { mutate } = useSWRConfig()
  const hasAccessToSpa = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  const handle3DSVerificationModalAppearance = async () => {
    const modal = await waitForElementVisibleInDOM('#Cardinal-Modal')

    modal.style.removeProperty('max-width')
  }

  const [sendMixpanelEvent] = useLazyService(mixpanelEvent)

  /**
   * handleSubmit - handles submit of user data and calls login endpoint
   * @param  {object} values - formik values
   * @param  {object} actions - formik actions
   * @type {function}
   */
  const onSubmit = async (values, { setFieldError }) => {
    try {
      const paymentsInfo = await getPaymentsRequest()
      const { email } = paymentsInfo.account
      const { customers } = paymentsInfo
      const testChargeAmount = '0.00'
      const { identifier: currentPackageIdentifier } = paymentsInfo.package
      const {
        companyName,
        cardNumber,
        expirationDate,
        cvv,
        nameSurname,
        country,
        address,
        postCode,
        city,
        vat,
        customerType,
        period,
        plan,
        method
      } = values

      const { identifier } = packages.find(
        ({ record_key }) => record_key === plan.value
      )

      if (identifier !== currentPackageIdentifier) {
        await changePackage({
          period: period.value,
          packageIdentifier: identifier,
          paymentMethodName: method
        })
      }

      if (customerType === COMPANY) {
        const companyInfo = {
          name: companyName,
          street: address,
          city,
          country: country.value,
          postal_code: postCode,
          tax_identifier: vat
        }

        if (isEmpty(customers)) {
          await saveInvoiceDataCompany(companyInfo)
        } else {
          await updateInvoiceDataCompany(companyInfo)
        }
      }

      if (customerType === INDIVIDUAL) {
        const customerInfo = {
          name: nameSurname,
          street: address,
          city,
          country: country.value,
          postal_code: postCode
        }

        if (isEmpty(customers)) {
          await saveInvoiceDataIndividual(customerInfo)
        } else {
          await updateInvoiceDataIndividual(customerInfo)
        }
      }

      if (method === PRZELEWY24_METHOD || method === INVOICE_METHOD) {
        const { SURVEY } = REGISTRATION

        await changeRegistrationStep(CREDIT_CARD)

        const authInfo = (await getAuthInfo()).data

        const { flow } = authInfo
        const { steps } = flow

        if (isLastStep(steps, CREDIT_CARD)) {
          await finishRegistration()

          await sendMixpanelEvent({ name: `Account Verified by ${method}` })

          await changeRegistrationStep(COMPLETED)

          mutate('payments/discount', undefined, { revalidate: true })

          if (hasAccessToSpa) {
            navigate(LANDINGS.WELCOME)
          } else {
            window.open(`${NEW_LANDINGI_APP_URL}/welcome`, '_self')
          }

          mutate('auth', authInfo)
        } else {
          navigate(SURVEY.DEFAULT)
        }

        return
      }

      const braintreeToken = await createBraintreeTokenRequest()

      const clientInstance = await client.create({
        authorization: braintreeToken.token
      })

      if (method === CREDIT_CARD_METHOD) {
        const creditCardResponse = await clientInstance.request({
          endpoint: 'payment_methods/credit_cards',
          method: 'post',
          data: {
            creditCard: {
              number: cardNumber,
              expirationDate,
              cvv,
              options: {
                validate: true
              }
            }
          }
        })

        const { nonce, binData } = creditCardResponse.creditCards[0]

        const paymentMethodToken = (await postPaymentMethods(nonce)).data

        const paymentMethodNonce = (
          await createBraintreeNonce(paymentMethodToken.token)
        ).data

        handle3DSVerificationModalAppearance()

        const { enrichedNonce, shouldPostSubscription } = await verifyWith3DS(
          testChargeAmount,
          paymentMethodNonce.nonce,
          binData,
          email,
          clientInstance,
          period.value,
          identifier
        )

        if (shouldPostSubscription) {
          if (!FREE_PLANS.includes(plan.value)) {
            await postSubscriptions(enrichedNonce, period.value, identifier)
          } else {
            await activateFreeAccount(CREDIT_CARD_METHOD)
          }

          const { SURVEY } = REGISTRATION

          await changeRegistrationStep(CREDIT_CARD)

          const authInfo = (await getAuthInfo()).data

          const { flow } = authInfo
          const { steps } = flow

          if (isLastStep(steps, CREDIT_CARD)) {
            await finishRegistration()

            await sendMixpanelEvent({
              name: 'Account Verified by Credit Card'
            })

            await changeRegistrationStep(COMPLETED)

            mutate('payments/discount', undefined, { revalidate: true })

            if (hasAccessToSpa) {
              navigate(LANDINGS.WELCOME)
            } else {
              window.open(`${NEW_LANDINGI_APP_URL}/welcome`, '_self')
            }

            mutate('auth', authInfo)
          } else {
            navigate(SURVEY.DEFAULT)
          }
        } else {
          emitTimingToastToggle(
            t('registration.flow.credit.card.step.wrong.credit.card.data'),
            'alert'
          )
        }
      }

      if (method === PAYPAL_METHOD) {
        const paypalCheckoutInstance = await paypalCheckout.create({
          client: clientInstance
        })

        await paypalCheckoutInstance.loadPayPalSDK({
          vault: true
        })

        const { paypal } = window

        const button = paypal.Buttons({
          style: {
            height: 44,
            layout: 'horizontal'
          },
          fundingSource: paypal.FUNDING.PAYPAL,
          createBillingAgreement: async () =>
            paypalCheckoutInstance.createPayment({
              flow: 'vault'
            }),
          onApprove: async data => {
            const payload = await paypalCheckoutInstance.tokenizePayment(data)

            const { nonce } = payload

            await postPaymentMethods(nonce)

            if (!FREE_PLANS.includes(plan.value)) {
              await postSubscriptionsPaypal(period.value, identifier)
            } else {
              await activateFreeAccount(PAYPAL)
            }

            await changeRegistrationStep(CREDIT_CARD)

            NiceModal.remove(PayPalModal)

            const authInfo = (await getAuthInfo()).data

            const { flow } = authInfo
            const { steps } = flow

            if (isLastStep(steps, CREDIT_CARD)) {
              await sendMixpanelEvent({
                name: 'Account Verified by Credit Card'
              })

              await changeRegistrationStep(COMPLETED)

              mutate('payments/discount', undefined, { revalidate: true })

              await finishRegistration()

              if (hasAccessToSpa) {
                navigate(LANDINGS.WELCOME)
              } else {
                window.open(`${NEW_LANDINGI_APP_URL}/welcome`, '_self')
              }

              mutate('auth', authInfo)
            }
          },
          onError: () =>
            emitTimingToastToggle(t('error.page.generic.title'), 'alert')
        })

        NiceModal.show(PayPalModal, { button })
      }
    } catch (error) {
      const code = error?.response?.data?.error?.code

      if (code === 'P0009') {
        NiceModal.show(MODAL_SKIP_CREDIT_CARD, {
          headerContent: t('skip.credit.card.modal.service.unavailable.header'),
          paragraphContent: (
            <Trans i18nKey='skip.credit.card.modal.service.unavailable.paragraph' />
          ),
          skipStepButtonContent: t(
            'skip.credit.card.modal.service.unavailable.skip.payment'
          ),
          tryLaterButtonContent: t(
            'skip.credit.card.modal.service.unavailable.try.later'
          ),
          finishRegistration
        })

        return
      }

      const { country } = values

      emitTimingToastToggle(
        t(getCreditCardStepErrorMessage(error, country, setFieldError)),
        'alert'
      )
    }
  }

  const formik = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues,
    validationSchema: CreditCardStepSchema()
  })

  useEffect(() => {
    if (
      (formik.values?.method === PRZELEWY24_METHOD ||
        formik.values?.method === INVOICE_METHOD) &&
      formik.values?.country?.value !== 'PL'
    ) {
      formik.setFieldValue('method', CREDIT_CARD_METHOD)
    }
  }, [formik])

  const currentCountry = countries.find(
    ({ iso }) => iso === formik.values.country.value
  )

  const is_european_union = currentCountry?.is_european_union

  const currency = currentCountry?.currency || PLN

  /**
   * getPrice - goes through packages list and looks for price that matches given params
   * @param {array} packages - packages list
   * @param {string} planRecordKey - plan record key
   * @param {string} currency - currency
   * @param {number} period - billing period
   * @type {function}
   */
  const getPrice = (packages, planRecordKey, currency, period) => {
    const { tariffs } = packages.find(
      accountPackage => accountPackage.record_key === planRecordKey
    )

    const { pricing } = tariffs.find(tariff => tariff.period === period)

    const { price } = pricing.find(
      pricingEntry => pricingEntry.currency === currency
    )

    return price
  }

  const getPlanPrice = (plan, period) => {
    let planPrice = !isEmpty(packages)
      ? getPrice(packages, plan, currency, period)
      : 0

    if (currency === PLN) {
      planPrice = convertPriceToNetto(planPrice)
    }

    return planPrice
  }

  const getVAT = (nettoPrice, planRecordKey, currency, period) => {
    if (isEmpty(packages) || currency !== PLN) {
      return 0
    }

    return formatPrice(
      getPrice(packages, planRecordKey, currency, period) - nettoPrice
    )
  }

  const skipPaymentMethod = isFreePlan =>
    NiceModal.show(MODAL_SKIP_CREDIT_CARD, {
      headerContent: t('registration.flow.credit.card.step.skip.modal.title'),
      paragraphContent: isFreePlan
        ? t('registration.flow.credit.card.step.skip.modal.description.free')
        : t(
            'registration.flow.credit.card.step.skip.modal.description.free.trial'
          ),
      skipStepButtonContent: t(
        'registration.flow.credit.card.step.skip.confirm'
      ),
      tryLaterButtonContent: t(
        'registration.flow.credit.card.step.skip.add.payment'
      ),
      finishRegistration
    })

  const { period, plan } = formik.values

  plansSelectOptions = currentPlans.map(({ name, recordKey }) => ({
    label: name,
    description: `${getPlanPrice(recordKey, period.value)} ${currency}`,
    value: recordKey
  }))

  const planPrice = getPlanPrice(plan.value, period.value)

  const vat = getVAT(planPrice, plan.value, currency, period.value)

  const hasAccessToOctoberSale =
    hasOctoberSaleFF &&
    [professionalPlan, PLANS.LITE_22, PLANS.AGENCY_22].includes(plan.value) &&
    period.value === 1

  const hasAccessTo1MonthSale =
    hasAccessTo1MonthSaleFF &&
    [professionalPlan, PLANS.LITE_22, PLANS.AGENCY_22].includes(plan.value) &&
    period.value === 1

  const hasAccessTo12MonthSale =
    hasAccessTo12MonthSaleFF &&
    [PLANS.PROFESSIONAL_24, PLANS.LITE_LIMITED_24].includes(plan.value) &&
    !hasCustomOffer

  const hasAccessToSale = hasAccessToOctoberSale || hasAccessTo1MonthSale

  const [isAnnualSaleToggled, setIsAnnualSaleToggled] = useState(
    period.value === 12
  )

  const handleAnnualSaleToggle = () => {
    setIsAnnualSaleToggled(toggled => {
      if (!hasAccessTo12MonthSaleFF) {
        return false
      }

      if (!toggled) {
        formik.setFieldValue('period', {
          value: 12,
          label: billingPeriods.find(period => period.value === 12)
        })
      }

      if (toggled) {
        formik.setFieldValue('period', {
          value: 1,
          label: billingPeriods.find(period => period.value === 1)
        })
      }

      return !toggled
    })
  }

  useEffect(() => {
    if (period.value === 1) {
      setIsAnnualSaleToggled(false)
    }

    if (period.value === 12) {
      setIsAnnualSaleToggled(true)
    }
  }, [period.value])

  const hasAccessToOctoberSaleBillingPeriods =
    (hasOctoberSaleFF || hasAccessTo1MonthSaleFF) &&
    [professionalPlan, PLANS.LITE_22, PLANS.AGENCY_22].includes(plan.value)

  const hasAccessToAnnualSaleBillingPeriods =
    isAnnualSaleToggled &&
    [PLANS.PROFESSIONAL_24, PLANS.LITE_LIMITED_24].includes(plan.value)

  const octoberSaleDiscountedPriceBrutto = {
    lite_22: {
      PLN: 60.89,
      USD: 14.5,
      BRL: 49.5,
      GBP: 12.5,
      EUR: 14.5
    },
    professional_22: {
      PLN: 115.01,
      USD: 24.5,
      BRL: 99.5,
      GBP: 22.5,
      EUR: 24.5
    },
    agency_22: {
      PLN: 306.87,
      USD: 74.5,
      BRL: 249.5,
      GBP: 59.5,
      EUR: 74.5
    }
  }[plan.value]?.[currency]

  const annualDiscountedPriceBrutto = hasAccessTo12MonthSale
    ? {
        lite_limited_24: {
          PLN: 920.04,
          USD: 238,
          BRL: 748,
          GBP: 200,
          EUR: 238
        },
        professional_24: {
          PLN: 2445.24,
          USD: 584,
          BRL: 2588,
          GBP: 508,
          EUR: 584
        }
      }[plan.value][currency]
    : 0

  const getSalePrices = () => {
    if (hasAccessToSale) {
      return octoberSaleDiscountedPriceBrutto
    }

    if (hasAccessToAnnualSaleBillingPeriods) {
      return annualDiscountedPriceBrutto
    }

    return 0
  }

  const salePrices = getSalePrices()

  const bruttoPrice =
    hasAccessToSale || (isAnnualSaleToggled && hasAccessTo12MonthSale)
      ? parseFloat(salePrices).toFixed(2)
      : parseFloat(planPrice) + parseFloat(vat)

  const annualSaleDiscountValue = {
    lite_limited_24: {
      PLN: 200,
      USD: 50,
      BRL: 200,
      GBP: 40,
      EUR: 50
    },
    professional_24: {
      PLN: 400,
      USD: 100,
      BRL: 400,
      GBP: 80,
      EUR: 100
    }
  }[plan.value]?.[currency]

  const discount =
    hasAccessToSale || (isAnnualSaleToggled && hasAccessTo12MonthSale)
      ? (
          parseFloat(planPrice) +
          parseFloat(vat) -
          parseFloat(salePrices)
        ).toFixed(2)
      : 0

  const discountNet =
    hasAccessToSale || (isAnnualSaleToggled && hasAccessTo12MonthSale)
      ? (
          parseFloat(planPrice) -
          parseFloat(
            currency === PLN ? convertPriceToNetto(salePrices) : salePrices
          )
        ).toFixed(2)
      : 0

  const annualSaleVat = formatPrice(
    convertPriceToNetto(annualDiscountedPriceBrutto) * 0.23
  )

  const chooseBillingPeriods = () => {
    if (hasAccessToOctoberSaleBillingPeriods) {
      return billingPeriodsOctoberSale
    }

    if (isAnnualSaleToggled && hasAccessTo12MonthSale) {
      return billingPeriodsAnnualSale
    }

    return billingPeriods
  }

  const value = {
    formik,
    customerTypeSelectOptions,
    plansSelectOptions,
    billingPeriods: chooseBillingPeriods(),
    discount,
    discountNet,
    hasAccessToOctoberSale,
    hasAccessTo1MonthSale,
    bruttoPrice,
    planPrice,
    vat,
    annualSaleVat,
    currency,
    is_european_union,
    mappedCountries,
    isLoaded: creditCardStepInfo.isLoaded,
    skipPaymentMethod,
    handleAnnualSaleToggle,
    isAnnualSaleToggled,
    hasAccessTo12MonthSale,
    annualSaleDiscountValue,
    getPlanPrice,
    currentPlans
  }

  return (
    <CreditCardStepContext.Provider value={value}>
      {children}
    </CreditCardStepContext.Provider>
  )
}

CreditCardStepProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useCreditCardStepContext = () => {
  const formik = useContext(CreditCardStepContext)

  if (!formik) {
    throw new Error(
      'useCreditCardStepContext must be used inside CreditCardStepProvider'
    )
  }

  return formik
}
