import { defaultTimezone } from '@constants/timeUnits'
import { format, isDate, parse } from 'date-fns'
import { enUS, pl, pt } from 'date-fns/locale'
import i18next from 'i18next'

export const parseDateString = (value: unknown, originalValue: string) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM / yy', new Date())
  return parsedDate
}

type Lng = 'pl' | 'en' | 'pt'

/**
 * Formats UTC string date to display with account set timezone
 * @param {string} dateUTCString -  UTC date in string format ex. "2022-03-16 15:57:45"
 * @returns formatted string date
 * 12hour - ex. 12 Mar 2021 03:00 AM/PM
 * 24hour - ex. 12 Mar 2021 15:00
 * https://date-fns.org/v2.14.0/docs/format - link to accepted string patterns
 */
export const formatDateUTCToDisplay = (
  dateUTCString: string,
  settings: {
    withTime?: boolean
  } = {
    withTime: true
  }
) => {
  const { withTime } = settings

  const currentLanguage = i18next.language as Lng

  const fallbackTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const timeZone = fallbackTimeZone || defaultTimezone

  const getDateConfig = (lng: Lng) => {
    switch (lng) {
      case 'pl':
        return {
          locale: pl,
          pattern: `dd MMM y ${withTime ? "HH':'mm" : ''}`,
          options: {
            hourCycle: 'h23',
            timeZone
          }
        }
      case 'en':
        return {
          locale: enUS,
          pattern: `dd MMM y ${withTime ? "HH':'mm a" : ''}`,
          options: {
            hour12: true,
            timeZone
          }
        }
      case 'pt':
        return {
          locale: pt,
          pattern: `dd MMM y ${withTime ? "HH':'mm" : ''} `,
          options: {
            hourCycle: 'h23',
            timeZone
          }
        }
      default:
        return {
          locale: enUS,
          pattern: `dd MMM y ${withTime ? "HH':'mm a" : ''}`,
          options: {
            hour12: true,
            timeZone
          }
        }
    }
  }

  const dateConfig = getDateConfig(currentLanguage)

  const { locale, options, pattern } = dateConfig as {
    locale: Locale
    options: Intl.DateTimeFormatOptions
    pattern: string
  }

  /**
   * Require formatting for Safari browser to prevent error: Invalid date format
   * ex. "2017-01-22 11:57:00" => "2017-01-22T11:57:00"
   */
  const parsedDateString = dateUTCString.replace(/ /g, 'T')

  const updatedDateWithTimeZone = new Date(parsedDateString).toLocaleString(
    'en-US',
    options
  )

  const formattedDate = format(new Date(updatedDateWithTimeZone), pattern, {
    locale
  })

  const formattedDateWithUpperCasedMonth = formattedDate
    .split(' ')
    .map((word, index) => {
      if (index === 1) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word
    })
    .join(' ')

  return formattedDateWithUpperCasedMonth.trim()
}

export const convertToUTCDate = (date: string) => {
  const currentDate = new Date(date)

  const currentTime = currentDate.getTime()

  const localTimeOffset = -1 * currentDate.getTimezoneOffset() * 60000

  const dateConverted = Math.round(
    new Date(currentTime + localTimeOffset).getTime() / 1000
  )

  return dateConverted
}

export const getTodayDate = () => new Date(new Date().toDateString())

export const getTodayDateWithTime = () => new Date()

export const getTimestamp = (date: Date) => Math.floor(date.getTime() / 1000)

export const getTimestampStartOfDay = (date: Date): number => {
  const newDate = new Date(date)

  newDate.setUTCHours(0, 0, 0, 0)

  return Math.floor(newDate.getTime() / 1000)
}

export const getTimestampEndOfDay = (date: Date): number => {
  const newDate = new Date(date)

  newDate.setUTCHours(23, 59, 59, 999)

  return Math.floor(newDate.getTime() / 1000)
}

const months = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december'
}

const mapNumberToMonth = (value: keyof typeof months) => months[value]

export const getDateObject = (date: Date) => ({
  day: date.getDate(),
  month: mapNumberToMonth(date.getMonth() as keyof typeof months),
  year: date.getFullYear()
})

export const getAgoDate = (days: number) => {
  const today = getTodayDate()

  return new Date(
    new Date(new Date().setDate(today.getDate() - days)).toDateString()
  )
}

export const getAgoDateWithTime = (days: number) => {
  const today = getTodayDate()

  return new Date(new Date().setDate(today.getDate() - days))
}

export const calculateDaysBetweenDates = (
  startDate: Date,
  endDate?: Date
): number => {
  const normalizeStartDate = new Date(startDate)
  const normalizeEndDate = new Date(endDate ?? new Date())

  normalizeStartDate.setHours(2)
  normalizeStartDate.setMinutes(0)
  normalizeStartDate.setSeconds(0)
  normalizeStartDate.setMilliseconds(0)

  normalizeEndDate.setHours(2)
  normalizeEndDate.setMinutes(0)
  normalizeEndDate.setSeconds(0)
  normalizeEndDate.setMilliseconds(0)

  // Calculate the time difference in milliseconds
  const timeDifference =
    normalizeEndDate.getTime() - normalizeStartDate.getTime()

  // Calculate the number of days by dividing the time difference by the number of milliseconds in a day
  const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  return Math.abs(days)
}

export const getAfterDate = (days: number) => {
  const today = getTodayDate()

  return new Date(new Date().setDate(today.getDate() + days))
}

export const addDaysToDate = (date: Date, daysToAdd: number): Date => {
  const result = new Date(date)

  result.setDate(result.getDate() + daysToAdd)

  return result
}

export const getDateString = (date: Date) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const convertToISO8601 = (dateTimeStr: string): string =>
  `${dateTimeStr.replace(/ /g, 'T')}.000Z`

export function formatTimestampToYearMonthDayFormat(
  dateFrom: Date,
  dateTo: Date
) {
  const date1 = new Date(dateFrom)
  const date2 = new Date(dateTo)

  const formatDate = (date: Date) => ({
    year: date.getFullYear(),
    month: (date.getMonth() + 1).toString().padStart(2, '0'),
    day: date.getDate().toString().padStart(2, '0'),
    hours: date.getHours().toString().padStart(2, '0'),
    minutes: date.getMinutes().toString().padStart(2, '0')
  })

  const dateStart = formatDate(date1)
  const dateEnd = formatDate(date2)

  if (
    dateStart.year === dateEnd.year &&
    dateStart.month === dateEnd.month &&
    dateStart.day === dateEnd.day
  ) {
    return {
      date_start: `${dateStart.year}-${dateStart.month}-${dateStart.day}-00:00`,
      date_end: `${dateEnd.year}-${dateEnd.month}-${dateEnd.day}-23:59`
    }
  }

  return {
    date_start: `${dateStart.year}-${dateStart.month}-${dateStart.day}`,
    date_end: `${dateEnd.year}-${dateEnd.month}-${dateEnd.day}`
  }
}

export const countDaysBetweenDates = ({
  fromDate,
  toDate
}: {
  fromDate: Date
  toDate: Date
}) => {
  const oneDay = 1000 * 60 * 60 * 24

  const onlyDate = (date: Date) => {
    const stringDate = getDateString(date)

    return stringDate.split('-').slice(0, 3).join('-')
  }
  const startDate = new Date(onlyDate(fromDate))
  const endDate = new Date(onlyDate(toDate))

  const startTimestamp = startDate.getTime()
  const endTimestamp = endDate.getTime()

  const differenceInMilliseconds = Math.abs(endTimestamp - startTimestamp)

  const daysDifference = Math.ceil(differenceInMilliseconds / oneDay)

  return daysDifference
}
