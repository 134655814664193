import { Paragraph, Spacer } from '@ui-kit'
import { Column } from 'simple-flexbox'

export const Content = ({
  icon,
  name,
  paragraphColor
}: {
  icon: React.ReactNode
  name: string
  paragraphColor: 'neutral' | 'neutral-4'
}) => {
  return (
    <Column
      justifyContent='center'
      alignItems='center'
      style={{
        height: '100%'
      }}
    >
      {icon}

      <Spacer space={10} />

      <Paragraph align='center' color={paragraphColor}>
        {name}
      </Paragraph>
    </Column>
  )
}
