import { Back, Spacer } from '@landingi/landingi-ui-kit'
import { LANDINGS } from '@routes/path'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { Info } from './Info'

export const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleBack = () => {
    navigate(LANDINGS.LANDINGS)
  }

  return (
    <Fragment>
      <Back onClick={handleBack} label={t('word.back')} />

      <Spacer />

      <Row alignItems='start'>
        <Info />
      </Row>

      <Spacer space='small' />
    </Fragment>
  )
}

Header.displayName = 'LandingsDashboardHeader'
