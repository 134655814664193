import { ErrorBoundaryFallback } from '@components/ErrorBoundaryFallback'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { FC, ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => (
  <SentryErrorBoundary fallback={<ErrorBoundaryFallback />}>
    {children}
  </SentryErrorBoundary>
)
