const POPUPS = {
  POPUPS: '/popups',
  POPUPS_OLD: '/popups-new',
  POPUPS_AB: '/popups/:identifier/ab',
  POPUPS_CREATE: '/popups/create',
  POPUPS_CREATE_INDENTIFIER: '/popups/create/:identifier',
  POPUPS_DELETE: '/popups/delete/:identifier',
  POPUPS_DASHBOARD: '/popups/dashboard/:identifier',
  POPUPS_DOWNLOAD: '/popups/download/:identifier',
  POPUPS_DUPLICATE: '/popups/duplicate/:identifier',
  POPUPS_DISPLAY_RULES: '/popups/display-rules/:identifier',
  POPUPS_INSTALL: '/popups/install/:identifier',
  POPUPS_PREVIEW: '/popups/preview/:identifier',
  POPUPS_TEMPLATES_PREVIEW: '/popups/templates/preview/:identifier',
  POPUPS_PUBLISH: '/popups/publish/:identifier',
  POPUPS_RENAME: '/popups/rename/:identifier',
  POPUPS_SETTINGS: '/popups/dashboard/:identifier/settings',
  POPUPS_UPLOAD: '/popups/upload',
  POPUPS_TEMPLATES: '/popups/templates'
}

export default POPUPS
