import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { Heading, Icon, Paragraph, Spacer, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const Header = () => {
  const { t } = useTranslation()

  const { landingName } = usePublishingOptionsContext()

  return (
    <Fragment>
      <Fragment>
        <Row>
          <Paragraph size={16} color='neutral-6'>
            {landingName}
          </Paragraph>
        </Row>

        <Spacer space={10} />
      </Fragment>

      <Row vertical='center'>
        <Icon icon='icon-wordpress-alt' size={26} />

        <Spreader spread={15} />

        <Heading level={1}>
          {t('publishing.options.boxes.box.wordpress.name')}
        </Heading>
      </Row>
    </Fragment>
  )
}

Header.displayName = 'PublishingOptionsViewsWordpressHeader'
