import { useStyles } from '@hooks/useStyles'
import React, { FC, ReactNode } from 'react'

import styles from './List.module.scss'

interface ListProps {
  children: ReactNode
  className?: string | string[]
}

export const List: FC<ListProps> = ({ children, className = '' }) => {
  const elementClasses = useStyles(
    {
      [styles.list]: true
    },
    className
  )

  return (
    <ul className={elementClasses}>
      {React.Children.toArray(children).filter(item => item)}
    </ul>
  )
}

List.displayName = 'List'
