import { domainStatus } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/domains'
import { Image } from '@ui-kit'
import { FC } from 'react'
import { Column, Row } from 'simple-flexbox'

import styles from './DomainLabel.module.scss'

interface DomainLabelProps {
  label: string
  status: string
}

export const DomainLabel: FC<DomainLabelProps> = ({ label, status }) => {
  const imageSource = domainStatus(status)

  return (
    <Row className={styles.label} vertical='center'>
      <Column horizontal='center' vertical='center'>
        {imageSource ? <Image src={imageSource} width='12px' /> : null}
      </Column>

      {label}
    </Row>
  )
}

DomainLabel.displayName = 'PublishingOptionsViewsChangeUrlDomainLabel'
