import {
  CustomLink,
  Heading,
  Panel,
  Paragraph,
  Spacer
} from '@landingi/landingi-ui-kit'
import { SPECTRUM_MARKETING_COLOR } from '@pages/Authentication/routes/WhitelabelPages/constants'
import styles from '@pages/Authentication/routes/WhitelabelPages/SpectrumMarketing/SpectrumMarketing.module.scss'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import RecoveryCodeForm from './Form'

/**
 * RecoveryCode - stateless presentational component
 * @return {object} An object of children
 */
const RecoveryCode = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel hasShadow={false}>
        <Heading level={1} bold>
          {t('two.factor.authentication.page.recovery.code.heading')}
        </Heading>

        {t('two.factor.authentication.enter.one.of.a.codes')}

        <Spacer space='medium' />

        <RecoveryCodeForm />
      </Panel>

      <Row
        justifyContent='space-between'
        className={styles.spectrummarketing__paragraph}
      >
        <Paragraph size={12} color='accent-2'>
          {t('two.factor.authentication.found.your.phone')}
          <Link
            style={{ color: SPECTRUM_MARKETING_COLOR }}
            to={`${REGISTRATION.TWO_FACTOR_AUTHENTICATION_SPECTRUM_MARKETING}`}
          >
            {t('two.factor.authentication.found.your.phone.link')}
          </Link>
        </Paragraph>

        <Paragraph size={12} color='accent-2'>
          {t('two.factor.authentication.cant.find')}
          <CustomLink
            target='_blank'
            size={12}
            href={t('word.create-support-ticket.url')}
            label={t('two.factor.authentication.cant.find.link')}
            color={SPECTRUM_MARKETING_COLOR}
          />
        </Paragraph>
      </Row>
    </Column>
  )
}

RecoveryCode.displayName = 'RecoveryCode'

export default RecoveryCode
