import { FreePlans } from '@components/Topbar/components/Infobars/components/FreePlans'
import { FreePlansTriggerToUpgrade } from '@components/Topbar/components/Infobars/components/FreePlansTriggerToUpgrade'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useCalcDatesForPromotion } from '@hooks/useCalcDatesForPromotion'
import { useCountdown } from '@ui-kit'
import { useMemo } from 'react'

import { AlertTypes, INFOBAR_TYPE } from '../constants'

export const useGetFreePlansType = (): {
  type: AlertTypes
  component: () => JSX.Element
  hideIcon?: boolean
  paddingTopBottom?: boolean
} | null => {
  const { user } = useUserContext()

  const hasFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')
  const hasAccessToPromotionForFreePlan = useFeatureFlags(
    'PROMOTION_FOR_FREE_PLAN'
  )

  const { date, promotionIsVisible, promotionIsLoading } =
    useCalcDatesForPromotion()

  const { isFinished } = useCountdown(date)

  const isInSQLStage = user?.platform_stage === 'SQL'

  const displayPromotionInfobar =
    hasAccessToPromotionForFreePlan &&
    isInSQLStage &&
    !isFinished &&
    promotionIsVisible

  const displayFreeTrialInfobar =
    user?.display_free_trial_infobar && !promotionIsLoading

  const renderInfobar = useMemo(() => {
    if (hasFreePlanRestrictions) {
      if (displayPromotionInfobar) {
        return {
          type: INFOBAR_TYPE.INFO,
          component: () => <FreePlansTriggerToUpgrade />,
          hideIcon: true,
          paddingTopBottom: false
        }
      }

      if (displayFreeTrialInfobar) {
        return {
          type: INFOBAR_TYPE.WARNING,
          component: () => <FreePlans />
        }
      }
    }

    return null
  }, [
    hasFreePlanRestrictions,
    displayPromotionInfobar,
    displayFreeTrialInfobar
  ])

  return renderInfobar
}
