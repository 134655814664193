import { NEW_LANDINGI_URL } from '@config/env'
import { BEGINNING, COMPLETED } from '@constants/registrationSteps'
import useQuery from '@hooks/useQuery'
import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { useRegisterContext } from '@pages/Authentication/contexts/register'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import { isLastStep } from '@pages/Authentication/helpers/steps'
import { useGoogleLogin } from '@react-oauth/google'
import { REGISTRATION } from '@routes/path'
import {
  changeRegistrationStep,
  getAuthInfo,
  loginWithOAuth,
  registerWithGoogle
} from '@services/authentication'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import SignUpButton from './SignUpButton'

/**
 * GoogleSignUp - stateful component used handling google sign up logic
 * @returns {object} children
 */
const GoogleSignUp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const queryParams = useQuery()
  const { startFromFirstStep } = useStepsContext()

  const navigate = useNavigate()

  const {
    chosenPackage,
    source,
    pricing,
    newSignup,
    isCustomOffer,
    lang,
    period,
    isDefaultRegistration
  } = useRegisterContext()

  const { mutate } = useSWRConfig()
  const { t } = useTranslation()

  const handleSignup = useGoogleLogin({
    onSuccess: async response => {
      try {
        setIsLoading(true)
        const { access_token } = response
        const timeZone = moment.tz.guess()
        const uri = window.location.href

        await registerWithGoogle(
          access_token,
          timeZone,
          chosenPackage,
          uri,
          source,
          pricing,
          newSignup,
          isCustomOffer,
          queryParams.get('promotion') || undefined,
          isDefaultRegistration ? 'yes' : 'no',
          lang
        )

        await loginWithOAuth(access_token, 'google', NEW_LANDINGI_URL)

        const { data: authInfo } = await getAuthInfo()

        startFromFirstStep()

        const { flow } = authInfo
        const { steps } = flow

        await changeRegistrationStep(BEGINNING)

        if (isLastStep(steps, BEGINNING)) {
          await changeRegistrationStep(COMPLETED)

          mutate('payments/discount', undefined, { revalidate: true })

          mutate('auth')

          return
        }

        mutate('auth')

        navigate(`${REGISTRATION.SURVEY.DEFAULT}?lang=${lang}&period=${period}`)
      } catch (error) {
        if (
          error?.response?.data?.error?.message ===
          'Given email address is already taken!'
        ) {
          emitTimingToastToggle(t('registration.flow.sign.up.a0008'), 'alert')
        } else {
          emitTimingToastToggle(t('error.page.generic.title'), 'alert')
        }
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <SignUpButton
      isLoading={isLoading}
      disabled={isLoading}
      onClick={handleSignup}
    />
  )
}

GoogleSignUp.displayName = 'Google Sign Up Component'

export default GoogleSignUp
