import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { BoxOutline, BoxOutlineProvider, Image, Radio } from '@ui-kit'
import { useTranslation } from 'react-i18next'

import styles from '../Sixth.module.scss'
import { TopicType } from '../topics'

export const StyleBox = ({
  topic,
  image
}: {
  topic: TopicType
  image: string
}) => {
  const { formik, setTopic } = useWizardContext()
  const { values } = formik

  const { t } = useTranslation()

  return (
    <BoxOutlineProvider controlledActive={values.topic === topic}>
      <BoxOutline
        padding={0}
        onClick={() => setTopic(topic)}
        containerClassName={styles.containerBox}
        data-testid={`wizard-topic-${topic}`}
      >
        <Image src={image} alt={topic} width='100%' />

        <div className={styles['sixth-step__radio']}>
          <Radio
            label={t(`wizard.topics.list.${topic}`)}
            id={topic}
            field={{
              name: topic,
              value: values.topic,
              onChange: () => setTopic(topic)
            }}
          />
        </div>
      </BoxOutline>
    </BoxOutlineProvider>
  )
}
