import { useStyles } from '@hooks/useStyles'
import { VariantProvider } from '@ui-kit/Tabs/context/VariantContext'
import { TabVariantType } from '@ui-kit/Tabs/types'
import { FC, HTMLAttributes, ReactNode } from 'react'

import styles from './Tab.module.scss'

export interface TabListProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  className?: string | string[]
  variant: TabVariantType
  children: ReactNode
}

export const TabList: FC<TabListProps> = ({
  className,
  children,
  variant,
  ...restProps
}) => {
  const tabListStyles = useStyles(
    {
      [styles.tab__list]: true,
      [styles[`tab__list--variant-${variant}`]]: variant
    },
    className
  )

  return (
    <VariantProvider variant={variant}>
      <div {...restProps} data-testid='tab-list' className={tabListStyles}>
        {children}
      </div>
    </VariantProvider>
  )
}

TabList.displayName = 'TabList'
