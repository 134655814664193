import { Heading, Image, Spacer } from '@landingi/landingi-ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

interface ErrorPresenterProps {
  errorImage: string
  title: string
  content: string
}

export const ErrorPresenter: FC<ErrorPresenterProps> = ({
  errorImage,
  title,
  content
}) => {
  const { t } = useTranslation()

  return (
    <Column alignItems='center'>
      <Image src={errorImage} size='720px' />

      <Spacer space='large' />

      <Heading level={1}>{t(title)}</Heading>

      <Heading level={3}>{t(content)}</Heading>
    </Column>
  )
}
