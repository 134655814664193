import { Button, Icon, Tooltip } from '@landingi/landingi-ui-kit'
import { useTranslation } from 'react-i18next'

export const HireUs = () => {
  const { t } = useTranslation()

  const handleOpenLandingPage = async () => {
    window.open(t('topbar.hireus.url'), '_blank')
  }

  return (
    <div id='topbar-hire-us'>
      <Tooltip content={t('topbar.hireus.tooltip.content')} align='center'>
        <Button
          variant='icon-transparent-hover'
          onClick={handleOpenLandingPage}
          data-testid='hire-us-button'
        >
          <Icon icon='icon-user-tie' />
        </Button>
      </Tooltip>
    </div>
  )
}

HireUs.displayName = 'TopbarHireUs'
