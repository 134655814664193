import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import ModalDownloadImage from '@images/landings/modal_landing_download_file.svg'
import {
  Button,
  Icon,
  Image,
  Modal,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { downloadLanding } from '@services/landings/download'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

export interface ModalDownloadLandingPageProps {
  uuid: string
}

export const ModalDownloadLandingPage =
  NiceModal.create<ModalDownloadLandingPageProps>(({ uuid }) => {
    const { t } = useTranslation()

    const { remove } = useModal()

    const [handleDownloadLandingPage, { isLoading }] = useLazyService(
      downloadLanding,
      {
        onSuccess: ({ data }) => {
          if (data.fileUrl) {
            window.open(data.fileUrl, '_self')

            remove()
          }
        },
        errorToastText: t('toast.error.something.went.wrong')
      }
    )

    return (
      <Modal
        isActive
        onClick={remove}
        i18n={{
          title: t('modal.landing.download.title')
        }}
      >
        <Spacer space='tiny' />

        <Row justifyContent='space-between'>
          <Column flex='14'>
            <Paragraph size={16}>
              <Trans
                i18nKey='modal.download.content.first'
                components={{
                  b: <b />
                }}
              />
            </Paragraph>

            <Paragraph size={16}>
              {t('modal.download.content.second')}
            </Paragraph>
          </Column>

          <Column flex='8'>
            <Image src={ModalDownloadImage} size='125px' />
          </Column>

          <Column flex='1'>
            <Spacer />
          </Column>
        </Row>

        <Spacer />

        <Row justifyContent='flex-end'>
          <Button
            size='medium'
            onClick={() => {
              handleDownloadLandingPage({ uuid })
            }}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            <Icon icon='icon-download' color='white' />

            <Spreader spread='tiny' />

            {t('modal.download.button.label')}
          </Button>
        </Row>
      </Modal>
    )
  })

ModalDownloadLandingPage.displayName = 'ModalDownloadLandingPage'
