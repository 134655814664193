const REGISTRATION = {
  REGISTER: {
    DEFAULT: '/register',
    VARIANT: '/register/:variantName'
  },
  SIGNUP: {
    DEFAULT: '/signup'
  },
  LOGIN: {
    DEFAULT: '/login'
  },
  SURVEY: {
    DEFAULT: '/account/survey'
  },
  FORGOT_PASSWORD: {
    DEFAULT: '/forgot-password'
  },
  CREDIT_CARD_STEP: {
    DEFAULT: '/credit-card-step'
  },
  COMPARE_PLANS: { DEFAULT: '/compare-plans' },
  CHANGE_PASSWORD: {
    DEFAULT: '/password-change/:token'
  },
  TWO_FACTOR_AUTHENTICATION: {
    DEFAULT: '/login/2fa/totp'
  },
  RECOVERY_CODE: {
    DEFAULT: '/login/recovery'
  },
  LOGIN_WL_TEST: '/login/:clientName',
  FORGOT_PASSWORD_WL_TEST: '/forgot-password/:clientName',
  CHANGE_PASSWORD_WL_TEST: '/password-change/:clientName/:token',
  RECOVERY_CODE_WL_TEST: '/login/recovery/:clientName',
  TWO_FACTOR_AUTHENTICATION_WL_TEST: '/login/2fa/totp/:clientName',
  /** WEB TO LEARN */
  LOGIN_WEBTOLEARN: '/login/webtolearn',
  FORGOT_PASSWORD_WEBTOLEARN: '/forgot-password/webtolearn',
  CHANGE_PASSWORD_WEBTOLEARN: '/password-change/webtolearn/:token',
  RECOVERY_CODE_WEB_TO_LEARN: '/login/recovery/webtolearn',
  TWO_FACTOR_AUTHENTICATION_WEB_TO_LEARN: '/login/2fa/totp/webtolearn',
  /** THRIVE COACH */
  LOGIN_THRIVE_COACH: '/login/thrivecoach',
  FORGOT_PASSWORD_THRIVE_COACH: '/forgot-password/thrivecoach',
  CHANGE_PASSWORD_THRIVE_COACH: '/password-change/thrivecoach/:token',
  TWO_FACTOR_AUTHENTICATION_THRIVE_COACH: '/login/2fa/totp/thrivecoach',
  RECOVERY_CODE_THRIVE_COACH: '/login/recovery/thrivecoach',
  /** SPECTRUM MARKETING */
  LOGIN_SPECTRUM_MARKETING: '/login/spectrum',
  FORGOT_PASSWORD_SPECTRUM_MARKETING: '/forgot-password/spectrum',
  CHANGE_PASSWORD_SPECTRUM_MARKETING: '/password-change/spectrum/:token',
  TWO_FACTOR_AUTHENTICATION_SPECTRUM_MARKETING: '/login/2fa/totp/spectrum',
  RECOVERY_CODE_SPECTRUM_MARKETING: '/login/recovery/spectrum',
  DEFAULT: '/'
}

export const REGISTRATION_ROUTES_ARRAY = Object.values(REGISTRATION)
  .reduce((acc, obj) => {
    if (typeof obj === 'object') {
      return acc.concat(Object.values(obj))
    }
    return acc.concat(obj)
  }, [])
  .filter(item => item !== '/')

const {
  TWO_FACTOR_AUTHENTICATION,
  RECOVERY_CODE,
  RECOVERY_CODE_SPECTRUM_MARKETING,
  RECOVERY_CODE_THRIVE_COACH,
  RECOVERY_CODE_WEB_TO_LEARN,
  RECOVERY_CODE_WL_TEST,
  TWO_FACTOR_AUTHENTICATION_SPECTRUM_MARKETING,
  TWO_FACTOR_AUTHENTICATION_THRIVE_COACH,
  TWO_FACTOR_AUTHENTICATION_WEB_TO_LEARN,
  TWO_FACTOR_AUTHENTICATION_WL_TEST
} = REGISTRATION

export const TWO_FACTOR_AUTHENTICATION_ROUTES = [
  TWO_FACTOR_AUTHENTICATION.DEFAULT,
  RECOVERY_CODE.DEFAULT,
  RECOVERY_CODE_SPECTRUM_MARKETING,
  RECOVERY_CODE_THRIVE_COACH,
  RECOVERY_CODE_WEB_TO_LEARN,
  RECOVERY_CODE_WL_TEST,
  TWO_FACTOR_AUTHENTICATION_SPECTRUM_MARKETING,
  TWO_FACTOR_AUTHENTICATION_THRIVE_COACH,
  TWO_FACTOR_AUTHENTICATION_WEB_TO_LEARN,
  TWO_FACTOR_AUTHENTICATION_WL_TEST
]

export default REGISTRATION
