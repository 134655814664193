import { useBoxOutlineContext } from '@ui-kit'
import React, { ReactNode } from 'react'

interface BoxContentProps {
  Content: ReactNode
  HoverContent: ReactNode
}

export const BoxContent: React.FC<BoxContentProps> = ({
  Content,
  HoverContent
}) => {
  const { isHovered } = useBoxOutlineContext()

  if (isHovered) {
    return HoverContent
  }

  return Content
}
