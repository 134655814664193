import { GTM_ID, PROD } from '@config/env'
import { useUserContext } from '@contexts/user'
import { useEffect, useRef } from 'react'
import TagManager from 'react-gtm-module'

export const useGTM = () => {
  const calledOnce = useRef(false)

  const {
    isAuth,
    language,
    userID,
    user,
    isLoading,
    isSupportMode,
    isSubaccount,
    planRecordKey
  } = useUserContext()

  useEffect(() => {
    if (!PROD || isLoading || isSupportMode || calledOnce.current) {
      return
    }

    TagManager.initialize({
      gtmId: GTM_ID
    })

    calledOnce.current = true
  }, [isLoading, isSupportMode])

  useEffect(() => {
    if (!PROD || isLoading || isSupportMode || isSubaccount) {
      return
    }

    if (isAuth && user?.platform_stage && language) {
      TagManager.dataLayer({
        dataLayer: {
          login: 'true',
          lang: language,
          account_uuid: userID,
          platform_stage: user?.platform_stage,
          record_key: planRecordKey
        }
      })

      return
    }

    if (!isAuth) {
      TagManager.dataLayer({
        dataLayer: {
          login: 'false'
        }
      })

      return
    }
  }, [
    isAuth,
    language,
    userID,
    user?.platform_stage,
    isLoading,
    isSupportMode,
    isSubaccount,
    planRecordKey
  ])
}
