import { ErrorPresenter } from '@components/ErrorPresenter'
import Error404Svg from '@images/errors/error-404.svg'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import styles from './NotFound.module.scss'

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Column justifyContent='center' className={styles.wrapper}>
      <ErrorPresenter
        errorImage={Error404Svg}
        title={t('error.page.404.title')}
        content={t('error.page.404.content')}
      />
    </Column>
  )
}
