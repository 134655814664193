import { useStyles } from '@hooks/useStyles'
import ImagePlaceholder from '@images/placeholder-no-image.svg'
import { Image } from '@landingi/landingi-ui-kit'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { FC } from 'react'

import styles from './Thumbnail.module.scss'

export const Thumbnail: FC = () => {
  const {
    landing: { thumbnail_url, is_published }
  } = useDashboardContext()

  const landingInfoPictureStyles = useStyles({
    [styles.landing__info]: true,
    [styles['landing__info--picture']]: true,
    [styles['landing__info--picture--unpublished']]: !is_published
  })

  return (
    <Image
      className={landingInfoPictureStyles}
      src={thumbnail_url || ImagePlaceholder}
    />
  )
}

Thumbnail.displayName = 'LandingDashboardHeaderThumbnail'
