import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import {
  Alert,
  Button,
  Check,
  emitTimingToastToggle,
  InputForm,
  ShowPassword,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { REGISTRATION } from '@routes/path'
import { postChangePassword } from '@services/authentication'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import styles from './Form.module.scss'
import FormSchema from './FormSchema'

/**
 * Change password form - stateful presentational component
 * @return {object} An object of children
 */
const ChangePasswordForm = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const { token } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (token) {
      window.history.replaceState({}, document.title, '/password-change')
    }
  }, [token])

  /**
   * renderChecks - renders checks components
   * @param {sting} password - password the checks positive value will depend on
   */
  const renderChecks = password => (
    <Row>
      <Column>
        <Check positive={hasLowerCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.lowercase')}
        </Check>

        <Spacer space='mini' />

        <Check positive={hasUpperCase(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.uppercase')}
        </Check>
      </Column>

      <Spreader spread='large' />

      <Column justifyContent='space-between'>
        <Check positive={hasNumber(password || '')} crossedOutOnPositive>
          {t('modal.add.user.one.number')}
        </Check>

        <Spacer space='mini' />

        <Check positive={password?.length > 9} crossedOutOnPositive>
          {t('modal.add.user.ten.minimum')}
        </Check>
      </Column>
    </Row>
  )

  /**
   * Handle input type state
   * @type {function}
   */
  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )

  /**
   * onSubmit - handles submit of user data
   * @param  {object} values - formik values
   * @type {function}
   */
  const onSubmit = useCallback(async values => {
    const { password } = values

    try {
      await postChangePassword({ token, password })

      const { LOGIN } = REGISTRATION

      emitTimingToastToggle(
        t('registration.flow.reset.password.grant.new.success'),
        'success'
      )

      navigate(LOGIN.DEFAULT)
    } catch {
      emitTimingToastToggle(t('error.page.generic.title'), 'alert')
    }
  }, [])

  const initialValues = {
    password: ''
  }

  const {
    isSubmitting,
    handleSubmit,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = useFormik({ initialValues, validationSchema: FormSchema(), onSubmit })

  /**
   * isPasswordValid - returns true if all checks have passed
   * @param {string} password - password to validate
   */
  const isPasswordValid = (password = '') =>
    hasLowerCase(password) &&
    hasUpperCase(password) &&
    hasNumber(password) &&
    password.length > 9

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <div className={styles['reset-password__password']}>
          <Row>
            <Column flexGrow='1'>
              <InputForm
                field={{
                  name: 'password',
                  value: values.password,
                  onChange: handleChange,
                  onBlur: handleBlur
                }}
                id='password'
                form={{
                  errors,
                  touched
                }}
                i18n={{
                  placeholder: t('registration.flow.password'),
                  label: t('registration.flow.password')
                }}
                type={passwordInputType}
              />
            </Column>

            <Spreader spread='tiny' />

            <Column>
              <ShowPassword setHidden={handleInputTypeState} />
            </Column>
          </Row>
        </div>
        {isPasswordValid(values.password) ? (
          <Alert type='success'>
            <Trans i18nKey='registration.flow.password.alert' />
          </Alert>
        ) : (
          renderChecks(values.password)
        )}
        <Spacer space='mini' />
        <Spacer space='tiny' />
        <Button
          type='submit'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          size='large'
        >
          {t('registration.flow.reset.password')}
        </Button>
      </Column>
    </form>
  )
}

ChangePasswordForm.displayName = 'GrantNewPasswordForm'

export default ChangePasswordForm
