import { useStyles } from '@hooks/useStyles'
import { Divider } from '@landingi/landingi-ui-kit'
import { Spacer } from '@ui-kit/Spacer'
import { FC, Fragment, ReactNode } from 'react'
import { Row } from 'simple-flexbox'

interface SummaryRowProps {
  className?: string | string[]
  'data-testid'?: string
  left: ReactNode
  right: ReactNode
  leftSideWidth?: string
  withDivider?: boolean
}

export const SummaryRow: FC<SummaryRowProps> = ({
  className = '',
  'data-testid': dataTestId = 'summaryRow',
  left,
  right,
  leftSideWidth = '350px',
  withDivider = true
}) => {
  const summaryRowStyles = useStyles({}, className)

  return (
    <Fragment>
      <Spacer />
      <Row className={summaryRowStyles} data-testid={dataTestId}>
        <div
          data-testid='left-side'
          style={{
            maxWidth: leftSideWidth,
            minWidth: leftSideWidth
          }}
        >
          {left}
        </div>
        <div>{right}</div>
      </Row>
      {withDivider && (
        <Fragment>
          <Spacer />
          <Divider />
        </Fragment>
      )}
    </Fragment>
  )
}

SummaryRow.displayName = 'SummaryRow'
