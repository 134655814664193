import activecampaignImg from '@images/registration/survey/appsIcons/activecampaignImg.svg'
import adobeAnalyticsImg from '@images/registration/survey/appsIcons/adobeAnalyticsImg.svg'
import adobeExperienceManagerImg from '@images/registration/survey/appsIcons/adobeExperienceManagerImg.svg'
import adobeMarketoImg from '@images/registration/survey/appsIcons/adobeMarketoImg.svg'
import agileCrmImg from '@images/registration/survey/appsIcons/agileCrmImg.svg'
import airbnbImg from '@images/registration/survey/appsIcons/airbnbImg.svg'
import amoCrmImg from '@images/registration/survey/appsIcons/amoCrmImg.svg'
import autopilotjourneyImg from '@images/registration/survey/appsIcons/autopilotjourneyImg.svg'
import aweberImg from '@images/registration/survey/appsIcons/aweberImg.svg'
import benchmarkEmailImg from '@images/registration/survey/appsIcons/benchmarkEmailImg.svg'
import benchmarkOneImg from '@images/registration/survey/appsIcons/benchmarkOneImg.svg'
import bigcommerceImg from '@images/registration/survey/appsIcons/bigcommerceImg.svg'
import booksyImg from '@images/registration/survey/appsIcons/booksyImg.svg'
import brevoImg from '@images/registration/survey/appsIcons/brevoImg.svg'
import callback24Img from '@images/registration/survey/appsIcons/callback24Img.svg'
import callpageImg from '@images/registration/survey/appsIcons/callpageImg.svg'
import callrailImg from '@images/registration/survey/appsIcons/callrailImg.svg'
import campaignmonitorImg from '@images/registration/survey/appsIcons/campaignmonitorImg.svg'
import capsuleImg from '@images/registration/survey/appsIcons/capsuleImg.svg'
import clicksendImg from '@images/registration/survey/appsIcons/clicksendImg.svg'
import constantContactImg from '@images/registration/survey/appsIcons/constantContactImg.svg'
import convertkitImg from '@images/registration/survey/appsIcons/convertkitImg.svg'
import cookiebotImg from '@images/registration/survey/appsIcons/cookiebotImg.svg'
import crazyeggImg from '@images/registration/survey/appsIcons/crazyeggImg.svg'
import creatioImg from '@images/registration/survey/appsIcons/creatioImg.svg'
import dripImg from '@images/registration/survey/appsIcons/dripImg.svg'
import ecwidImg from '@images/registration/survey/appsIcons/ecwidImg.svg'
import egitoImg from '@images/registration/survey/appsIcons/egitoImg.svg'
import egoiImg from '@images/registration/survey/appsIcons/egoiImg.svg'
import facebookConversationsApiImg from '@images/registration/survey/appsIcons/facebookConversationsApiImg.svg'
import followupbossImg from '@images/registration/survey/appsIcons/followupbossImg.svg'
import fondyImg from '@images/registration/survey/appsIcons/fondyImg.svg'
import freshchatImg from '@images/registration/survey/appsIcons/freshchatImg.svg'
import freshmailImg from '@images/registration/survey/appsIcons/freshmailImg.svg'
import fullstoryImg from '@images/registration/survey/appsIcons/fullstoryImg.svg'
import getresponseImg from '@images/registration/survey/appsIcons/getresponseImg.svg'
import gmailImg from '@images/registration/survey/appsIcons/gmailImg.svg'
import googleAdsImg from '@images/registration/survey/appsIcons/googleAdsImg.svg'
import googleAnalyticsImg from '@images/registration/survey/appsIcons/googleAnalyticsImg.svg'
import googleSheetsImg from '@images/registration/survey/appsIcons/googleSheetsImg.svg'
import googleTagManagerImg from '@images/registration/survey/appsIcons/googleTagManagerImg.svg'
import gotowebinarImg from '@images/registration/survey/appsIcons/gotowebinarImg.svg'
import heapAnalyticsImg from '@images/registration/survey/appsIcons/heapanalyticsImg.svg'
import hotjarImg from '@images/registration/survey/appsIcons/hotjarImg.svg'
import hubspotImg from '@images/registration/survey/appsIcons/hubspotImg.svg'
import instagramForBusinessImg from '@images/registration/survey/appsIcons/instagramForBusinessImg.svg'
import intercomImg from '@images/registration/survey/appsIcons/intercomImg.svg'
import jotformImg from '@images/registration/survey/appsIcons/jotformImg.svg'
import keapImg from '@images/registration/survey/appsIcons/keapImg.svg'
import kissmetricsImg from '@images/registration/survey/appsIcons/kissmetricsImg.svg'
import leadbyteImg from '@images/registration/survey/appsIcons/leadbyteImg.svg'
import leadsbridgeImg from '@images/registration/survey/appsIcons/leadsbridgeImg.svg'
import leadsquaredImg from '@images/registration/survey/appsIcons/leadsquaredImg.svg'
import linkedinAdsImg from '@images/registration/survey/appsIcons/linkedinAdsImg.svg'
import linkedinImg from '@images/registration/survey/appsIcons/linkedinImg.svg'
import livechatImg from '@images/registration/survey/appsIcons/livechatImg.svg'
import livespaceImg from '@images/registration/survey/appsIcons/livespaceImg.svg'
import livestormImg from '@images/registration/survey/appsIcons/livestormImg.svg'
import livewebinarImg from '@images/registration/survey/appsIcons/livewebinarImg.svg'
import mailchimpImg from '@images/registration/survey/appsIcons/mailchimpImg.svg'
import mailerliteImg from '@images/registration/survey/appsIcons/mailerliteImg.svg'
import matomoImg from '@images/registration/survey/appsIcons/matomoImg.svg'
import mercadopagoImg from '@images/registration/survey/appsIcons/mercadopagoImg.svg'
import microsoftAdvertisingImg from '@images/registration/survey/appsIcons/microsoftAdvertisingImg.svg'
import microsoftDynamics365Img from '@images/registration/survey/appsIcons/microsoftDynamics365Img.svg'
import microsoftOutlookImg from '@images/registration/survey/appsIcons/microsoftOutlookImg.svg'
import olarkImg from '@images/registration/survey/appsIcons/olarkImg.svg'
import onesignalImg from '@images/registration/survey/appsIcons/onesignalImg.svg'
import optinmonsterImg from '@images/registration/survey/appsIcons/optinmonsterImg.svg'
import outgrowImg from '@images/registration/survey/appsIcons/outgrow.svg'
import paypalImg from '@images/registration/survey/appsIcons/paypalImg.svg'
import payuImg from '@images/registration/survey/appsIcons/payuImg.svg'
import pinterestTagImg from '@images/registration/survey/appsIcons/pinterestTagImg.svg'
import pipedriveImg from '@images/registration/survey/appsIcons/pipedriveImg.svg'
import pipelineCrmImg from '@images/registration/survey/appsIcons/pipelineCrmImg.svg'
import piwikproImg from '@images/registration/survey/appsIcons/piwikproImg.svg'
import rdstationImg from '@images/registration/survey/appsIcons/rdstationImg.svg'
import salesflareImg from '@images/registration/survey/appsIcons/salesflareImg.svg'
import salesforceImg from '@images/registration/survey/appsIcons/salesforceImg.svg'
import salesforceMarketingCloudImg from '@images/registration/survey/appsIcons/salesforceMarketingCloudImg.svg'
import salesmanagoImg from '@images/registration/survey/appsIcons/salesmanagoImg.svg'
import sendgridImg from '@images/registration/survey/appsIcons/sendgridImg.svg'
import sendowlImg from '@images/registration/survey/appsIcons/sendowlImg.svg'
import sharpspringAdsImg from '@images/registration/survey/appsIcons/sharpspringImg.svg'
import shopifyImg from '@images/registration/survey/appsIcons/shopifyImg.svg'
import slackImg from '@images/registration/survey/appsIcons/slackImg.svg'
import stripeImg from '@images/registration/survey/appsIcons/stripeImg.svg'
import sugarcrmImg from '@images/registration/survey/appsIcons/sugarcrmImg.svg'
import thrivecoachImg from '@images/registration/survey/appsIcons/thrivecoachImg.svg'
import tiktokImg from '@images/registration/survey/appsIcons/tiktokImg.svg'
import trustindexImg from '@images/registration/survey/appsIcons/trustindexImg.svg'
import trustpilotImg from '@images/registration/survey/appsIcons/trustpilotImg.svg'
import twitterAdsImg from '@images/registration/survey/appsIcons/twitterImg.svg'
import typeformImg from '@images/registration/survey/appsIcons/typeformImg.svg'
import usercomImg from '@images/registration/survey/appsIcons/usercomImg.svg'
import vimeoImg from '@images/registration/survey/appsIcons/vimeoImg.svg'
import visualWebsiteOptimizerImg from '@images/registration/survey/appsIcons/vwoImg.svg'
import whatsappBusinessImg from '@images/registration/survey/appsIcons/whatsappBusinessImg.svg'
import wistiaImg from '@images/registration/survey/appsIcons/wistiaImg.svg'
import woocommerceImg from '@images/registration/survey/appsIcons/woocommerceImg.svg'
import wordpressImg from '@images/registration/survey/appsIcons/wordpressImg.svg'
import yandexmetricaImg from '@images/registration/survey/appsIcons/yandexmetricaImg.svg'
import youtubeImg from '@images/registration/survey/appsIcons/youtubeImg.svg'
import youtubeStudioImg from '@images/registration/survey/appsIcons/youtubeStudioImg.svg'
import zapierImg from '@images/registration/survey/appsIcons/zapierImg.svg'
import zendeskChatImg from '@images/registration/survey/appsIcons/zendeskChatImg.svg'
import zendeskImg from '@images/registration/survey/appsIcons/zendeskImg.svg'
import zendeskSellImg from '@images/registration/survey/appsIcons/zendeskSellImg.svg'
import zohoCrmImg from '@images/registration/survey/appsIcons/zohoImg.svg'
import zohoMeetingImg from '@images/registration/survey/appsIcons/zohoMeetingImg.svg'
import zoomImg from '@images/registration/survey/appsIcons/zoomImg.svg'

const apps = [
  {
    value: 'Gmail',
    label: 'Gmail',
    icon: gmailImg
  },
  {
    value: 'Google Analytics',
    label: 'Google Analytics',
    icon: googleAnalyticsImg
  },
  {
    value: 'Google Ads',
    label: 'Google Ads',
    icon: googleAdsImg
  },
  {
    value: 'Google Tag Manager',
    label: 'Google Tag Manager',
    icon: googleTagManagerImg
  },
  {
    value: 'Google Sheets',
    label: 'Google Sheets',
    icon: googleSheetsImg
  },
  {
    value: 'Zapier',
    label: 'Zapier',
    icon: zapierImg
  },
  {
    value: 'HubSpot',
    label: 'HubSpot',
    icon: hubspotImg
  },
  {
    value: 'Pipedrive',
    label: 'Pipedrive',
    icon: pipedriveImg
  },
  {
    value: 'Salesflare',
    label: 'Salesflare',
    icon: salesflareImg
  },
  {
    value: 'ActiveCampaign',
    label: 'ActiveCampaign',
    icon: activecampaignImg
  },
  {
    value: 'Mailchimp',
    label: 'Mailchimp',
    icon: mailchimpImg
  },
  {
    value: 'Facebook Conversions API',
    label: 'Facebook Conversions API',
    icon: facebookConversationsApiImg
  },
  {
    value: 'Instagram for Business',
    label: 'Instagram for Business',
    icon: instagramForBusinessImg
  },
  {
    value: 'LinkedIn',
    label: 'LinkedIn',
    icon: linkedinImg
  },
  {
    value: 'Slack',
    label: 'Slack',
    icon: slackImg
  },
  {
    value: 'WhatsApp Business',
    label: 'WhatsApp Business',
    icon: whatsappBusinessImg
  },
  {
    value: 'WordPress',
    label: 'WordPress',
    icon: wordpressImg
  },
  {
    value: 'Zoom',
    label: 'Zoom',
    icon: zoomImg
  },
  {
    value: 'YouTube',
    label: 'YouTube',
    icon: youtubeImg
  },
  {
    value: 'YouTube Studio',
    label: 'YouTube Studio',
    icon: youtubeStudioImg
  },
  {
    value: 'Shopify',
    label: 'Shopify',
    icon: shopifyImg
  },
  {
    value: 'Microsoft Outlook',
    label: 'Microsoft Outlook',
    icon: microsoftOutlookImg
  },
  {
    value: 'Microsoft Dynamics 365',
    label: 'Microsoft Dynamics 365',
    icon: microsoftDynamics365Img
  },
  {
    value: 'Zendesk',
    label: 'Zendesk',
    icon: zendeskImg
  },
  {
    value: 'Stripe',
    label: 'Stripe',
    icon: stripeImg
  },
  {
    value: 'PayPal',
    label: 'PayPal',
    icon: paypalImg
  },
  {
    value: 'WooCommerce',
    label: 'WooCommerce',
    icon: woocommerceImg
  },
  {
    value: 'Twitter Ads',
    label: 'Twitter Ads',
    icon: twitterAdsImg
  },
  {
    value: 'Microsoft Advertising',
    label: 'Microsoft Advertising',
    icon: microsoftAdvertisingImg
  },
  {
    value: 'LinkedIn Ads',
    label: 'LinkedIn Ads',
    icon: linkedinAdsImg
  },
  {
    value: 'Cookiebot',
    label: 'Cookiebot',
    icon: cookiebotImg
  },
  {
    value: 'GetResponse',
    label: 'GetResponse',
    icon: getresponseImg
  },
  {
    value: 'Crazy Egg',
    label: 'Crazy Egg',
    icon: crazyeggImg
  },
  {
    value: 'HotJar',
    label: 'HotJar',
    icon: hotjarImg
  },
  {
    value: 'FullStory',
    label: 'FullStory',
    icon: fullstoryImg
  },
  {
    value: 'TikTok',
    label: 'TikTok',
    icon: tiktokImg
  },
  {
    value: 'Vimeo',
    label: 'Vimeo',
    icon: vimeoImg
  },
  {
    value: 'Callback24',
    label: 'Callback24',
    icon: callback24Img
  },
  {
    value: 'CallPage',
    label: 'CallPage',
    icon: callpageImg
  },
  {
    value: 'Constant Contact',
    label: 'Constant Contact',
    icon: constantContactImg
  },
  {
    value: 'Freshchat',
    label: 'Freshchat',
    icon: freshchatImg
  },
  {
    value: 'Intercom',
    label: 'Intercom',
    icon: intercomImg
  },
  {
    value: 'LiveChat',
    label: 'LiveChat',
    icon: livechatImg
  },
  {
    value: 'Zendesk Chat',
    label: 'Zendesk Chat',
    icon: zendeskChatImg
  },
  {
    value: 'Booksy',
    label: 'Booksy',
    icon: booksyImg
  },
  {
    value: 'Typeform',
    label: 'Typeform',
    icon: typeformImg
  },
  {
    value: 'Trustpilot',
    label: 'Trustpilot',
    icon: trustpilotImg
  },
  {
    value: 'Freshmail',
    label: 'Freshmail',
    icon: freshmailImg
  },
  {
    value: 'Zendesk Sell',
    label: 'Zendesk Sell',
    icon: zendeskSellImg
  },
  {
    value: 'Zoho CRM',
    label: 'Zoho CRM',
    icon: zohoCrmImg
  },
  {
    value: 'Agile CRM',
    label: 'Agile CRM',
    icon: agileCrmImg
  },
  {
    value: 'amoCRM',
    label: 'amoCRM',
    icon: amoCrmImg
  },
  {
    value: 'AWeber',
    label: 'AWeber',
    icon: aweberImg
  },
  {
    value: 'BenchmarkONE',
    label: 'BenchmarkONE',
    icon: benchmarkOneImg
  },
  {
    value: 'Capsule',
    label: 'Capsule',
    icon: capsuleImg
  },
  {
    value: 'Creatio',
    label: 'Creatio',
    icon: creatioImg
  },
  {
    value: 'Drip',
    label: 'Drip',
    icon: dripImg
  },
  {
    value: 'E-goi',
    label: 'E-goi',
    icon: egoiImg
  },
  {
    value: 'Ecwid',
    label: 'Ecwid',
    icon: ecwidImg
  },
  {
    value: 'Egito CRM',
    label: 'Egito CRM',
    icon: egitoImg
  },
  {
    value: 'Follow Up Boss',
    label: 'Follow Up Boss',
    icon: followupbossImg
  },
  {
    value: 'Keap',
    label: 'Keap',
    icon: keapImg
  },
  {
    value: 'LeadByte',
    label: 'LeadByte',
    icon: leadbyteImg
  },
  {
    value: 'LeadsBridge',
    label: 'LeadsBridge',
    icon: leadsbridgeImg
  },
  {
    value: 'LeadSquared',
    label: 'LeadSquared',
    icon: leadsquaredImg
  },
  {
    value: 'LiveSpace',
    label: 'LiveSpace',
    icon: livespaceImg
  },
  {
    value: 'Pipeline CRM',
    label: 'Pipeline CRM',
    icon: pipelineCrmImg
  },
  {
    value: 'SALESmanago',
    label: 'SALESmanago',
    icon: salesmanagoImg
  },
  {
    value: 'SugarCRM',
    label: 'SugarCRM',
    icon: sugarcrmImg
  },
  {
    value: 'Thrive Coach',
    label: 'Thrive Coach',
    icon: thrivecoachImg
  },
  {
    value: 'Autopilot Journeys',
    label: 'Autopilot Journeys',
    icon: autopilotjourneyImg
  },
  {
    value: 'Benchmark Email',
    label: 'Benchmark Email',
    icon: benchmarkEmailImg
  },
  {
    value: 'Brevo',
    label: 'Brevo',
    icon: brevoImg
  },
  {
    value: 'Campaign Monitor',
    label: 'Campaign Monitor',
    icon: campaignmonitorImg
  },
  {
    value: 'ClickSend',
    label: 'ClickSend',
    icon: clicksendImg
  },
  {
    value: 'ConvertKit',
    label: 'ConvertKit',
    icon: convertkitImg
  },
  {
    value: 'Mailer Lite',
    label: 'Mailer Lite',
    icon: mailerliteImg
  },
  {
    value: 'RD Station',
    label: 'RD Station',
    icon: rdstationImg
  },
  {
    value: 'Salesforce Marketing Cloud',
    label: 'Salesforce Marketing Cloud',
    icon: salesforceMarketingCloudImg
  },
  {
    value: 'SendGrid',
    label: 'SendGrid',
    icon: sendgridImg
  },
  {
    value: 'User.com',
    label: 'User.com',
    icon: usercomImg
  },
  {
    value: 'Adobe Analytics',
    label: 'Adobe Analytics',
    icon: adobeAnalyticsImg
  },
  {
    value: 'CallRail',
    label: 'CallRail',
    icon: callrailImg
  },
  {
    value: 'Heap Analytics',
    label: 'Heap Analytics',
    icon: heapAnalyticsImg
  },
  {
    value: 'Kissmetrics',
    label: 'Kissmetrics',
    icon: kissmetricsImg
  },
  {
    value: 'Matomo',
    label: 'Matomo',
    icon: matomoImg
  },
  {
    value: 'Salesforce',
    label: 'Salesforce',
    icon: salesforceImg
  },
  {
    value: 'Piwik PRO',
    label: 'Piwik PRO',
    icon: piwikproImg
  },
  {
    value: 'Yandex Metrica',
    label: 'Yandex Metrica',
    icon: yandexmetricaImg
  },
  {
    value: 'Visual Website Optimizer',
    label: 'Visual Website Optimizer',
    icon: visualWebsiteOptimizerImg
  },
  {
    value: 'Pinterest Tag',
    label: 'Pinterest Tag',
    icon: pinterestTagImg
  },
  {
    value: 'SharpSpring Ads',
    label: 'SharpSpring Ads',
    icon: sharpspringAdsImg
  },
  {
    value: 'Wistia',
    label: 'Wistia',
    icon: wistiaImg
  },
  {
    value: 'BigCommerce',
    label: 'BigCommerce',
    icon: bigcommerceImg
  },
  {
    value: 'Fondy',
    label: 'Fondy',
    icon: fondyImg
  },
  {
    value: 'Jotform',
    label: 'Jotform',
    icon: jotformImg
  },
  {
    value: 'Mercado Pago',
    label: 'Mercado Pago',
    icon: mercadopagoImg
  },
  {
    value: 'PayU',
    label: 'PayU',
    icon: payuImg
  },
  {
    value: 'SendOwl',
    label: 'SendOwl',
    icon: sendowlImg
  },
  {
    value: 'Olark',
    label: 'Olark',
    icon: olarkImg
  },
  {
    value: 'GoToWebinar',
    label: 'GoToWebinar',
    icon: gotowebinarImg
  },
  {
    value: 'Livestorm',
    label: 'Livestorm',
    icon: livestormImg
  },
  {
    value: 'LiveWebinar',
    label: 'LiveWebinar',
    icon: livewebinarImg
  },
  {
    value: 'Zoho Meeting',
    label: 'Zoho Meeting',
    icon: zohoMeetingImg
  },
  {
    value: 'Adobe Experience Manager',
    label: 'Adobe Experience Manager',
    icon: adobeExperienceManagerImg
  },
  {
    value: 'Airbnb',
    label: 'Airbnb',
    icon: airbnbImg
  },
  {
    value: 'Marketo',
    label: 'Marketo',
    icon: adobeMarketoImg
  },
  {
    value: 'OneSignal',
    label: 'OneSignal',
    icon: onesignalImg
  },
  {
    value: 'OptinMonster',
    label: 'OptinMonster',
    icon: optinmonsterImg
  },
  {
    value: 'Outgrow',
    label: 'Outgrow',
    icon: outgrowImg
  },
  {
    value: 'Trustindex',
    label: 'Trustindex',
    icon: trustindexImg
  }
]

export default apps
