import SummerSaleMate from '@images/dealmate/summer-sale-register-dealmate.svg'
import GABadgeCategoryLeaders from '@images/registration/GA-Badge-CategoryLeaders.svg'
import HighPerformerMidMarketReward from '@images/registration/HighPerformerMidMarketReward.png'
import HighPerformerReward from '@images/registration/HighPerformerReward.png'
import LeadCaptureSmallBusinessReward from '@images/registration/LeadCaptureSmallBusinessReward.png'
import SABadgeFrontrunners from '@images/registration/SA-Badge-FrontRunners-2023.png'
import { List, ListItem } from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import {
  Badge,
  Heading,
  Icon,
  Image,
  Paragraph,
  Spacer,
  Spreader
} from '@ui-kit'
import { motion } from 'framer-motion'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './RefreshedColumnContent.module.scss'

const Christmas = () => {
  const { secondColumnControls } = useAnimationContext()

  const { t } = useTranslation()

  return (
    <div className={styles['summer-sale-column-content']}>
      <motion.div
        className={styles['column-content']}
        animate={secondColumnControls}
      >
        <Row alignItems='center'>
          <Image src={SummerSaleMate} height='70px' />

          <Spreader spread={10} />

          <Heading level={1} weight={600}>
            <Trans i18nKey='summer.sale.we.heat.up.deals' />
          </Heading>
        </Row>

        <Spacer space={10} />

        <Heading level={2}>
          <Trans i18nKey='summer.sale.limited.offer' />
        </Heading>

        <Spacer space={10} />

        <Row alignItems='baseline'>
          <Badge variant='warning-filled' weight={600}>
            {t('summer.sale.save.up.to')}
          </Badge>

          <Spreader spread={5} />

          <Paragraph size={14}>{t('annual.sale.for.first.payment')}</Paragraph>
        </Row>

        <Spacer space={30} />

        <Paragraph weight={600} size={16}>
          {t('annual.by.register.you.get.more')}
        </Paragraph>

        <List>
          <Spacer space={10} />

          <ListItem>
            <Row>
              <Icon icon='icon-check-circle' color='primary' size={12} />

              <Spreader spread={20} />

              <Paragraph size={14}>
                <Trans i18nKey='summer.sale.registration.features.1' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space={10} />

          <ListItem>
            <Row>
              <Icon icon='icon-check-circle' color='primary' size={12} />

              <Spreader spread={20} />

              <Paragraph size={14}>
                <Trans i18nKey='summer.sale.registration.features.2' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space={10} />

          <ListItem>
            <Row>
              <Icon icon='icon-check-circle' color='primary' size={12} />

              <Spreader spread={20} />

              <Paragraph size={14}>
                <Trans i18nKey='summer.sale.registration.features.3' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space={10} />

          <ListItem>
            <Row>
              <Icon icon='icon-check-circle' color='primary' size={12} />

              <Spreader spread={20} />

              <Paragraph size={14}>
                <Trans i18nKey='summer.sale.registration.features.4' />
              </Paragraph>
            </Row>
          </ListItem>

          <Spacer space={10} />

          <ListItem>
            <Row>
              <Icon icon='icon-check-circle' color='primary' size={12} />

              <Spreader spread={20} />

              <Paragraph size={14}>
                <Trans i18nKey='summer.sale.registration.features.5' />
              </Paragraph>
            </Row>
          </ListItem>
        </List>

        <Spacer space={15} />

        <Paragraph size={14}>
          {t('annual.by.register.we.are.waiting')}
        </Paragraph>

        <Spacer space={20} />

        <div className={styles['column-content__logos']}>
          <Image src={HighPerformerReward} width='54px' />

          <Image src={HighPerformerMidMarketReward} width='54px' />

          <Image src={LeadCaptureSmallBusinessReward} width='54px' />

          <Image src={GABadgeCategoryLeaders} width='54px' />

          <Image src={SABadgeFrontrunners} width='54px' />
        </div>
      </motion.div>
    </div>
  )
}

Christmas.displayName = 'Christmas'

export default memo(Christmas)
