import { useStyles } from '@hooks/useStyles'
import { FC } from 'react'

import styles from './StepNumber.module.scss'

interface StepNumberProps {
  className?: string | string[]
  step: number
  size?: number
  variant?: 'primary' | 'success' | 'neutral-2'
  'data-testid'?: string
}

export const StepNumber: FC<StepNumberProps> = ({
  className = '',
  step,
  size = 14,
  variant = 'primary',
  'data-testid': dataTestId = 'stepNumber'
}) => {
  const stepNumberStyles = useStyles(
    {
      [styles['step-number']]: true,
      [styles[`step-number-size--${size}`]]: true,
      [styles[`step-number-variant--${variant}`]]: true
    },
    className
  )

  return (
    <span data-testid={dataTestId} className={stepNumberStyles}>
      {step}
    </span>
  )
}

StepNumber.displayName = 'StepNumber'
