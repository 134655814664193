import { removeLocalStorage } from '@helpers/storage'

export const SURVEY_QUESTIONS_SKIPPED_INFO = 'surveyQuestionsSkippedInfo'
export const SURVEY_ACTIVE_SURVEY_BOXES_IDS = 'surveyActiveSurveyBoxesIDs'
export const SURVEY_QUESTIONS_ANSWERS = 'surveyQuestionsAnswers'
export const CURRENT_REGISTRATION_STEP_NUMBER = 'currentRegistrationStepNumber'

export const clearSurveyLocalStorage = () => {
  removeLocalStorage(SURVEY_QUESTIONS_SKIPPED_INFO)
  removeLocalStorage(SURVEY_ACTIVE_SURVEY_BOXES_IDS)
  removeLocalStorage(SURVEY_QUESTIONS_ANSWERS)
  removeLocalStorage(CURRENT_REGISTRATION_STEP_NUMBER)
}
