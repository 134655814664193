import { PROD } from '@config/env'
import client from '@lib/http/client'
import useSWR from 'swr'

export const saveRestoreId = (restoreId: string) =>
  client.post('freshdesk/restoreid/save', { restoreid: restoreId })

interface UseGetHotjarInfo {
  account: {
    country: string
    identifier: string
    is_trial: boolean
    record_key: string
  }
}

export const useGetHotjarInfo = () =>
  useSWR<UseGetHotjarInfo>(PROD ? 'web-analytic-tools/v1/hotjar' : null)
