import { Heading } from '@landingi/landingi-ui-kit'
import { FC } from 'react'

import styles from './Header.module.scss'

interface HeaderProps {
  heading: string
}

export const Header: FC<HeaderProps> = ({ heading }) => (
  <div className={styles.header_container}>
    <Heading level={2} margin='none'>
      {heading}
    </Heading>
  </div>
)

Header.displayName = 'TranslateLandingModalHeader'
