import { APP_LANDINGI_URL } from '@config/env'
import MicrosoftIcon from '@images/registration/microsoft_icon.svg'
import { Button, Image, Spreader } from '@ui-kit'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const SignInButton = ({ customColor }: { customColor?: string }) => {
  const { t } = useTranslation()

  const dynamicStyles = {
    color: customColor,
    borderColor: customColor
  }

  const handleOnClick = () => {
    window.open(`${APP_LANDINGI_URL}/api/auth/connect/microsoft`, '_self')
  }

  return (
    <Row alignItems='center' justifyContent='center'>
      <Button variant='outlined' onClick={handleOnClick} style={dynamicStyles}>
        <Image src={MicrosoftIcon} width='18px' height='18px' />

        <Spreader spread={10} />

        {t('registration.flow.sign.in.microsoft')}
      </Button>
    </Row>
  )
}

SignInButton.displayName = 'MicrosoftSignInButton'
