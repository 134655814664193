import { EDITOR_DOMAIN } from '@config/env'
import { useAcl } from '@contexts/acl'
import NiceModal from '@ebay/nice-modal-react'
import { MODAL_LANDING_TRANSLATE } from '@pages/Landings/components/Modals/ModalLandingTranslate'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { Button, ButtonLink, Spreader } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { DropdownListItems } from './DropdownListItems'

export const Actions = () => {
  const { t } = useTranslation()

  const { landing } = useDashboardContext()

  const hasLandingAccessEdit = useAcl('landing_pages.landing.edit')
  const hasAccessToTranslate = useAcl('landing_pages.landing.translate')

  const { master_hash, identifier, name } = landing

  const handleTranslate = () => {
    NiceModal.show(MODAL_LANDING_TRANSLATE, {
      uuid: identifier,
      name
    })
  }

  return (
    <Row justifyContent='flex-end' alignContent='flex-start'>
      {hasLandingAccessEdit ? (
        <ButtonLink
          target='_blank'
          href={`${EDITOR_DOMAIN}/${master_hash}`}
          icon='icon-create'
          variant='primary'
        >
          {t('word.edit')}
        </ButtonLink>
      ) : null}

      <Spreader spread={10} />

      {hasAccessToTranslate ? (
        <Fragment>
          <Button
            id='hj-translate-button-primary'
            variant='text-primary'
            icon='icon-ai-option'
            onClick={handleTranslate}
          >
            {t('word.lp.translate')}
          </Button>

          <Spreader spread={10} />
        </Fragment>
      ) : null}

      <DropdownListItems />
    </Row>
  )
}

Actions.displayName = 'LandingsDashboardHeaderRowActions'
