import { Button, InputForm } from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import {
  SPECTRUM_MARKETING,
  SPECTRUM_MARKETING_COLOR
} from '@pages/Authentication/routes/WhitelabelPages/constants'
import resetPasswordSchema from '@pages/Authentication/routes/WhitelabelPages/ResetPassword/FormSchema'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

/**
 * ResetPassword form - stateless presentational component
 * @return {object} An object of children
 */
const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const { resetPassword } = useWhitelabelPageContext()

  /**
   * handleSubmit - handles submit of user data
   * @param  {object} values - formik values
   * @type {function}
   */
  const onSubmit = useCallback(async values => {
    const { email } = values

    await resetPassword(email, SPECTRUM_MARKETING)
  }, [])

  const initialValues = {
    email: ''
  }

  const {
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    values,
    errors,
    touched,
    handleChange,
    handleBlur
  } = useFormik({
    onSubmit,
    initialValues,
    validationSchema: resetPasswordSchema()
  })

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <InputForm
          id='email'
          allowHotjar
          field={{
            name: 'email',
            value: values.email,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          i18n={{
            label: t('registration.flow.email'),
            placeholder: t('registration.flow.email')
          }}
          form={{
            errors,
            touched
          }}
        />

        <Button
          type='submit'
          size='large'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
          customEnabledBackgroundColor={SPECTRUM_MARKETING_COLOR}
          customStyle={{ borderRadius: '4px' }}
        >
          {t('registration.flow.submit')}
        </Button>
      </Column>
    </form>
  )
}

ResetPasswordForm.displayName = 'Enhanced Reset Password Form'

export default ResetPasswordForm
