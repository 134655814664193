import { CREDIT_CARD, SURVEY } from '@constants/registrationSteps'
import { useUserContext } from '@contexts/user'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import {
  clearSurveyLocalStorage,
  CURRENT_REGISTRATION_STEP_NUMBER
} from '@pages/Authentication/helpers/localStorage'
import { useRegistrationConfig } from '@pages/Authentication/helpers/useRegistrationConfig'
import { REGISTRATION } from '@routes/path'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const routes = {
  CREDIT_CARD: REGISTRATION.CREDIT_CARD_STEP,
  SURVEY: REGISTRATION.SURVEY
}

const StepsContext = createContext(null)

const creditCardStepNumber = 7

const getStartingCurrentStep = pathname => {
  const { CREDIT_CARD } = routes

  if (pathname === CREDIT_CARD.DEFAULT) {
    return creditCardStepNumber
  }

  return Number(getLocalStorage(CURRENT_REGISTRATION_STEP_NUMBER)) || 0
}

export const StepsProvider = ({ children }) => {
  const location = useLocation()
  const { pathname } = location

  const [currentStep, setCurrentStep] = useState(
    getStartingCurrentStep(pathname)
  )

  const { auth, isAuth } = useUserContext()
  const { lang, period } = useRegistrationConfig()

  const navigate = useNavigate()
  const surveySteps = 6

  let maxSteps = 0

  const startFromFirstStep = useCallback(() => {
    clearSurveyLocalStorage()
    setCurrentStep(getStartingCurrentStep(pathname))
  }, [pathname])

  const calculateMaxRegistrationSteps = () => {
    if (isAuth) {
      const { flow } = auth
      const { steps } = flow
      const stepNames = steps.map(({ name }) => name)

      if (stepNames.includes(SURVEY)) {
        maxSteps += surveySteps
      }
      if (stepNames.includes(CREDIT_CARD)) {
        maxSteps += 1
      }
    }
  }

  calculateMaxRegistrationSteps()

  const getSurveyStep = useCallback(() => {
    if (currentStep > surveySteps) {
      return surveySteps
    }

    return currentStep
  }, [currentStep])

  /**
   * set current step on location change, so when coming back by using browser back button it works properly
   */
  useEffect(() => {
    if (isAuth) {
      const { CREDIT_CARD, SURVEY } = routes

      if (location.pathname === SURVEY.DEFAULT) {
        setCurrentStep(getSurveyStep())
      }

      if (location.pathname === CREDIT_CARD.DEFAULT) {
        setCurrentStep(creditCardStepNumber)
      }
    }
  }, [auth, isAuth, location.pathname, getSurveyStep])

  /**
   * Decrement current step. If currentStep is 7 navigate to survey
   */
  const previousStep = useCallback(() => {
    setCurrentStep(step => step - 1)

    const { flow } = auth
    const { steps } = flow
    const stepNames = steps.map(({ name }) => name)

    if (
      stepNames.includes(CREDIT_CARD) &&
      currentStep === creditCardStepNumber
    ) {
      const { SURVEY } = routes

      navigate(`${SURVEY.DEFAULT}?lang=${lang}&period=${period}`, {
        state: { transitionedFromRoute: true }
      })
    }
  }, [auth, currentStep, navigate])

  const nextStep = () => {
    setCurrentStep(step => step + 1)
  }

  useEffect(
    () => setLocalStorage(CURRENT_REGISTRATION_STEP_NUMBER, currentStep),
    [currentStep]
  )

  const value = useMemo(
    () => ({
      currentStep,
      previousStep,
      nextStep,
      maxSteps,
      surveySteps,
      getSurveyStep,
      startFromFirstStep
    }),
    [currentStep, maxSteps, previousStep, getSurveyStep, startFromFirstStep]
  )

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
}

StepsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useStepsContext = () => {
  const steps = useContext(StepsContext)

  if (!steps) {
    throw new Error('useStepsContext must be used inside StepsProvider')
  }

  return steps
}
