import { PROD } from '@config/env'
import { getLoginErrorMessage } from '@helpers/errors'
import {
  Button,
  emitTimingToastToggle,
  InputForm,
  ShowPassword,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useStepsContext } from '@pages/Authentication/contexts/steps'
import FormSchema from '@pages/Authentication/routes/Login/FormSchema'
import { REGISTRATION } from '@routes/path'
import { login } from '@services/authentication'
import { emitTimingToast, Spacer } from '@ui-kit'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

import styles from './Login.module.scss'

/**
 * LoginForm - stateful presentational component
 * @param {object} props - props
 * @return {object} An object of children
 */
const LoginForm = () => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigate = useNavigate()
  const { startFromFirstStep } = useStepsContext()
  const { t } = useTranslation()

  /**
   * Handle input type state
   * @type {function}
   */
  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )

  const { mutate } = useSWRConfig()

  /**
   * onSubmit of user data and calls login endpoint
   * @param  {object} values - formik values
   * @type {function}
   */
  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      const { email, password } = values

      let captchaChallenge

      try {
        if (PROD) {
          captchaChallenge = await executeRecaptcha('login')
        } else {
          captchaChallenge = true
        }

        await login({
          email,
          password,
          captchaChallenge
        })

        startFromFirstStep()

        navigate(REGISTRATION.TWO_FACTOR_AUTHENTICATION.DEFAULT)

        mutate('auth')
      } catch (error) {
        emitTimingToastToggle(t(getLoginErrorMessage(error)), 'alert', 99999999)

        if (error?.code !== 'A0010' && error?.code !== 'A0011') {
          setFieldError('email', t(getLoginErrorMessage(error)))
          setFieldError('password', t(getLoginErrorMessage(error)))
        }
      }
    },
    [executeRecaptcha, mutate, t]
  )

  const initialValues = {
    email: '',
    password: ''
  }

  const {
    values,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors
  } = useFormik({
    initialValues,
    validationSchema: FormSchema(),
    onSubmit
  })

  const handleToastWhenIsNotValid = () => {
    if (!isValid) {
      emitTimingToast({
        type: 'error',
        message: t('error.form.is.not.valid')
      })
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Column>
        <InputForm
          className={styles['login-form__input']}
          field={{
            name: 'email',
            value: values.email,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          i18n={{
            placeholder: t('registration.flow.email'),
            label: t('registration.flow.email')
          }}
          form={{
            errors,
            touched
          }}
          id='email'
          autoFocus
        />

        <Spacer space={20} />

        <Row>
          <Column flexGrow='1'>
            <InputForm
              field={{
                name: 'password',
                value: values.password,
                onChange: handleChange,
                onBlur: handleBlur
              }}
              i18n={{
                placeholder: t('registration.flow.password'),
                label: t('registration.flow.password')
              }}
              type={passwordInputType}
              form={{
                errors,
                touched
              }}
              id='password'
            />
          </Column>

          <Spreader spread='tiny' />

          <Column>
            <ShowPassword setHidden={handleInputTypeState} />
          </Column>
        </Row>

        <Button
          type='submit'
          size='large'
          isLoading={isSubmitting}
          onClick={handleToastWhenIsNotValid}
        >
          {t('registration.flow.sign.in')}
        </Button>
      </Column>
    </form>
  )
}

LoginForm.displayName = 'LoginForm'

export default LoginForm
