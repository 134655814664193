import { useEffectOnceConditionally } from '@hooks/useEffectOnceConditionally'
import client from '@lib/http/client'
import { useCallback } from 'react'

interface MixpanelEventRequest {
  name: string
  type?: string
  properties?: object
}

export const mixpanelEvent = ({
  name,
  type,
  properties
}: MixpanelEventRequest) =>
  client.post('frontend-event/mixpanel', {
    name,
    type,
    properties
  })

export const useMixpanelEventOnLoad = (
  { name, type, properties }: MixpanelEventRequest,
  condition: boolean
) => {
  const callback = useCallback(
    () =>
      mixpanelEvent({
        name,
        type,
        properties
      }),
    [name, type, properties]
  )

  useEffectOnceConditionally(callback, condition)
}
