import { LongLogo, ShortLogo } from '@components/Logo'
import { ToggleButton } from '@components/Sidebar/components/ToggleButton'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import AGENCY from '@routes/path/agency'
import LANDINGS from '@routes/path/landings'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './Header.module.scss'

interface HeaderProps {
  sidebarMini: boolean
  isToggleButtonVisible?: boolean
}

export const Header: FC<HeaderProps> = ({
  sidebarMini,
  isToggleButtonVisible,
  ...rest
}) => {
  const { userLogo, isAgency } = useUserContext()

  const hasAccessToAgencyDashboard =
    useFeatureFlags('AGENCY_DASHBOARD') && isAgency

  const hasAnimation = !getLocalStorage('lp-disableAnimation')

  if (hasAnimation) {
    setLocalStorage('lp-disableAnimation', true)
  }

  const landingiLogo = sidebarMini ? (
    <ShortLogo hasAnimation={hasAnimation} />
  ) : (
    <LongLogo hasAnimation={hasAnimation} />
  )

  const logoVariants = {
    mini: { maxWidth: 30, x: 0 },
    normal: { maxWidth: 175, x: 0 }
  }

  const animateAndInitial = sidebarMini ? 'mini' : 'normal'

  const startPage = hasAccessToAgencyDashboard
    ? AGENCY.AGENCY_DASHBOARD
    : LANDINGS.LANDINGS

  return (
    <div className={styles.header}>
      <NavLink to={startPage} {...rest}>
        {userLogo === 'landingi' ? (
          landingiLogo
        ) : (
          <AnimatePresence>
            <motion.img
              className={styles.header__logo}
              src={userLogo || ''}
              variants={logoVariants}
              initial={animateAndInitial}
              animate={animateAndInitial}
              transition={{ duration: 0.5, ease: [0.65, 0, 0.3, 1] }}
            />
          </AnimatePresence>
        )}
      </NavLink>

      {isToggleButtonVisible ? <ToggleButton /> : null}
    </div>
  )
}

Header.displayName = 'SidebarHeader'
