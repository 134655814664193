import styles from './PulsingLogo.module.scss'

/**
 * PulsingLogo - stateless presentational component
 * @param {object} props - props
 */
const PulsingLogo = () => (
  <div className={styles.logo}>
    <svg
      className={styles['loader-circle']}
      width='13px'
      height='13px'
      viewBox='0 0 22 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <circle cx='11' cy='11' r='11' />
    </svg>
    <svg
      className={styles['loader-circle-2']}
      width='10px'
      height='10px'
      viewBox='0 0 22 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <circle cx='11' cy='11' r='11' />
    </svg>
    <svg
      className={styles['loader-triangle']}
      width='20px'
      height='20px'
      viewBox='0 0 40 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <path
        d='M31.673,18.327,6.673,25,0,0Z'
        transform='translate(15.836 39.929) rotate(-120)'
      />
    </svg>

    <svg
      className={styles['loader-triangle-2']}
      width='20px'
      height='20px'
      viewBox='0 0 40 45'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <path
        d='M31.673,18.327,6.673,25,0,0Z'
        transform='translate(15.836 39.929) rotate(-120)'
      />
    </svg>
    <svg
      className={styles['loader-square']}
      width='10px'
      height='10px'
      viewBox='0 0 10 10'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect width='15' height='15' />
    </svg>
    <svg
      className={styles['loader-square-2']}
      width='10px'
      height='10px'
      viewBox='0 0 10 10'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect width='15' height='15' />
    </svg>

    <svg
      className={styles.sygnet}
      version='1.1'
      width='80'
      height='80'
      viewBox='0 0 96 96'
      style={{ enableBackground: 'new 0 0 96 96' }}
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='landingi_logo'
        className={styles.st0}
        d='M24.2,12.1l47.7,47.7L47.7,83.9L0,36.2L24.2,12.1L24.2,12.1z M71.9,12.1L96,36L71.9,59.7
	V12.1L71.9,12.1z'
      />
    </svg>
  </div>
)

PulsingLogo.displayName = 'PulsingLogo'

export default PulsingLogo
