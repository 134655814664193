import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import {
  CustomLink,
  Icon,
  Input,
  Loader,
  Modal,
  PerfectDropdownSelect,
  Spacer,
  Spreader,
  Tooltip
} from '@landingi/landingi-ui-kit'
import { useLimits } from '@services/resourceslimits/useLimits'
import { editSubaccount } from '@services/subaccounts'
import { useFormik } from 'formik'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

interface ModalSubaccountEditProps {
  uuid: string
  name: string
  limit_uuid?: string
}

export const ModalSubaccountEdit = NiceModal.create<ModalSubaccountEditProps>(
  ({ uuid, name, limit_uuid }) => {
    const { t } = useTranslation()

    const { remove } = useModal()

    const hasAccessToLimits = useFeatureFlags('SUBACCOUNT_RESOURCE_LIMITS')

    const emphasisedOptions = [
      {
        value: '',
        label: t('agency.resources.limits.none')
      }
    ]

    const { data: resourceLimitsresponse, isLoading: limitsLoading } =
      useLimits({
        page: 0,
        limit: 100
      })

    let resourcesLimits: { label: string; value: string }[] = []

    if (hasAccessToLimits && resourceLimitsresponse?.collection) {
      resourcesLimits = resourceLimitsresponse.collection.map(
        ({ limit_name, limit_uuid }) => ({
          value: limit_uuid,
          label: limit_name
        })
      )
    }

    const [handleEdit, { isLoading }] = useLazyService(editSubaccount, {
      successToastText: t('subaccount.modal.edit.toast'),
      errorToastText: t('toast.error.something.went.wrong'),
      onSuccess: () => {
        remove()
      }
    })

    const {
      isSubmitting,
      handleSubmit,
      isValid,
      dirty,
      handleChange,
      values,
      setFieldValue
    } = useFormik({
      initialValues: {
        name,
        resourcesLimit:
          resourcesLimits?.find(
            ({ value }: { value: string }) => value === limit_uuid
          )?.value ?? emphasisedOptions[0].value
      },
      validationSchema: toFormikValidationSchema(
        z.object({
          name: z.string(),
          resourcesLimit: z.string().optional()
        })
      ),
      onSubmit: async () => {
        // TODO: add limit
        await handleEdit({ uuid, name, lp_limit: 20 })
      }
    })

    const labelTooltip = (
      <Trans
        i18nKey='agency.resources.limits.label'
        components={{
          tooltip: (
            <span>
              <Tooltip
                content={t('agency.resources.limits.label.tooltip')}
                align='center'
                placement='top'
              >
                <Icon icon='icon-info-circle' size={10} />
              </Tooltip>
            </span>
          )
        }}
      />
    )

    const customContent = !resourcesLimits.length ? (
      <div>
        <Spreader spread='small' />
        {t('agency.resource.limits.empty.message')}

        {' - '}

        <CustomLink
          variant='active'
          label={t('agency.resource.limits.create.first')}
          href='/agency/resource-limits'
        />
      </div>
    ) : null

    return (
      <Modal
        isActive
        onClick={remove}
        i18n={{
          title: t('sidebar.modal.edit.heading'),
          action: t('word.save.changes'),
          cancel: t('word.cancel')
        }}
        onAction={handleSubmit}
        isButtonLoading={isSubmitting || isLoading}
        isButtonDisabled={!dirty || !isValid || isSubmitting}
        hasFooter
      >
        {limitsLoading && hasAccessToLimits ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <Spacer space='tiny' />

            <Row wrap>
              <Column flex='1'>
                <Input
                  name='name'
                  i18n={{
                    placeholder: t('subaccount.modal.name'),
                    label: t('subaccount.modal.name')
                  }}
                  onChange={handleChange}
                  value={values.name}
                />
              </Column>
            </Row>

            {hasAccessToLimits && (
              <Fragment>
                <Spacer space='tiny' />

                <PerfectDropdownSelect
                  overflowStyle={{ maxHeight: 200 }}
                  options={resourcesLimits || []}
                  formikKey='resourcesLimit'
                  onChange={(key, value) => {
                    if (typeof key === 'string') {
                      setFieldValue(key, value)
                    }
                  }}
                  optionalContent={customContent}
                  emphasisedOptions={emphasisedOptions}
                  // @ts-ignore
                  label={labelTooltip}
                  value={values.resourcesLimit}
                  alwaysShowLabel
                />
              </Fragment>
            )}
          </form>
        )}
      </Modal>
    )
  }
)

ModalSubaccountEdit.displayName = 'ModalSubaccountEdit'
