import { useStyles } from '@hooks/useStyles'
import BlackFridayBannerBackground from '@images/dealmate/black_friday_banner_cc_step.png'
import { Badge, Heading, Image, Paragraph, Toggle } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './SalePromo12Banner.module.scss'

interface BlackFridayBannerProps {
  isChecked: boolean
  handleToggle: () => undefined
  currency: string
  discount: number
}

export const BlackFridayBanner: FC<BlackFridayBannerProps> = ({
  isChecked,
  handleToggle,
  currency,
  discount
}) => {
  const { t } = useTranslation()

  const salePromoBannerStyles = useStyles({
    [styles['sale-promo-banner']]: true,
    [styles['sale-promo-banner--checked']]: isChecked
  })

  const toggleLabelStyles = useStyles({
    [styles['sale-promo-banner__toggle']]: true,
    [styles['sale-promo-banner__toggle-unchecked']]: !isChecked
  })

  return (
    <Row className={salePromoBannerStyles}>
      <Toggle
        id='black-friday-toggle'
        checked={isChecked}
        className={toggleLabelStyles}
        onChange={handleToggle}
        color='success'
      />

      <Image
        className={styles['sale-promo-banner__image']}
        src={BlackFridayBannerBackground}
        width='100%'
      />

      <div className={styles['sale-promo-banner__text']}>
        <Heading color='white' level={2} weight={600}>
          {t('black.friday.banner.heading')}
        </Heading>

        <Column alignItems='start'>
          <Badge
            variant='calmviolet-filled'
            weight={600}
            className={styles['sale-promo-banner__badge']}
          >
            {t('annual.save.price', { discount, currency })}
          </Badge>

          <Paragraph size={12} color='white'>
            {t('black.friday.banner.annual.plan.and.gifts')}
          </Paragraph>
        </Column>
      </div>
    </Row>
  )
}
