import { COMPLETED } from '@constants/registrationSteps'
import { REGISTRATION } from '@routes/path'

export const routes = {
  CREDIT_CARD: REGISTRATION.CREDIT_CARD_STEP,
  SURVEY: REGISTRATION.SURVEY
}

/**
 * Returns route depending on currentStep
 * @param {string} currentStep - current step of registration flow
 * @return
 */
export const getRouteBasedOnCurrentStep = currentStep => routes[currentStep]

/**
 * checks if given step is last
 * @param {array} steps - array of steps
 * @param {string} currentStep - current step
 * @returns
 */
export const isLastStep = (steps, currentStep) => {
  const stepsNames = steps.map(({ name }) => name)

  const isLastStep =
    stepsNames[stepsNames.indexOf(currentStep) + 1] === COMPLETED

  return isLastStep
}
