import { Icon } from '@ui-kit'
import { Trans } from 'react-i18next'

export const getServices = () => [
  {
    title: (
      <Trans i18nKey='addons.store.landing.page.design.features.1.title' />
    ),
    description: (
      <Trans i18nKey='addons.store.landing.page.design.features.1.description' />
    ),
    icon: <Icon icon='icon-mobile' color='primary' size={26} />
  },
  {
    title: (
      <Trans i18nKey='addons.store.landing.page.design.features.2.title' />
    ),
    description: (
      <Trans i18nKey='addons.store.landing.page.design.features.2.description' />
    ),
    icon: <Icon icon='icon-web' color='primary' size={26} />
  },
  {
    title: (
      <Trans i18nKey='addons.store.landing.page.design.features.3.title' />
    ),
    description: (
      <Trans i18nKey='addons.store.landing.page.design.features.3.description' />
    ),
    icon: <Icon icon='icon-button' color='primary' size={26} />
  }
]
