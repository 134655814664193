import { List, ListItem } from '@landingi/landingi-ui-kit'
import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={1} />

        <Spreader spread={15} />

        {t('addons.store.landing.page.import.faq.what.requirements.question')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t(
            'addons.store.landing.page.import.faq.what.requirements.answer.paragraph.1'
          )}
        </Paragraph>

        <List listStyle='ordered-disc'>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t(
                'addons.store.landing.page.import.faq.what.requirements.answer.list.1'
              )}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t(
                'addons.store.landing.page.import.faq.what.requirements.answer.list.2'
              )}
            </Paragraph>
          </ListItem>
        </List>
        <Paragraph size={14}>
          {t(
            'addons.store.landing.page.import.faq.what.requirements.answer.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.landing.page.design.question.2')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.landing.page.design.answer.2')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.landing.page.import.faq.what.materials.question')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t('addons.store.landing.page.import.faq.what.materials.answer')}
        </Paragraph>

        <List listStyle='ordered-disc'>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t(
                'addons.store.landing.page.import.faq.what.materials.answer.list.1'
              )}
            </Paragraph>
          </ListItem>
          <ListItem variant='list'>
            <Paragraph size={14}>
              {t(
                'addons.store.landing.page.import.faq.what.materials.answer.list.2'
              )}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t(
                'addons.store.landing.page.import.faq.what.materials.answer.list.3'
              )}
            </Paragraph>
          </ListItem>

          <ListItem variant='list'>
            <Paragraph size={14}>
              {t(
                'addons.store.landing.page.import.faq.what.materials.answer.list.4'
              )}
            </Paragraph>
          </ListItem>
        </List>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        {t('addons.store.landing.page.import.faq.how.long.does.it.question')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.landing.page.import.faq.how.long.does.it.answer.paragraph.1'
          )}
        </Paragraph>
        <Paragraph>
          {t(
            'addons.store.landing.page.import.faq.how.long.does.it.answer.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  }
]
