import { useUserContext } from '@contexts/user'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export type SubaccountResponse = {
  id: number
  uuid: string
  name?: string
  email?: string
  isActive?: boolean
  isMain: boolean
  isDisabled?: boolean
  limitUuid?: string
}

export const useSubaccountsIfPossible = () => {
  const { isTypeOwner, isTypeAdmin, isSubaccount } = useUserContext()

  const { data, isLoading, error, mutate } = useSWR<
    SubaccountResponse[],
    AxiosError
  >(isTypeOwner || (isTypeAdmin && isSubaccount) ? 'subaccounts' : null, {
    revalidateOnMount: true,
    onErrorRetry: error => {
      if (error.status === 403) return
    }
  })

  return {
    data,
    isLoading,
    error,
    mutate
  }
}

export const useSubaccounts = () => {
  const { data, isLoading, error, mutate } = useSWR<
    SubaccountResponse[],
    AxiosError
  >('subaccounts', {
    revalidateOnMount: true
  })

  return {
    data,
    isLoading,
    error,
    mutate
  }
}
