import { useLazyService } from '@hooks/useLazyService'
import {
  AB_TESTS_TYPE_AI,
  STATUS_DONE,
  STATUS_NONE,
  STATUS_PENDING,
  STATUS_UNDEFINED
} from '@pages/Landings/routes/Dashboard/routes/Optimization/ABTests/AIVariant/constants'
import { AB_TESTS_TYPE_MAIN } from '@pages/Landings/routes/Dashboard/routes/Optimization/constants'
import {
  changeVariantParam,
  createAIVariant
} from '@services/landings/dashboard/abTests/actions'
import {
  useGetVariantList,
  Variant
} from '@services/landings/dashboard/abTests/useGetVariantList'
import {
  GetLandingResponse,
  LandingData,
  useGetLanding
} from '@services/landings/useGetLanding'
import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const useDashboardState = () => {
  const { t } = useTranslation()
  const { identifier } = useParams()

  // the current variant hash, set when the variant's traffic changes
  const [changedVariantHash, setChangedVariantHash] = useState('')

  const {
    data: landing,
    isLoading: isLoadingLanding,
    mutate: mutateLanding,
    error: errorLanding
  } = useGetLanding({ uuid: identifier })

  const setNewLandingData = (newData: Partial<LandingData>) => {
    if (!landing) {
      return
    }

    mutateLanding(
      {
        ...landing,
        data: {
          ...landing.data,
          ...newData
        }
      },
      {
        revalidate: false
      }
    )
  }

  const [changeVariantParamRequest, { isLoading: isLoadingChangeParam }] =
    useLazyService(changeVariantParam, {
      errorToastText: t('error.page.generic.title')
    })

  // AI VARIANT
  // _____________________________________________
  const getAIVariantData = (data: Variant[]) => {
    const aiVariants: Array<{ type: string; createdAt: string }> = []

    data.map((item: Variant) => {
      const { type } = item

      if (
        // @ts-ignore
        type.includes(AB_TESTS_TYPE_AI) &&
        !type.includes(AB_TESTS_TYPE_MAIN)
      ) {
        // @ts-ignore
        aiVariants.push(item)
      }
    })

    const aiVariantsSorted = aiVariants?.sort(
      (a: { createdAt: string }, b: { createdAt: string }) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )

    return aiVariantsSorted.length ? aiVariantsSorted[0] : {}
  }

  const [aiVariantData, setAiVariantData] = useState<Variant[]>([])

  const aiVariantExist = aiVariantData.some(
    (item: Variant) =>
      // @ts-ignore
      item.type.includes(AB_TESTS_TYPE_AI) &&
      !item.type.includes(AB_TESTS_TYPE_MAIN)
  )

  const [aiVariantStatus, setAiVariantStatus] = useState<
    | typeof STATUS_UNDEFINED
    | typeof STATUS_NONE
    | typeof STATUS_DONE
    | typeof STATUS_PENDING
  >(STATUS_UNDEFINED)

  const landingIdentifier = landing?.data.identifier

  const { mutate: mutateVariants } = useGetVariantList({
    // @ts-ignore
    identifier: landingIdentifier
  })

  const [handleCreateAIVariant] = useLazyService(createAIVariant, {
    onSuccess: () => {
      mutateVariants()
    },
    onError: () => setAiVariantStatus(STATUS_NONE),
    successToastText: t('ai.variant.panel.create.success'),
    errorToastText: t('toast.error.something.went.wrong')
  })

  const handleClickOnCreateAIVariant = () => {
    setAiVariantStatus(STATUS_PENDING)

    // @ts-ignore
    handleCreateAIVariant(landingIdentifier)
  }
  // _____________________________________________

  return {
    isLoading: isLoadingLanding,
    landing: landing?.data as GetLandingResponse['data'],
    errorLanding,
    setNewLandingData,
    changeVariantParamRequest,
    isLoadingChangeParam,
    changedVariantHash,
    setChangedVariantHash,

    // AI VARIANT
    // _____________________________________________
    aiVariantStatus,
    setAiVariantStatus,
    aiVariantData: getAIVariantData(aiVariantData),
    setAiVariantData,
    aiVariantExist,
    handleClickOnCreateAIVariant
    // _____________________________________________
  }
}

type DashboardState = ReturnType<typeof useDashboardState>

const DashboardContext = createContext<DashboardState | null>(null)

interface DashboardProviderProps {
  children: ReactNode
}

export const DashboardProvider: FC<DashboardProviderProps> = ({ children }) => {
  const value = useDashboardState()

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => {
  const dashboard = useContext(DashboardContext)

  if (!dashboard) {
    throw new Error('useDashboardContext must be used inside DashboardProvider')
  }

  return dashboard
}
