import { Icon } from '@ui-kit/Icon'
import { ButtonHTMLAttributes, FC, Fragment, ReactNode } from 'react'
import Ink from 'react-ink'

import styles from './Button.module.scss'

export interface ButtonContentProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  children?: ReactNode
  isLoading?: boolean
  icon?: string
  hasBackgroundRipple?: boolean
}

export const ButtonContent: FC<ButtonContentProps> = ({
  children,
  isLoading = false,
  icon,
  hasBackgroundRipple = true
}) => {
  return (
    <Fragment>
      {isLoading ? (
        <Icon icon='icon-spinner' spin data-testid='loaderIcon' />
      ) : (
        <Fragment>
          {icon && (
            <Icon
              className={styles.button__icon}
              icon={icon}
              data-testid='icon'
            />
          )}

          {children && children}
        </Fragment>
      )}

      {hasBackgroundRipple && <Ink />}
    </Fragment>
  )
}

ButtonContent.displayName = 'Button'
