import client from '@lib/http/client'

export interface ChangeVariantParamRequest {
  identifier: string
  variantHash: string
  name?: string
  traffic?: string
}

export const changeVariantParam = ({
  identifier,
  variantHash,
  name,
  traffic
}: ChangeVariantParamRequest) =>
  client.patch(`landing-page/landings/${identifier}/variants/${variantHash}`, {
    ...(name && { name }),
    ...(traffic && { traffic })
  })

interface StartABTestsRequest {
  identifier: string
}

export const startABTests = ({ identifier }: StartABTestsRequest) =>
  client.post(`landing-page/landings/${identifier}/ab-test`)

interface StopABTestsRequest {
  identifier: string
}

export const stopABTests = ({ identifier }: StopABTestsRequest) =>
  client.patch(`landing-page/landings/${identifier}/stop-ab-test`)

interface SetNewMasterVariantRequest {
  identifier: string
  variantHash: string
}

export const setNewMasterVariant = ({
  identifier,
  variantHash
}: SetNewMasterVariantRequest) =>
  client.patch(`landing-page/landings/${identifier}/set-new-master`, {
    variantHash
  })

interface DuplicateVariantRequest {
  identifier: string
  variantHash: string
}

export const duplicateVariant = ({
  identifier,
  variantHash
}: DuplicateVariantRequest) =>
  client.post(`landing-page/landings/${identifier}/variants`, {
    sourceHash: variantHash
  })

export const duplicateVariantDev = ({
  identifier,
  variantHash
}: DuplicateVariantRequest) =>
  client.post(
    `landing-page/landings/${identifier}/variants`,
    {
      sourceHash: variantHash
    },
    {
      timeout: 60000
    }
  )

interface DuplicateLandingPageRequest {
  identifier: string
  variantHash: string
}

export const duplicateLandingPage = ({
  identifier,
  variantHash
}: DuplicateLandingPageRequest) =>
  client.post(`landing-page/landings`, {
    landingUuid: identifier,
    sourceHash: variantHash
  })

interface DeleteVariantRequest {
  identifier: string
  variantHash: string
}

export const deleteVariant = ({
  identifier,
  variantHash
}: DeleteVariantRequest) =>
  client.delete(`landing-page/landings/${identifier}/variants/${variantHash}`)

// AI VARIANT
// _____________________________________________
export const createAIVariant = (landingUuid: string) =>
  client.post(`landing-page/landings/${landingUuid}/variants/create-ai-variant`)
// _____________________________________________
