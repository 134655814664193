import { NEW_LANDINGI_URL } from '@config/env'

export const SUBMITTED = 'SUBMITTED'
export const NONE = 'NONE'
export const LOADING = 'LOADING'
export const EMPTY = 'EMPTY'
export const DATA = 'DATA'
export const EMPTY_VALUE = ''

export const NEW_LANDINGI_APP_URL = `https://${NEW_LANDINGI_URL}`
