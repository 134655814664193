import type { ComponentType } from 'react'
import * as React from 'react'

const importErrorHandler = (
  err: string
): {
  default: ComponentType<any>
} => {
  // Get the last reload time from local storage and the current time
  const timeStr = sessionStorage.getItem('last-reload')
  const attempts = Number(sessionStorage.getItem('reload-attempts') || 0)
  const time = timeStr ? Number(timeStr) : null
  const now = Date.now()

  // If the last reload time is more than 10 seconds ago, reload the page
  const isReloading = !time || time + 10_000 < now

  // Or last reload was more that 10 seconds, but it was less than 3 attempts
  const isReloadingWithAttemptsLimit = attempts < 3

  if (isReloading || isReloadingWithAttemptsLimit) {
    sessionStorage.setItem('last-reload', String(now))

    if (!isReloading) {
      sessionStorage.setItem('reload-attempts', String(attempts + 1))
    } else {
      // Reset the reload attempts, when the last reload was more than 10 seconds ago
      sessionStorage.setItem('reload-attempts', '0')
    }

    window.location.reload()
    return { default: () => null }
  }

  // We let ErrorBoundary handle the error
  throw new Error(err)
}

export const lazy = (
  factory: () => Promise<{ default: ComponentType<any> }>
) => {
  return React.lazy(() => factory().catch(importErrorHandler))
}
