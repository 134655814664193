import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Modal, Spacer } from '@landingi/landingi-ui-kit'
import { useTranslation } from 'react-i18next'

import { ModalLandingDuplicateForm } from './ModalLandingDuplicateForm'

interface ModalLandingDuplicateProps {
  name: string
  uuid: string
  resetFilterAndSorter: () => void
}

export const ModalLandingDuplicate =
  NiceModal.create<ModalLandingDuplicateProps>(
    ({ name, uuid, resetFilterAndSorter }) => {
      const { t } = useTranslation()

      const { remove } = useModal()

      return (
        <Modal
          isActive
          onClick={remove}
          i18n={{
            title: t('modal.landing.duplicate.title')
          }}
        >
          <Spacer space='small' />

          <ModalLandingDuplicateForm
            name={name}
            uuid={uuid}
            resetFilterAndSorter={resetFilterAndSorter}
          />
        </Modal>
      )
    }
  )

ModalLandingDuplicate.displayName = 'ModalLandingDuplicate'
