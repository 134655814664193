import { useAcl } from '@contexts/acl'
import { NotFound } from '@pages/NotFound/routes/NotFound'
import { FC, ReactElement } from 'react'

interface AclProps {
  acl: string
  children: ReactElement
}

export const Acl: FC<AclProps> = ({ acl, children }) => {
  const hasAccess = useAcl(acl)

  return hasAccess ? children : <NotFound />
}

Acl.displayName = 'Acl'
