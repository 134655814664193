import { useLazyService } from '@hooks/useLazyService'
import { useStyles } from '@hooks/useStyles'
import { Notification } from '@landingi/landingi-ui-kit'
import {
  closeMessageNewLandingi,
  closeSupportMessage
} from '@services/settings/closeSupportMessage'
import { useGetSupportMessage } from '@services/settings/useGetSupportMessage'
import { FC } from 'react'

import styles from './Message.module.scss'

interface MessageProps {
  identifier: number
  message: string
  category: 'error' | 'info'
}

export const Message: FC<MessageProps> = ({
  message,
  category,
  identifier
}) => {
  const messageStyles = useStyles({
    [styles.message]: true,
    [styles[`message--${category}`]]: true
  })

  const { mutate } = useGetSupportMessage()

  const [closeSupportMessageRequest] = useLazyService(closeSupportMessage)

  const [closeSupportMessageNewLandingiRequest] = useLazyService(
    closeMessageNewLandingi
  )

  const handleCloseSupportMessage = async () => {
    mutate(undefined, { revalidate: false })

    await closeSupportMessageRequest({ identifier })
    await closeSupportMessageNewLandingiRequest({ identifier })
  }

  const notificationType = {
    error: 'alert',
    info: 'info'
  } as const

  return (
    <Notification
      className={messageStyles}
      type={notificationType[category]}
      onClick={handleCloseSupportMessage}
      isClosable
    >
      <div
        className={styles.message__format}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Notification>
  )
}
