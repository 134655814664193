import { FC, HTMLAttributes, ReactNode } from 'react'

import type { TabsProviderProps } from './context'
import { TabsProvider } from './context'

export interface TabsProps
  extends HTMLAttributes<HTMLDivElement>,
    TabsProviderProps {
  children: ReactNode
}

export const Tabs: FC<TabsProps> = ({
  children,
  initialTab,
  isScrollable,
  scrollOffset,
  ...restProps
}) => (
  <TabsProvider
    initialTab={initialTab}
    isScrollable={isScrollable}
    scrollOffset={scrollOffset}
  >
    <div {...restProps}>{children}</div>
  </TabsProvider>
)

Tabs.displayName = 'Tabs'
