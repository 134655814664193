import { NEW_LANDINGI_URL } from '@config/env'
import { ANNUALLY, MONTHLY } from '@constants/billingPeriods'
import { useUserContext } from '@contexts/user'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { getPrice } from '@helpers/getPrice'
import { useLazyService } from '@hooks/useLazyService'
import BlueShield from '@images/topbar/blue-shield.png'
import IconInfo from '@images/topbar/info-icon.png'
import { storePricingAgreement } from '@services/pricing/storePricingAgreement'
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  emitTimingToast,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  Panel,
  Paragraph,
  Spacer,
  Spreader
} from '@ui-kit'
import { Fragment, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'
import { mutate } from 'swr'

export const ModalPricingUpdate = NiceModal.create(() => {
  const { t } = useTranslation()
  const { remove } = useModal()

  const { hasSubscription, accountCurrency, billingPeriod } = useUserContext()

  const savedMoney = {
    USD: 338,
    PLN: 1118,
    BRL: 1598,
    EUR: 338,
    GBP: 258
  }[accountCurrency]

  const newPrice = {
    1: {
      USD: 69,
      PLN: 249,
      BRL: 299,
      EUR: 69,
      GBP: 59
    },
    12: {
      USD: 57,
      PLN: 199,
      BRL: 249,
      EUR: 57,
      GBP: 49
    }
  }[billingPeriod || MONTHLY][accountCurrency]

  const newPriceAnnuallyInAdvance = {
    USD: 684,
    EUR: 684,
    GBP: 588,
    BRL: 2988,
    PLN: 2388
  }[accountCurrency]

  const { priceNetWithCurrency } = getPrice({
    price: newPrice,
    accountCurrency
  })

  const { priceNetWithCurrency: newPriceNetAnnuallyInAdvance } = getPrice({
    price: newPriceAnnuallyInAdvance,
    accountCurrency
  })

  const {
    priceNetWithCurrency: savedMoneyWithCurrency,
    priceNet: savedMoneyNet
  } = getPrice({
    price: savedMoney,
    accountCurrency
  })

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [handleSubmitAgreement] = useLazyService(storePricingAgreement, {
    onSuccess: remove
  })

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      await handleSubmitAgreement()

      await mutate('payments/pricing/info')
    } catch {
      emitTimingToast({ type: 'error', message: 'error.please.try.again' })
    } finally {
      remove()

      setIsLoading(false)
    }
  }

  const openCalendly = useCallback(
    () => window.open(t('modal.pricing.update.contact.us.link'), '_blank'),
    [t]
  )

  const openPayments = useCallback(
    () => window.open(`https://${NEW_LANDINGI_URL}/payments`, '_self'),
    []
  )

  const annuallyText =
    billingPeriod === ANNUALLY && hasSubscription
      ? ` (${newPriceNetAnnuallyInAdvance} ${t('pricing.update.alert.yearly.in.advance')})`
      : ''

  const panelButton = hasSubscription ? (
    <Button variant='text-primary' size={12} onClick={openCalendly}>
      {t('modals.pricing.update.check.annual.offer')}
    </Button>
  ) : (
    <Button variant='text-primary' size={12} onClick={openPayments}>
      {t('modals.pricing.update.buy.now')}
    </Button>
  )

  const isPanelVisible =
    (billingPeriod !== ANNUALLY && hasSubscription) || !hasSubscription

  return (
    <Modal onClose={remove}>
      <ModalHeader
        heading={
          <Row justifyContent='center'>
            <Image src={IconInfo} width='28px' height='28px' />
            <Spreader spread={10} />
            {t('modals.pricing.update.title')}
          </Row>
        }
      />
      <ModalBody>
        <Paragraph size={16}>
          <Trans i18nKey='modals.pricing.update.description.1st' />
        </Paragraph>

        <Paragraph size={16}>
          <Trans i18nKey='modals.pricing.update.description.2nd' />
        </Paragraph>

        <Spacer space={15} />

        <Alert variant='info'>
          <Trans
            i18nKey='modals.pricing.update.alert'
            values={{
              price: priceNetWithCurrency,
              annually: annuallyText
            }}
          />
        </Alert>

        <Spacer space={15} />

        {isPanelVisible ? (
          <Fragment>
            <Panel border='primary' surface={0} padding={15}>
              <Column justifyContent='space-between'>
                <Row alignItems='center'>
                  <Image src={BlueShield} width='22px' height='22px' />

                  <Spreader spread={10} />

                  <Heading level={5}>
                    {t('modals.pricing.update.panel.title')}
                  </Heading>

                  <Spreader spread={10} />

                  <Badge variant='warning-border' weight={600}>
                    {t('modals.pricing.update.panel.save')} {savedMoneyNet}{' '}
                    {accountCurrency}
                  </Badge>
                </Row>

                <Spacer space={5} />

                <Paragraph size={12}>
                  <Trans
                    i18nKey='modals.pricing.update.panel.description'
                    values={{ price: savedMoneyWithCurrency }}
                  />
                </Paragraph>

                <Row justifyContent='end'>{panelButton}</Row>
              </Column>
            </Panel>

            <Spacer space={15} />
          </Fragment>
        ) : null}

        <Checkbox
          id='modal-pricing-update-checkbox'
          i18n={{ label: t('modals.pricing.update.checkbox.label') }}
          checked={isCheckboxChecked}
          onChange={() => setIsCheckboxChecked(prev => !prev)}
        />

        <Spacer space={30} />

        <Row justifyContent='space-between'>
          <Button variant='text-primary' onClick={openCalendly}>
            {t('modals.pricing.update.button.contact.us')}
          </Button>

          <Button
            disabled={!isCheckboxChecked || isLoading}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            {t('button.ok.i.got.it')}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  )
})
