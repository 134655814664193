import { useStyles } from '@hooks/useStyles'
import { FC } from 'react'

import styles from './Indicator.module.scss'

interface IndicatorProps {
  className?: string | string[]
  variant?: 'almostcyan' | 'calmviolet' | 'success' | 'warning' | 'error'
  'data-testid'?: string
}

export const Indicator: FC<IndicatorProps> = ({
  className = '',
  variant = 'error',
  'data-testid': dataTestId = 'indicator'
}) => {
  const indicatorStyles = useStyles(
    {
      [styles.indicator]: true,
      [styles[`indicator--${variant}`]]: true
    },
    className
  )

  return <span data-testid={dataTestId} className={indicatorStyles} />
}

Indicator.displayName = 'Indicator'
