import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import { t } from 'i18next'
import { object, string } from 'yup'

const FormSchema = () =>
  object().shape({
    password: string()
      .test('contain lowercase', ' ', value => hasLowerCase(value))
      .test('contain uppercase', ' ', value => hasUpperCase(value))
      .test('contain number', ' ', value => hasNumber(value))
      .min(10, ' ')
      .required(t('form.validation.isRequired'))
  })

export default FormSchema
