import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import { FullLayout } from '@layouts/FullLayout'
import ADDONS_STORE from '@routes/path/addons'

import { AddonsStoreProvider } from './Addons/contexts'

const Addons = lazy(() =>
  import('@pages/AddonsStore/routes/Addons').then(({ Addons }) => ({
    default: Addons
  }))
)

const Addon = lazy(() =>
  import('@pages/AddonsStore/routes/Addons/routes/Addon').then(({ Addon }) => ({
    default: Addon
  }))
)

export const routes = [
  {
    path: ADDONS_STORE.ADDONS_STORE,
    element: (
      <Acl acl='payments.add_on_store.view_page'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: ADDONS_STORE.ADDONS_STORE,
        element: (
          <AddonsStoreProvider>
            <Addons />
          </AddonsStoreProvider>
        )
      }
    ]
  },
  {
    path: ADDONS_STORE.ADDON,
    element: (
      <Acl acl='payments.product_add_on.view_page'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: ADDONS_STORE.ADDON,
        element: (
          <AddonsStoreProvider>
            <Addon />
          </AddonsStoreProvider>
        )
      }
    ]
  }
]
