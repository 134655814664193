import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import CleanLayout from '@layouts/Clean/Clean'
import { PRICING } from '@routes/path'

const AccountPackage = lazy(() =>
  import('@pages/Pricing/routes/AccountPackage').then(({ AccountPackage }) => ({
    default: AccountPackage
  }))
)

export const routes = [
  {
    path: PRICING.PRICING,
    element: <CleanLayout />,
    children: [
      {
        path: PRICING.PRICING,
        element: (
          <Acl acl='plan.view_change_plan'>
            <AccountPackage />
          </Acl>
        )
      }
    ]
  }
]
