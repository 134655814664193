import {
  COMPANY_PRESENTATION,
  CONTEST_SIGNUP,
  EVENT_SIGNUP,
  LpGoal,
  MOBILE_APP_PROMOTION,
  NEWSLETTER_SIGNUP,
  OFFER_DOWNLOAD,
  PRODUCT_SALE,
  RECRUITMENT,
  RESOURCE_DOWNLOAD,
  SAAS_REGISTRATION,
  SCHEDULE_MEETING,
  SERVICE_SALE,
  WAITLIST,
  WEBINAR_SIGNUP
} from '../constants/lpGoals'
import {
  DARK_HERO_1,
  DARK_HERO_2,
  DARK_HERO_3,
  DARK_HERO_4,
  DARK_HERO_5,
  DARK_HERO_6,
  DARK_HERO_7,
  DARK_HERO_8,
  DARK_HERO_9,
  DARK_HERO_10,
  DARK_HERO_11,
  DARK_HERO_12,
  DARK_HERO_13,
  DARK_HERO_14,
  DARK_HERO_15,
  DARK_HERO_16,
  DARK_HERO_17,
  DARK_HERO_18,
  DARK_HERO_19,
  DARK_HERO_20,
  DARK_HERO_21,
  DARK_HERO_22
} from './sections/dark'
import {
  ELEGANT_HERO_1,
  ELEGANT_HERO_2,
  ELEGANT_HERO_3,
  ELEGANT_HERO_4,
  ELEGANT_HERO_5,
  ELEGANT_HERO_6,
  ELEGANT_HERO_7,
  ELEGANT_HERO_8,
  ELEGANT_HERO_9,
  ELEGANT_HERO_10,
  ELEGANT_HERO_11,
  ELEGANT_HERO_12,
  ELEGANT_HERO_13,
  ELEGANT_HERO_14,
  ELEGANT_HERO_15,
  ELEGANT_HERO_16,
  ELEGANT_HERO_17,
  ELEGANT_HERO_18,
  ELEGANT_HERO_19,
  ELEGANT_HERO_20,
  ELEGANT_HERO_21,
  ELEGANT_HERO_22,
  ELEGANT_HERO_23
} from './sections/elegant'
import {
  MINIMAL_HERO_1,
  MINIMAL_HERO_2,
  MINIMAL_HERO_3,
  MINIMAL_HERO_4,
  MINIMAL_HERO_5,
  MINIMAL_HERO_6,
  MINIMAL_HERO_7,
  MINIMAL_HERO_8,
  MINIMAL_HERO_9,
  MINIMAL_HERO_10,
  MINIMAL_HERO_11,
  MINIMAL_HERO_12,
  MINIMAL_HERO_13,
  MINIMAL_HERO_14,
  MINIMAL_HERO_15,
  MINIMAL_HERO_16,
  MINIMAL_HERO_17,
  MINIMAL_HERO_18,
  MINIMAL_HERO_19,
  MINIMAL_HERO_20,
  MINIMAL_HERO_21,
  MINIMAL_HERO_22,
  MINIMAL_HERO_23,
  MINIMAL_HERO_24
} from './sections/minimal'
import {
  MODERN_HERO_1,
  MODERN_HERO_2,
  MODERN_HERO_3,
  MODERN_HERO_4,
  MODERN_HERO_5,
  MODERN_HERO_6,
  MODERN_HERO_7,
  MODERN_HERO_8,
  MODERN_HERO_9,
  MODERN_HERO_10,
  MODERN_HERO_11,
  MODERN_HERO_12,
  MODERN_HERO_13,
  MODERN_HERO_14,
  MODERN_HERO_15
} from './sections/modern'
import { HeroSectionStyles, SectionType } from './types'

const heroSectionStyles: HeroSectionStyles = {
  modern: {
    [COMPANY_PRESENTATION]: [MODERN_HERO_12, MODERN_HERO_13],
    [CONTEST_SIGNUP]: [MODERN_HERO_1, MODERN_HERO_2],
    [EVENT_SIGNUP]: [MODERN_HERO_8, MODERN_HERO_9],
    [MOBILE_APP_PROMOTION]: [MODERN_HERO_11, MODERN_HERO_14, MODERN_HERO_15],
    [NEWSLETTER_SIGNUP]: [MODERN_HERO_1, MODERN_HERO_2],
    [OFFER_DOWNLOAD]: [MODERN_HERO_6, MODERN_HERO_7],
    [PRODUCT_SALE]: [MODERN_HERO_12, MODERN_HERO_13],
    [RECRUITMENT]: [MODERN_HERO_12, MODERN_HERO_13],
    [RESOURCE_DOWNLOAD]: [MODERN_HERO_4],
    [SAAS_REGISTRATION]: [MODERN_HERO_5, MODERN_HERO_10],
    [SCHEDULE_MEETING]: [MODERN_HERO_3],
    [SERVICE_SALE]: [MODERN_HERO_12, MODERN_HERO_13],
    [WAITLIST]: [MODERN_HERO_1, MODERN_HERO_2],
    [WEBINAR_SIGNUP]: [MODERN_HERO_8, MODERN_HERO_9]
  },
  elegant: {
    [COMPANY_PRESENTATION]: [ELEGANT_HERO_7, ELEGANT_HERO_8, ELEGANT_HERO_9],
    [CONTEST_SIGNUP]: [ELEGANT_HERO_1, ELEGANT_HERO_2, ELEGANT_HERO_3],
    [EVENT_SIGNUP]: [ELEGANT_HERO_16, ELEGANT_HERO_17, ELEGANT_HERO_18],
    [MOBILE_APP_PROMOTION]: [ELEGANT_HERO_10, ELEGANT_HERO_11, ELEGANT_HERO_12],
    [NEWSLETTER_SIGNUP]: [ELEGANT_HERO_1, ELEGANT_HERO_2, ELEGANT_HERO_3],
    [OFFER_DOWNLOAD]: [ELEGANT_HERO_19, ELEGANT_HERO_20],
    [PRODUCT_SALE]: [ELEGANT_HERO_7, ELEGANT_HERO_8, ELEGANT_HERO_9],
    [RECRUITMENT]: [ELEGANT_HERO_7, ELEGANT_HERO_8, ELEGANT_HERO_9],
    [RESOURCE_DOWNLOAD]: [ELEGANT_HERO_21, ELEGANT_HERO_22, ELEGANT_HERO_23],
    [SAAS_REGISTRATION]: [ELEGANT_HERO_13, ELEGANT_HERO_14, ELEGANT_HERO_15],
    [SCHEDULE_MEETING]: [ELEGANT_HERO_4, ELEGANT_HERO_5, ELEGANT_HERO_6],
    [SERVICE_SALE]: [ELEGANT_HERO_4, ELEGANT_HERO_5, ELEGANT_HERO_6],
    [WAITLIST]: [ELEGANT_HERO_1, ELEGANT_HERO_2, ELEGANT_HERO_3],
    [WEBINAR_SIGNUP]: [ELEGANT_HERO_16, ELEGANT_HERO_17, ELEGANT_HERO_18]
  },
  dark: {
    [COMPANY_PRESENTATION]: [DARK_HERO_2, DARK_HERO_14, DARK_HERO_15],
    [CONTEST_SIGNUP]: [DARK_HERO_2, DARK_HERO_11, DARK_HERO_13, DARK_HERO_15],
    [EVENT_SIGNUP]: [DARK_HERO_16, DARK_HERO_17, DARK_HERO_18],
    [MOBILE_APP_PROMOTION]: [DARK_HERO_3, DARK_HERO_4],
    [NEWSLETTER_SIGNUP]: [DARK_HERO_11, DARK_HERO_12, DARK_HERO_13],
    [OFFER_DOWNLOAD]: [DARK_HERO_9, DARK_HERO_13],
    [PRODUCT_SALE]: [DARK_HERO_2, DARK_HERO_9, DARK_HERO_14, DARK_HERO_15],
    [RECRUITMENT]: [DARK_HERO_19, DARK_HERO_21],
    [RESOURCE_DOWNLOAD]: [DARK_HERO_5, DARK_HERO_6, DARK_HERO_7, DARK_HERO_8],
    [SAAS_REGISTRATION]: [DARK_HERO_1, DARK_HERO_14, DARK_HERO_15],
    [SCHEDULE_MEETING]: [DARK_HERO_20, DARK_HERO_21],
    [SERVICE_SALE]: [DARK_HERO_2, DARK_HERO_10, DARK_HERO_14],
    [WAITLIST]: [DARK_HERO_17, DARK_HERO_18],
    [WEBINAR_SIGNUP]: [
      DARK_HERO_16,
      DARK_HERO_17,
      DARK_HERO_18,
      DARK_HERO_19,
      DARK_HERO_22
    ]
  },
  minimal: {
    [COMPANY_PRESENTATION]: [MINIMAL_HERO_1, MINIMAL_HERO_2, MINIMAL_HERO_3],
    [CONTEST_SIGNUP]: [MINIMAL_HERO_4, MINIMAL_HERO_5, MINIMAL_HERO_6],
    [EVENT_SIGNUP]: [MINIMAL_HERO_16, MINIMAL_HERO_17, MINIMAL_HERO_18],
    [MOBILE_APP_PROMOTION]: [MINIMAL_HERO_10, MINIMAL_HERO_11, MINIMAL_HERO_12],
    [NEWSLETTER_SIGNUP]: [MINIMAL_HERO_4, MINIMAL_HERO_5, MINIMAL_HERO_6],
    [OFFER_DOWNLOAD]: [MINIMAL_HERO_19, MINIMAL_HERO_20, MINIMAL_HERO_21],
    [PRODUCT_SALE]: [MINIMAL_HERO_1, MINIMAL_HERO_2, MINIMAL_HERO_3],
    [RECRUITMENT]: [MINIMAL_HERO_1, MINIMAL_HERO_2, MINIMAL_HERO_3],
    [RESOURCE_DOWNLOAD]: [MINIMAL_HERO_22, MINIMAL_HERO_23, MINIMAL_HERO_24],
    [SAAS_REGISTRATION]: [MINIMAL_HERO_13, MINIMAL_HERO_14, MINIMAL_HERO_15],
    [SCHEDULE_MEETING]: [MINIMAL_HERO_7, MINIMAL_HERO_8, MINIMAL_HERO_9],
    [SERVICE_SALE]: [MINIMAL_HERO_7, MINIMAL_HERO_8, MINIMAL_HERO_9],
    [WAITLIST]: [MINIMAL_HERO_4, MINIMAL_HERO_5, MINIMAL_HERO_6],
    [WEBINAR_SIGNUP]: [MINIMAL_HERO_16, MINIMAL_HERO_17, MINIMAL_HERO_18]
  }
} as const

export const isSectionForGoal = (
  style: 'modern' | 'dark' | 'elegant' | 'minimal',
  goal: LpGoal,
  previousSection?: SectionType
) => {
  const lpGoal = goal

  const goalSections = heroSectionStyles[style][lpGoal]

  return (
    previousSection &&
    (goalSections as readonly SectionType[])
      .map(section => section.name)
      .includes(previousSection.name)
  )
}

export const randomizeHeroSection = (
  style: 'modern' | 'dark' | 'elegant' | 'minimal',
  goal: LpGoal
) => {
  const lpGoal = goal

  const goalSections = heroSectionStyles[style][lpGoal]

  const randomIndex = Math.floor(Math.random() * goalSections.length)

  const sectionId = goalSections[randomIndex]

  return sectionId
}
