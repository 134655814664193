import { CurrencyType, PLN } from '@constants/currencies'
import {
  PROMOTION_3M_PLANS,
  promotion3MDiscountedPricesBrutto
} from '@constants/promotions'
import {
  convertPriceToNetto,
  splitPriceByFloatingPoint
} from '@helpers/payment'
import { Paragraph } from '@landingi/landingi-ui-kit'
import { useAccountPackageContext } from '@pages/Pricing/routes/AccountPackage/contexts'
import { Badge } from '@ui-kit/Badge'
import { Price as PriceComponent } from '@ui-kit/Price'
import { PriceDescription } from '@ui-kit/Price/PriceDescription'
import { Spacer } from '@ui-kit/Spacer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './Price.module.scss'

interface PriceProps {
  amount: number
  currency: CurrencyType
  canBePaidAnnually?: boolean
  recordKey: (typeof PROMOTION_3M_PLANS)[number]
}

export const Promotion3MPrice: FC<PriceProps> = ({
  amount,
  currency,
  canBePaidAnnually = true,
  recordKey
}) => {
  const { accountCurrency } = useAccountPackageContext()
  const { t } = useTranslation()

  const splittedPrice = splitPriceByFloatingPoint(amount)

  const discountedPrice =
    accountCurrency === PLN
      ? convertPriceToNetto(
          promotion3MDiscountedPricesBrutto[recordKey][accountCurrency]
        )
      : promotion3MDiscountedPricesBrutto[recordKey][accountCurrency]

  return (
    <Column>
      <PriceComponent amount={discountedPrice} currency={currency}>
        <PriceDescription>
          {accountCurrency === PLN && t('change.plan.page.net')}{' '}
          {canBePaidAnnually && t('change.plan.page.per.month')}
        </PriceDescription>
      </PriceComponent>

      <Row className={styles['price-container__row']} wrap>
        <Paragraph
          size={14}
          padding='none'
          color='accent-2'
          decoration='line-through'
        >
          {splittedPrice.decimalPoint}
          {splittedPrice.floatingPoint &&
            `,${splittedPrice.floatingPoint}`}{' '}
          {accountCurrency}{' '}
          {accountCurrency === PLN && t('change.plan.page.net')}{' '}
        </Paragraph>

        <Badge variant='warning-border' weight={600}>
          {t('promotion3M.50percent.x.3months')}
        </Badge>
      </Row>
      <Spacer space={10} />
    </Column>
  )
}

Promotion3MPrice.displayName = 'Promotion3MPrice'
