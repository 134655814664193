import { WizardSupportedResolutions } from '@constants/wizardRatioMap'

export const extractResolution = (url: string): WizardSupportedResolutions => {
  const regex = /_(\d+x\d+)_/
  const match = url.match(regex)

  if (!match?.[1]) {
    throw new Error('Resolution not found in url')
  }

  return match[1] as WizardSupportedResolutions
}
