import ActiveNoSsl from '@images/landings/publishingOptions/active_no_ssl.svg'
import ActiveSsl from '@images/landings/publishingOptions/active_ssl.svg'
import Error from '@images/landings/publishingOptions/error.svg'
import HourglassStart from '@images/landings/publishingOptions/hourglass_start.svg'

export const domainStatus = (status: string) => {
  switch (status) {
    case 'installed':
      return ActiveSsl
    case 'failed':
      return Error
    case 'awaiting':
    case 'pending':
    case 'sent':
      return HourglassStart
    case 'notfound':
      return ActiveNoSsl
    default:
      return null
  }
}

export const domainStatusDisabled = (status: string) => {
  switch (status) {
    case 'failed':
    case 'awaiting':
    case 'pending':
    case 'sent':
      return true
    default:
      return false
  }
}

export const domainPath = (path: string) => (path ? `/${path}` : '/')

export const getLandingPageData = (message: string) => {
  const landingPageData = message.match(/<([^|>]+)\|([^|>]+)\|([^|>]+)>/)

  return {
    uuid: landingPageData?.[1] || null,
    hash: landingPageData?.[2] || null,
    name: landingPageData?.[3] || null
  }
}
