import { emitTimingToastToggle } from '@landingi/landingi-ui-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateEffect } from './useUpdateEffect'

export const useCopyToClipboard = (text: string) => {
  const [isCopied, setCopied] = useState(false)

  const { t } = useTranslation()

  useUpdateEffect(() => {
    emitTimingToastToggle(t('word.copied'), 'success')
  }, [isCopied])

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(text)

      setCopied(prev => !prev)
    } catch {
      emitTimingToastToggle(t('error.page.generic.title'), 'alert')
    }
  }

  return [handleCopy]
}
