import AIBRseCase1 from '@images/addons/usecases/ai-background-remover/AIBR-usecase-1.png'
import AIBRseCase2 from '@images/addons/usecases/ai-background-remover/AIBR-usecase-2.png'
import AIBRseCase3 from '@images/addons/usecases/ai-background-remover/AIBR-usecase-3.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Trans
        i18nKey='addons.store.ai.background.remover.use.cases.1.title'
        components={{ br: <br /> }}
      />
    ),
    image: AIBRseCase1,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.ai.background.remover.use.cases.1.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.ai.background.remover.use.cases.1.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.ai.background.remover.use.cases.2.title'),
    reverse: true,
    image: AIBRseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.ai.background.remover.use.cases.2.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.ai.background.remover.use.cases.2.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.ai.background.remover.use.cases.3.title'),
    image: AIBRseCase3,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.ai.background.remover.use.cases.3.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.ai.background.remover.use.cases.3.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  }
]
