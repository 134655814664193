const DOMAINS = {
  DOMAINS: '/domains',
  DOMAIN_ADD: '/domain/add',
  DOMAIN_REMOVE: '/domain/remove/:identifier',
  DOMAIN_INSTALL_SSL: '/domain/install-ssl/:identifier',
  DOMAIN_SHARE: '/domain/share/:identifier',
  DOMAIN_SETUP: '/domain/setup/:identifier'
}

export default DOMAINS
