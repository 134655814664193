import ErrorImage from '@images/error_image.svg'
import { CustomLink, Image, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import styles from './ErrorBoundaryFallback.module.scss'

export const ErrorBoundaryFallback = () => {
  const { t } = useTranslation()

  return (
    <Column
      className={styles.container}
      alignItems='center'
      data-testid='error-boundary-fallback'
    >
      <Image src={ErrorImage} />

      <Spacer space='tiny' />

      <Paragraph weight={700} padding='none' color='accent-5'>
        {t('error.component.title')}
      </Paragraph>

      <Spacer space='mini' />

      <Paragraph padding='none' color='accent-5'>
        {t('error.component.description')}
      </Paragraph>

      <CustomLink
        label={t('error.component.link')}
        href={t('word.create-support-ticket.url')}
      />
    </Column>
  )
}
