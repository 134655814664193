import LDAUseCase1 from '@images/addons/usecases/lead-data-anonymization/LDA-usecase-1.png'
import LDAUseCase2 from '@images/addons/usecases/lead-data-anonymization/LDA-usecase-2.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: t('addons.store.leads.anonymization.use.cases.1.title'),
    image: LDAUseCase1,
    reverse: true,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.leads.anonymization.use.cases.1.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.leads.anonymization.use.cases.1.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.leads.anonymization.use.cases.2.title'),
    image: LDAUseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.leads.anonymization.use.cases.2.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.leads.anonymization.use.cases.2.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  }
]
