const validateDomain =
  /([a-z\p{Script=Cyrl}\p{Script=Han}\p{Script=Latn}\p{Script=Hang}0-9][a-z\p{Script=Cyrl}\p{Script=Han}\p{Script=Latn}\p{Script=Hang}0-9/-]{1,63}\.[a-z/.]{2,6})$/gmu

export const getNakedDomain = (name?: string) => {
  const nakedDomain = name?.match(validateDomain) || ''

  return nakedDomain
}

export const getSubdomain = (name: string) => {
  const subdomain = name?.replace(validateDomain, '')

  return subdomain?.substring(0, subdomain.length - 1)
}

export const isDomain = (name: string) => {
  const subdomain = getSubdomain(name)

  return subdomain?.substring(0, 3) === 'www' && subdomain.length === 3
}

export const getDomainErrorMessage = (code: string) => {
  switch (code) {
    case 'DN0000':
      return 'modal.domains.valid.add.domain.error.dn0000'
    case 'DN0001':
      return 'modal.domains.valid.add.domain.error.dn0001'
    case 'DN0002':
      return 'modal.domains.valid.add.domain.error.dn0002'
    case 'DN0003':
      return 'modal.domains.valid.add.domain.error.dn0003'
    case 'c1051bb4-d103-4f74-8988-acbcafc7fdc3':
      return 'modal.domains.valid.add.domain.error.dn0004.1'
    case 'd94b19cc-114f-4f44-9cc4-4138e80a87b9':
      return 'modal.domains.valid.add.domain.error.dn0004.2'
    case '35e6a710-aa2e-4719-b58e-24b35749b767':
      return 'modal.domains.valid.add.domain.error.dn0004.3'
    case '9b2c21ae-2406-4333-87cc-754b889ee3c5':
      return 'modal.domains.valid.add.domain.error.dn0004.4'
    case 'fbdec7f1-6871-4fa8-9c85-d917e076c5a7':
    case 'DN0005':
      return 'modal.domains.valid.add.domain.error.dn0005'
    case 'DN0006':
      return 'modal.domains.valid.add.domain.error.dn0006'
    case 'DN0007':
      return 'modal.domains.valid.add.domain.error.dn0007'
    default:
      return 'toast.error.something.went.wrong'
  }
}

export const isTestDomain = (domain: string) => {
  const autoDomain = 'pagepreview.link'
  const legacyAutoDomain = 'landpage.co'
  const localAutoDomain = 'lp.landingi.cc'
  const localLegacyAutoDomain = 'lp.landingi.it'
  const custreamDomain = 'custream.com'
  const landpagepreviewDomain = 'landpagepreview.com'
  const landpageDashPreviewDomain = 'landpage-preview.com'

  const testDomains = [
    autoDomain,
    legacyAutoDomain,
    localAutoDomain,
    localLegacyAutoDomain,
    custreamDomain,
    landpagepreviewDomain,
    landpageDashPreviewDomain
  ]

  return testDomains.some(testDomain => domain.includes(testDomain))
}
