import { SectionType } from '../types'

export const DARK_HERO_1: SectionType = {
  name: 'dark_hero_1'
}

export const DARK_HERO_2: SectionType = {
  name: 'dark_hero_2'
}

export const DARK_HERO_3: SectionType = {
  name: 'dark_hero_3'
}

export const DARK_HERO_4: SectionType = {
  name: 'dark_hero_4'
}

export const DARK_HERO_5: SectionType = {
  name: 'dark_hero_5'
}

export const DARK_HERO_6: SectionType = {
  name: 'dark_hero_6'
}

export const DARK_HERO_7: SectionType = {
  name: 'dark_hero_7'
}

export const DARK_HERO_8: SectionType = {
  name: 'dark_hero_8'
}

export const DARK_HERO_9: SectionType = {
  name: 'dark_hero_9'
}

export const DARK_HERO_10: SectionType = {
  name: 'dark_hero_10'
}

export const DARK_HERO_11: SectionType = {
  name: 'dark_hero_11'
}

export const DARK_HERO_12: SectionType = {
  name: 'dark_hero_12'
}

export const DARK_HERO_13: SectionType = {
  name: 'dark_hero_13'
}

export const DARK_HERO_14: SectionType = {
  name: 'dark_hero_14'
}

export const DARK_HERO_15: SectionType = {
  name: 'dark_hero_15'
}

export const DARK_HERO_16: SectionType = {
  name: 'dark_hero_16'
}

export const DARK_HERO_17: SectionType = {
  name: 'dark_hero_17'
}

export const DARK_HERO_18: SectionType = {
  name: 'dark_hero_18'
}

export const DARK_HERO_19: SectionType = {
  name: 'dark_hero_19'
}

export const DARK_HERO_20: SectionType = {
  name: 'dark_hero_20'
}

export const DARK_HERO_21: SectionType = {
  name: 'dark_hero_21'
}

export const DARK_HERO_22: SectionType = {
  name: 'dark_hero_22'
}

export const DARK_HERO_23: SectionType = {
  name: 'dark_hero_23'
}

export const DARK_HERO_24: SectionType = {
  name: 'dark_hero_23'
}
