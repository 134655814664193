import Loading from '@images/landings/wizard/ai_loading.mp4'
import GenerationFinishedImg from '@images/landings/wizard/generation_finished.svg'
import WizardLoadingImage from '@images/landings/wizard/wizard_error_image.svg'
import { LANDINGS } from '@routes/path'
import { Button, Heading, Hint, Image, Paragraph, Spacer } from '@ui-kit'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import styles from './WizardGenerationLayout.module.scss'

const WizardError = () => {
  const { handleSubmit } = useWizardContext()
  const { t } = useTranslation()

  return (
    <Row className={styles['wizard-survey-layout']} justifyContent='center'>
      <Row className={styles['wizard-survey-layout__content']}>
        <Column
          className={styles['wizard-survey-layout__first-column']}
          justifyContent='center'
        >
          <Column className={styles['error-content']} alignItems='start'>
            <Heading level={2}>{t('wizard.error.page.title')}</Heading>

            <Spacer space={15} />

            <Paragraph size={14}>
              {t('wizard.error.page.description')}
            </Paragraph>

            <Spacer space={30} />

            <Button onClick={() => handleSubmit()}>
              {t('wizard.error.page.button')}
            </Button>
          </Column>
        </Column>
        <Row className={styles['wizard-survey-layout__second-column']}>
          <Row
            className={styles['wizard-survey-layout__second-column-content']}
            alignItems='center'
            justifyContent='center'
          >
            <Image src={WizardLoadingImage} />
          </Row>
        </Row>
      </Row>
    </Row>
  )
}

const hints = [
  'wizard.generating.hint.1',
  'wizard.generating.hint.2',
  'wizard.generating.hint.3'
]

const LoadingVideo = () => {
  const { t } = useTranslation()
  return (
    <Column alignItems='center'>
      <video
        src={Loading}
        width='181px'
        height='181px'
        autoPlay
        muted
        playsInline
        loop
      />
      <Paragraph color='neutral-6' size={16} align='center'>
        {t('wizard.preparing.layout')}
      </Paragraph>
    </Column>
  )
}

const GenerationFinished = () => {
  const { t } = useTranslation()

  return (
    <Column alignItems='center'>
      <Image src={GenerationFinishedImg} />

      <Spacer space={10} />

      <Paragraph size={16} align='center' weight={600}>
        {t('wizard.your.page.is.ready')}
      </Paragraph>
    </Column>
  )
}

export const WizardGenerationLayout = () => {
  const { generationStatus } = useWizardContext()

  const hintTextKey = useMemo(
    () => hints[Math.floor(Math.random() * hints.length)],
    []
  )
  const { t } = useTranslation()

  if (!generationStatus) {
    return <Navigate to={LANDINGS.LANDINGS_CREATE_WIZARD_FIRST} />
  }

  if (generationStatus === 'error') {
    return <WizardError />
  }

  return (
    <Row className={styles['wizard-survey-layout']} justifyContent='center'>
      <Row className={styles['wizard-survey-layout__content']}>
        <Column
          className={styles['wizard-survey-layout__first-column']}
          justifyContent='center'
        >
          <Hint>
            <Trans i18nKey={hintTextKey} />
          </Hint>

          <Spacer space={20} />

          <Hint isTip>{t('wizard.generation.tip')}</Hint>
        </Column>
        <Row className={styles['wizard-survey-layout__second-column']}>
          <Row
            className={styles['wizard-survey-layout__second-column-content']}
            alignItems='center'
            justifyContent='center'
          >
            {generationStatus === 'finished' ? (
              <GenerationFinished />
            ) : (
              <LoadingVideo />
            )}
          </Row>
        </Row>
      </Row>
    </Row>
  )
}
