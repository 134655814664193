export const INFOBAR_TYPE = {
  INFO: 'info',
  WARNING: 'warning',
  ALERT: 'alert'
} as const

export type AlertTypes =
  | typeof INFOBAR_TYPE.INFO
  | typeof INFOBAR_TYPE.WARNING
  | typeof INFOBAR_TYPE.ALERT

export const COUNTRY_CODES = {
  BR: 'BR'
}
