import { BoxOutline, BoxOutlineProvider } from '@ui-kit'
import { FC, ReactElement } from 'react'

import styles from './Box.module.scss'
import { BoxContent } from './BoxContent'

interface BoxProps {
  Content: ReactElement
  HoverContent: ReactElement
  disabled?: boolean
  onClick?: () => void
}

export const Box: FC<BoxProps> = ({
  Content,
  HoverContent,
  onClick,
  disabled
}) => {
  return (
    <BoxOutlineProvider>
      <BoxOutline
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        className={styles.boxNew}
        padding={0}
      >
        <BoxContent Content={Content} HoverContent={HoverContent} />
      </BoxOutline>
    </BoxOutlineProvider>
  )
}

Box.displayName = 'PublishingOptionsViewsMainBoxesBox'
