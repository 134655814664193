import SelectBoxCheckbox from '@components/SelectBoxCheckbox'
import arrowsImg from '@images/registration/survey/questionIcons/arrows.jpg'
import shoppingBagImg from '@images/registration/survey/questionIcons/shopping_bag.png'
import signImg from '@images/registration/survey/questionIcons/sign.png'
import thumbsUpImg from '@images/registration/survey/questionIcons/thumb_up.png'
import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useSurveyContext } from '@pages/Authentication/contexts/survey'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import FooterButtons from './FooterButtons'
import styles from './Questions.module.scss'

/**
 * Fourth - stateful presentational component representing survey question
 * @param {bool} isValid - formik isValid
 * @param {bool} isSubmitting - formik isSubmitting
 * @param {function} handleBack - function that handles clicking back arrow to go back to previous quesiton
 * @param {function} setFieldValue - formik setFieldValue
 * @param {function} handleSkipStep - function that handles clicking skip button
 * @param {function} validateForm - formik validateForm
 * @param {bool} isLastQuestion - information whether question is last or not
 * @param {function} onBlur - callback called on blur input
 * @param {object} dirty - formik dirty object
 * @return {object} - children
 */
const Fourth = ({
  isValid,
  isSubmitting,
  isLastQuestion,
  handleSkipStep,
  setFieldValue,
  validateForm,
  values,
  onBlur
}) => {
  const {
    activeSurveyBoxesIDs,
    setActiveSurveyBoxesIDs,
    questionsSkippedInfo,
    setQuestionsSkippedInfo
  } = useSurveyContext()

  const { t } = useTranslation()

  const questionsIDs = [
    'ads',
    'social-media',
    'direct-sales',
    'own-process-optimisation'
  ]

  useEffect(() => {
    validateForm()
  }, [])

  /**
   * handles clicking Next button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleNext = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    newQuestionsSkippedInfo.fourth = false
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
  }

  /**
   * handles clicking Skip step button and sets flag informing whether question was skipped or not
   * @type {function}
   */
  const handleSkipStepWrapper = () => {
    const newQuestionsSkippedInfo = questionsSkippedInfo

    setActiveSurveyBoxesIDs(
      activeSurveyBoxesIDs.filter(id => !questionsIDs.includes(id))
    )

    questionsIDs.forEach(id => setFieldValue(id, false))

    newQuestionsSkippedInfo.fourth = true
    setQuestionsSkippedInfo(newQuestionsSkippedInfo)
    handleSkipStep()
  }

  /**
   * adds survey box relevant to checked checkbox to actibve survey boxes and removes ones that aren't checked
   * @param {object} event
   */
  const handleFieldChange = event => {
    const { checked, name } = event.target

    if (checked) {
      setActiveSurveyBoxesIDs([...activeSurveyBoxesIDs, name])
    } else {
      setActiveSurveyBoxesIDs(activeSurveyBoxesIDs.filter(id => id !== name))
    }
  }

  return (
    <LayoutGroup>
      <motion.div
        layout='position'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Column>
          <motion.div layout>
            <Row>
              <Column>
                <Heading level={1} margin='none'>
                  {t('registration.flow.survey.what.are.the.goals')}
                </Heading>

                <Spacer space='mini' />

                <Paragraph size={16} padding='none'>
                  {t(
                    'registration.flow.survey.feel.free.to.pick.more.than.one'
                  )}
                </Paragraph>

                <Spacer space='small' />

                <Spacer space='tiny' />
              </Column>
            </Row>
          </motion.div>
          <div>
            <div className={styles['fourth-question-boxes']}>
              {/* ads */}
              <SelectBoxCheckbox
                field={{
                  name: 'ads',
                  value: values.ads,
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='ads'
                i18n={{ title: t('registration.flow.survey.raise.revenue') }}
                iconSource={signImg}
                onChange={handleFieldChange}
                variant='responsive'
                className={styles['fourth-question-boxes__ads']}
              />
              {/* social-media */}
              <SelectBoxCheckbox
                field={{
                  name: 'social-media',
                  value: values['social-media'],
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='social-media'
                i18n={{
                  title: t('registration.flow.survey.boost.social.media')
                }}
                iconSource={thumbsUpImg}
                onChange={handleFieldChange}
                variant='responsive'
                className={styles['fourth-question-boxes__socialMedia']}
              />
              {/* direct-sales */}
              <SelectBoxCheckbox
                field={{
                  name: 'direct-sales',
                  value: values['direct-sales'],
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='direct-sales'
                i18n={{
                  title: t('registration.flow.survey.increase.direct.sales')
                }}
                iconSource={shoppingBagImg}
                onChange={handleFieldChange}
                variant='responsive'
                className={styles['fourth-question-boxes__directSales']}
              />
              {/* own-process-optimisation */}
              <SelectBoxCheckbox
                field={{
                  name: 'own-process-optimisation',
                  value: values['own-process-optimisation'],
                  onBlur
                }}
                form={{ errors: {}, touched: {}, setFieldValue }}
                id='own-process-optimisation'
                i18n={{
                  title: t(
                    'registration.flow.survey.optimize.and.improve.company.workflow'
                  )
                }}
                iconSource={arrowsImg}
                onChange={handleFieldChange}
                variant='responsive'
                className={
                  styles['fourth-question-boxes__ownProcessOptimisation']
                }
              />
            </div>
          </div>
          <Spacer space='medium' />
          <Spacer space='mini' />
          <FooterButtons
            isLastQuestion={isLastQuestion}
            isSubmitting={isSubmitting}
            handleSkipStep={handleSkipStepWrapper}
            handleNext={handleNext}
            isValid={isValid}
          />
        </Column>
      </motion.div>
    </LayoutGroup>
  )
}

Fourth.propTypes = {
  values: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleSkipStep: PropTypes.func,
  validateForm: PropTypes.func,
  isLastQuestion: PropTypes.bool,
  onBlur: PropTypes.func.isRequired
}

export default Fourth
