import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import CleanLayout from '@layouts/Clean'
import { FullLayout } from '@layouts/FullLayout'
import { LANDINGS } from '@routes/path'
import { Suspense } from 'react'

import { Dashboard } from './Dashboard'
import dashboardRoutes from './Dashboard/routes'
import { CreateLandingWizard } from './Landings/CreateLandingWizard'
import { WizardProvider } from './Landings/CreateLandingWizard/contexts'
import { WizardCoomingSoonLayout } from './Landings/CreateLandingWizard/layouts/WizardCoomingSoonLayout'
import { WizardGenerationLayout } from './Landings/CreateLandingWizard/layouts/WizardGenerationLayout'
import { WizardSurveyLayout } from './Landings/CreateLandingWizard/layouts/WizardSurveyLayout'
import FirstStep from './Landings/CreateLandingWizard/steps/First'
import { FifthStep } from './Landings/CreateLandingWizard/steps/Fitfth'
import { FourthStep } from './Landings/CreateLandingWizard/steps/Fourth/Fourth'
import SecondStep from './Landings/CreateLandingWizard/steps/Second'
import { SixthStep } from './Landings/CreateLandingWizard/steps/Sixth/Sixth'
import ThirdStep from './Landings/CreateLandingWizard/steps/Third'
import { ChangeUrl } from './LandingsPublishingOptions/components/Views/ChangeUrl'
import { Embed } from './LandingsPublishingOptions/components/Views/Embed'
import { Main } from './LandingsPublishingOptions/components/Views/Main'
import { Wordpress } from './LandingsPublishingOptions/components/Views/Wordpress'

const Landings = lazy(() =>
  import('@pages/Landings/routes/Landings').then(({ Landings }) => ({
    default: Landings
  }))
)

const LandingsPublishingOptions = lazy(() =>
  import('@pages/Landings/routes/LandingsPublishingOptions').then(
    ({ LandingsPublishingOptions }) => ({
      default: LandingsPublishingOptions
    })
  )
)

export const publishingOptionsRoutes = [
  {
    path: LANDINGS.LANDINGS_PUBLISHING_OPTIONS.DEFAULT,
    element: <LandingsPublishingOptions />,
    children: [
      {
        path: LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN,
        element: <Main />
      },
      {
        path: LANDINGS.LANDINGS_PUBLISHING_OPTIONS.CHANGE_URL,
        element: <ChangeUrl />
      },
      {
        path: LANDINGS.LANDINGS_PUBLISHING_OPTIONS.WORDPRESS,
        element: <Wordpress />
      },
      {
        path: LANDINGS.LANDINGS_PUBLISHING_OPTIONS.EMBED,
        element: <Embed />
      }
    ]
  }
]

export const routes = [
  {
    path: LANDINGS.WELCOME,
    element: <FullLayout />,
    children: [
      {
        path: LANDINGS.WELCOME,
        element: (
          <Suspense>
            <Acl acl='landing_pages.view_list'>
              <Landings />
            </Acl>
          </Suspense>
        )
      }
    ]
  },
  {
    path: LANDINGS.LANDINGS_CREATE,
    element: (
      <Acl acl='landing_pages.landing.create'>
        <CleanLayout />
      </Acl>
    ),
    children: [
      {
        path: LANDINGS.LANDINGS_CREATE,
        element: <CreateLandingWizard />
      }
    ]
  },
  {
    path: LANDINGS.LANDINGS_CREATE_WIZARD,
    element: (
      <Acl acl='landing_pages.landing.create'>
        <WizardProvider />
      </Acl>
    ),
    children: [
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_FIRST,
        element: (
          <WizardSurveyLayout>
            <FirstStep />
          </WizardSurveyLayout>
        )
      },
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_SECOND,
        element: (
          <WizardSurveyLayout>
            <SecondStep />
          </WizardSurveyLayout>
        )
      },
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_THIRD,
        element: (
          <WizardSurveyLayout>
            <ThirdStep />
          </WizardSurveyLayout>
        )
      },
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_FOURTH,
        element: (
          <WizardSurveyLayout>
            <FourthStep />
          </WizardSurveyLayout>
        )
      },
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_FIFTH,
        element: (
          <WizardSurveyLayout>
            <FifthStep />
          </WizardSurveyLayout>
        )
      },
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_SIXTH,
        element: (
          <WizardSurveyLayout>
            <SixthStep />
          </WizardSurveyLayout>
        )
      },
      {
        path: LANDINGS.LANDINGS_CREATE_WIZARD_SEVENTH,
        element: <WizardCoomingSoonLayout />
      },
      {
        path: LANDINGS.LANDINGS_WIZARD_GENERATION,
        element: <WizardGenerationLayout />
      }
    ]
  },
  {
    path: LANDINGS.LANDINGS,
    element: <FullLayout />,
    children: [
      {
        path: LANDINGS.LANDINGS,
        element: (
          <Suspense>
            <Acl acl='landing_pages.view_list'>
              <Landings />
            </Acl>
          </Suspense>
        )
      },
      {
        path: LANDINGS.LANDINGS_ARCHIVED,
        element: (
          <Suspense>
            <Acl acl='landing_pages.view_list'>
              <Landings />
            </Acl>
          </Suspense>
        )
      },
      {
        path: LANDINGS.LANDINGS_GROUP,
        element: (
          <Suspense>
            <Acl acl='landing_pages.view_list'>
              <Landings />
            </Acl>
          </Suspense>
        )
      },
      {
        path: LANDINGS.LANDINGS_UNGROUPED,
        element: (
          <Suspense>
            <Acl acl='landing_pages.view_list'>
              <Landings />
            </Acl>
          </Suspense>
        )
      },
      {
        path: LANDINGS.LANDINGS_DASHBOARD,
        element: (
          <Acl acl='landing_pages.dashboard.view_list'>
            <Dashboard />
          </Acl>
        ),
        children: dashboardRoutes
      }
    ]
  },
  ...publishingOptionsRoutes
]
