import { useStyles } from '@hooks/useStyles'
import { FC } from 'react'

import styles from './Spreader.module.scss'

interface SpreaderProps {
  className?: string | string[]
  spread?: 5 | 10 | 15 | 20 | 30 | 40 | 50 | 'max'
  'data-testid'?: string
}

export const Spreader: FC<SpreaderProps> = ({
  className = '',
  spread = 15,
  'data-testid': dataTestId = 'spreader'
}) => {
  const spreaderStyles = useStyles(
    {
      [styles.spreader]: true,
      [styles[`spreader--spread-${spread}`]]: true
    },
    className
  )

  return <span data-testid={dataTestId} className={spreaderStyles} />
}

Spreader.displayName = 'Spreader'
