import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Modal, Spacer } from '@landingi/landingi-ui-kit'
import { useTranslation } from 'react-i18next'

import { ModalLandingRenameForm } from './ModalLandingRenameForm'

interface ModalLandingRenameProps {
  name: string
  uuid: string
  callback: () => void
}

export const ModalLandingRename = NiceModal.create<ModalLandingRenameProps>(
  ({ name, uuid, callback }) => {
    const { remove } = useModal()

    const { t } = useTranslation()

    return (
      <Modal
        isActive
        onClick={remove}
        i18n={{
          title: t('modal.landing.rename.title')
        }}
      >
        <Spacer />

        <ModalLandingRenameForm name={name} uuid={uuid} callback={callback} />
      </Modal>
    )
  }
)

ModalLandingRename.displayName = 'ModalLandingRename'
