import { Hint, Spacer } from '@ui-kit'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { Column } from 'simple-flexbox'

import { useWizardContext } from '../contexts'
import useSteps from '../helpers/useSteps'
import { SectionPreview } from './SectionPreview'

interface SecondColumnContentProps {}

const NAME = 'NAME'
const GOAL = 'GOAL'
const STYLE = 'STYLE'
const COLOR_AND_FONT = 'COLOR_AND_FONT'
const TOPIC = 'TOPIC'

const hints = {
  [NAME]: [
    'wizard.second.column.name.hint.1',
    'wizard.second.column.name.hint.2',
    'wizard.second.column.name.hint.3'
  ],
  [GOAL]: [
    'wizard.second.column.goal.hint.1',
    'wizard.second.column.goal.hint.2',
    'wizard.second.column.goal.hint.3'
  ],
  [STYLE]: [
    'wizard.second.column.style.hint.1',
    'wizard.second.column.style.hint.2',
    'wizard.second.column.style.hint.3'
  ],
  [COLOR_AND_FONT]: [
    'wizard.second.column.color_and_font.hint.1',
    'wizard.second.column.color_and_font.hint.2',
    'wizard.second.column.color_and_font.hint.3'
  ],
  [TOPIC]: [
    'wizard.second.column.topic.hint.1',
    'wizard.second.column.topic.hint.2',
    'wizard.second.column.topic.hint.3'
  ]
} as const

const hintNames = {
  1: NAME,
  2: GOAL,
  3: GOAL,
  4: STYLE,
  5: COLOR_AND_FONT,
  6: TOPIC
} as const

const SecondColumnContent: React.FC<SecondColumnContentProps> = () => {
  const { stepNumber } = useSteps()
  const { selectedHeroSection } = useWizardContext()
  const name = hintNames[stepNumber as keyof typeof hintNames] || NAME // if stepNumber for some reason is not in hintNames, default to NAME
  const hintTextKey = useMemo(
    () => hints[name]?.[Math.floor(Math.random() * hints[name].length)],
    [name]
  )

  return (
    <Column>
      {selectedHeroSection && <SectionPreview id={selectedHeroSection.name} />}

      <Spacer space={30} />

      <Hint keyProp={name}>
        <Trans i18nKey={hintTextKey} />
      </Hint>
    </Column>
  )
}

export default SecondColumnContent
