import { useFeatureFlags } from '@contexts/featureFlags'
import { Alert, Instruction, Spacer } from '@landingi/landingi-ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { wordpressSteps, wordpressStepsSemrush } from './data/wordpressSteps'

export const Wordpress = () => {
  const { t } = useTranslation()

  const isSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const steps = isSemrushIntegration ? wordpressStepsSemrush : wordpressSteps

  return (
    <Fragment>
      <Header />

      <Instruction data={steps} />

      <Spacer space='small' />

      <Alert type='info'>{t('publishing.options.embed.alert')}</Alert>

      <Spacer />

      <Footer />
    </Fragment>
  )
}

Wordpress.displayName = 'PublishingOptionsViewsWordpress'
