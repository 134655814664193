import { Image, Panel, Spacer } from '@landingi/landingi-ui-kit'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Column } from 'simple-flexbox'

import { useWhitelabelPageContext, WhitelabelPageProvider } from './Context'
import styles from './Layout.module.scss'

const MainLayout = ({ children }) => {
  const { getWhitelabelPageConfig } = useWhitelabelPageContext()
  const { background, logoUrl, panelStyle } = getWhitelabelPageConfig()

  return (
    <div className={styles['whitelabel-layout']} style={{ background }}>
      <Column alignItems='center'>
        <Image className={styles['whitelabel-layout__logo']} src={logoUrl} />

        <Spacer />

        <motion.div className={styles['whitelabel-layout__content']}>
          <Panel className={styles['whitelabel-layout__panel']} {...panelStyle}>
            {children}
          </Panel>
        </motion.div>
      </Column>
    </div>
  )
}

MainLayout.displayName = 'MainLayout'

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
}

const MainLayoutWrapper = ({ children }) => (
  <WhitelabelPageProvider>
    <MainLayout>{children}</MainLayout>
  </WhitelabelPageProvider>
)

MainLayoutWrapper.displayName = 'MainLayoutWrapper'

MainLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default MainLayoutWrapper
