import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Alert, Modal, Spacer } from '@landingi/landingi-ui-kit'
import { useTranslation } from 'react-i18next'

import { LandingModalResetForm } from './LandingModalResetForm'

interface MadalLandingResetProps {
  uuid: string
  name: string
}

export const ModalLandingReset = NiceModal.create<MadalLandingResetProps>(
  ({ uuid, name }) => {
    const { t } = useTranslation()

    const { remove } = useModal()

    return (
      <Modal
        isActive
        onClick={remove}
        i18n={{
          title: t('word.reset.lp')
        }}
      >
        <Alert type='warning'>{t('modal.landing.reset.alert.content')}</Alert>

        <Spacer />

        {t('modal.landing.reset.content', { name })}

        <Spacer />

        <LandingModalResetForm uuid={uuid} />
      </Modal>
    )
  }
)

ModalLandingReset.displayName = 'ModalLandingReset'
