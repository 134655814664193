import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Loader, Modal } from '@landingi/landingi-ui-kit'
import { useGetCreditsPackage } from '@services/credits/useGetCreditsPackage'
import { useTranslation } from 'react-i18next'

import styles from './ModalBuyCredits.module.scss'
import { ModalBuyCreditsContent } from './ModalBuyCreditsContent'

interface ModalBuyCreditsProps {
  properties: {
    click_location: string
    type: string
  }
}

export const ModalBuyCredits = NiceModal.create<ModalBuyCreditsProps>(
  ({ properties }) => {
    const { t } = useTranslation()
    const { remove } = useModal()

    const data = useGetCreditsPackage()

    return (
      <Modal
        isActive
        isClosable
        onClick={remove}
        customZIndex={{
          backdrop: 250999,
          dialog: 251000
        }}
        i18n={{
          title: t('modals.buyCredits.title')
        }}
        className={styles.modal}
      >
        {data ? (
          <ModalBuyCreditsContent data={data} properties={properties} />
        ) : (
          <Loader />
        )}
      </Modal>
    )
  }
)
