export const PLANS = {
  CORE_20: 'core_20',
  CORE_20_12m: 'core_20_12m',
  CORE_21: 'core_21',
  CORE_DISC_20: 'core_disc_20',
  CORE_DISC_21: 'core_disc_21',
  CORE_21_12M: 'core_21_12m',
  CORE_20_12M: 'core_20_12m',
  CREATE_20: 'create_20',
  CREATE_20_12m: 'create_20_12m',
  CREATE_21: 'create_21',
  CREATE_21_12M: 'create_21_12m',
  CREATE_20_12M: 'create_20_12m',
  CREATE_DISC_20: 'create_disc_20',
  CREATE_DISC_21: 'create_disc_21',
  AUTOMATE_20: 'automate_20',
  AUTOMATE_20_12M: 'automate_20_12m',
  AUTOMATE_21: 'automate_21',
  AUTOMATE_DISC_20: 'automate_disc_20',
  AUTOMATE_DISC_21: 'automate_disc_21',
  AUTOMATE_21_12M: 'automate_21_12m',
  AGENCY_19: 'agency_19',
  AGENCY_PRO_19: 'agency_pro_19',
  AGENCY_20: 'agency_20',
  AGENCY_20_12M: 'agency_20_12m',
  AGENCY_PRO_20_12M: 'agency_pro_20_12m',
  AGENCY_21: 'agency_21',
  AGENCY_21_12M: 'agency_21_12m',
  AGENCY_22: 'agency_22',
  AGENCY_100K_23: 'agency_uv100k_23',
  AGENCY_200K_23: 'agency_uv200k_23',
  AGENCY_500K_23: 'agency_uv500k_23',
  AGENCY_1M_23: 'agency_uv1m_23',
  FREE_22: 'free_22',
  FREE2LP_22: 'free2lp_22',
  FREE_LIMITED_24: 'free_limited_24',
  LITE_22: 'lite_22',
  LITE_24: 'lite_24',
  LITE_LIMITED_24: 'lite_limited_24',
  LITE_DISC_22: 'lite_disc_22',
  PROFESSIONAL_22: 'professional_22',
  PROFESSIONAL_24: 'professional_24',
  PROFESSIONAL_DISC_22: 'professional_disc_22',
  PROFESSIONAL_100K_23: 'professional_uv100k_23',
  PROFESSIONAL_200K_23: 'professional_uv200k_23',
  PROFESSIONAL_500K_23: 'professional_uv500k_23',
  PROFESSIONAL_1M_23: 'professional_uv1m_23',
  BR_PROFESSIONAL_22: 'br_professional_22',
  BR_ESSENTIAL_22: 'br_essential_22',
  BR_PAGEX_22: 'br_pagex_22',
  APP_SUMO: 'APP_SUMO',
  UNLIMITED_22: 'unlimited_22'
} as const

export const FREE_PLANS = [
  PLANS.FREE_22,
  PLANS.FREE2LP_22,
  PLANS.APP_SUMO,
  PLANS.FREE_LIMITED_24
] as const

export const PROFESSIONAL_PLANS = [
  PLANS.PROFESSIONAL_24,
  PLANS.PROFESSIONAL_100K_23,
  PLANS.PROFESSIONAL_200K_23,
  PLANS.PROFESSIONAL_500K_23,
  PLANS.PROFESSIONAL_1M_23
] as const

export const AGENCY_PLANS = [
  PLANS.AGENCY_22,
  PLANS.AGENCY_100K_23,
  PLANS.AGENCY_200K_23,
  PLANS.AGENCY_500K_23,
  PLANS.AGENCY_1M_23
] as const

export const CORE_PLANS = [
  PLANS.CORE_20,
  PLANS.CORE_21,
  PLANS.CORE_DISC_20,
  PLANS.CORE_DISC_21,
  PLANS.CORE_21_12M,
  PLANS.CORE_20_12M
] as const

export const CREATE_PLANS = [
  PLANS.CREATE_20,
  PLANS.CREATE_21,
  PLANS.CREATE_21_12M,
  PLANS.CREATE_20_12M,
  PLANS.CREATE_DISC_20,
  PLANS.CREATE_DISC_21
] as const

export const defaultFreePlan = PLANS.FREE_LIMITED_24
export const defaultLitePlan = PLANS.LITE_LIMITED_24
export const defaultProfessionalPlan = PLANS.PROFESSIONAL_24
export const defaultAgencyPlan = PLANS.AGENCY_22
export const defaultUnlimitedPlan = PLANS.UNLIMITED_22

export const defaultCorePlan = PLANS.CORE_21
export const defaultCreatePlan = PLANS.CREATE_21
export const defaultAutomatePlan = PLANS.AUTOMATE_21
export const defaultOldAgencyPlan = PLANS.AGENCY_21

export const plans2022 = [
  {
    name: 'Free',
    recordKey: PLANS.FREE_22
  },
  {
    name: 'Lite',
    recordKey: PLANS.LITE_22
  },
  {
    name: 'Professional',
    recordKey: PLANS.PROFESSIONAL_22
  },
  {
    name: 'Agency',
    recordKey: PLANS.AGENCY_22
  },
  {
    name: 'Unlimited',
    recordKey: PLANS.UNLIMITED_22
  }
]

export const plans2024 = [
  {
    name: 'Free',
    recordKey: PLANS.FREE_22
  },
  {
    name: 'Lite',
    recordKey: PLANS.LITE_22
  },
  {
    name: 'Professional',
    recordKey: PLANS.PROFESSIONAL_24
  },
  {
    name: 'Agency',
    recordKey: PLANS.AGENCY_22
  },
  {
    name: 'Unlimited',
    recordKey: PLANS.UNLIMITED_22
  }
]

export const usePlans = (planRecordKey: string) => {
  const plans = {
    free: {
      name: 'Free',
      recordKey: defaultFreePlan
    },
    lite: {
      name: 'Lite',
      recordKey: defaultLitePlan
    },
    professional: {
      name: 'Professional',
      recordKey: defaultProfessionalPlan
    },
    agency: {
      name: 'Agency',
      recordKey: defaultAgencyPlan
    },
    unlimited: {
      name: 'Unlimited',
      recordKey: defaultUnlimitedPlan
    }
  }

  const { free, lite, professional, agency, unlimited } = plans

  const defautPlans = [lite, professional, unlimited]

  if (planRecordKey === defaultAgencyPlan) {
    return [lite, professional, agency, unlimited]
  }

  if (planRecordKey === defaultFreePlan) {
    return [free, ...defautPlans]
  }

  return defautPlans
}

export const ACCOUNT_TYPES = { BUSINESS: 'business', AGENCY: 'agency' }
