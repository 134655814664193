import client from '@lib/http/client'

export interface RenameLandingRequest {
  uuid: string
  newName: string
}

export const renameLanding = ({ uuid, newName }: RenameLandingRequest) =>
  client.put(`landing-page/landings/${uuid}/rename`, {
    newName
  })
