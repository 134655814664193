import { TFunction } from 'i18next'

export const getOverview = (t: TFunction<'translation', undefined>) => ({
  heading: t('addons.store.ai.content.generation.overview.heading'),
  leftSide: (
    <div>
      <iframe
        src={t('addons.store.ai.content.generation.overview.arcade')}
        title={t('addons.store.ai.content.generation.overview.title')}
        frameBorder='0'
        loading='lazy'
        allowFullScreen
        style={{
          width: '640px',
          height: '360px',
          colorScheme: 'light'
        }}
      />
    </div>
  ),
  list: [
    t('addons.store.ai.content.generation.overview.list.1'),
    t('addons.store.ai.content.generation.overview.list.2'),
    t('addons.store.ai.content.generation.overview.list.3')
  ]
})
