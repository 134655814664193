import { OPEN_PRODUCT_UPDATES_DRAWER } from '@components/ProductUpdates/constants/constants'
import { useUserContext } from '@contexts/user'
import { Button, Icon, Indicator, Tooltip } from '@landingi/landingi-ui-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ProductUpdates.module.scss'

export const ProductUpdates = () => {
  const { t } = useTranslation()

  const { isNewNotification } = useUserContext()

  const [isAlert, setAlert] = useState(isNewNotification)

  const handleOpenProductUpdates = () => {
    document.dispatchEvent(new Event(OPEN_PRODUCT_UPDATES_DRAWER))

    setAlert(false)
  }

  return (
    <div id='topbar-product-updates'>
      <Tooltip
        content={t('topbar.productupdates.tooltip.content')}
        align='center'
      >
        <Indicator
          className={styles['product-updates__indicator']}
          onClick={handleOpenProductUpdates}
          isAlert={isAlert}
        >
          <Button variant='icon-transparent-hover'>
            <Icon icon='icon-bullhorn' />
          </Button>
        </Indicator>
      </Tooltip>
    </div>
  )
}

ProductUpdates.displayName = 'TopbarProductUpdates'
