import { useStyles } from '@hooks/useStyles'
import { PaddingType } from '@ui-kit/types/paddingType'
import { SurfaceLevel } from '@ui-kit/types/surface'
import { FC, Fragment, ReactNode } from 'react'
import { useHover, useLayer } from 'react-laag'

import styles from './Popover.module.scss'

interface PopoverProps {
  children: ReactNode
  content: ReactNode
  surface: SurfaceLevel
  placement?: 'top' | 'left' | 'right' | 'bottom'
  padding?: PaddingType
  disabled?: boolean
  'data-testid'?: string
}

export const Popover: FC<PopoverProps> = ({
  children,
  content,
  surface,
  padding = 20,
  placement = 'bottom',
  disabled = false,
  'data-testid': dataTestId = 'popover-trigger'
}) => {
  const [isHover, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 })

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isHover,
    placement: `${placement}-center`,
    triggerOffset: 8,
    onParentClose: () => null
  })

  const popoverContentStyles = useStyles({
    [styles.popover]: true,
    [styles[`popover--surface-${surface}`]]: true,
    [styles[`popover--padding--${padding}`]]: true
  })

  return (
    <Fragment>
      {disabled && children}

      {!disabled && (
        <span
          data-testid={dataTestId}
          className={styles.popover__trigger}
          {...triggerProps}
          {...hoverProps}
        >
          {children}

          {isHover &&
            renderLayer(
              <div
                data-testid={`popover-content-${placement}`}
                className={popoverContentStyles}
                {...layerProps}
              >
                {content}
              </div>
            )}
        </span>
      )}
    </Fragment>
  )
}

Popover.displayName = 'Popover'
