import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal from '@ebay/nice-modal-react'
import { removeLocalStorage } from '@helpers/storage'
import { useLazyService } from '@hooks/useLazyService'
import { emitCloseDropdown } from '@landingi/landingi-ui-kit'
import * as Sentry from '@sentry/react'
import {
  logout,
  logoutNewLandingi
} from '@services/authentication/authentication'
import { mutate } from 'swr'

import { setLogoutPath } from './logoutPath'

export const useLogout = () => {
  const clearSWRCache = () =>
    mutate(() => true, undefined, { revalidate: false })

  const CUSTOM_LOGOUT = useFeatureFlags('CUSTOM_LOGOUT')

  const [handleLogoutNewLandingi] = useLazyService(logoutNewLandingi, {
    onSuccess: () => {
      Sentry.setUser(null)

      emitCloseDropdown()

      NiceModal.remove('MODAL_TRIAL_EXPIRED')

      removeLocalStorage('lp-disableAnimation')
      removeLocalStorage('disableBannerAnimation')

      sessionStorage.clear()

      /* external API that adds something to window makes it unnecessary complicated to type */

      // @ts-ignore
      window?.FreshworksWidget?.('hide')

      // @ts-ignore
      window?.fcWidget?.destroy()

      if (CUSTOM_LOGOUT) {
        setLogoutPath('/login/israel')
      }

      clearSWRCache()
    }
  })

  const [handleLogoutAccount] = useLazyService(logout, {
    onSuccess: handleLogoutNewLandingi
  })

  return handleLogoutAccount
}
