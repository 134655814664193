import PulsingLogo from '@components/PulsingLogo'
import { Spacer } from '@landingi/landingi-ui-kit'
import Header from '@pages/Authentication/components/Header'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import styles from '@pages/Authentication/Layouts/CreditCardStepLayout.module.scss'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { Column, Row } from 'simple-flexbox'

const CreditCardStep = ({ children }) => {
  const { logoControls, shouldPlayFinalAnimation } = useAnimationContext()

  return shouldPlayFinalAnimation ? (
    <motion.div
      className={styles['final-animation-layout']}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <PulsingLogo />
    </motion.div>
  ) : (
    <Row className={styles.mainLayout}>
      <Column className={styles['mainLayout__first-column']}>
        <motion.div
          className={styles['first-column__content']}
          animate={logoControls}
        >
          <Header />
          {children}
          <Spacer space='small' />
        </motion.div>
      </Column>

      <Column className={styles['mainLayout__second-column']} />
    </Row>
  )
}

CreditCardStep.displayName = 'CreditCardStepLayout'

CreditCardStep.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(CreditCardStep)
