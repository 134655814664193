import { t } from 'i18next'

export const columns = [
  {
    header: t('subaccount.modal.name'),
    identifier: 'landingsduplicate_info',
    accessor: 'landingsduplicate_info',
    minWidth: '100%'
  }
]
