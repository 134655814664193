type TooltipType = 'translateTooltip'
type IsDisabledType = 'translateIsDisabled'

interface DropdownItem {
  icon: string
  title: string
  callback: string
  isEnabled: string
  tooltip?: TooltipType
  isDisabled?: IsDisabledType
  id?: string
  badge?: string
}

type DropdownGroup = DropdownItem[]

export const dropdownItems: DropdownGroup[] = [
  [
    {
      icon: 'icon-duplicate',
      title: 'word.duplicate',
      callback: 'handleDuplicate',
      isEnabled: 'hasAccessToDuplicate'
    },
    {
      icon: 'icon-edit',
      title: 'word.rename',
      callback: 'handleRename',
      isEnabled: 'hasAccessToRename'
    },
    {
      icon: 'icon-eye-open',
      title: 'word.preview',
      callback: 'handlePreview',
      isEnabled: 'hasAccessToPreview'
    }
  ],
  [
    {
      icon: 'icon-ai-option',
      title: 'word.lp.translate',
      callback: 'handleTranslate',
      isEnabled: 'hasAccessToTranslate',
      tooltip: 'translateTooltip' as const,
      isDisabled: 'translateIsDisabled' as const,
      id: 'hj-translate-button'
    },
    {
      icon: 'icon-globe',
      title: 'word.publishing.options',
      callback: 'handlePublishingOptions',
      isEnabled: 'hasAccessToPublishingOptions'
    },
    {
      icon: 'icon-users',
      title: 'word.leads',
      callback: 'handleLeads',
      isEnabled: 'hasAccessToLeads'
    },
    {
      icon: 'icon-random',
      title: 'word.optimization',
      callback: 'handleOptimization',
      isEnabled: 'hasAccessToOptimization'
    }
  ],
  [
    {
      icon: 'icon-clone-alt',
      title: 'word.move.to.account',
      callback: 'handleMoveToAnotherAccount',
      isEnabled: 'hasAccessToMoveToAccount'
    },
    {
      icon: 'icon-clone-alt',
      title: 'word.duplicate.to.accounts',
      callback: 'handleDuplicateToAccounts',
      isEnabled: 'hasAccessToDuplicateToAccounts'
    },
    {
      icon: 'icon-reset',
      title: 'word.reset.lp',
      callback: 'handleReset',
      isEnabled: 'hasAccessToReset'
    },
    {
      icon: 'icon-download',
      title: 'word.download',
      callback: 'handleDownload',
      isEnabled: 'hasAccessToDownload'
    }
  ],
  [
    {
      icon: 'icon-trash',
      title: 'word.archive',
      callback: 'handleArchive',
      isEnabled: 'hasAccessToArchive'
    }
  ]
]
