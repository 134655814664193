import GoogleIcon from '@images/registration/google_icon.svg'
import { Button, Image, Spreader } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'
import { Row } from 'simple-flexbox'

/**
 * GoogleButton - stateless presentantional component used as google button
 * @param {object} props - props
 * @param {function} props.onClick
 * @param {bool} props.disabled
 * @param {bool} props.isLoading
 * @param {node} props.children
 * @returns {object} children
 */
const GoogleButton = ({ onClick, disabled, isLoading, children }) => (
  <Button
    variant='secondary-outlined'
    size='large'
    onClick={onClick}
    disabled={disabled}
    isLoading={isLoading}
    hasIcon
  >
    <Row alignItems='center' justifyContent='center'>
      <Image src={GoogleIcon} />

      <Spreader spread='small' />

      {children}
    </Row>
  </Button>
)

GoogleButton.displayName = 'Google Button'

GoogleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired
}

GoogleButton.defaultProps = {
  disabled: false,
  isLoading: false
}

export default GoogleButton
