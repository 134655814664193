import { ANNUALLY } from '@constants/billingPeriods'
import { CurrencyType } from '@constants/currencies'
import { PLANS } from '@constants/plans'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import styles from '@pages/Authentication/routes/ComparePlans/ComparePlans.module.scss'
import { Promotion12MPrice } from '@pages/Pricing/routes/AccountPackage/components/PlanPanels/components/Price'
import { REGISTRATION } from '@routes/path'
import { useGetAccountInfo } from '@services/account'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Badge,
  Button,
  Divider,
  Heading,
  Icon,
  Spacer,
  Spreader
} from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { getFeatures } from '../helpers/getFeatures'
import { getLimits } from '../helpers/getLimits'
import { getSaveValue } from '../helpers/getSaveValue'
import { PlanRecordKey } from '../types'
import { NormalPrice } from './NormalPrice'

interface PlanProps {
  planName: string
  planRecordKey: PlanRecordKey
  billingPeriod: number
}

export const Plan = ({ planRecordKey, planName, billingPeriod }: PlanProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { hasCustomOffer } = useGetAccountInfo()

  const {
    formik: { values, setFieldValue },
    currency,
    getPlanPrice
  } = useCreditCardStepContext() as {
    formik: {
      values: {
        plan: {
          value: string
          label: string
        }
        period: {
          value: number
        }
      }
      setFieldValue: (
        field: string,
        value: {
          value: string | number
          label?: string
        }
      ) => void
    }
    currency: CurrencyType
    getPlanPrice: (plan: string, billingPeriod: number) => number
  }

  const planPrice =
    planName === 'Free'
      ? 0
      : getPlanPrice(planRecordKey, billingPeriod) / billingPeriod

  const handleChoosePlan = () => {
    setFieldValue('plan', {
      value: planRecordKey,
      label: planName
    })

    setFieldValue('period', {
      value: billingPeriod
    })

    mixpanelEvent({
      name: '[CreditCardStep] Change Plan',
      properties: {
        plan: values.plan.value,
        billingPeriod: values.period.value,
        newPlan: planRecordKey,
        newBillingPeriod: billingPeriod
      }
    })

    navigate(REGISTRATION.CREDIT_CARD_STEP.DEFAULT)
  }

  const { FREE_22, FREE_LIMITED_24, UNLIMITED_22, PROFESSIONAL_24 } = PLANS

  const isProfessionalPlan = planRecordKey === PROFESSIONAL_24

  const isNotFreePlan =
    planRecordKey !== FREE_22 && planRecordKey !== FREE_LIMITED_24

  const annualSaving = isNotFreePlan ? getSaveValue(planRecordKey, currency) : 0

  const hasAccessToPromotion12MFF = useFeatureFlags('PROMOTION_12M')

  const hasAccessToPromotion12M = hasAccessToPromotion12MFF && !hasCustomOffer

  const shouldShowPromotion12MPrice =
    hasAccessToPromotion12M &&
    billingPeriod === ANNUALLY &&
    planRecordKey !== UNLIMITED_22 &&
    isNotFreePlan

  return (
    <Column className={styles.plans__plan}>
      {!isProfessionalPlan ? (
        <Spacer space={30} />
      ) : (
        <Fragment>
          <Row>
            <Badge variant='almostcyan-filled' weight={600}>
              <Icon icon='icon-star' />

              <Spreader spread={5} />

              {t('account.package.marketers.and.agencies.favorite')}
            </Badge>
          </Row>

          <Spacer space={10} />
        </Fragment>
      )}

      <Heading weight={600}>{planName}</Heading>

      <Spacer space={20} />

      {shouldShowPromotion12MPrice ? (
        <Promotion12MPrice
          amount={planPrice}
          currency={currency}
          recordKey={planRecordKey}
          annualSaving={annualSaving}
        />
      ) : (
        <NormalPrice
          planPrice={planPrice}
          currency={currency}
          annualSaving={annualSaving}
          billingPeriod={billingPeriod}
          isNotFreePlan={isNotFreePlan}
        />
      )}

      <Spacer space={20} />

      <Button size={16} onClick={handleChoosePlan}>
        {t('change.plan.page.choose.plan')}
      </Button>

      <Spacer space={20} />

      {getFeatures(planRecordKey, t)}

      <Spacer space={10} />

      <Divider />

      <Spacer space={10} />

      {getLimits(planRecordKey)}
    </Column>
  )
}
