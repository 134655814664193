import { useStyles } from '@hooks/useStyles'
import { Checkbox, Error, Heading, Image } from '@landingi/landingi-ui-kit'
import { LayoutGroup, motion } from 'framer-motion'
import PropTypes from 'prop-types'

import styles from './SelectBoxCheckbox.module.scss'

/**
 * Select Box (checkbox) - stateless presentational component
 * @param {object} props - props
 * @param {string|array} props.className - list of class names, default: ''
 * @param {object} props.field - react-formik field properties
 * @param {object} props.form - react-formik form properties
 * @param {string} props.id - id of the element
 * @param {string} props.label - label, default: ''
 * @param {string} props.type - type of element, default: 'checkbox'
 * @param {variant} props.variant - variant element, default: 'small', oneOf: 'small', 'big', 'wide', 'responsive'
 * @param {string} props.title - title to be shown in select box
 * @param {string} props.iconSource - source of image to be shown in select box
 * @param {object} props.activeContent - React node to be shown when checkbox is checked
 * @param {object} centerTextInActiveContent - used to center active content - shouldn't be used when activeContent is input
 * @return {object} An object of children element
 */
const SelectBox = ({
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  className,
  onChange,
  type,
  variant,
  iconSource,
  activeContent,
  centerTextInActiveContent,
  i18n
}) => {
  const variantValues = {
    small: {
      headingLevel: 4,
      iconSize: 48
    },
    big: {
      headingLevel: 3,
      iconSize: 48
    },
    wide: {
      headingLevel: 3,
      iconSize: 48
    },
    responsive: {
      headingLevel: 4,
      iconSize: 48
    }
  }

  /**
   * @function shouldShowActiveContent - returns true when there is activeContent prop given
   * @return {bool}
   */
  const shouldShowActiveContent = () => activeContent

  /**
   * @function getInitialIconOpacity - returns 0 if there is active content and 1 when there is not one
   * it is needed due to fact, that icon should fade in only when it transitions from state when there
   * was active content shown on hover
   * @return {bool}
   */
  const getInitialIconOpacity = () => (activeContent ? 0 : 1)

  const labelStyles = useStyles(
    {
      [styles.selectBox]: true,
      [styles[`selectBox--${variant}`]]: variant,
      [styles['selectBox--active']]: value
    },
    className
  )

  const activeContentStyles = useStyles({
    [styles['selectBox__active-content']]: true,
    [styles['selectBox__active-content--centered']]: centerTextInActiveContent
  })

  return (
    <LayoutGroup>
      <motion.label className={labelStyles} data-testid='label' layout>
        <motion.div layout>
          <motion.div className={styles['selectBox__content-container']}>
            <Checkbox
              id={id}
              field={{
                name,
                value,
                onChange: event => {
                  setFieldValue(name, event?.currentTarget?.checked)
                  onChange(event)
                },
                onBlur
              }}
              form={{ errors, touched, setFieldValue }}
              type={type}
            />

            <motion.div
              initial={{ opacity: getInitialIconOpacity() }}
              animate={{ opacity: 1 }}
              data-testid='icon'
              layout
            >
              <Image
                src={iconSource}
                size={variantValues[variant].iconSize}
                height={variantValues[variant].iconSize}
              />
            </motion.div>

            <motion.div layout>
              <Heading
                level={variantValues[variant].headingLevel}
                margin='none'
              >
                {i18n.title}
              </Heading>

              {shouldShowActiveContent() && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={activeContentStyles}
                  layout
                >
                  {activeContent}
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        </motion.div>

        <div />

        {touched[name] && <Error error={errors[name]} />}
      </motion.label>
    </LayoutGroup>
  )
}

SelectBox.displayName = 'Form2 / SelectBoxCheckbox'

SelectBox.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.instanceOf(Object),
    touched: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func
  }).isRequired,
  id: PropTypes.string.isRequired,
  iconSource: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  activeContent: PropTypes.node,
  variant: PropTypes.oneOf(['small', 'big', 'wide', 'responsive']),
  centerTextInActiveContent: PropTypes.bool,
  onChange: PropTypes.func,
  i18n: PropTypes.shape({ title: PropTypes.string })
}

SelectBox.defaultProps = {
  className: '',
  type: 'checkbox',
  onChange: () => null,
  variant: 'small',
  activeContent: null,
  centerTextInActiveContent: false,
  i18n: { title: '' }
}

export default SelectBox
