import BlackFridayCheck from '@images/dealmate/blackFriday/black-friday-check.png'
import GABadgeCategoryLeaders from '@images/registration/GA-Badge-CategoryLeaders.svg'
import HighPerformerMidMarketReward from '@images/registration/HighPerformerMidMarketReward.png'
import HighPerformerReward from '@images/registration/HighPerformerReward.png'
import LeadCaptureSmallBusinessReward from '@images/registration/LeadCaptureSmallBusinessReward.png'
import SABadgeFrontrunners from '@images/registration/SA-Badge-FrontRunners-2023.png'
import { List, ListItem } from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { Heading, Image, Paragraph, Spacer, Spreader } from '@ui-kit'
import { motion } from 'framer-motion'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './RefreshedColumnContent.module.scss'

const BlackFriday = () => {
  const { secondColumnControls } = useAnimationContext()

  const { t } = useTranslation()

  return (
    <div className={styles['black-friday-column-content']}>
      <div className={styles['black-friday-column-content__shapes']}>
        <motion.div
          className={styles['column-content']}
          animate={secondColumnControls}
        >
          <Heading
            className={styles['black-friday-column-content__h1']}
            level={1}
            color='white'
            weight={600}
          >
            {t('black.friday.sale.title')}
          </Heading>

          <Heading
            className={styles['black-friday-column-content__h2']}
            level={2}
            color='white'
            weight={600}
          >
            {t('black.friday.sale.subtitle')}
          </Heading>

          <Spacer space={10} />

          <div className={styles['black-friday-column-content__badge']}>
            <Paragraph color='white' size={16} weight={600}>
              {t('black.friday.sale.badge')}
            </Paragraph>
          </div>

          <Spacer space={30} />

          <Paragraph color='white'>
            <Trans
              i18nKey='black.friday.sale.you.save'
              components={{
                span: (
                  <span
                    className={styles['black-friday-column-content__you-save']}
                  />
                )
              }}
            />
          </Paragraph>

          <Spacer />

          <Paragraph color='white'>
            <Trans i18nKey='black.friday.sale.gift.eventtracker' />
          </Paragraph>

          <Spacer space={5} />

          <Paragraph color='white'>
            <Trans i18nKey='black.friday.sale.gift.credits' />
          </Paragraph>

          <Spacer />

          <Paragraph color='white' weight={600} size={16}>
            <Trans i18nKey='black.friday.sale.gift.additionally.benefits' />
          </Paragraph>

          <List>
            <Spacer space={10} />

            <ListItem>
              <Row vertical='center'>
                <Image src={BlackFridayCheck} width='17px' height='17px' />

                <Spreader spread={15} />

                <Paragraph color='white'>
                  <Trans i18nKey='black.friday.sale.gift.additionally.benefits.1' />
                </Paragraph>
              </Row>
            </ListItem>

            <Spacer space={10} />

            <ListItem>
              <Row vertical='center'>
                <Image src={BlackFridayCheck} width='17px' height='17px' />

                <Spreader spread={15} />

                <Paragraph color='white'>
                  <Trans i18nKey='black.friday.sale.gift.additionally.benefits.2' />
                </Paragraph>
              </Row>
            </ListItem>

            <Spacer space={10} />

            <ListItem>
              <Row vertical='center'>
                <Image src={BlackFridayCheck} width='17px' height='17px' />

                <Spreader spread={15} />

                <Paragraph color='white'>
                  <Trans i18nKey='black.friday.sale.gift.additionally.benefits.3' />
                </Paragraph>
              </Row>
            </ListItem>
          </List>

          <Spacer space={10} />

          <Paragraph color='white'>{t('black.friday.sale.opinions')}</Paragraph>

          <Spacer space={25} />

          <div className={styles['column-content__logos']}>
            <Image src={HighPerformerReward} width='54px' />
            <Image src={HighPerformerMidMarketReward} width='54px' />
            <Image src={LeadCaptureSmallBusinessReward} width='54px' />
            <Image src={GABadgeCategoryLeaders} width='54px' />
            <Image src={SABadgeFrontrunners} width='54px' />
          </div>
        </motion.div>
      </div>
    </div>
  )
}

BlackFriday.displayName = 'BlackFriday'

export default memo(BlackFriday)
