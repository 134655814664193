import { useUserContext } from '@contexts/user'

import {
  AccountVariantsTypes,
  FREE_TRIAL_WITH_CREDIT_CARD,
  FREE_TRIAL_WITHOUT_CREDIT_CARD,
  PAID_ACCOUNT
} from '../types'

type UseGetAccountVariantProps = {
  paidAccountACL: boolean | undefined
  freeTrialWithCreditCardACL: boolean | undefined
  freeTrialWithoutCreditCardACL: boolean | undefined
}

export const useGetAccountVariant = ({
  paidAccountACL,
  freeTrialWithCreditCardACL,
  freeTrialWithoutCreditCardACL
}: UseGetAccountVariantProps) => {
  const { user, hasFreeTrial, hasSubscription } = useUserContext()

  const wasAccountPaymentRegistered =
    user?.payment?.was_account_payment_registered

  let accountVariant: AccountVariantsTypes | null = null

  if (paidAccountACL && wasAccountPaymentRegistered) {
    accountVariant = PAID_ACCOUNT
  }

  if (freeTrialWithCreditCardACL && hasFreeTrial && hasSubscription) {
    accountVariant = FREE_TRIAL_WITH_CREDIT_CARD
  }

  if (freeTrialWithoutCreditCardACL && hasFreeTrial && !hasSubscription) {
    accountVariant = FREE_TRIAL_WITHOUT_CREDIT_CARD
  }

  return accountVariant
}
