export const breakpoints = {
  'breakpoint-mobile': 320,
  'breakpoint-420': 420,
  'breakpoint-550': 550,
  'breakpoint-biggerMobile': 720,
  'breakpoint-tablet': 768,
  'breakpoint-870': 870,
  'breakpoint-960': 960,
  'breakpoint-laptop': 1024,
  'breakpoint-1070': 1070,
  'breakpoint-biggerLaptop': 1130,
  'breakpoint-1200': 1200,
  'breakpoint-desktop': 1350,
  'breakpoint-hd': 1920
}
