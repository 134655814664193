/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable prefer-rest-params */
// @ts-nocheck
import { FRESHWIDGET_ID } from '@constants/webAnalytics'

interface InitializeFreshDeskProps {
  user: {
    email: string
    lang: string
  }
  owner: {
    owner_email: string
    plan: string
    admin_url: boolean
    is_trial: string
    lifecycle_stage: string
  }
  restoreI?: string
}

// initializes fresh desk
export const initializeFreshDesk = ({
  owner,
  user
}: InitializeFreshDeskProps) => {
  const {
    owner_email: ownerEmail,
    admin_url: adminUrl,
    is_trial: isTrial,
    plan: packageName,
    lifecycle_stage: lifecycleStage
  } = owner

  const { lang, email } = user

  window.fwSettings = {
    widget_id: `${FRESHWIDGET_ID}`,
    locale: `${lang}`
  }

  if (!window.FreshworksWidget) {
    !(function () {
      if (typeof window.FreshworksWidget !== 'function') {
        const n = function () {
          n.q.push(arguments)
        }
        ;(n.q = []), (window.FreshworksWidget = n)
      }
    })()

    const widgetScript = document.createElement('script')

    widgetScript.type = 'text/javascript'
    widgetScript.async = true
    widgetScript.defer = true
    widgetScript.src = `https://widget.freshworks.com/widgets/${FRESHWIDGET_ID}.js`

    document.head.appendChild(widgetScript)
  }

  const { FreshworksWidget } = window

  FreshworksWidget('show')

  FreshworksWidget('identify', 'ticketForm', {
    email
  })

  FreshworksWidget('prefill', 'ticketForm', {
    status: 2,
    custom_fields: {
      cf_owner: ownerEmail,
      cf_admin_url: adminUrl,
      cf_plan: packageName,
      cf_trial: isTrial,
      cf_platform_stage: lifecycleStage
    }
  })

  FreshworksWidget('hide', 'ticketForm', [
    'priority',
    'custom_fields.cf_owner',
    'custom_fields.cf_admin_url',
    'custom_fields.cf_plan',
    'custom_fields.cf_trial',
    'custom_fields.cf_platform_stage'
  ])

  FreshworksWidget('setLabels', {
    pl: {
      banner: 'Jak możemy Ci pomóc?',
      launcher: 'Pomoc',
      contact_form: {
        title: 'Formularz kontaktowy',
        submit: 'Wyślij zapytanie',
        confirmation:
          'Dziękujemy za kontakt, odpiszemy najszybciej, jak to możliwe.'
      }
    }
  })
}
