import { MODAL_GENERATE_QR_CODE } from '@components/Modals/ModalGenerateQRCode'
import { CLICK_ON_QR_CODE_MODAL, DASHBOARD } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal from '@ebay/nice-modal-react'
import { isTestDomain } from '@helpers/domains'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { useLazyService } from '@hooks/useLazyService'
import { EditableLabel } from '@landingi/landingi-ui-kit'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { PublicationType } from '@pages/Landings/routes/Landings/components/LandingsTable/components/PublicationType'
import LANDINGS from '@routes/path/landings'
import { renameLanding, RenameLandingRequest } from '@services/landings/rename'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Button,
  CustomLink,
  Icon,
  Paragraph,
  Spacer,
  Spreader,
  StatusIcon,
  Tooltip
} from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { Actions } from '../Actions'
import { Badges } from './Badges'
import { statusOfTheDomain } from './helpers'
import styles from './Info.module.scss'
import { Thumbnail } from './Thumbnail'

export const Info = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { landing, setNewLandingData } = useDashboardContext()

  const {
    domain_url,
    identifier: landingUuid,
    is_published,
    name,
    publication_type
  } = landing

  const isFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')

  const isSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const hasAccessToPublishingOptions = useAcl(
    'landing_pages.landing.publishing_options'
  )

  const hasAccessToRename = useAcl('landing_pages.landing.rename')

  // Render publication type only for published landings and only non-test domains on free plan
  const shouldRenderPublicationType =
    is_published &&
    !(
      isTestDomain(domain_url) &&
      isFreePlanRestrictions &&
      !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)
    )

  // Render publication status only for test domains on free plan without ALLOW_TEST_AUTODOMAIN feature flag
  const shouldRenderPublicationStatus =
    isTestDomain(domain_url) &&
    isFreePlanRestrictions &&
    !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)

  // Render link for all domains on paid plan or free plan with ALLOW_TEST_AUTODOMAIN feature flag
  const shouldRenderLink = !(
    isTestDomain(domain_url) &&
    isFreePlanRestrictions &&
    !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)
  )

  const hasNotAccessToQRCode =
    isTestDomain(domain_url) && isFreePlanRestrictions

  const [handleCopy] = useCopyToClipboard(domain_url)

  const handleOpenQRCodeModal = () => {
    mixpanelEvent({
      name: CLICK_ON_QR_CODE_MODAL,
      properties: {
        click_location: DASHBOARD
      }
    })

    NiceModal.show(MODAL_GENERATE_QR_CODE, {
      url: `https://${domain_url}`,
      clickLocation: DASHBOARD
    })
  }

  const [renameLandingService, { isLoading }] =
    useLazyService<RenameLandingRequest>(renameLanding, {
      successToastText: t('toast.saved.success'),
      errorToastText: t('toast.saved.fail'),
      onSuccess: (res, { newName }) => setNewLandingData({ name: newName })
    })

  const handleRenameLanding = async (name: string) => {
    await renameLandingService({
      uuid: landingUuid,
      newName: name
    })
  }

  const handleOpenPublishingOptions = () => {
    const path = generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
      identifier: landingUuid
    })

    navigate(`${path}?dashboard`)
  }

  return (
    <div className={styles['landing__info--text']}>
      <Link
        to={generatePath(LANDINGS.LANDINGS_DASHBOARD, {
          identifier: landingUuid
        })}
      >
        <Thumbnail />
      </Link>

      <Column>
        <Row justifyContent='space-between' alignItems='center'>
          <EditableLabel
            initialName={name}
            isClickable={hasAccessToRename}
            placeholder={t('word.landings')}
            onChange={handleRenameLanding}
            isLoading={isLoading}
            isDisabled={!hasAccessToRename}
            tooltip={{
              focused: t('dashboard.header.hover.tooltip.focused'),
              notFocused: t('dashboard.header.hover.tooltip.not.focused')
            }}
          />

          <Actions />
        </Row>

        <Spacer space={10} />

        <div className={styles['landing__info--domain']}>
          <StatusIcon variant={is_published ? 'active' : 'inactive'} />

          {shouldRenderPublicationType && (
            <Fragment>
              <Spreader spread={10} />

              <PublicationType publicationType={publication_type} />
            </Fragment>
          )}

          <Spreader spread={10} />

          {shouldRenderPublicationStatus && (
            <Paragraph color={is_published ? 'success' : 'neutral-4'}>
              {is_published ? t('word.published') : t('word.not.published')}
            </Paragraph>
          )}

          {shouldRenderLink && (
            <Fragment>
              <CustomLink
                href={`https://${domain_url}`}
                target='_blank'
                variant={statusOfTheDomain(is_published)}
              >
                {domain_url}
              </CustomLink>

              <Spreader spread={30} />

              <Tooltip content={t('copy.link.to.clipboard')}>
                <Button
                  variant='text-primary'
                  size={12}
                  onClick={handleCopy}
                  isSquare
                >
                  <Icon icon='icon-link' />
                </Button>
              </Tooltip>
            </Fragment>
          )}

          <Row className={styles['landing__info--actions']}>
            {!isSemrushIntegration && (
              <Tooltip
                content={
                  hasNotAccessToQRCode
                    ? t('modal.generate.qr.code.tooltip.disabled')
                    : t('modal.generate.qr.code.tooltip')
                }
              >
                <Button
                  variant='text-primary'
                  size={12}
                  onClick={handleOpenQRCodeModal}
                  isSquare
                  disabled={hasNotAccessToQRCode}
                >
                  <Icon icon='icon-qr-code' />
                </Button>
              </Tooltip>
            )}

            {hasAccessToPublishingOptions ? (
              <Fragment>
                <Spreader spread={5} />

                <Button
                  variant='text-primary'
                  size={12}
                  onClick={handleOpenPublishingOptions}
                >
                  {t('word.change.url')}
                </Button>
              </Fragment>
            ) : null}
          </Row>
        </div>

        <Spacer space={10} />

        <Badges />
      </Column>
    </div>
  )
}

Info.displayName = 'LandingDashboardHeaderInfo'
