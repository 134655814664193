import { cards } from '@constants/cards'
import PropTypes from 'prop-types'

import CardImage from './CardImage'
import styles from './CardImages.module.scss'

/**
 * @function CardImages - stateless presentational component showing images of credit cards.
 * If given card number matches credit card it is highlighted.
 * @param {string} props.cardNumber - credit card number
 */
const CardImages = ({ cardNumber }) => (
  <div className={styles['cards-imgs']}>
    {cards.map((props, index) => (
      <CardImage key={index} cardNumber={cardNumber} {...props} />
    ))}
  </div>
)

CardImages.displayName = 'Card Images'

CardImages.propTypes = {
  cardNumber: PropTypes.string
}

CardImages.defaultProps = {
  cardNumber: ''
}

export default CardImages
