import client from '@lib/http/client'

export interface ArchiveLandingRequest {
  uuids: string[]
}

export const archiveLanding = ({ uuids }: ArchiveLandingRequest) =>
  client.put('landing-page/landings/archive', { landingPageUuids: uuids })

export interface RestoreLandingRequest {
  uuids: string[]
}

export const restoreLanding = ({ uuids }: RestoreLandingRequest) =>
  client.patch('landing-page/landings/restore-from-archive', {
    landingPageUuids: uuids
  })
