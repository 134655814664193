import { List, ListItem } from '@landingi/landingi-ui-kit'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={1} />

        <Spreader spread={15} />

        <Trans i18nKey='addons.store.landing.page.design.question.1' />
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          <Trans i18nKey='addons.store.landing.page.design.answer.1.paragraph.1' />
        </Paragraph>

        <Spacer space={10} />

        <Paragraph size={14}>
          <Trans i18nKey='addons.store.landing.page.design.answer.1.paragraph.2' />
        </Paragraph>

        <Spacer space={10} />

        <Paragraph size={14}>
          <Trans i18nKey='addons.store.landing.page.design.answer.1.paragraph.3' />
        </Paragraph>

        <Spacer space={10} />

        <Row>
          <Paragraph size={14}>
            <Trans
              i18nKey='addons.store.landing.page.design.answer.1.paragraph.4'
              components={{
                a: (
                  <a
                    href={t(
                      'addons.store.landing.page.design.answer.1.paragraph.4.link'
                    )}
                    target='_blank'
                    rel='noreferrer'
                  />
                )
              }}
            />
          </Paragraph>
        </Row>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        <Trans i18nKey='addons.store.landing.page.design.question.2' />
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        <Trans i18nKey='addons.store.landing.page.design.answer.2' />
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        <Trans i18nKey='addons.store.landing.page.design.question.3' />
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          <Trans i18nKey='addons.store.landing.page.design.answer.3.paragraph.1' />
        </Paragraph>

        <Spacer space={10} />

        <Paragraph size={14}>
          <Trans i18nKey='addons.store.landing.page.design.answer.3.paragraph.2' />
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        <Trans i18nKey='addons.store.landing.page.design.question.4' />
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          <Trans i18nKey='addons.store.landing.page.design.answer.4' />
        </Paragraph>

        <List listStyle='ordered-disc'>
          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.1' />
          </ListItem>

          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.2' />
          </ListItem>

          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.3' />
          </ListItem>

          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.4' />
          </ListItem>

          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.5' />
          </ListItem>

          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.6' />
          </ListItem>

          <ListItem variant='list'>
            <Trans i18nKey='addons.store.landing.page.design.answer.4.bullet.point.7' />
          </ListItem>
        </List>
      </Fragment>
    )
  }
]
