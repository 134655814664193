import Dark from '@images/landings/wizard/dark.png'
import DarkPl from '@images/landings/wizard/dark-pl.png'
import Elegant from '@images/landings/wizard/elegant.png'
import ElegantPl from '@images/landings/wizard/elegant-pl.png'
import Minimal from '@images/landings/wizard/minimal.png'
import MinimalPl from '@images/landings/wizard/minimal-pl.png'
import Modern from '@images/landings/wizard/modern.png'
import ModernPl from '@images/landings/wizard/modern-pl.png'
import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { LpStyle } from '@pages/Landings/routes/Landings/CreateLandingWizard/types'
import { BoxOutline, BoxOutlineProvider, Image, Radio } from '@ui-kit'
import i18next from 'i18next'

import styles from '../Fourth.module.scss'

const imagesEn = {
  dark: Dark,
  elegant: Elegant,
  minimal: Minimal,
  modern: Modern
}

const imagesPl = {
  dark: DarkPl,
  elegant: ElegantPl,
  minimal: MinimalPl,
  modern: ModernPl
}

export const StyleBox = ({ lpStyle }: { lpStyle: LpStyle }) => {
  const { formik, setStyle } = useWizardContext()
  const { values } = formik
  const { language } = i18next

  const images = language === 'pl' ? imagesPl : imagesEn

  return (
    <BoxOutlineProvider controlledActive={values.style === lpStyle}>
      <BoxOutline
        padding={5}
        onClick={() => setStyle(lpStyle)}
        data-testid={`wizard-${lpStyle}`}
      >
        <Radio
          label={lpStyle}
          id={lpStyle}
          field={{
            name: lpStyle,
            value: values.style,
            onChange: () => setStyle(lpStyle)
          }}
          className={styles['fourth-step__radio']}
        />
        <Image src={images[lpStyle]} alt='modern' width='100%' />
      </BoxOutline>
    </BoxOutlineProvider>
  )
}
