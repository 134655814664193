import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import {
  Button,
  Loader,
  Modal,
  Paragraph,
  PerfectDropdownSelect,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { moveLandingToAnotherAccount } from '@services/landings/move'
import {
  useGetAccountsList,
  useGetAccountsListInfinite
} from '@services/subaccounts'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

interface ModalMoveToAnotherAccountProps {
  name: string
  uuid: string
  refreshTable?: () => void
}

export const ModalMoveToAnotherAccount =
  NiceModal.create<ModalMoveToAnotherAccountProps>(
    ({ name, uuid, refreshTable }) => {
      const { t } = useTranslation()

      const [targetAccount, setTargetAccount] = useState<string | null>(null)
      const [searchPhrase, setSearchPhrase] = useState('')

      const { remove } = useModal()

      const [moveLandingRequest, { isLoading: isSubmitting }] = useLazyService(
        moveLandingToAnotherAccount,
        {
          successToastText: t('modal.landings.move.toast'),
          onSuccess: () => {
            remove()

            if (refreshTable) {
              refreshTable()
            }
          }
        }
      )

      const handleMoveLanding = async () => {
        if (targetAccount && uuid) {
          await moveLandingRequest({
            landingUuid: uuid,
            accountIdentifier: targetAccount
          })
        }
      }

      const { data: mainAccount, isLoading: mainAccountIsLoading } =
        useGetAccountsList({
          filters: {
            only_main_account: true
          }
        })

      const mainAccountCollection = useMemo(
        () => mainAccount?.collection ?? [],
        [mainAccount]
      )

      const {
        data: withoutMainAccount,
        isLoading: isSubaccountsLoading,
        size,
        setSize,
        total
      } = useGetAccountsListInfinite({
        filters: {
          without_main_account: true,
          name: searchPhrase
        }
      })

      const handleSearchAccountsList = (value?: string) => {
        setSearchPhrase(value || '')
      }

      const handleLoadMore = () => {
        setSize(size + 1)
      }

      const options = () => {
        const mainAccount = mainAccountCollection.map(({ identifier }) => ({
          label: t('word.main-account'),
          value: identifier
        }))

        const subaccounts = (withoutMainAccount?.flat() || []).map(
          ({ name, identifier }) => ({
            label: name,
            value: identifier
          })
        )

        return [...(searchPhrase ? [] : mainAccount), ...subaccounts]
      }

      const isLastPage = total / 10 <= size

      const isLoading = mainAccountIsLoading && isSubaccountsLoading

      return (
        <Modal
          isActive
          onClick={remove}
          i18n={{
            title: t('modal.landing.move.title')
          }}
        >
          {isLoading && <Loader />}

          {!isLoading && (
            <Column>
              <Paragraph padding='none'>
                {t('modal.landings.move.to.another.account.content', {
                  landingname: name
                })}
              </Paragraph>

              <Spacer />

              <PerfectDropdownSelect
                options={options()}
                onChange={value => {
                  setTargetAccount(String(value))
                }}
                value={targetAccount}
                hasSearcher
                handleOnSearchChange={handleSearchAccountsList}
                label={t('modal.landings.move.select')}
                isLoading={isSubaccountsLoading}
                overflowStyle={{ maxHeight: 200 }}
                hasLoadMoreButton={!isLastPage}
                loadMoreEvent={handleLoadMore}
                i18n={{
                  placeholder: t('word.search'),
                  loadmore: t('word.loadmore')
                }}
                data-testid='accounts-dropdown'
              />

              <Spacer />

              <Row justifyContent='flex-end'>
                <Button variant='secondary' onClick={remove}>
                  {t('label.cancel')}
                </Button>

                <Spreader />

                <Button
                  isDisabled={!targetAccount || isSubmitting}
                  onClick={handleMoveLanding}
                  isLoading={isSubmitting}
                >
                  {t('modal.landings.move.button')}
                </Button>
              </Row>
            </Column>
          )}
        </Modal>
      )
    }
  )
