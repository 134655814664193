export default {
  options: {
    arrowColor: '#5D92FF',
    backgroundColor: '#5D92FF',
    textColor: '#FFFFFF',
    width: '250px',
    zIndex: 3400000
  },
  tooltip: {
    padding: '15px',
    borderRadius: 0,
    boxShadown: 0
  },
  tooltipContainer: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'semibold',
    borderRadius: 0,
    boxShadown: 0
  },
  tooltipTitle: {
    fontSize: 16,
    color: '#ffffff',
    margin: '0 0 20px 0'
  },
  tooltipContent: {
    padding: 0,
    color: '#ffffff'
  },
  buttonSkip: {
    fontSize: 12,
    padding: '10px 20px 10px 0px'
  },
  buttonNext: {
    backgroundColor: '#2550AA',
    borderRadius: 0,
    color: '#FFFFFF',
    fontSize: 14,
    height: '28px',
    padding: '8px 14px',
    outline: 'none'
  },
  buttonClose: {
    display: 'none'
  },
  spotlight: {
    borderRadius: 0
  }
} as const
