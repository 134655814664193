import SpectrumMarketingImage from '@images/registration/exwhitelabels/spectrummarketing/spectrummarketing-image.svg'
import SpectrumMarketingLogo from '@images/registration/exwhitelabels/spectrummarketing/spectrummarketing-logo.svg'
import { Image } from '@landingi/landingi-ui-kit'
import { WhitelabelPageProvider } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { Row } from 'simple-flexbox'

import styles from './SpectrumMarketing.module.scss'

const SpectrumMarketing = ({ children }) => (
  <Row className={styles.whitelabelLayout}>
    <div className={styles.spectrumMarketing__firstColumn}>
      <div className={styles.whitelabelLayout__content}>
        <Image
          className={styles.spectrumMarketing__logo}
          size={294}
          height={94}
          src={SpectrumMarketingLogo}
        />
        <motion.div className={styles.whitelabelLayout__form}>
          {children}
        </motion.div>
      </div>
    </div>
    <div className={styles.spectrumMarketing__secondColumn}>
      <Image
        size='75%'
        className={styles.spectrumMarketing__image}
        src={SpectrumMarketingImage}
      />
    </div>
  </Row>
)

SpectrumMarketing.displayName = 'SpectrumMarketing'

SpectrumMarketing.propTypes = {
  children: PropTypes.node.isRequired
}

const SpectrumMarketingWrapper = ({ children }) => (
  <WhitelabelPageProvider>
    <SpectrumMarketing>{children}</SpectrumMarketing>
  </WhitelabelPageProvider>
)

SpectrumMarketingWrapper.displayName = 'SpectrumMarketingWrapper'

SpectrumMarketingWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(SpectrumMarketingWrapper)
