import AICGUseCase1 from '@images/addons/usecases/ai-content-generation/AICG-usecase-1.png'
import AICGUseCase2 from '@images/addons/usecases/ai-content-generation/AICG-usecase-2.png'
import AICGUseCase3 from '@images/addons/usecases/ai-content-generation/AICG-usecase-3.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: t('addons.store.ai.content.generation.use.cases.1.title'),
    image: AICGUseCase1,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.ai.content.generation.use.cases.1.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.ai.content.generation.use.cases.1.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.ai.content.generation.use.cases.2.title'),
    reverse: true,
    image: AICGUseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.ai.content.generation.use.cases.2.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.ai.content.generation.use.cases.2.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.ai.content.generation.use.cases.3.title'),
    image: AICGUseCase3,
    content: (
      <Fragment>
        <Paragraph>
          {t(
            'addons.store.ai.content.generation.use.cases.3.content.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t(
            'addons.store.ai.content.generation.use.cases.3.content.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  }
]
