import GoogleIcon from '@images/registration/google_icon.svg'
import { Button, Image, Spreader } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

/**
 * SignUpButton - stateless presentantional component used as google sign up button
 * @returns {object} children
 */
const SignUpButton = ({ onClick, disabled, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant='secondary-outlined'
      size='large'
      onClick={onClick}
      isDisabled={disabled}
      isLoading={isLoading}
      hasIcon
    >
      <Row alignItems='center' justifyContent='center'>
        <Image src={GoogleIcon} />

        <Spreader spread='small' />

        {t('registration.flow.sign.up.google')}
      </Row>
    </Button>
  )
}

SignUpButton.displayName = 'Google Sign Up Button'

SignUpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
}

SignUpButton.defaultProps = {
  disabled: false,
  isLoading: false
}

export default SignUpButton
