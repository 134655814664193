import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useLocation } from 'react-router-dom'

export const NotFoundNewLandingi = () => {
  const location = useLocation()

  window.open(`${NEW_LANDINGI_APP_URL}${location.pathname}`, '_self')

  return null
}
