import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

export type UseHoverOptions = {
  /**
   * Amount of milliseconds to wait while hovering before opening.
   * Default is `0`
   */
  delayEnter?: number
  /**
   * Amount of milliseconds to wait when mouse has left the trigger before closing.
   * Default is `0`
   */
  delayLeave?: number
  /**
   * Determines whether the layer should hide when the user starts scrolling.
   * Default is `true`
   */
  hideOnScroll?: boolean
}

export type PlainCallback = (...args: any[]) => void

export type UseHoverProps = {
  onMouseEnter: PlainCallback
  onMouseLeave: PlainCallback
  onTouchStart: PlainCallback
  onTouchMove: PlainCallback
  onTouchEnd: PlainCallback
}

enum Status {
  ENTERING,
  LEAVING,
  IDLE
}

export function useHover({
  delayEnter = 0,
  delayLeave = 0,
  hideOnScroll = true
}: UseHoverOptions = {}): readonly [UseHoverProps, boolean, () => void] {
  const [show, setShow] = useState(false)

  const timeout = useRef<number | null>(null)

  const status = useRef<Status>(Status.IDLE)

  const hasTouchMoved = useRef<boolean>(false)

  const removeTimeout = useCallback(function removeTimeout() {
    clearTimeout(timeout.current!)
    timeout.current = null
    status.current = Status.IDLE
  }, [])

  function onMouseEnter() {
    // if was leaving, stop leaving
    if (status.current === Status.LEAVING && timeout.current) {
      removeTimeout()
    }

    if (show) {
      return
    }

    status.current = Status.ENTERING
    timeout.current = window.setTimeout(() => {
      setShow(true)
      timeout.current = null
      status.current = Status.IDLE
    }, delayEnter)
  }

  function onMouseLeave(_: MouseEvent<any>, immediate?: boolean) {
    // if was waiting for entering,
    // clear timeout
    if (status.current === Status.ENTERING && timeout.current) {
      removeTimeout()
    }

    if (!show) {
      return
    }

    if (immediate) {
      setShow(false)
      timeout.current = null
      status.current = Status.IDLE
      return
    }

    status.current = Status.LEAVING
    timeout.current = window.setTimeout(() => {
      setShow(false)
      timeout.current = null
      status.current = Status.IDLE
    }, delayLeave)
  }

  // make sure to clear timeout on unmount
  useEffect(() => {
    function onScroll() {
      if (show && hideOnScroll) {
        removeTimeout()
        setShow(false)
      }
    }

    window.addEventListener('scroll', onScroll, true)

    return () => {
      window.removeEventListener('scroll', onScroll, true)

      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [show, hideOnScroll, removeTimeout])

  const hoverProps: UseHoverProps = {
    onMouseEnter,
    onMouseLeave,
    onTouchStart: () => {
      hasTouchMoved.current = false
    },
    onTouchMove: () => {
      hasTouchMoved.current = true
    },
    onTouchEnd: () => {
      if (!hasTouchMoved.current && !show) {
        setShow(true)
      }

      hasTouchMoved.current = false
    }
  }

  return [hoverProps, show, () => onMouseLeave(null!, true)] as const
}
