import { wait } from '@helpers/promise'
import { useAnimation } from 'framer-motion'
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

const AnimationContext = createContext(null)

export const AnimationProvider = ({ children }) => {
  const [transitionedToSurvey, setTransitionedToSurvey] = useState(false)
  const [transitionedToCreditCardStep, setTransitionedToCreditCardStep] =
    useState(false)
  const [shouldPlayFinalAnimation, setShouldPlayFinalAnimation] =
    useState(false)
  const formControls = useAnimation()
  const logoControls = useAnimation()
  const secondColumnControls = useAnimation()
  const fadeIn = useAnimation()
  const surveyBoxShapeControls = useAnimation()
  const shapesContainerControls = useAnimation()
  const backgroundControls = useAnimation()

  const playRegistrationToSurveyTransition = async () => {
    await formControls.start({
      opacity: 0,
      transition: { duration: 0.7, delay: 0.3 }
    })

    logoControls.start({
      opacity: 0,
      transition: { duration: 0.3 }
    })

    await secondColumnControls.start({
      opacity: 0,
      transition: { duration: 0.3 }
    })

    shapesContainerControls.start({
      opacity: 0,
      transition: { duration: 0.3 }
    })

    await backgroundControls.start({
      backgroundColor: '#f9f9f9',
      transition: { duration: 0.3, delay: 0.3 }
    })

    setTransitionedToSurvey(true)
  }

  const playSurveyEnter = () => {
    surveyBoxShapeControls.start({
      opacity: 1,
      transition: { duration: 1 }
    })

    fadeIn.start({
      opacity: 1,
      transition: { duration: 1 }
    })
  }

  const playCreditCardStepEnter = () => {
    shapesContainerControls.start({
      opacity: 1,
      transition: { duration: 0.7 }
    })

    fadeIn.start({
      opacity: 1,
      transition: { duration: 0.7, delay: 0.5 }
    })
  }

  const playSurveyStart = () =>
    backgroundControls.start({
      backgroundColor: '#fff'
    })

  const finishRegistration = async () => {
    backgroundControls.start({
      backgroundColor: '#f9f9f9',
      transition: { duration: 1 }
    })

    fadeIn.start({
      opacity: 0,
      transition: { duration: 1 }
    })

    await shapesContainerControls.start({
      opacity: 0,
      transition: { duration: 1 }
    })

    await wait(1000)

    setShouldPlayFinalAnimation(true)

    await wait(3200)
  }

  const values = useMemo(
    () => ({
      formControls,
      logoControls,
      secondColumnControls,
      fadeIn,
      transitionedToSurvey,
      transitionedToCreditCardStep,
      playRegistrationToSurveyTransition,
      playSurveyEnter,
      playCreditCardStepEnter,
      surveyBoxShapeControls,
      backgroundControls,
      setTransitionedToCreditCardStep,
      shapesContainerControls,
      playSurveyStart,
      shouldPlayFinalAnimation,
      finishRegistration
    }),
    [
      fadeIn,
      transitionedToSurvey,
      transitionedToCreditCardStep,
      shouldPlayFinalAnimation,
      shapesContainerControls
    ]
  )

  return (
    <AnimationContext.Provider value={values}>
      {children}
    </AnimationContext.Provider>
  )
}

AnimationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAnimationContext = () => {
  const animation = useContext(AnimationContext)

  if (!animation) {
    throw new Error('useAnimationContext must be used inside AnimationProvider')
  }

  return animation
}
