import { Button, Heading, Input } from '@landingi/landingi-ui-kit'
import styles from '@pages/Authentication/routes/Survey/Form/questions/Questions.module.scss'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

const EmptySearchResultsComponent = ({
  addCustomOption,
  searchPhrase,
  addedOptions
}) => {
  const [appName, setAppName] = useState(searchPhrase)
  const { t } = useTranslation()

  const handleInputChange = useCallback(
    event => setAppName(event?.target?.value),
    []
  )

  const handleButtonClick = useCallback(
    () =>
      addCustomOption({
        value: appName,
        label: appName
      }),
    [addCustomOption, appName]
  )

  return (
    <Column alignItems='center'>
      <Heading level={3} bold>
        {t('registration.flow.survey.empty.search.result.heading.3')}
      </Heading>
      <Heading level={4}>
        {t('registration.flow.survey.empty.search.result.heading.4')}
      </Heading>
      <div className={styles['search-component__actions']}>
        <Input onChange={handleInputChange} value={appName} />

        <Button
          onClick={handleButtonClick}
          isDisabled={appName.length === 0 || addedOptions.includes(appName)}
        >
          {t('registration.flow.survey.empty.search.result.add.app')}
        </Button>
      </div>
    </Column>
  )
}

EmptySearchResultsComponent.propTypes = {
  addCustomOption: PropTypes.func.isRequired,
  searchPhrase: PropTypes.string,
  addedOptions: PropTypes.array
}

EmptySearchResultsComponent.defaultProps = {
  searchPhrase: ''
}

export default EmptySearchResultsComponent
