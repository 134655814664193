import { useStyles } from '@hooks/useStyles'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import { Loader, Spacer } from '@ui-kit'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Column } from 'simple-flexbox'

import styles from './ComparePlans.module.scss'
import { BillingPeriod } from './components/BillingPeriod'
import { Plans } from './components/Plans'

export interface FormValues {
  plan: {
    value: string
    label: string
  }
  period: {
    value: number
  }
}

export const ComparePlans = () => {
  const {
    formik: { values },
    isLoaded
  } = useCreditCardStepContext() as {
    formik: { values: FormValues }
    isLoaded: boolean
  }

  const [billingPeriod, setBillingPeriod] = useState(values.period.value)

  const comparePlansStyles = useStyles({
    [styles['compare-plans']]: true
  })

  if (!isLoaded) {
    return <Loader />
  }

  return (
    <motion.div
      initial={{ x: '-100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ ease: [0.37, 0, 0.39, 1], duration: 0.85 }}
      className={comparePlansStyles}
    >
      <Column className={styles['compare-plans__wrapper']}>
        <BillingPeriod
          billingPeriod={billingPeriod}
          setBillingPeriod={setBillingPeriod}
        />

        <Spacer space={30} />

        <Plans billingPeriod={billingPeriod} />
      </Column>

      <Spacer space={50} />
    </motion.div>
  )
}
