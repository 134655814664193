export const generateRandomNumbers = ({
  count,
  min,
  max
}: {
  count: number
  min: number
  max: number
}) => {
  if (count > max + 1) {
    throw new Error('Count cannot be greater than max')
  }

  const uniqueNumbers = new Set()

  while (uniqueNumbers.size < count) {
    uniqueNumbers.add(Math.floor(Math.random() * (max - min + 1)) + min)
  }

  return Array.from(uniqueNumbers)
}
