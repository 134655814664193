import {
  CustomLink,
  Heading,
  Paragraph,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import RecoveryCodeForm from './Form'

const RecoveryCode = () => {
  const { t } = useTranslation()
  const { clientName } = useParams()
  const { getWhitelabelPageConfig } = useWhitelabelPageContext()
  const { mainColor } = getWhitelabelPageConfig()

  return (
    <Column>
      <Heading level={1} bold align='center'>
        {t('two.factor.authentication.page.recovery.code.heading')}
      </Heading>

      <Paragraph align='justify' size={16} padding='none' weight={400}>
        {t('two.factor.authentication.enter.one.of.a.codes')}
      </Paragraph>

      <Spacer space='medium' />

      <RecoveryCodeForm />

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Row justifyContent='space-between'>
        <Paragraph size={12} color='accent-2'>
          {t('two.factor.authentication.found.your.phone')}
          <Link
            style={{ color: mainColor }}
            to={`${REGISTRATION.TWO_FACTOR_AUTHENTICATION.DEFAULT}/${clientName}`}
          >
            {t('two.factor.authentication.found.your.phone.link')}
          </Link>
        </Paragraph>

        <Paragraph size={12} color='accent-2'>
          {t('two.factor.authentication.cant.find')}
          <CustomLink
            target='_blank'
            size={12}
            href={t('word.create-support-ticket.url')}
            label={t('two.factor.authentication.cant.find.link')}
            color={mainColor}
          />
        </Paragraph>
      </Row>
    </Column>
  )
}

RecoveryCode.displayName = 'RecoveryCode'

export default RecoveryCode
