import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.2')}
      </Row>
    ),
    content: <Paragraph size={14}>{t('addons.store.faq.answer.2')}</Paragraph>
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.what.do.i.need.a.cookie.consent.tool.for')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.what.do.i.need.a.cookie.consent.tool.for.answer')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        {t('addons.store.is.it.complicated.to.manage.cookie.data')}
      </Row>
    ),
    content: (
      <Fragment>
        <Paragraph size={14}>
          {t(
            'addons.store.is.it.complicated.to.manage.cookie.data.paragraph.1'
          )}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph size={14}>
          {t(
            'addons.store.is.it.complicated.to.manage.cookie.data.paragraph.2'
          )}
        </Paragraph>
      </Fragment>
    )
  }
]
