import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { COMPLETED, CREDIT_CARD } from '@constants/registrationSteps'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, Modal, Paragraph } from '@landingi/landingi-ui-kit'
import styles from '@pages/Authentication/components/SkipCreditStep/SkipCreditCard.module.scss'
import { isLastStep } from '@pages/Authentication/helpers/steps'
import { LANDINGS, REGISTRATION } from '@routes/path'
import { changeRegistrationStep, getAuthInfo } from '@services/authentication'
import { emitTimingToast } from '@ui-kit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'

const SkipCrediCard = NiceModal.create(() => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { remove } = useModal()
  const { mutate } = useSWRConfig()
  const { args } = useModal()
  const hasAccessToSpa = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  const {
    headerContent,
    paragraphContent,
    skipStepButtonContent,
    tryLaterButtonContent,
    finishRegistration
  } = args

  const onButtonClick = async () => {
    try {
      setIsLoading(true)

      await changeRegistrationStep(CREDIT_CARD, true)

      const { data: authInfo } = await getAuthInfo()

      const { flow } = authInfo
      const { steps } = flow

      if (isLastStep(steps, CREDIT_CARD)) {
        await changeRegistrationStep(COMPLETED)

        mutate('payments/discount', undefined, { revalidate: true })

        remove()

        await finishRegistration()

        setTimeout(() => {
          if (hasAccessToSpa) {
            navigate(LANDINGS.WELCOME)
          } else {
            window.open(`${NEW_LANDINGI_APP_URL}/welcome`, '_self')
          }

          mutate('auth', authInfo)
        }, 3200)

        return
      }

      navigate(REGISTRATION.SURVEY.DEFAULT)

      remove()
    } catch {
      emitTimingToast({
        message: t('error.page.generic.title'),
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isActive
      onClick={remove}
      i18n={{
        title: headerContent
      }}
      className={styles['skip-credit-card-modal']}
    >
      <Paragraph weight={400} line={20} size={16}>
        {paragraphContent}
      </Paragraph>

      <div className={styles['skip-credit-card-modal__buttons']}>
        <Button variant='secondary' onClick={remove}>
          {tryLaterButtonContent}
        </Button>

        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={onButtonClick}
        >
          {skipStepButtonContent}
        </Button>
      </div>
    </Modal>
  )
})

SkipCrediCard.displayName = 'SkipCrediCard'

export default SkipCrediCard
