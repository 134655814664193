import { useEffect, useState } from 'react'

import {
  convertStartDate,
  convertTargetDate,
  getCurrentDate,
  getReturnValues
} from '../helpers'

export const useCountdown = ({
  startDate,
  endDate
}: {
  startDate?: string
  endDate: string
}) => {
  const countDownStartDate = Number(getCurrentDate())
  const countDownEndDate = Number(convertTargetDate(endDate))

  const [countDown, setCountDown] = useState(
    countDownEndDate - countDownStartDate
  )

  const countdownIsDisabled =
    !!startDate && countDownStartDate < Number(convertStartDate(startDate))

  const isFinished =
    countdownIsDisabled ||
    countDown <= 0 ||
    countDownStartDate > countDownEndDate ||
    Number.isNaN(countDownEndDate)

  useEffect(() => {
    const interval = setInterval(() => {
      if (isFinished) {
        clearInterval(interval)

        return
      }

      setCountDown(countDownEndDate - countDownStartDate)
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownStartDate, countDownEndDate, isFinished])

  return getReturnValues(countDown, isFinished)
}
