import { useEffect, useRef } from 'react'

export const useEffectOnceConditionally = (
  callback: () => void,
  condition: boolean
) => {
  const calledOnce = useRef(false)

  useEffect(() => {
    if (!condition || calledOnce.current) {
      return
    }

    calledOnce.current = true

    callback()
  }, [condition, callback])
}
