import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { Button, Heading, Input, Paragraph, Spacer } from '@ui-kit'
import React, { ChangeEvent, ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './First.module.scss'

const FirstStep: React.FC = () => {
  const { t } = useTranslation()
  const { formik, validators } = useWizardContext()
  const { values, setFieldValue, isSubmitting } = formik
  const { websiteName } = values
  const { isFirstStepValid } = validators

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue('websiteName', event.target.value)
  }

  return (
    <div className={styles['first-step']}>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.is.your.site.called')}
      </Heading>
      <Spacer space={20} />
      <Paragraph size={14} color='neutral-6'>
        {t('landings.create.wizard.landing.modal.dont.stress.you.can.change')}
      </Paragraph>
      <Spacer space={20} />
      <Input
        size={16}
        value={websiteName}
        onChange={handleChangeName}
        id='websiteName'
        autoFocus
        i18n={{ label: t('landings.create.wizard.landing.modal.website.name') }}
      />
      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button
          icon='icon-arrow-right'
          disabled={!isFirstStepValid || isSubmitting}
          type='submit'
          isLoading={isSubmitting}
        >
          {t('landings.create.wizard.next.step')}
        </Button>
      </Row>
    </div>
  )
}

export default FirstStep
