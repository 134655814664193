import { ErrorBoundaryFallback } from '@components/ErrorBoundaryFallback'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { Divider, LimitBar, PerfectDropdown } from '@landingi/landingi-ui-kit'
import { useGetCredits } from '@services/credits/useGetCredits'
import { useGetAccountLimits } from '@services/limits'
import { Indicator } from '@ui-kit/Indicator'
import { Spacer } from '@ui-kit/Spacer'
import { useTranslation } from 'react-i18next'

import { BuyCreditsButton } from './components/Buttons/BuyCreditsButton'
import { ExtendLink } from './components/Buttons/ExtendLink'
import { UpgradeButton } from './components/Buttons/UpgradeButton'
import { Credits } from './components/Credits'
import styles from './MyUsage.module.scss'

export const MyUsage = () => {
  const { t } = useTranslation()

  const {
    landingPages,
    uniqueVisits,
    domains,
    subaccounts,
    isWarning,
    isAlert,
    error: limitsError
  } = useGetAccountLimits()

  const { credits, error: creditsError } = useGetCredits()

  const { isAgency, isNormal, hasFreeTrial } = useUserContext()

  const hasAccessToSubaccountsLimit = isAgency && subaccounts
  const hasAccessToUniqueUsersLimit = (isAgency || isNormal) && uniqueVisits
  const hasAccessToDomainsLimit = (isAgency || isNormal) && domains
  const hasAccessToLandingPagesLimit =
    useFeatureFlags('PRICING_2022') && landingPages

  const freeTrialRegularLimit = {
    regularLimit: uniqueVisits?.regularLimit,
    tooltipInQuantity: t('topbar.unique-visitors.tooltip')
  }

  const indicatorVariant = () => {
    if (isAlert) {
      return 'error'
    }

    if (isWarning) {
      return 'warning'
    }

    return undefined
  }

  return (
    <div
      id='topbar-my-usage'
      className={styles['my-usage']}
      data-testid='topbar-my-usage'
    >
      {isWarning || isAlert ? (
        <Indicator
          className={styles['my-usage__indicator']}
          variant={indicatorVariant()}
        />
      ) : null}

      <PerfectDropdown
        icon='icon-speed'
        label={t('topbar.my-usage')}
        size='auto'
        fontColor='color-3'
        fontWeight={600}
        hasHoverLabel
      >
        {limitsError || creditsError ? (
          <div className={styles['my-usage__error']}>
            <ErrorBoundaryFallback />
          </div>
        ) : (
          <div className={styles['my-usage__content']}>
            <div className={styles['my-usage__limits']}>
              {hasAccessToDomainsLimit ? (
                <LimitBar
                  limitText={t('topbar.custom-domains')}
                  limit={domains.domainsLimit}
                  quantity={domains.activeOwnDomainsCount}
                  tooltipInQuantity={t('topbar.custom-domains.tooltip')}
                  padding='none'
                />
              ) : null}

              {hasAccessToUniqueUsersLimit ? (
                <LimitBar
                  limitText={t('topbar.unique-visitors')}
                  limit={uniqueVisits.uniqueVisitsLimit}
                  quantity={uniqueVisits.uniqueVisitsCount}
                  padding='none'
                  {...(hasFreeTrial && freeTrialRegularLimit)}
                />
              ) : null}

              {hasAccessToLandingPagesLimit ? (
                <LimitBar
                  limitText={t('topbar.published-landing-pages')}
                  limit={landingPages.landingPagePublicationLimit}
                  quantity={landingPages.landingPagePublicationCount}
                  padding='none'
                />
              ) : null}

              {hasAccessToSubaccountsLimit ? (
                <LimitBar
                  limitText={t('topbar.active-subaccounts')}
                  limit={subaccounts.subaccountsActiveLimit}
                  quantity={subaccounts.subaccountsActiveCount}
                  padding='none'
                />
              ) : null}
            </div>

            <ExtendLink />

            <Spacer space={20} />

            <Divider variant='dropdown' />

            <Spacer space={20} />

            {credits !== undefined ? <Credits credits={credits} /> : null}

            <BuyCreditsButton />

            <UpgradeButton />
          </div>
        )}
      </PerfectDropdown>
    </div>
  )
}

MyUsage.displayName = 'TopbarMyUsage'
