import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Worker from '@images/registration/worker.png'
import {
  Button,
  Image,
  Modal,
  Paragraph,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import styles from '@pages/Authentication/components/WorkingOnIt/WorkingOnIt.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

const WorkingOnItModal = NiceModal.create(() => {
  const { t } = useTranslation()

  const { remove } = useModal()

  return (
    <Modal
      isActive
      onClick={remove}
      i18n={{
        title: t('modal.working.on.it.title')
      }}
      className={styles['working-on-it-modal']}
    >
      <Row alignItems='center'>
        <Image src={Worker} size='120px' />
        <Spreader spread='small' />
        <Paragraph weight={400} line={20} size={16}>
          <Trans i18nKey='modal.working.on.it.description' />
        </Paragraph>
      </Row>

      <Spacer space='small' />

      <Row justifyContent='flex-end'>
        <Button onClick={remove}>{t('modal.working.on.it.okay.close')}</Button>
      </Row>
    </Modal>
  )
})

WorkingOnItModal.displayName = 'WorkingOnItModal'

export default WorkingOnItModal
