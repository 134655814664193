import { useFeatureFlags } from '@contexts/featureFlags'
import { Button, Paragraph } from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const Footer = () => {
  const { t } = useTranslation()

  const { handleClose } = usePublishingOptionsContext()

  const isUnbrandedContent = useFeatureFlags('UNBRANDED_CONTENT')

  return (
    <Row justifyContent='space-between' alignItems='center'>
      <Row alignItems='center'>
        {isUnbrandedContent ? null : (
          <Paragraph padding='none' weight={400}>
            <Trans
              i18nKey='publishing.options.footer.paragraph'
              components={{
                a: (
                  <a
                    href={t('publishing.options.footer.paragraph.link.href')}
                    target='_blank'
                    rel='noreferrer'
                  />
                )
              }}
            />
          </Paragraph>
        )}
      </Row>

      <Button onClick={handleClose}>
        {t('publishing.options.footer.button.close')}
      </Button>
    </Row>
  )
}

Footer.displayName = 'PublishingOptionsViewsMainFooter'
