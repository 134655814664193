import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  ACCOUNT,
  ECOMMERCE,
  INVOICES,
  PAYMENTS,
  PRICING,
  PROFILE,
  SETTINGS
} from '@routes/path'
import { useGetAccountInfo } from '@services/account'

import { ITEMS } from '../types'

export const AccountData = () => {
  const { isAgency, hasSubscription } = useUserContext()

  const { hasCustomOffer } = useGetAccountInfo()

  const USERS = isAgency ? ACCOUNT.ACCOUNT_AGENCY_USERS : ACCOUNT.ACCOUNT_USERS

  const hasAccessToPromotion12MFF = useFeatureFlags('PROMOTION_12M')

  const hasAccessToPromotion12M = hasAccessToPromotion12MFF && !hasCustomOffer

  const getPlanAndBillingUrl = () => {
    if (hasAccessToPromotion12M) {
      if (hasSubscription) {
        return `${NEW_LANDINGI_APP_URL}${PAYMENTS.SUBSCRIPTION.SUBSCRIPTION}`
      }

      return `${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`
    }

    if (hasSubscription) {
      return `${NEW_LANDINGI_APP_URL}${PAYMENTS.PAYMENTS}`
    }

    return PRICING.PRICING
  }

  return [
    {
      label: 'topbar.my-profile',
      icon: 'icon-users-circle',
      href: `${NEW_LANDINGI_APP_URL}${PROFILE.PROFILES}`,
      divider: 'bottom'
    },
    {
      label: 'topbar.subaccounts',
      icon: 'icon-subaccount',
      href: `${NEW_LANDINGI_APP_URL}/agency/accounts`,
      access: ITEMS.SUBACCOUNTS
    },
    {
      label: 'topbar.settings',
      icon: 'icon-cog',
      href: `${NEW_LANDINGI_APP_URL}${SETTINGS.SETTINGS}`,
      access: ITEMS.SETTINGS
    },
    {
      label: 'topbar.payment-gateways',
      icon: 'icon-money',
      href: `${NEW_LANDINGI_APP_URL}${ECOMMERCE.ECOMMERCE_PAYMENT_GATEWAYS}`,
      access: ITEMS.PAYMENT_GATEWAYS
    },
    {
      label: 'topbar.users',
      icon: 'icon-users',
      href: `${NEW_LANDINGI_APP_URL}${USERS}`,
      access: ITEMS.USERS
    },
    {
      label: 'topbar.invoices',
      icon: 'icon-file-alt',
      href: `${NEW_LANDINGI_APP_URL}${INVOICES.INVOICES}`,
      access: ITEMS.INVOICES
    },
    {
      label: 'topbar.plan-and-billing',
      icon: 'icon-credit-card',
      href: getPlanAndBillingUrl(),
      access: ITEMS.PLAN_AND_BILLING,
      divider: 'top'
    },
    {
      label: 'topbar.logout',
      icon: 'icon-exit',
      onClick: 'handleLogoutAccount',
      divider: 'top'
    }
  ]
}

AccountData.displayName = 'TopbarAccountData'
