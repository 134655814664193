export type ExpandType =
  | '/dashboard'
  | '/landings'
  | '/lightboxes'
  | '/smart-sections'
  | '/products'
  | '/popups'
  | '/leads'
  | '/orders'
  | '/domains'
  | '/fonts'
  | '/limits'
  | '/templates'
  | '/integrations'
  | '/addons'
  | '/my-account'
  | '/welcome'
  | null

export const MY_ACCOUNT = '/my-account'
