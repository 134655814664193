import { CustomLink } from '@ui-kit/CustomLink'
import { Paragraph } from '@ui-kit/Paragraph'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

export const getPricing = (
  t: TFunction<'translation', undefined>,
  UNBRANDED_CONTENT_FF?: boolean
) => ({
  list: [
    <Paragraph size={16}>
      <Trans i18nKey='addons.store.feature.is.billed.in.credits' />{' '}
      {!UNBRANDED_CONTENT_FF && (
        <CustomLink
          href={t('modals.credits.packages.what.are.credits.url')}
          size={16}
          target='_blank'
        >
          {t('addons.store.what.are.credits')}
        </CustomLink>
      )}
    </Paragraph>,
    <Trans i18nKey='addons.store.ai.seo.generation.pricing.list.1' />,
    <Trans i18nKey='addons.store.ai.seo.generation.pricing.list.2' />,
    <Trans i18nKey='addons.store.ai.seo.generation.pricing.list.3' />
  ],
  whatsPriceFor: t('addon.store.per.single.use')
})
