import { Badge, Tooltip } from '@landingi/landingi-ui-kit'
import { PublicationType as PublicationTypes } from '@services/landings/list/types'
import { Trans } from 'react-i18next'

import { PublicationType } from './components/PublicationType'
import { UrlCell } from './components/UrlCell'

export default [
  {
    identifier: 'publicationType',
    render: ({ publicationType }: { publicationType: PublicationTypes }) => (
      <PublicationType publicationType={publicationType} />
    ),
    header: <Trans i18nKey='word.domain.type' />,
    width: '200px'
  },
  {
    identifier: 'badge',
    render: ({ isMain }: { isMain: boolean }) =>
      isMain ? (
        <Tooltip
          content={<Trans i18nKey='publishing.options.main.url' />}
          placement='top'
        >
          <Badge type='accent-5'>
            <Trans i18nKey='word.main' />
          </Badge>
        </Tooltip>
      ) : null,
    width: '100px'
  },
  {
    identifier: 'url',
    render: UrlCell,
    header: <Trans id='smart.section.landing.page.list.url' />,
    width: '510px'
  }
]
