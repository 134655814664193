import SaleDealMate from '@images/dealmate/landingi-dealmate-october-sale.svg'
import { Badge, Image, Paragraph } from '@landingi/landingi-ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'

import styles from './SalePromoBanner.module.scss'

interface SalePromoBannerProps {
  promoPeriodText: string
}

export const SalePromoBanner: FC<SalePromoBannerProps> = ({
  promoPeriodText
}) => {
  const { t } = useTranslation()

  return (
    <Row className={styles['sale-promo-banner']}>
      <Image src={SaleDealMate} />
      <Column
        className={styles['sale-promo-banner__text']}
        justifyContent='center'
      >
        <Paragraph weight={700} padding='none' color='brand'>
          {t('october.sale.promo.nice.to.deal.with.you')}
        </Paragraph>
        <Paragraph size={12} padding='none' color='brand'>
          <Badge type='warning'>-50%</Badge>
          {promoPeriodText}
        </Paragraph>
      </Column>
    </Row>
  )
}
