import { PLANS } from '@constants/plans'
import { Icon, Paragraph, Spacer, Spreader, Tooltip } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Row } from 'simple-flexbox'

import { PlanRecordKey } from '../types'

const { PROFESSIONAL_24, UNLIMITED_22 } = PLANS

export const getFeatures = (
  planRecordKey: PlanRecordKey,
  t: TFunction<'translation', undefined>
) => {
  const isUnlimitedPlan = planRecordKey === UNLIMITED_22

  const isProfessionalOrUnlimitedPlan =
    planRecordKey === PROFESSIONAL_24 || planRecordKey === UNLIMITED_22

  return (
    <Fragment>
      <Paragraph weight={600}>
        {t('compare.plans.description.landing.page.builder')}
      </Paragraph>

      <Spacer space={5} />

      <Paragraph weight={600}>
        {t('compare.plans.description.form.builder')}
      </Paragraph>

      <Spacer space={5} />

      <Paragraph weight={600}>
        {t('compare.plans.description.fast.cloud.hosting')}
      </Paragraph>

      <Spacer space={5} />

      <Paragraph weight={600}>
        {t('compare.plans.description.all.integrations')}
      </Paragraph>

      <Spacer space={5} />

      <Tooltip content={t('compare.plans.description.ai.assistant.tooltip')}>
        <Row>
          <Paragraph weight={600}>
            {t('compare.plans.description.ai.assistant')}
          </Paragraph>

          <Spreader spread={5} />

          <Icon icon='icon-help_outline' size={14} color='neutral-4' />
        </Row>
      </Tooltip>

      <Spacer space={5} />

      <Paragraph weight={600}>
        {t('compare.plans.description.pre.designed.sections')}
      </Paragraph>

      {isProfessionalOrUnlimitedPlan && (
        <Fragment>
          <Spacer space={5} />

          <Tooltip
            content={t(
              'plans.another.features.programmatic.landing.page.tooltip'
            )}
          >
            <Row>
              <Paragraph weight={600}>
                {t('plans.another.features.programmatic.landing.page')}
              </Paragraph>

              <Spreader spread={5} />

              <Icon icon='icon-help_outline' size={14} color='neutral-4' />
            </Row>
          </Tooltip>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.smart.sections')}
          </Paragraph>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.custom.fonts')}
          </Paragraph>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.ab.testing')}
          </Paragraph>

          <Spacer space={5} />

          <Tooltip
            content={t('compare.plans.description.ecommerce.hub.tooltip')}
          >
            <Row>
              <Paragraph weight={600}>
                {t('compare.plans.description.ecommerce.hub')}
              </Paragraph>

              <Spreader spread={5} />

              <Icon icon='icon-help_outline' size={14} color='neutral-4' />
            </Row>
          </Tooltip>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.popup.builder')}
          </Paragraph>
        </Fragment>
      )}

      {isUnlimitedPlan && (
        <Fragment>
          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.audit.log')}
          </Paragraph>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.reverse.proxy')}
          </Paragraph>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.domain.whitelisting')}
          </Paragraph>

          <Spacer space={5} />

          <Tooltip
            content={t('compare.plans.description.custom.development.tooltip')}
          >
            <Row>
              <Paragraph weight={600}>
                {t('compare.plans.description.custom.development')}
              </Paragraph>

              <Spreader spread={5} />

              <Icon icon='icon-help_outline' size={14} color='neutral-4' />
            </Row>
          </Tooltip>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.dedicatet.account.manager')}
          </Paragraph>

          <Spacer space={5} />

          <Paragraph weight={600}>
            {t('compare.plans.description.custom.paper.work')}
          </Paragraph>
        </Fragment>
      )}
    </Fragment>
  )
}
