export const NONE = 'none' as const
export const DISABLED = 'disabled' as const

export const PUBLISHED = 'published' as const
export const UNPUBLISHED = 'unpublished' as const

export const POPUP_DISPLAY_RULES = {
  DEFAULT: 'default',
  ACTIVE: 'active',
  SUCCESS: 'success',
  ERROR: 'error'
} as const
