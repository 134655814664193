import { NEW_LANDINGI_URL } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useLazyService } from '@hooks/useLazyService'
import SearchEmpty from '@images/search_empty.svg'
import {
  Alert,
  Button,
  emitTimingToastToggle,
  InputForm,
  Message,
  PerfectDropdownSelect
} from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import {
  domainPath,
  domainStatusDisabled,
  getLandingPageData
} from '@pages/Landings/routes/LandingsPublishingOptions/helpers/domains'
import { LANDINGS } from '@routes/path'
import { useGetDomainsListInfinite } from '@services/domains/list'
import { assignDomainToLandingPage } from '@services/landings/publishingOptions'
import { useGetLanding } from '@services/landings/useGetLanding'
import { Divider, Icon, Spacer, Spreader, Tooltip } from '@ui-kit'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'
import { v4 as uuidv4 } from 'uuid'

import styles from './ChangeUrl.module.scss'
import changeUrlSchema from './changeUrlSchema'
import { DomainLabel } from './components/DomainLabel'
import { Header } from './components/Header'
import { TestDomain } from './components/TestDomain'

export const ChangeUrl = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { mutate } = useSWRConfig()

  const initialLandingPageToDetach = useMemo(
    () => ({
      uuid: '',
      name: '',
      hash: ''
    }),
    []
  )

  const [landingPageToDetach, setLandingPageToDetach] = useState<{
    uuid: string | null
    name: string | null
    hash: string | null
  }>(initialLandingPageToDetach)

  const [searchPhrase, setSearchPhrase] = useState('')

  const { data, isLoading, size, setSize, total } = useGetDomainsListInfinite({
    filters: {
      name: searchPhrase
    }
  })

  const { isLoading: landingPageIsLoading, error: landingPageError } =
    useGetLanding({
      uuid: landingPageToDetach.uuid || ''
    })

  const alertIsVisible =
    landingPageToDetach.uuid &&
    landingPageError?.response?.status !== 404 &&
    !landingPageIsLoading

  const options =
    data?.flat().map(({ name, status }) => ({
      value: name,
      label: <DomainLabel label={name} status={status} />,
      disabled: domainStatusDisabled(status),
      tooltip: domainStatusDisabled(status)
        ? t('publishing.options.change.url.domain.tooltip')
        : undefined
    })) || []

  const {
    uuid,
    defaultChangeUrlProps,
    landingPageUrls: { handleRefreshlUrls },
    publishingOptions: {
      data: { accountTestDomain, isDedicatedTestDomain }
    }
  } = usePublishingOptionsContext()

  const handleBackToMainView = () => {
    navigate(
      generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
        identifier: uuid
      })
    )
  }

  const isUnbrandedContent = useFeatureFlags('UNBRANDED_CONTENT')

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const isFreePlanRestrictions =
    useFeatureFlags('FREE_PLAN_RESTRICTIONS') &&
    !hasAllowTestAutoDomainFeatureFlag

  const initialValues = {
    domain: defaultChangeUrlProps.selectedDomain || '',
    path: defaultChangeUrlProps.selectedPath || ''
  }

  const [handleAssignDomainToLandingPage] = useLazyService(
    assignDomainToLandingPage,
    {
      onSuccess: () => {
        mutate(`landing-page/landings/${uuid}/publishing-options`)

        handleRefreshlUrls()
        navigate(
          generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
            identifier: uuid
          })
        )
      },
      successToastText: t('publishing.options.change.url.toast.success'),
      onError: error => {
        const { response } = error as AxiosError<{
          error: {
            code: string
            message: string
          }
        }>

        const code = response?.data?.error?.code
        const message = response?.data?.error?.message

        // LP0002 - limited user permissions
        if (code === 'LP0002') {
          emitTimingToastToggle(
            t('publishing.options.change.url.permission.toast.error'),
            'alert'
          )

          return
        }

        // LP0006 - forbidden path
        if (code === 'LP0006') {
          emitTimingToastToggle(
            <Trans
              i18nKey='publishing.options.change.url.block.toast.error'
              components={{
                a: (
                  <a
                    href={t('publishing.options.change.url.path.url')}
                    target='_blank'
                    rel='noreferrer'
                  />
                )
              }}
            />,
            'alert'
          )

          return
        }

        // LP0005 - domain already assigned
        if (code === 'LP0005') {
          setLandingPageToDetach(getLandingPageData(message || ''))

          emitTimingToastToggle(
            t('publishing.options.change.url.exist.toast.error'),
            'alert'
          )

          return
        }

        emitTimingToastToggle(t('error.page.generic.title'), 'alert')
      }
    }
  )

  const onSubmit = async ({
    domain,
    path
  }: {
    domain: string
    path: string
  }) => {
    await handleAssignDomainToLandingPage({
      uuid,
      domain,
      path: domainPath(path)
    })
  }

  const {
    values,
    setValues,
    handleSubmit,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    dirty
  } = useFormik<{ domain: string; path: string }>({
    initialValues,
    validationSchema: changeUrlSchema,
    onSubmit,
    enableReinitialize: true
  })

  const [domainDetach] = useLazyService(assignDomainToLandingPage, {
    onSuccess: () => {
      setLandingPageToDetach(initialLandingPageToDetach)
      handleSubmit()
    },
    errorToastText: t('toast.error.something.went.wrong')
  })

  const handleDomainDetach = async (uuid?: string | null) => {
    if (!uuid) return

    domainDetach({
      uuid,
      domain: accountTestDomain,
      path: `/${uuidv4()}`
    })
  }

  const handleCancelDetach = useCallback(() => {
    setLandingPageToDetach(initialLandingPageToDetach)
  }, [setLandingPageToDetach, initialLandingPageToDetach])

  const { domain, path } = values

  const testDomainSelected =
    domain === accountTestDomain && !isDedicatedTestDomain
  const [assignedUrl, setAssignedUrl] = useState<string>('')

  const handleSelectOnChange = (
    field: string | number | null,
    value?: string | number | null
  ) => {
    if (field && value && typeof value === 'string') {
      setValues({ domain: value, path: '' })
    }

    handleCancelDetach()
  }

  const handleChangePath = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e)

    handleCancelDetach()
  }

  const handleLoadMore = () => {
    setSize(size + 1)
  }

  const optionalContent = (
    <Fragment>
      {isFreePlanRestrictions ? null : (
        <TestDomain
          setValues={setValues}
          handleCancelDetach={handleCancelDetach}
        />
      )}

      <div>
        <Divider />

        <Button
          variant='secondary'
          hasIcon
          fitWidth
          align='left'
          onClick={() =>
            window.open(`https://${NEW_LANDINGI_URL}/domains`, '_self')
          }
        >
          <Icon icon='icon-plus' color='primary' />

          {t('publishing.options.change.url.dropdown.add')}
        </Button>
      </div>
    </Fragment>
  )

  const inputDescription = isUnbrandedContent ? null : (
    <span className={styles['path-input__description']}>
      <Icon icon='icon-info-circle' />

      <span>
        <Trans
          i18nKey='publishing.options.change.url.path.description'
          components={{
            a: (
              <a
                href={t('publishing.options.change.url.path.url')}
                target='_blank'
                rel='noreferrer'
              />
            )
          }}
        />
      </span>
    </span>
  )

  const emptyMessage = (
    <Message
      title={t('message.empty.search.results')}
      message={t('message.empty.search.results.small.2')}
      url={SearchEmpty}
      height={60}
      titleLevel={4}
      messageLevel={5}
    />
  )

  const itemsPerPage = 10

  const isLastPage = total / itemsPerPage <= size

  return (
    <Fragment>
      <Header />

      <Spacer space={30} />

      <form
        className={styles['configuration-panel-wrapper']}
        onSubmit={handleSubmit}
        noValidate
      >
        <Column>
          <Row horizontal='space-between'>
            <PerfectDropdownSelect
              className={styles['dropdown-select']}
              label={t('word.subdomain')}
              value={domain}
              options={options}
              optionalContent={optionalContent}
              onChange={handleSelectOnChange}
              handleOnSearchChange={(value?: string) =>
                setSearchPhrase(value || '')
              }
              formikKey='domain'
              overflowStyle={{ maxHeight: 180 }}
              i18n={{
                placeholder: t('word.subdomain'),
                loadmore: t('word.loadmore')
              }}
              hasSearcher
              customValue
              dropdownLabel={({ value }) => value}
              emptyMessage={emptyMessage}
              hasLoadMoreButton={!isLastPage}
              loadMoreEvent={handleLoadMore}
              isLoading={isLoading}
              data-testid='domain-select'
            />

            <Spreader spread={10} />

            <Column className={styles['path-slash']} vertical='end'>
              /
            </Column>

            <Spreader spread={10} />

            <Tooltip
              content={
                <Trans i18nKey='publishing.options.test.domain.selected.tooltip' />
              }
              disabled={!testDomainSelected}
            >
              <InputForm
                className={styles['path-input']}
                field={{
                  name: 'path',
                  value: path,
                  onChange: handleChangePath,
                  onBlur: handleBlur
                }}
                i18n={{
                  placeholder: t(
                    'publishing.options.change.url.path.placeholder'
                  ),
                  label: t('publishing.options.change.url.path.placeholder'),
                  description: inputDescription
                }}
                form={{
                  errors,
                  touched
                }}
                disabled={testDomainSelected}
                background='transparent'
              />
            </Tooltip>
          </Row>

          <Spacer space={30} />

          {alertIsVisible ? (
            <Fragment>
              <Alert type='info'>
                <Row horizontal='space-between' vertical='center'>
                  <Column>
                    <span>
                      <a
                        href={`https://${assignedUrl}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {assignedUrl}
                      </a>

                      <Trans
                        i18nKey='publishing.options.change.url.alert'
                        components={{
                          a: (
                            <a
                              className={styles.link}
                              href={`https://${NEW_LANDINGI_URL}/preview/${landingPageToDetach.hash}`}
                              target='_blank'
                              rel='noreferrer'
                            />
                          )
                        }}
                        values={{
                          name: landingPageToDetach.name
                        }}
                      />
                    </span>
                  </Column>

                  <Row>
                    <Button
                      variant='secondary'
                      size='tiny'
                      onClick={handleCancelDetach}
                    >
                      {t('word.no').toUpperCase()}
                    </Button>

                    <Button
                      variant='secondary'
                      size='tiny'
                      onClick={() =>
                        handleDomainDetach(landingPageToDetach.uuid)
                      }
                    >
                      {t('word.yes').toUpperCase()}
                    </Button>
                  </Row>
                </Row>
              </Alert>

              <Spacer space={30} />
            </Fragment>
          ) : null}

          <Row justifyContent='flex-end'>
            <Button variant='secondary' onClick={handleBackToMainView}>
              {t('word.cancel')}
            </Button>

            <Spreader spread={10} />

            <Button
              type='submit'
              isLoading={isSubmitting}
              isDisabled={Boolean(
                isSubmitting || !isValid || !dirty || landingPageToDetach.uuid
              )}
              onClick={() => {
                setAssignedUrl(path ? `${domain}/${path}` : domain)
              }}
            >
              {t('word.save')}
            </Button>
          </Row>
        </Column>
      </form>
    </Fragment>
  )
}

ChangeUrl.displayName = 'PublishingOptionsViewsChangeUrl'
