import {
  Button,
  emitTimingToastToggle,
  InputForm
} from '@landingi/landingi-ui-kit'
import { postResetPassword } from '@services/authentication'
import { useFormik } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import FormSchema from './FormSchema'

const ResetPasswordForm = () => {
  const { t } = useTranslation()

  /**
   * onSubmit - handles submit of user data
   * @param  {object} values - formik values
   * @type {function}
   */
  const onSubmit = useCallback(async values => {
    const { email } = values

    try {
      await postResetPassword({ email })
    } finally {
      emitTimingToastToggle(
        t('registration.flow.reset.password.success'),
        'success'
      )
    }
  }, [])

  const initialValues = {
    email: ''
  }

  const {
    isSubmitting,
    handleSubmit,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = useFormik({ initialValues, validationSchema: FormSchema(), onSubmit })

  return (
    <form onSubmit={handleSubmit}>
      <Column>
        <InputForm
          field={{
            name: 'email',
            value: values.email,
            onChange: handleChange,
            onBlur: handleBlur
          }}
          id='email'
          form={{
            errors,
            touched
          }}
          i18n={{
            placeholder: t('registration.flow.email'),
            label: t('registration.flow.email')
          }}
        />

        <Button
          type='submit'
          size='large'
          isLoading={isSubmitting}
          isDisabled={!dirty || !isValid || isSubmitting}
        >
          {t('registration.flow.reset.password')}
        </Button>
      </Column>
    </form>
  )
}

ResetPasswordForm.displayName = 'ResetPasswordForm'

export default ResetPasswordForm
