import AmexImage from '@images/registration/creditCard/amex.svg'
import DiscoverImage from '@images/registration/creditCard/discover.svg'
import MaestroImage from '@images/registration/creditCard/maestro.svg'
import MasterCardImage from '@images/registration/creditCard/master_card.svg'
import VisaImage from '@images/registration/creditCard/visa.svg'

export const cards = [
  {
    name: 'Visa',
    prefixNumber: /^4/,
    cvvLength: 3,
    image: VisaImage
  },
  {
    name: 'Mastercard',
    prefixNumber: /^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720|5[1-5])/,
    cvvLength: 3,
    image: MasterCardImage
  },
  {
    name: 'Maestro',
    prefixNumber:
      /^(5018|5020|5038|5893|6304|6759|6761|6762|6763|6759|676770|676774)/,
    cvvLength: 3,
    image: MaestroImage
  },
  {
    name: 'Discover',
    prefixNumber:
      /^(62212[6-9]|6221[3-9]\d|622[2-8]\d{2}|6229[01]\d|62292[0-5]|6011|644|645|646|647|648|649|65)/,
    cvvLength: 3,
    image: DiscoverImage
  },
  {
    name: 'Amex',
    prefixNumber: /^(34|37)/,
    cvvLength: 4,
    image: AmexImage
  }
]
