import addonsStore from '@images/tours/addonsStore/addons-store.png'
import { Image } from '@ui-kit/Image'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Trans } from 'react-i18next'
import { Column } from 'simple-flexbox'

export const steps = [
  {
    target: '#sidebar-add-ons',
    content: (
      <Column>
        <Paragraph color='white' size={14} weight={600}>
          <Trans
            i18nKey='addons.store.tour.navigations.title'
            components={{ b: <b /> }}
          />
        </Paragraph>

        <Spacer space={10} />

        <Image src={addonsStore} />

        <Spacer space={10} />

        <Paragraph color='white' size={12}>
          <Trans i18nKey='addons.store.tour.navigations.description' />
        </Paragraph>
      </Column>
    ),
    offset: 10,
    disableBeacon: true,
    placement: 'right-end' as const,
    locale: {
      last: <Trans i18nKey='addons.store.tour.navigations.close' />
    }
  }
]
