import { useStyles } from '@hooks/useStyles'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spreader } from '@ui-kit/Spreader'
import {
  FC,
  FocusEventHandler,
  Fragment,
  ReactEventHandler,
  ReactNode
} from 'react'

import styles from './Toggle.module.scss'

export interface ToggleProps {
  id: string
  className?: string | string[]
  name?: string
  color?: 'success' | 'primary'
  label?: ReactNode
  labelIsClickable?: boolean
  onChange?: ReactEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  checked?: boolean
  disabled?: boolean
  isLoading?: boolean
  'data-testid'?: string
}

export const Toggle: FC<ToggleProps> = ({
  id,
  className,
  name,
  color = 'success',
  label,
  labelIsClickable,
  onChange,
  onBlur,
  checked,
  disabled,
  isLoading,
  'data-testid': dataTestId = 'toggle'
}) => {
  const containerStyles = useStyles(
    {
      [styles.toggle__container]: true
    },
    className
  )

  const toggleStyles = useStyles({
    [styles.toggle]: true,
    [styles[`toggle__checked-${color}`]]: checked && color,
    [styles[`toggle__checked-${color}-disabled`]]: checked && disabled && color,
    [styles['toggle-disabled']]: !checked && disabled
  })

  const labelStyles = useStyles({
    [styles['toggle__label-not-clickable']]: !labelIsClickable
  })

  return (
    <div className={containerStyles}>
      <label
        className={toggleStyles}
        htmlFor={id}
        data-testid={`${dataTestId}-wrapper`}
      >
        <input
          id={id}
          type='checkbox'
          className={styles.toggle__checkbox}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
          disabled={disabled}
          data-testid={dataTestId}
        />

        <span className={styles.toggle__button}>
          {isLoading && (
            <Icon
              icon='icon-spinner'
              color='neutral-2'
              size={12}
              spin
              data-testid={`${dataTestId}-spinner`}
            />
          )}
        </span>
      </label>

      {label && (
        <Fragment>
          <Spreader spread={10} />

          <label
            className={labelStyles}
            htmlFor={id}
            data-testid={`${dataTestId}-label`}
          >
            <Paragraph size={16}>{label}</Paragraph>
          </label>
        </Fragment>
      )}
    </div>
  )
}
