import { getWhatHappensAfterFreeTrialQuestion } from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import ADDONS_STORE from '@routes/path/addons'
import { Paragraph, Spacer, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getWhatHappensAfterFreeTrialQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.ab.tests.faq.question.2')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>{t('addons.store.ab.tests.faq.answer.2')}</Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.ab.tests.faq.question.3')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        <Trans
          i18nKey='addons.store.ab.tests.faq.answer.3'
          components={{
            br: <Spacer space={10} />,
            a: <Link to={ADDONS_STORE.EVENT_TRACKER_ADDON} />
          }}
        />
      </Paragraph>
    )
  }
]
