import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, CustomLink, Modal, Paragraph } from '@landingi/landingi-ui-kit'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './PrivacyPolicy.module.scss'

const PrivacyPolicy = NiceModal.create(() => {
  const { t } = useTranslation()
  const { remove } = useModal()

  return (
    <Modal
      isActive
      onClick={remove}
      i18n={{
        title: t('registration.flow.privacy.policy.title')
      }}
      className={styles['privacy-policy-modal']}
    >
      <Paragraph>
        <Trans i18nKey='registration.flow.privacy.policy.first.paragraph' />
      </Paragraph>

      <Paragraph>
        {t('registration.flow.privacy.policy.second.paragraph')}

        <CustomLink
          href={t('word.create-support-ticket.url')}
          target='_blank'
          rel='noreferrer'
          label={t('support.email')}
        />
      </Paragraph>

      <Paragraph>
        {t('registration.flow.privacy.policy.third.paragraph')}
      </Paragraph>

      <Paragraph>
        {t('registration.flow.privacy.policy.fourth.paragraph')}
        <CustomLink
          href={t('privacy.policy.link')}
          target='_blank'
          rel='noreferrer'
          label={t('privacy.policy')}
        />
      </Paragraph>

      <Row justifyContent='flex-end'>
        <Button size='medium' onClick={remove}>
          {t('word.close')}
        </Button>
      </Row>
    </Modal>
  )
})

export default PrivacyPolicy
