import {
  CustomLink,
  Heading,
  Panel,
  Paragraph,
  Spacer
} from '@landingi/landingi-ui-kit'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import RecoveryCodeForm from './Form'

const RecoveryCode = () => {
  const { t } = useTranslation()

  return (
    <Column>
      <Panel borderRadius='10px'>
        <Heading level={1} bold>
          {t('two.factor.authentication.page.recovery.code.heading')}
        </Heading>

        {t('two.factor.authentication.enter.one.of.a.codes')}

        <Spacer space='medium' />

        <RecoveryCodeForm />
      </Panel>

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Row justifyContent='space-between'>
        <Paragraph size={12} color='white'>
          {t('two.factor.authentication.found.your.phone')}
          <Link
            to={`${REGISTRATION.TWO_FACTOR_AUTHENTICATION_WEB_TO_LEARN}`}
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            {t('two.factor.authentication.found.your.phone.link')}
          </Link>
        </Paragraph>

        <Paragraph size={12} color='white'>
          {t('two.factor.authentication.cant.find')}
          <CustomLink
            underlined
            target='_blank'
            size={12}
            href={t('word.create-support-ticket.url')}
            label={t('two.factor.authentication.cant.find.link')}
            color='white'
          />
        </Paragraph>
      </Row>
    </Column>
  )
}

RecoveryCode.displayName = 'RecoveryCode'

export default RecoveryCode
