import { Image, Spreader } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * CVVTooltipContent - stateless presentational component, content of cvv tooltip shown in credit card forms
 * @param {string} image - card image url
 * @return {object} children
 */
const CVVTooltipContent = ({ image }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      {t('subscription.credit.card.cvv.code')}

      <Spreader spread='tiny' />

      <Image size={100} src={image} />
    </Fragment>
  )
}

CVVTooltipContent.displayName = 'CVVTooltipContent'

CVVTooltipContent.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}

export default CVVTooltipContent
