import { useUserContext } from '@contexts/user'
import client from '@lib/http/client'
import { REGISTRATION } from '@routes/path'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import useSWR from 'swr'

export const useValidateChangePasswordToken = () => {
  const { isLoading, isAuth } = useUserContext()
  const params = useParams()
  const { pathname } = useLocation()

  const isPasswordChangeRoute =
    !isAuth &&
    matchPath(REGISTRATION.CHANGE_PASSWORD.DEFAULT, pathname) &&
    params?.token

  const {
    error: changePasswordTokenValidationError,
    isLoading: isChangePasswordTokenValidating
  } = useSWR(
    !isLoading && isPasswordChangeRoute
      ? ['auth/reset-password/validate', params?.token]
      : null,
    ([url, token]) => client.post(url, { token }),
    { shouldRetryOnError: false }
  )

  const hasAccessToPasswordChange =
    changePasswordTokenValidationError &&
    !isChangePasswordTokenValidating &&
    isPasswordChangeRoute

  return {
    hasAccessToPasswordChange,
    isChangePasswordTokenValidating
  }
}
