import { t } from 'i18next'
import { object, string } from 'yup'

const FormSchema = () =>
  object().shape({
    email: string()
      .email(t('registration.flow.sign.up.invalid.mail'))
      .max(250)
      .required(t('form.validation.isRequired'))
  })

export default FormSchema
