import { useStyles } from '@hooks/useStyles'
import { useTabsContext } from '@ui-kit/Tabs/context'
import { useVariantContext } from '@ui-kit/Tabs/context/VariantContext'
import { AnchorHTMLAttributes, FC, PointerEvent, ReactNode } from 'react'
import Ink from 'react-ink'
import { NavLink } from 'react-router-dom'

import styles from './Tab.module.scss'

export interface LinkTabProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'className' | 'href'> {
  className?: string | string[]
  children: ReactNode
  name: string
  onDisabledTabClick?: (event: PointerEvent<HTMLAnchorElement>) => void
  'data-testid'?: string
  to: string
}

export const LinkTab: FC<LinkTabProps> = ({
  className,
  children,
  name,
  onClick,
  onDisabledTabClick,
  'data-testid': dataTestId = 'tab',
  to,
  ...restProps
}) => {
  const { changeActiveTab } = useTabsContext()
  const { variant } = useVariantContext()

  const tabStyles = useStyles(
    {
      [styles.tab]: true,
      [styles[`tab--variant-${variant}`]]: true
    },
    className
  )

  const activeTabStyles = useStyles(
    {
      [styles.tab]: true,
      [styles[`tab--variant-${variant}`]]: true,
      [styles[`tab--variant-${variant}--active`]]: true
    },
    className
  )

  const handleClick = (event: PointerEvent<HTMLAnchorElement>) => {
    changeActiveTab(name)

    onClick?.(event)
  }

  const chooseStyle = ({ isActive }: { isActive: boolean }) =>
    isActive ? activeTabStyles : tabStyles

  return (
    <NavLink
      {...restProps}
      data-testid={dataTestId}
      className={chooseStyle}
      onClick={handleClick}
      to={to}
      end
    >
      {children}

      <Ink />
    </NavLink>
  )
}

LinkTab.displayName = 'LinkTab'
