import {
  getAIAddonFirstQuestion,
  getAIAddonSecondQuestion
} from '@pages/AddonsStore/routes/Addons/addons/helpers/FAQ'
import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  getAIAddonFirstQuestion(t),
  getAIAddonSecondQuestion(t),
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.ai.content.generation.question')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.ai.content.generation.answer')}
      </Paragraph>
    )
  }
]
