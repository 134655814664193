import { convertHexColorToRgba, convertRgbaToHex } from '@helpers/color'
import { useStyles } from '@hooks/useStyles'
import { HexInput, IntegerInput } from '@ui-kit/Input'
import { RGBColor } from '@ui-kit/types/color'
import { FC, MouseEventHandler, PointerEventHandler, useCallback } from 'react'
import { RgbaColorPicker } from 'react-colorful'
import { Row } from 'simple-flexbox'

import { Button } from '../Button'
import { Spacer } from '../Spacer'
import { Spreader } from '../Spreader'
import styles from './ColorPicker.module.scss'
import { useSelectPixelColor } from './hooks/useSelectPixelColor'
import { useShowMagnifier } from './hooks/useShowMagnifier'

export interface ColorPickerProps {
  colorValue: RGBColor
  includeAlpha?: boolean
  onChange: (newColor: RGBColor) => void
  onStartColorChange?: () => void
  onStartPixelColorSelecting?: () => void
  onFinishPixelColorSelecting?: () => void
  includeEyeDropper?: boolean
}

export const ColorPicker: FC<ColorPickerProps> = ({
  colorValue,
  includeAlpha = true,
  onChange,
  onStartColorChange,
  onStartPixelColorSelecting,
  onFinishPixelColorSelecting,
  includeEyeDropper = false
}) => {
  const colorPickerStyles = useStyles({
    [styles.colorPicker]: true,
    [styles['colorPicker--withoutAlpha']]: !includeAlpha
  })

  const { selectPixelColor } = useSelectPixelColor(
    onChange,
    onStartPixelColorSelecting,
    onFinishPixelColorSelecting
  )

  const { showMagnifier } = useShowMagnifier()

  const confirmAlphaChange = useCallback(
    (value: number) => {
      onChange({
        ...colorValue,
        a: value / 100
      })
    },
    [onChange, colorValue]
  )

  const handlePointerDown = useCallback<PointerEventHandler>(
    event => {
      /**
       * Prevents dragging the color picker from changing the color
       * when is used inside a draggable component as <Dialog/>
       */
      const stopDragging = () => {
        event.stopPropagation()
        document.dispatchEvent(new PointerEvent('pointerup'))
      }

      stopDragging()
      onStartColorChange?.()
    },
    [onStartColorChange]
  )

  const handleConfirmHexInput = useCallback(
    (hexColorValue: string) => {
      onChange(convertHexColorToRgba(hexColorValue))
    },
    [onChange]
  )

  const handleSelectPixelColor: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      async e => {
        const screenshotCanvas = await selectPixelColor()
        showMagnifier(e.pageX, e.pageY, screenshotCanvas)
      },
      [showMagnifier, selectPixelColor]
    )

  return (
    <div className={colorPickerStyles} data-testid='color-picker'>
      <div onPointerDown={handlePointerDown}>
        <RgbaColorPicker
          color={colorValue}
          onChange={onChange}
          data-testid='RgbaColorPicker'
        />
      </div>
      <Spacer space={10} />
      <Row
        className={styles.inputs}
        justifyContent={includeAlpha ? 'space-between' : 'flex-start'}
        alignItems='center'
      >
        {includeEyeDropper && (
          <Button
            icon='icon-create'
            size={12}
            variant='text-neutral'
            onClick={handleSelectPixelColor}
          />
        )}

        <HexInput
          className={styles.hex}
          id='color-picker-hex-input'
          value={convertRgbaToHex(colorValue)}
          onConfirm={handleConfirmHexInput}
        />

        {includeAlpha && (
          <Row alignItems='center'>
            <div className={styles.alphaLabel}>%</div>

            <Spreader spread={5} />

            <IntegerInput
              id='alpha'
              className={styles.alphaInput}
              size={14}
              valueToSet={Math.round(colorValue.a * 100)}
              min={0}
              max={100}
              onConfirm={confirmAlphaChange}
            />
          </Row>
        )}
      </Row>
    </div>
  )
}

ColorPicker.displayName = 'ColorPicker'
