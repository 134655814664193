import { CustomLink, Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={1} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.programmatic.lp.1')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.faq.answer.programmatic.lp.1')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.programmatic.lp.2')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.faq.answer.programmatic.lp.2')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.faq.question.programmatic.lp.3')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.faq.answer.programmatic.lp.3')}
        <CustomLink
          href={t('addons.store.faq.answer.programmatic.lp.3.link')}
          target='_blank'
        >
          {t('addons.store.faq.answer.programmatic.lp.3.help.center')}
        </CustomLink>
        .
      </Paragraph>
    )
  }
]
