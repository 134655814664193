import { Acl } from '@components/Acl'
import { lazy } from '@helpers/lazy'
import CleanLayout from '@layouts/Clean/Clean'
import { FullLayout } from '@layouts/FullLayout'
import { PROGRAMMATIC } from '@routes/path'

const Programmatic = lazy(() =>
  import('@pages/Programmatic/routes/Programmatic').then(
    ({ Programmatic }) => ({
      default: Programmatic
    })
  )
)

const ProgrammaticProcess = lazy(() =>
  import('@pages/Programmatic/routes/ProgrammaticProcess').then(
    ({ ProgrammaticProcess }) => ({
      default: ProgrammaticProcess
    })
  )
)

const CreateModal = lazy(() =>
  import('@pages/Programmatic/routes/CreateModal').then(({ CreateModal }) => ({
    default: CreateModal
  }))
)

export const routes = [
  {
    path: PROGRAMMATIC.PROGRAMMATIC,
    element: (
      <Acl acl='programmatic_landing_page.process.view_list'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: PROGRAMMATIC.PROGRAMMATIC,
        element: <Programmatic />
      }
    ]
  },
  {
    path: PROGRAMMATIC.PROGRAMMATIC_PROCESS,
    element: (
      <Acl acl='programmatic_landing_page.process.view_list'>
        <FullLayout />
      </Acl>
    ),
    children: [
      {
        path: PROGRAMMATIC.PROGRAMMATIC_PROCESS,
        element: <ProgrammaticProcess />
      }
    ]
  },
  {
    path: PROGRAMMATIC.CREATE_MODAL,
    element: (
      <Acl acl='programmatic_landing_page.process.create'>
        <CleanLayout />
      </Acl>
    ),
    children: [
      {
        path: PROGRAMMATIC.CREATE_MODAL,
        element: <CreateModal />
      }
    ]
  }
]
