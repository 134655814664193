import { SectionType } from '../types'

export const ELEGANT_HERO_1: SectionType = {
  name: 'elegant_hero_1'
}

export const ELEGANT_HERO_2: SectionType = {
  name: 'elegant_hero_2'
}

export const ELEGANT_HERO_3: SectionType = {
  name: 'elegant_hero_3'
}

export const ELEGANT_HERO_4: SectionType = {
  name: 'elegant_hero_4'
}

export const ELEGANT_HERO_5: SectionType = {
  name: 'elegant_hero_5'
}

export const ELEGANT_HERO_6: SectionType = {
  name: 'elegant_hero_6'
}

export const ELEGANT_HERO_7: SectionType = {
  name: 'elegant_hero_7'
}

export const ELEGANT_HERO_8: SectionType = {
  name: 'elegant_hero_8'
}

export const ELEGANT_HERO_9: SectionType = {
  name: 'elegant_hero_9'
}

export const ELEGANT_HERO_10: SectionType = {
  name: 'elegant_hero_10'
}

export const ELEGANT_HERO_11: SectionType = {
  name: 'elegant_hero_11'
}

export const ELEGANT_HERO_12: SectionType = {
  name: 'elegant_hero_12'
}

export const ELEGANT_HERO_13: SectionType = {
  name: 'elegant_hero_13'
}

export const ELEGANT_HERO_14: SectionType = {
  name: 'elegant_hero_14'
}

export const ELEGANT_HERO_15: SectionType = {
  name: 'elegant_hero_15'
}

export const ELEGANT_HERO_16: SectionType = {
  name: 'elegant_hero_16'
}

export const ELEGANT_HERO_17: SectionType = {
  name: 'elegant_hero_17'
}

export const ELEGANT_HERO_18: SectionType = {
  name: 'elegant_hero_18'
}

export const ELEGANT_HERO_19: SectionType = {
  name: 'elegant_hero_19'
}

export const ELEGANT_HERO_20: SectionType = {
  name: 'elegant_hero_20'
}

export const ELEGANT_HERO_21: SectionType = {
  name: 'elegant_hero_21'
}

export const ELEGANT_HERO_22: SectionType = {
  name: 'elegant_hero_22'
}

export const ELEGANT_HERO_23: SectionType = {
  name: 'elegant_hero_23'
}
