import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={1} />

        <Spreader spread={15} />

        {t('addons.store.ai.multi.language.faq.question.1')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.ai.multi.language.faq.answer.1')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        {t('addons.store.ai.multi.language.faq.question.2')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.ai.multi.language.faq.answer.2')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={3} />

        <Spreader spread={15} />

        {t('addons.store.ai.multi.language.faq.question.3')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.ai.multi.language.faq.answer.3')}
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={4} />

        <Spreader spread={15} />

        {t('addons.store.ai.multi.language.faq.question.4')}
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        {t('addons.store.ai.multi.language.faq.answer.4')}
      </Paragraph>
    )
  }
]
