import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Modal, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './PayPal.module.scss'

/**
 * PayPal stateful container component
 * @return {object} An object of children elements
 */
export const PayPalModal = NiceModal.create(({ button }) => {
  const { t } = useTranslation()
  const { remove, visible } = useModal()

  useEffect(() => {
    if (visible) {
      button?.render('#paypal-button')
    }
  }, [visible])

  return (
    <Modal
      isActive
      i18n={{
        title: t('registration.flow.credit.card.step.continue.with.paypal')
      }}
      onClick={remove}
      className={styles['paypal-modal']}
    >
      <Paragraph size={16} line={22} padding='none'>
        <Trans i18nKey='registration.flow.credit.card.step.paypal.paragraph' />
      </Paragraph>

      <Spacer />

      <Row justifyContent='flex-end'>
        <div id='paypal-button' className={styles['paypal-button']} />
      </Row>
    </Modal>
  )
})

PayPalModal.displayName = 'PaypalModal'

PayPalModal.propTypes = {
  button: PropTypes.object
}
