import AgencyUseCase1 from '@images/addons/usecases/agency/Agency-usecase-1.png'
import AgencyUseCase2 from '@images/addons/usecases/agency/Agency-usecase-2.png'
import { Paragraph, Spacer } from '@ui-kit'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { Trans } from 'react-i18next'

export const getUseCases = (t: TFunction<'translation', undefined>) => [
  {
    title: <Trans i18nKey='addons.store.advertise.agency.use.cases.1.title' />,
    image: AgencyUseCase1,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.advertise.agency.use.cases.1.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.advertise.agency.use.cases.1.content.paragraph.2')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.advertise.agency.use.cases.1.content.paragraph.3')}
        </Paragraph>
      </Fragment>
    )
  },
  {
    title: t('addons.store.advertise.agency.use.cases.2.title'),
    reverse: true,
    image: AgencyUseCase2,
    content: (
      <Fragment>
        <Paragraph>
          {t('addons.store.advertise.agency.use.cases.2.content.paragraph.1')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.advertise.agency.use.cases.2.content.paragraph.2')}
        </Paragraph>

        <Spacer space={10} />

        <Paragraph>
          {t('addons.store.advertise.agency.use.cases.2.content.paragraph.3')}
        </Paragraph>
      </Fragment>
    )
  }
]
