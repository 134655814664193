import { useAcl } from '@contexts/acl'
import { useModal } from '@ebay/nice-modal-react'
import { ACCOUNT } from '@routes/path'
import { mixpanelEvent } from '@services/mixpanel'
import { Button, Tooltip } from '@ui-kit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from '../ModalUpgradePlan.module.scss'

interface PrimaryButtonProps {
  customContent?: string
  customUrl?: string
  featureNameForMixpanel?: string
}

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  customContent,
  customUrl,
  featureNameForMixpanel
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { remove } = useModal()

  const hasAccessToChangePlan = useAcl('plan.change_plan')

  const handleClick = () => {
    if (featureNameForMixpanel) {
      mixpanelEvent({
        name: 'Access to feature - upgrade button',
        properties: {
          feature: featureNameForMixpanel
        }
      })
    }

    if (customUrl) {
      navigate(customUrl)

      remove()

      return
    }

    navigate(ACCOUNT.ACCOUNT_PACKAGE)

    remove()
  }

  const content = customContent || t('upgrade.account')

  return (
    <Tooltip
      content={t('upgrade.account.tooltip')}
      disabled={hasAccessToChangePlan}
    >
      <Button
        className={styles['upgrade-modal__footer__link']}
        variant='primary'
        onClick={handleClick}
        disabled={!hasAccessToChangePlan}
      >
        {content}
      </Button>
    </Tooltip>
  )
}
