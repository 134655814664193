import moment from 'moment-timezone'

moment.tz.setDefault('UTC')

export const formatNumber = (number: number) => {
  return number.toString().padStart(2, '0')
}

export const getDateRangeForEveryMonth = ({
  startDay,
  endDay
}: {
  startDay: number
  endDay?: number
}) => {
  const lastDayOfMonth = moment().endOf('month').date()
  const currentMonth = moment().month()
  const currentYear = moment().year()

  const startDate = moment({
    year: currentYear,
    month: currentMonth,
    day: startDay
  })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format('YYYY-MM-DD')

  const endDate = moment({
    year: currentYear,
    month: currentMonth,
    day: endDay ?? lastDayOfMonth
  })
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .format('YYYY-MM-DD')

  return [startDate, endDate]
}

export const getCurrentDate = () => moment(new Date()).format('x')

export const convertStartDate = (dateString: string) => {
  return moment(dateString)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 999
    })
    .format('x')
}

export const convertTargetDate = (dateString: string) => {
  return moment(dateString)
    .set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999
    })
    .format('x')
}

export const getReturnValues = (countDown: number, isFinished: boolean) => {
  const days = formatNumber(Math.floor(countDown / (1000 * 60 * 60 * 24)))

  const hours = formatNumber(
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  )

  const minutes = formatNumber(
    Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  )

  const zeroTime = { days: '00', hours: '00', minutes: '00' }

  return {
    ...(isFinished ? zeroTime : { days, hours, minutes }),
    isFinished
  }
}
