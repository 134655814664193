import PulsingLogo from '@components/PulsingLogo'
import { useStyles } from '@hooks/useStyles'
import { Spacer } from '@landingi/landingi-ui-kit'
import Header from '@pages/Authentication/components/Header'
import RegistrationSecondColumn from '@pages/Authentication/components/SecondColumn/Registration'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { useHideSecondColumn } from '@pages/Authentication/hooks/useHideSecondColumn'
import styles from '@pages/Authentication/Layouts/Layout.module.scss'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { Column, Row } from 'simple-flexbox'

const Registration = ({ children }) => {
  const secondColumnIsHidden = useHideSecondColumn()

  const { logoControls, backgroundControls, shouldPlayFinalAnimation } =
    useAnimationContext()

  const firstColumnStyles = useStyles({
    [styles['mainLayout__first-column']]: true,
    [styles['mainLayout__first-column--only']]: secondColumnIsHidden
  })

  return shouldPlayFinalAnimation ? (
    <motion.div
      className={styles['final-animation-layout']}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <PulsingLogo />
    </motion.div>
  ) : (
    <Row className={styles.mainLayout}>
      <motion.div
        className={firstColumnStyles}
        initial={{ backgroundColor: '#fff' }}
        animate={backgroundControls}
      >
        <motion.div
          className={styles['first-column__content']}
          animate={logoControls}
        >
          <Header />

          {children}

          <Spacer space='small' />
        </motion.div>
      </motion.div>

      {!secondColumnIsHidden && (
        <Column className={styles['mainLayout__second-column']}>
          <RegistrationSecondColumn />
        </Column>
      )}
    </Row>
  )
}

Registration.displayName = 'RegistrationLayout'

Registration.propTypes = {
  children: PropTypes.node.isRequired
}

export default memo(Registration)
