export const ADDONS_IDS = {
  OPTIMIZATION_ADDON: 'ab-tests',
  ECOMMERCE_ADDON: 'e-commerce',
  POPUPS_ADDON: 'popups',
  SMART_SECTIONS: 'smart-sections',
  EVENT_TRACKER: 'eventtracker',
  COOKIE_CONSENT: 'cookie-consent',
  AI_SEO_GENERATION: 'ai-seo-generation',
  AI_MULTI_LANGUAGE: 'multi-language-landing-pages',
  AI_CONTENT_GENERATION: 'ai-content-generation',
  AI_BACKGROUND_REMOVER: 'ai-background-remover',
  IMPORT_FROM_FIGMA: 'import-from-figma',
  LANDING_PAGE_IMPORT: 'landing-page-import',
  LANDING_PAGE_DESIGN: 'landing-page-design',
  LEADS_ANONYMIZATION: 'leads-anonymization',
  PROGRAMMATIC_LP: 'programmatic-lp',
  AGENCY: 'agency'
} as const

export type AddonID = (typeof ADDONS_IDS)[keyof typeof ADDONS_IDS]
