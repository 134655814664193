import { Heading, Image, Paragraph } from '@landingi/landingi-ui-kit'
import ShapesContainer from '@pages/Authentication/components/SecondColumn/ShapesContainer'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ColumnContent.module.scss'

/**
 * Second column variant - stateless presentational component
 * @param {object} props - props
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {string} props.imageSrc
 * @return {object} An object of children
 */
const Variant = ({ title, subtitle, imageSrc }) => {
  const { t } = useTranslation()
  const { secondColumnControls } = useAnimationContext()

  return (
    <Fragment>
      <ShapesContainer />
      <motion.div
        className={styles['column-content']}
        animate={secondColumnControls}
      >
        <Heading level={3} bold>
          {t(title)}
        </Heading>

        <Paragraph color='accent-2' line={22}>
          {t(subtitle)}
        </Paragraph>

        <Image src={imageSrc} width='100%' />
      </motion.div>
    </Fragment>
  )
}

Variant.displayName = 'Variant'

Variant.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imageSrc: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object])
}

export default memo(Variant)
