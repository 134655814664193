import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import { Alert, Input, Modal, Spacer } from '@landingi/landingi-ui-kit'
import { removeSubaccount } from '@services/subaccounts'
import { ChangeEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface ModalSubaccountDeleteProps {
  uuid: string
  name: string
}

export const ModalSubaccountDelete =
  NiceModal.create<ModalSubaccountDeleteProps>(({ uuid, name }) => {
    const { t } = useTranslation()

    const [isButtonDisabled, setButtonDisabled] = useState(true)

    const { remove } = useModal()

    const [request, { isLoading }] = useLazyService(removeSubaccount, {
      successToastText: t('toast.delete.subaccount.success'),
      errorToastText: t('toast.delete.subaccount.error'),
      onSuccess: () => {
        remove()
      }
    })

    const handleSubaccountDelete = async () => {
      await request(uuid)
    }

    const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (!value.match(/^delete$/i)) {
        setButtonDisabled(true)

        return
      }

      setButtonDisabled(false)
    }

    return (
      <Modal
        isActive
        onClick={remove}
        i18n={{
          title: t('sidebar.modal.delete.heading'),
          action: t('word.delete'),
          cancel: t('word.cancel')
        }}
        isButtonLoading={isLoading}
        isButtonDisabled={isButtonDisabled || isLoading}
        onAction={handleSubaccountDelete}
        actionVariant='alert'
        hasFooter
        hasEnterKeyDown
      >
        <Alert type='warning'>
          <Trans
            i18nKey='sidebar.modal.delete.alert.warning'
            components={{
              b: <strong />
            }}
          />
        </Alert>

        <Spacer />

        <Trans
          i18nKey='sidebar.modal.delete.content'
          values={{ name }}
          components={{
            b: <strong />
          }}
        />

        <Spacer />

        <Input
          i18n={{
            placeholder: t('modal.delete.input.placeholder')
          }}
          onChange={handleOnChangeInput}
        />
      </Modal>
    )
  })

ModalSubaccountDelete.displayName = 'ModalSubaccountDelete'
