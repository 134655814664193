import { PLN } from '@constants/currencies'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {string} - currency
 * @type function
 * @return returns brutto text when currency is PLN
 */
const BruttoPrice = ({ currency }) => {
  const { t } = useTranslation()

  return currency === PLN ? (
    <Fragment> {t('registration.flow.credit.card.step.gross')}</Fragment>
  ) : null
}

BruttoPrice.displayName = 'BruttoPrice'

BruttoPrice.propTypes = {
  currency: PropTypes.string.isRequired
}

export default BruttoPrice
