import { motion } from 'framer-motion'
import { FC, ReactNode } from 'react'

interface ItemAnimationProps {
  children: ReactNode
}

export const ItemAnimation: FC<ItemAnimationProps> = ({ children }) => (
  <motion.div
    key='content'
    initial='collapsed'
    animate='open'
    exit='collapsed'
    variants={{
      open: { opacity: 1 },
      collapsed: { opacity: 0 }
    }}
    transition={{ duration: 1, ease: [0.65, 0, 0.3, 1] }}
  >
    {children}
  </motion.div>
)

ItemAnimation.displayName = 'SidebarItemAnimation'
