import { CurrencyType, PLN } from '@constants/currencies'
import { formatPrice } from '@helpers/payment'
import i18next from 'i18next'

export const NET = 'net'
export const GROSS = 'gross'

const convertPriceToGross = (nettoPrice: number) => nettoPrice * 1.23

const convertPriceToGrossWhenNeeded = (
  nettoPrice: number,
  currency: CurrencyType
) => (currency === PLN ? convertPriceToGross(nettoPrice) : nettoPrice)

const convertPriceToNet = (bruttoPrice: number) => bruttoPrice / 1.23

const convertPriceToNetWhenNeeded = (
  bruttoPrice: number,
  currency: CurrencyType
) => (currency === PLN ? convertPriceToNet(bruttoPrice) : bruttoPrice)

type PriceType = typeof NET | typeof GROSS

export const getPrice = (
  { price, accountCurrency }: { price: number; accountCurrency: CurrencyType },
  type: PriceType = NET
) => {
  let priceGross

  let priceNet

  if (type === NET) {
    priceGross = convertPriceToGrossWhenNeeded(price, accountCurrency)
    priceNet = price
  } else {
    priceNet = convertPriceToNetWhenNeeded(price, accountCurrency)
    priceGross = price
  }

  const formattedPriceGross = formatPrice(priceGross)
  const formattedPriceNet = formatPrice(priceNet)

  const currencyNet =
    accountCurrency === 'PLN'
      ? `${accountCurrency} ${i18next.t('word.net')}`
      : accountCurrency

  const currencyGross =
    accountCurrency === 'PLN'
      ? `${accountCurrency} ${i18next.t('word.gross')}`
      : accountCurrency

  const priceGrossWithCurrency = `${formattedPriceGross} ${currencyGross}`

  const priceNetWithCurrency = `${formattedPriceNet} ${currencyNet}`

  return {
    priceGrossWithCurrency,
    priceNetWithCurrency,
    priceNet: formattedPriceNet,
    priceGross: formattedPriceGross
  }
}
