import { useCallback, useEffect } from 'react'

export const useKeyPress = (targetKey: string, callback: () => void) => {
  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        callback()
      }
    },
    [targetKey, callback]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])
}
