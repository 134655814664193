import { useStyles } from '@hooks/useStyles'
import { Panel } from '@ui-kit/Panel'
import { FC, ReactNode } from 'react'

import styles from './DropdownList.module.scss'

export type DropdownListSize =
  | 'small'
  | 'medium'
  | 'big'
  | 'large'
  | 'auto'
  | 'full-width'

interface DropdownListProps {
  className?: string | string[]
  children: ReactNode
  size?: DropdownListSize
  'data-testid'?: string
}

export const DropdownList: FC<DropdownListProps> = ({
  className,
  children,
  size = 'auto',
  'data-testid': dataTestId = 'dropdown-list'
}) => {
  const dropdownListStyles = useStyles(
    {
      [styles['dropdown-list']]: true,
      [styles[`dropdown-list--size-${size}`]]: true
    },
    className
  )

  return (
    <Panel
      data-testid={dataTestId}
      className={dropdownListStyles}
      surface={2}
      padding={{ y: 15, x: 0 }}
    >
      {children}
    </Panel>
  )
}
