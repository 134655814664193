import { useStyles } from '@hooks/useStyles'
import { ButtonHTMLAttributes, forwardRef } from 'react'

import styles from './Button.module.scss'
import { ButtonContent } from './ButtonContent'
import { ButtonBaseProps } from './types'

export interface ButtonProps
  extends ButtonBaseProps,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = '',
      children,
      variant = 'primary',
      size = 14,
      type = 'button',
      isLoading = false,
      icon = '',
      hasBackgroundRipple = true,
      disabled,
      active,
      isSquare,
      fullWidth = false,
      indicator: Indicator,
      'data-testid': dataTestId,
      align = 'center',
      ...restProps
    },
    ref
  ) => {
    const buttonStyles = useStyles(
      {
        [styles.button]: true,
        [styles[`button--variant-${variant}`]]: variant,
        [styles[`button--variant-${variant}--active`]]: active,
        [styles[`button--variant-${variant}--loading`]]: isLoading,
        [styles[`button--size-${size}`]]: size,
        [styles[`button--size-${size}-square`]]:
          (icon && !children) || isSquare,
        [styles[`button--align-${align}`]]: align,
        [styles[`button--full-width`]]: fullWidth
      },
      className
    )

    return (
      <button
        {...restProps}
        ref={ref}
        type={type}
        className={buttonStyles}
        data-testid={dataTestId}
        disabled={disabled}
      >
        <ButtonContent
          isLoading={isLoading}
          icon={icon}
          hasBackgroundRipple={hasBackgroundRipple && !disabled}
        >
          {children}

          {Indicator ? (
            <span className={styles.indicator}>{Indicator}</span>
          ) : null}
        </ButtonContent>
      </button>
    )
  }
)

Button.displayName = 'Button'
