import { ANNUALLY } from '@constants/billingPeriods'
import { CurrencyType, PLN } from '@constants/currencies'
import {
  Badge,
  Icon,
  Paragraph,
  Price,
  Spacer,
  Spreader,
  Tooltip
} from '@ui-kit'
import { PriceDescription } from '@ui-kit/Price/PriceDescription'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

interface NormalPriceProps {
  planPrice: number
  currency: CurrencyType
  annualSaving: number
  billingPeriod: number
  isNotFreePlan: boolean
}

export const NormalPrice = ({
  planPrice,
  currency,
  annualSaving,
  billingPeriod,
  isNotFreePlan
}: NormalPriceProps) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Price amount={planPrice} currency={currency}>
        <PriceDescription>
          {currency === PLN && t('change.plan.page.net')}{' '}
          {t('change.plan.page.per.month')}
        </PriceDescription>
      </Price>

      {billingPeriod === ANNUALLY && isNotFreePlan && (
        <Fragment>
          <Tooltip content={t('change.plan.page.billed.once.annually.tooltip')}>
            <Paragraph size={14} color='neutral-6'>
              {t('change.plan.page.billed.once.annually')}

              <Spreader spread={5} />

              <Icon icon='icon-help_outline' color='neutral-4' />
            </Paragraph>
          </Tooltip>

          <Spacer space={5} />

          <Row>
            <Badge variant='warning-filled' weight={600}>
              {t('compare.plans.save', {
                saveValue: annualSaving,
                currency
              })}
            </Badge>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}
