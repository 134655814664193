import { Badge } from '@ui-kit/Badge'
import { LinkTab, TabList, Tabs } from '@ui-kit/Tabs'
import { FC } from 'react'

interface RouterTabsProps {
  routes: {
    title: string
    path: string
    isHidden?: boolean
    badge?: string
    className?: string
  }[]
}

export const RouterTabs: FC<RouterTabsProps> = ({ routes }) => {
  return (
    <Tabs initialTab={routes[0].title}>
      <TabList variant='border-line'>
        {routes.map(({ title, path, isHidden, badge, className }) =>
          !isHidden ? (
            <LinkTab name={title} to={path} key={title} className={className}>
              {title}{' '}
              {badge ? <Badge variant='success-filled'>{badge}</Badge> : null}
            </LinkTab>
          ) : null
        )}
      </TabList>
    </Tabs>
  )
}

RouterTabs.displayName = 'RouterTabs'
