import { useGetSupportMessage } from '@services/settings/useGetSupportMessage'

import { Message } from './components/Message'

export const SupportMessage = () => {
  const { data } = useGetSupportMessage()

  if (!data) {
    return null
  }

  return <Message {...data} />
}
