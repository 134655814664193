import { PLN } from '@constants/currencies'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * @param {string} - currency
 * @type function
 * @return returns netto text when currency is PLN
 */
const NettoPrice = ({ currency }) => {
  const { t } = useTranslation()

  return currency === PLN ? (
    <Fragment> {t('registration.flow.credit.card.step.net')}</Fragment>
  ) : null
}

NettoPrice.displayName = 'NettoPrice'

NettoPrice.propTypes = {
  currency: PropTypes.string.isRequired
}

export default NettoPrice
