import { useFeatureFlags } from '@contexts/featureFlags'
import { NotFound } from '@pages/NotFound/routes/NotFound'
import { FC, ReactElement } from 'react'

interface FeatureFlagProps {
  ff: string
  children: ReactElement
}

export const FeatureFlag: FC<FeatureFlagProps> = ({ ff, children }) => {
  const hasAccess = useFeatureFlags(ff)

  return hasAccess ? children : <NotFound />
}

FeatureFlag.displayName = 'FeatureFlag'
