import { useUserContext } from '@contexts/user'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export type AppcuesDataResponse = {
  userUuid: string
  created_at: { formatted: string; timestamp: number }
  expires_at: { formatted: string | null; timestamp: number }
  plan: string
  email: string
  language: string
  firstname: string
  platformStage: string
  experienceLevel: string
  signupPurpose: string
  signupGoal: string
  country: string
  region: string
}

export const useGetAppcuesData = () => {
  const { isAuth } = useUserContext()

  const { data, ...rest } = useSWR<AppcuesDataResponse, AxiosError>(
    isAuth ? 'web-analytic-tools/v1/appcues' : null
  )

  return { data, ...rest }
}
