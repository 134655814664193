import { useStyles } from '@hooks/useStyles'
import { FC, InputHTMLAttributes } from 'react'

import styles from './Checkbox.module.scss'

interface CheckboxProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'className' | 'type' | 'size'
  > {
  className?: string | string[]
  i18n?: {
    label: string
  }
  id: string
  checked?: boolean
  disabled?: boolean
}

export const Checkbox: FC<CheckboxProps> = ({
  className,
  i18n,
  id,
  checked = false,
  disabled = false,
  children,
  ...restProps
}) => {
  const checkboxStyles = useStyles(
    {
      [styles.checkbox]: true,
      [styles['checkbox--disabled']]: disabled
    },
    className
  )

  return (
    <label className={checkboxStyles} htmlFor={id}>
      <input
        {...restProps}
        className={styles.checkbox__input}
        type='checkbox'
        id={id}
        checked={checked}
        disabled={disabled}
      />

      <span className={styles.checkbox__control} />

      {i18n?.label && (
        <span className={styles.checkbox__label}>{i18n.label}</span>
      )}

      {children}
    </label>
  )
}

Checkbox.displayName = 'Checkbox'
