import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import { UPGRADE_MODALS } from '@constants/modals'
import { useUserContext } from '@contexts/user'
import NiceModal from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import {
  emitTimingToastToggle,
  Heading,
  Toggle
} from '@landingi/landingi-ui-kit'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { togglePublicationState } from '@services/landings/publishingOptions'
import { Paragraph, Spacer, Spreader } from '@ui-kit'
import { AxiosError } from 'axios'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

export const Header = () => {
  const { t } = useTranslation()

  const { isSubaccount } = useUserContext()

  const {
    uuid,
    publishingOptions: {
      data: { isLandingPagePublished, ...restPublishingOptions }
    },
    landingName
  } = usePublishingOptionsContext()

  const { mutate } = useSWRConfig()

  const [handleTogglePublicationState, { isLoading }] = useLazyService(
    togglePublicationState,
    {
      onSuccess: () => {
        mutate(
          `landing-page/landings/${uuid}/publishing-options`,
          {
            isLandingPagePublished: !isLandingPagePublished,
            ...restPublishingOptions
          },
          {
            revalidate: false
          }
        )

        if (isLandingPagePublished) {
          emitTimingToastToggle(
            t('publishing.options.toast.unpublish'),
            'success'
          )
        } else {
          emitTimingToastToggle(
            t('publishing.options.toast.publish'),
            'success'
          )
        }
      },
      onError: error => {
        const { response } = error as AxiosError<{
          error: {
            code: string
            message: string
          }
        }>

        const code = response?.data?.error?.code

        // LP0004 - publish limit
        if (code === 'LP0004') {
          if (isSubaccount) {
            NiceModal.show(MODAL_UPGRADE_PLAN, {
              upgradeType: UPGRADE_MODALS.SUBACCOUNT_PUBLISH_LIMIT
            })
          } else {
            NiceModal.show(MODAL_UPGRADE_PLAN, {
              upgradeType: UPGRADE_MODALS.PUBLISH_LIMIT
            })
          }
        }
      }
    }
  )

  const changeStatus = () => {
    handleTogglePublicationState({
      uuid
    })
  }

  return (
    <Fragment>
      <Fragment>
        <Row>
          <Paragraph size={16} color='neutral-6'>
            {landingName}
          </Paragraph>
        </Row>

        <Spacer space={10} />
      </Fragment>

      <Row horizontal='space-between' vertical='center'>
        <Heading level={1}>{t('word.publishing.options')}</Heading>

        <Row vertical='center'>
          <Column>
            <Paragraph size={16} weight={600}>
              {t(
                isLandingPagePublished
                  ? 'publishing.options.header.published'
                  : 'publishing.options.header.unpublished'
              )}
            </Paragraph>
          </Column>

          <Spreader spread={10} />

          <Toggle
            id='publishingOptions'
            name='publishingOptions'
            checked={isLandingPagePublished}
            onChange={changeStatus}
            isLoading={isLoading}
            disabled={isLoading}
            data-testid='publishing-options-toggle'
          />
        </Row>
      </Row>
    </Fragment>
  )
}

Header.displayName = 'PublishingOptionsViewsMainHeader'
