import './Toastify.scss'

import { mapIconToClass } from '@helpers/icons'
import { Button } from '@ui-kit/Button'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spreader } from '@ui-kit/Spreader'
import type { ToastType } from '@ui-kit/Toast/types'
import { FC, ReactNode } from 'react'

import styles from './Toast.module.scss'

export interface ToastProps {
  children: ReactNode
  type: ToastType
  onCloseClick?: () => void
}

export const Toast: FC<ToastProps> = ({ children, type, onCloseClick }) => (
  <div className={styles.toast__body} data-testid='toast'>
    <div className={styles.toast__body__content}>
      <Icon
        className={styles.toast__body__icon}
        icon={mapIconToClass(type)}
        size={14}
        color='white'
      />

      <Spreader spread={15} />

      <Paragraph size={14} color='white'>
        {children}
      </Paragraph>
    </div>

    <Button
      variant='white'
      size={12}
      icon='icon-remove'
      onClick={onCloseClick}
      data-testid='toast-close'
    />
  </div>
)

Toast.displayName = 'Toast'
