import { SignInButton } from '@components/MicrosoftButtons/MicrosoftSignIn'
import { Heading, Paragraph, Spacer } from '@landingi/landingi-ui-kit'
import { useWhitelabelPageContext } from '@pages/Authentication/Layouts/WhitelabelPage/Context'
import { REGISTRATION } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Column } from 'simple-flexbox'

import LoginForm from './Form'
import styles from './Layout.module.scss'

const Login = () => {
  const { getWhitelabelPageConfig } = useWhitelabelPageContext()
  const { mainColor, hasMicrosoftSignInButton } = getWhitelabelPageConfig()
  const { t } = useTranslation()
  const { clientName } = useParams()

  if (hasMicrosoftSignInButton) {
    return (
      <Column className={styles['login-layout']}>
        <Heading level={1} bold align='center'>
          {t('registration.flow.sign.in.to.account')}
        </Heading>

        <Spacer space='medium' />

        <SignInButton customColor={mainColor} />

        <Spacer space='mini' />

        <Spacer space='tiny' />
      </Column>
    )
  }

  return (
    <Column className={styles['login-layout']}>
      <Heading level={1} bold align='center'>
        {t('registration.flow.sign.in.to.account')}
      </Heading>

      <Spacer space='medium' />

      <LoginForm />

      <Spacer space='mini' />

      <Spacer space='tiny' />

      <Paragraph size={12} color='accent-2' align='center' padding='none'>
        <Link
          to={`${REGISTRATION.FORGOT_PASSWORD.DEFAULT}/${clientName}`}
          style={{ color: mainColor }}
        >
          {t('registration.flow.forgotten.password')}
        </Link>
      </Paragraph>
    </Column>
  )
}

Login.displayName = 'WhitelabelLogin'

export default Login
