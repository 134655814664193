import { KeyboardKey } from '@constants/keyboardKey'
import { useKeyPress } from '@hooks/useKeyPress'
import { useStyles } from '@hooks/useStyles'
import {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo
} from 'react'

import { ModalContext } from './context'
import styles from './Modal.module.scss'

export type ModalSize = 420 | 655 | 850 | 1070 | 'fullscreen'

export type ModalLevel = 1 | 2 | 3

export interface ModalProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'className'> {
  children: ReactNode
  className?: string | string[]
  /** @default false */
  isCentered?: boolean
  /** @default 'medium' */
  size?: ModalSize
  onClose: () => void
  /** @default 1 */
  level?: ModalLevel
  'data-testid'?: string
}

export const Modal: FC<ModalProps> = ({
  children,
  className = '',
  isCentered = false,
  size = 655,
  onClose,
  level = 1,
  'data-testid': dataTestId = 'modal',
  ...restProps
}) => {
  const modalStyles = useStyles(
    {
      [styles.modal]: true,
      [styles[`modal--size-${size}`]]: !!size
    },
    className
  )

  const modalBackdropStyles = useStyles({
    [styles.modal__backdrop]: true,
    [styles[`modal__backdrop--level-${level}`]]: !!level
  })

  const modalCtx = useMemo(
    () => ({ isCentered, onClose, isFullscreen: size === 'fullscreen' }),
    [isCentered, onClose, size]
  )

  const cancelPropagation: MouseEventHandler = useCallback(
    e => e.stopPropagation(),
    []
  )

  useKeyPress(KeyboardKey.Escape, onClose)

  return (
    <ModalContext.Provider value={modalCtx}>
      <div
        className={modalBackdropStyles}
        onClick={onClose}
        data-testid='modal-backdrop'
      >
        <div
          {...restProps}
          className={modalStyles}
          data-testid={dataTestId}
          onClick={cancelPropagation}
        >
          {children}
        </div>
      </div>
    </ModalContext.Provider>
  )
}

Modal.displayName = 'Modal'
